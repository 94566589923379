// fp-clipboard
//
// Usage:
//
// Simple usage:
// <input v-fp-clipboard>
//
// Trigger method when copy is successful:
// <input v-fp-clipboard="{'onCopy': onCopyMethod}">

import Vue from 'vue'

Vue.directive('fpClipboard', {
  bind (element, { value }) {
    element.addEventListener('click', function (event) {
      let textarea
      switch (element.nodeName) {
        case 'TEXTAREA':
        case 'INPUT':
          document.execCommand('selectAll', false, null)
          document.execCommand('copy', false, null)
          break
        default:
          textarea = document.createElement('textarea')
          textarea.value = element.innerText
          element.appendChild(textarea)
          textarea.select()
          document.execCommand('copy')
          element.removeChild(textarea)
          break
      }
    })
    element.addEventListener('copy', function () {
      if (!value) return
      if (value.onCopy && (typeof value.onCopy === 'function')) {
        value.onCopy()
      }
      if (value.onCopy && value.onCopy === true) {
        const message = document.createElement('div')
        message.classList.add('clipboard-message')
        message.innerHTML = Vue.prototype.$t('clipboard.copy.done')
        element.style.position = 'relative'
        element.appendChild(message)
        setTimeout(_ => {
          element.removeChild(message)
        }, 3000)
      }
    })
  }
})
