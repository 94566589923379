import Vue from 'vue'
import stream from 'stream'

class LogStream extends stream.Readable {
  constructor (jobId, execId, service) {
    super()
    this.jobId = jobId
    this.service = service
    this.execId = execId
    this.ended = false
    const request = {
      method: 'logs_subscribe',
      data: {
        job_id: jobId,
        execution_id: execId,
        service
      }
    }
    this.onMessage = this.onMessage.bind(this)
    Vue.WebSockets.subscribe(`dpe.logs.${this.jobId}${this.execId ? `.${this.execId}` : ''}`, this.onMessage)
    Vue.WebSockets.send('DPE', request)
  }

  onMessage ({ data }) {
    if (!this.ended) {
      data.log.split('\n').forEach(line => {
        this.push(Buffer.from(line, 'utf-8'))
      })
    }
  }

  /* istanbul ignore next */
  _read () {
  }

  close () {
    const request = {
      method: 'logs_unsubscribe',
      data: {
        job_id: this.jobId,
        execution_id: this.execId,
        service: this.service
      }
    }
    Vue.WebSockets.send('DPE', request)
    Vue.WebSockets.unsubscribe(`DPE_LOGS_${this.jobId}${this.execId ? `_${this.execId}` : ''}`, this.onMessage)
    this.ended = true
  }
}

export default LogStream
