import Vue from 'vue'

export default {
  async get () {
    this.cache = this.cache || Vue.api.KING.users.me()
    const { token } = await this.cache
    return token
  },
  async delete () {
    this.cache = null
  }
}
