<template lang="pug">
.add-filter
  fp-loading(v-if="loading")
  .add-filter-container(v-else)
    fpui-button(
      v-show="!showAddFilter"
      color="grey"
      reverse
      icon-left
      noshadow
      unbordered
      autoWidth
      icon="fp4 fp4-plus"
      :disabled="!attributes.length"
      @click="open"
    ) {{ $t('query.filter.add') }}

    .add-filter-selection-container(
      v-show="showAddFilter"
    )
      i.fp4.fp4-gear
      span.label {{ $t('dashboarding.choose_filter') }}
      i.fp4.fp4-xmark(
        @click="close()"
      )

      .add-filter-selection
        .search
          fpui-input-search(
            ref="search"
            v-model="search"
            autofocus
          )
        .attributes-list(
          :style="{ height: attributesFiltered.length < 6 ? 'auto' : '216px', 'overflow-y': attributesFiltered.length < 6 ? 'hidden' : 'auto' }"
        )
          .attribute(
            v-for="att in attributesFiltered"
            v-tooltip="{ content: att, classes: ['no-max-width'] }"
            @click="addFilter(att)"
          )
            span {{ att }}
          .attribute.empty(
            v-if="!attributesFiltered.length"
          )
            span {{ $t('dashboard.filters.no_attributes') }}
</template>

<script>
import _intersection from 'lodash/intersection'
import _uniq from 'lodash/uniq'
import Config from '@/shared/Config'

export default {
  props: {
    item: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      showAddFilter: false,
      search: '',
      loading: false,
      config: null
    }
  },
  computed: {
    metasTables () {
      return this.$store.getters.DWH_METAS_TABLES
    },
    diamondsWithVirtualAttributes () {
      return this.$store.getters.DWH_DIAMONDS_WITH_VA
    },
    allAttributes () {
      return [...this.$store.getters.DWH_ALL_ATTRIBUTES].sort()
    },
    fullAttributes () {
      return this.$store.getters.DWH_ATTRIBUTES_FULL || {}
    },
    attributes () {
      if (!this.item.tiles || !this.item.tiles.length) return []

      let attrs = []
      this.item.tiles.forEach(tile => {
        const query = this.$store.getters.QB_QUERY_BY_SHARED_ID(this.item.repository, tile.query, this.item.repository_version) || {}
        let configuration = query?.configuration

        if (query?.type === 'sql') {
          const from = configuration?.sql.includes('FROM') ? 'FROM ' : 'from '
          const table = configuration?.sql?.split(from)[1]?.split(' ')[0]
          configuration = {
            table_name: table
          }
        }

        attrs = attrs.concat(this.getCompatiblesAttributes(configuration))
      })

      return _uniq(attrs).filter(att => !this.filtersAlreadySet.includes(att)).sort()
    },
    attributesFiltered () {
      if (!this.search) return this.attributes
      return this.attributes.filter(att => att.toLowerCase().includes(this.search.toLowerCase()))
    },
    filtersAlreadySet () {
      return Object.keys(this.item?.filter || {})
    }
  },
  async mounted () {
    this.config = await Config()
    window.addEventListener('click', this.close)
  },
  destroyed () {
    window.removeEventListener('click', this.close)
  },
  methods: {
    isParent (target) {
      if (target === this.$el) return true
      if (target.parentNode) return this.isParent(target.parentNode)
      return false
    },
    close ($event) {
      if ($event && this.isParent($event.target)) return
      this.showAddFilter = false
      this.search = ''
    },
    open () {
      if (!this.showAddFilter) {
        this.$analytics.track('Add dashboard filter', {
          organization_id: this.config?.ORGANIZATION_ID,
          shared_id: this.item.shared_id
        })
        this.showAddFilter = true
        process.nextTick(_ => {
          if (this.$refs.search?.setInputFocus) this.$refs.search.setInputFocus()
        })
      } else {
        this.close()
      }
    },
    async addFilter (attribute) {
      this.loading = true
      this.close()

      try {
        const queryName = attribute
        let query = this.$store.getters.QB_QUERIES.find(q => q.tags?.path === `Automatically saved from dashboards/${this.item.name}/Filters` && q.display_name === queryName)

        if (!query) {
          query = this.$api.QUERY_ADMIN.queries.new({
            tags: {
              path: `Automatically saved from dashboards/${this.item.name}/Filters`
            },
            repository: this.$store.getters.QB_REPOSITORY_ACTIVE?._id,
            repository_version: this.$store.getters.QB_REPOSITORY_ACTIVE?.editingVersion?._id,
            name: `${this.item.name}_${queryName}`,
            display_name: queryName,
            configuration: {
              data: {
                fields: {
                  [attribute]: ['select_distinct']
                }
              }
            }
          })
          query = await query.create()
          this.$store.commit('FOREPAAS_QB_QUERY_CREATE', query)
        }

        this.item.update('filter', {
          ...this.item.filter,
          [attribute]: {
            type: this.getFilterType(attribute),
            query: query.shared_id
          }
        })
      } catch (err) {
        console.error(err)
        this.$fpuiMessageBlock.error(err)
      }
      this.loading = false
    },
    getFilterType (attribute) {
      const type = this.fullAttributes[attribute]?.type?.main
      if (!type) return 'categorical'

      if (['date'].includes(type)) return 'date'
      if (['datetime'].includes(type)) return 'datetime'
      if (['bool'].includes(type)) return 'boolean'

      return 'categorical'
    },
    getCompatiblesAttributes (queryConfiguration) { // Also used in Tile.vue, AdvancedFiltersModal.vue, QueryFields.vue
      if (!queryConfiguration) return []
      /* Hide incompatible fields vs selected fields */

      // If table is forces, get only attributes from this table
      if (queryConfiguration.table_name) {
        const tableForced = this.metasTables.find(table => table.name === queryConfiguration.table_name)
        const attributesTableForced = this.$store.getters.DWH_ATTRIBUTES_BY_TABLE_ID_BY_ID(tableForced?._id).map(att => att.name)

        const diamonds = this.diamondsWithVirtualAttributes.find(diamond => diamond.name === queryConfiguration.table_name)

        return diamonds?.attributes || attributesTableForced
      } else {
        // Get Data, Scales and Filters fields selected
        let dataScaleFilterOrder = []
        Object.keys(queryConfiguration).forEach(val => {
          if (val === 'filter' && queryConfiguration[val]) {
            dataScaleFilterOrder = [...dataScaleFilterOrder, ...Object.keys(queryConfiguration[val])]
          }
          if (val === 'scale' && queryConfiguration[val]?.fields && queryConfiguration[val]?.fields.length > 0) {
            dataScaleFilterOrder = [...dataScaleFilterOrder, ...queryConfiguration[val].fields]
          }
          if (val === 'data' && queryConfiguration[val]?.fields) {
            dataScaleFilterOrder = [...dataScaleFilterOrder, ...Object.keys(queryConfiguration[val].fields)]
          }
          if (val === 'order' && queryConfiguration[val]) {
            dataScaleFilterOrder = [...dataScaleFilterOrder, ...Object.keys(queryConfiguration[val])]
          }
        })

        if (dataScaleFilterOrder.length > 0 && this.diamondsWithVirtualAttributes.length) {
          let diamonds = []

          // If field(s) selected ...
          if (dataScaleFilterOrder.length === 1) {
            diamonds = this.diamondsWithVirtualAttributes.filter(diamond => diamond.attributes.includes(dataScaleFilterOrder[0]))
          } else {
            const diamondsNameList = []
            dataScaleFilterOrder.forEach(dsf => {
              // For each fields selected, get a list of diamond's name they are into
              const diamond = this.diamondsWithVirtualAttributes.filter(diamond => diamond.attributes.includes(dsf))
              const diamondsNames = []
              diamond.forEach(d => {
                diamondsNames.push(d.name)
              })
              diamondsNameList.push(diamondsNames)
            })
            let diamondNameCommon = []
            diamondsNameList.forEach((t, idx) => {
              // Get the diamond's name in common between the list of names we get previously
              if (idx === 0) {
                diamondNameCommon = t
              } else {
                diamondNameCommon = _intersection(diamondNameCommon, t)
              }
            })
            // Get the diamonds with compatible fields from from the names
            diamonds = this.diamondsWithVirtualAttributes.filter(diamond => diamondNameCommon.includes(diamond.name))
          }

          if (diamonds.length > 0) {
            let allFields = []
            diamonds.forEach(diamond => {
              allFields = [...allFields, ...diamond.attributes]
            })

            return this.allAttributes.filter(field => allFields.includes(field))
          } else {
            return Object.keys(queryConfiguration.data.fields) || []
          }
        } else {
          return this.allAttributes
        }
      }
    }
  }
}
</script>

<style lang="less">
.add-filter {
  position: relative;
  .add-filter-selection-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: 1px solid #E4E7EC;
    border-radius: 5px;
    background: white;
    height: 32px;
    padding: 10px;

    .fp4-gear {
      color: #97A7B7;
      font-size: 16px;
      margin-right: 6px;
    }
    .label {
      color: #748294;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      margin-right: 15px;
      padding-bottom: 0;
    }
    .fp4-xmark {
      color: #CBD3DB;
      font-size: 16px;
      cursor: pointer;

      &:hover {
        color: #0089c0;
      }
    }

    .add-filter-selection {
      position: absolute;
      top: 36px;
      left: 0;
      background: white;
      width: 294px;
      box-shadow: 0px 3px 13px rgba(151, 167, 183, 0.3);
      border-radius: 7px;
      z-index: 1000;

      .search {
        width: 100%;
        height: 52px;
        padding: 10px;
        border-bottom: 1px solid#F6F9FC;
      }

      .attributes-list {
        .attribute {
          height: 44px;
          font-weight: 400 !important;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.01em;
          color: #3E4550;
          display: flex;
          align-items: center;
          padding: 0 20px;
          cursor: pointer;

          span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          &.empty {
            font-style: italic;
            color: #97a7b7;
          }

          &:hover {
            background: rgba(246, 249, 252);
            box-shadow: inset 0px -1px 0px #F6F9FC;
            font-weight: 400;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
