/* istanbul ignore file */
import Config from '@/shared/Config'
import Socket from '@/shared/store/socket'
import Session from '@/shared/store/session'
import axios from 'axios'
import { ConcurrencyManager } from 'axios-concurrency'
import jsonToFormData from 'json-form-data'
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash/debounce'
import _get from 'lodash/get'
import _isEqual from 'lodash/isEqual'
import _omit from 'lodash/omit'
import _pick from 'lodash/pick'
import Vue from 'vue'
import { parseString } from 'xml2js'
import TokenInjector from './TokenInjector'

ConcurrencyManager(axios, 8)

const later = async (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

class API {
  constructor (ID, disableWebsocket = false, cmpTokenInjector = false) {
    this.xhrID = ID
    this.__disable_websocket = disableWebsocket
    this.__cmp_token_injector = cmpTokenInjector
  }

  needToReconnect (err, cmp) {
    if (err.error_code === 'INVALID_SESSION') return cmp
    return err.message === 'Authentication Error' ||
      (typeof err.code === 'string' && err.code.toLowerCase().replace(/ /g, '') === 'invalidtoken') ||
      err.message.toLowerCase().replace(/ /g, '') === 'invalidtoken'
  }

  needRetry (err) {
    return err.status === 401 || err.message === 'Authentication Error' || err.code === 'Network Error' || err.code === 'ECONNABORTED'
  }

  async parseXml (data) {
    return new Promise((resolve, reject) => {
      if (typeof (data) === 'object') return resolve(data)
      parseString(data, (err, result) => {
        if (err) {
          return reject(err)
        }
        return resolve(result)
      })
    })
  }

  async requestXML (options) {
    options.responseType = 'text'
    try {
      const response = await axios(options)
      if (response?.data) response.data = await this.parseXml(response.data)
      return response
    } catch (err) {
      if (err.response?.data) err.response.data = await this.parseXml(err.response.data)
      throw err
    }
  }

  async paginateListing (options = {}, skipIndex = 0, items = []) {
    options.originalResponse = true
    options.params = options.params || {}
    options.params.skip = skipIndex
    options.params.limit = 1000

    const { status, headers, data } = await this.request(options)
    items = items.concat(data)
    if (options.onProgress) options.onProgress(items)
    if (status === 206 && headers['content-range']) {
      const [, contentRange] = headers['content-range'].split(' ')
      let [current, hint] = contentRange.split('/')
      hint = parseInt(hint)
      const [, end] = current.split('-')
      if (parseInt(end) < hint) return this.paginateListing(options, skipIndex + 1000, items)
    }
    return items
  }

  async request (options, retryCount = 0) {
    const config = await Config()
    options.baseURL = options.baseURL || (config)[this.xhrID]
    options.withCredentials = true
    options.timeout = typeof (options.timeout) !== 'undefined' ? options.timeout : 60 * 1000
    options.headers = options.headers || {}
    if (this.__cmp_token_injector) {
      try {
        const token = await TokenInjector.get()
        const headersKey = typeof this.__cmp_token_injector === 'boolean' ? 'Authorization' : this.__cmp_token_injector
        options.headers[headersKey] = `Bearer ${token}`
      } catch (err) {
        console.error(err.stack)
      }
    }
    options.params = options.params || {}
    options.retries = isNaN(options.retries) ? 3 : options.retries
    options.retriesDelay = isNaN(options.retriesDelay) ? 1000 : options.retriesDelay
    if (!this.__disable_websocket && options.socketId !== false && Socket?.state?.socketId) {
      options.headers['Socket-Id'] = Socket?.state?.socketId
    }

    try {
      const response = options.responseType === 'xml' ? await this.requestXML(options) : await axios(options)
      if (options.originalResponse) return response
      return response.data
    } catch (err) {
      err.message = _get(err, 'response.data.message') || _get(err, 'response.data.Error.Message[0]') || _get(err, 'response.data.error') || err.message
      err.code = _get(err, 'response.data.error') || _get(err, 'response.data.Error.Code[0]') || err.code || err.message
      err.description = _get(err, 'response.data.description') || _get(err, 'response.data.content.error') || err.message
      err.status = _get(err, 'response.status') || err.status
      err.error_code = _get(err, 'response.data.error_code') || ''
      err.service = this.xhrID

      if (err.status === 401 && err.message?.toLowerCase().includes('mfa required')) {
        try {
          await Vue.api.KING.logout()
          throw err
        } catch (err) {
        // Error of logout should not block the leave of the app from UI
          console.error(err)
        }
      }

      if (options.retries > retryCount && (options.retryOn ? options.retryOn(err) : this.needRetry(err))) {
        console.info(`ProcessToARetryDueTo::${err.message}:Number:${retryCount + 1}`)
        await TokenInjector.delete()
        await later(options.retriesDelay)
        return this.request(options, retryCount + 1)
      }
      if (!options.noReconnect && this.needToReconnect(err, config.CMP)) {
        if (Session.state?.session) { Session.state.session = null }
        err.silent = true
        if (config.CMP) {
          document.location.href = config.FPUI
          throw err
        }
      }
      throw err
    }
  }

  async health (retries = null, retriesDelay = null) {
    return await this.request({
      url: 'health',
      retries,
      retriesDelay
    })
  }
}

class APIObject extends API {
  constructor (ID, options, disableWebsocket = false, cmpTokenInjector = false) {
    super(ID, disableWebsocket, cmpTokenInjector)
    this.assign(options)
    this.init()
  }

  init () {
    // Autosave configuration
    this.__saveQueue = {}
    this.__v = 0
    this.__backup = {}
    this.__saving = false
    this.__error = false
    this.__saveDebounced = _debounce(this.save, 1000)
  }

  get saving () {
    return this.__saving
  }

  get error () {
    return this.__error
  }

  get hasChanges () {
    return !!Object.keys(this._filter(this.__saveQueue)).length
  }

  get saveQueue () {
    return this.__saveQueue
  }

  get backupQueue () {
    return _pick(this.__backup, Object.keys(this.saveQueue))
  }

  refreshSaveQueue (key) {
    const saveKey = key.split(/[.\][]/).filter(v => v)[0]
    const saveValue = _get(this, saveKey)
    if (!_isEqual(this.__backup[saveKey], saveValue)) {
      Vue.set(this.__saveQueue, saveKey, saveValue)
    } else {
      Vue.delete(this.__saveQueue, saveKey)
    }
  }

  recursiveSet (obj, key, value) {
    const keys = key.split(/[.\][]/).filter(v => v)
    if (keys.length <= 1) return Vue.set(obj, key, value)
    const ck = keys.shift()
    Vue.set(obj, ck, obj[ck] || {})
    return this.recursiveSet(obj[ck], keys.join('.'), value)
  }

  update (key, value, autoSave = true) {
    if (_isEqual(_get(this, key), value)) return
    this.recursiveSet(this, key, value)
    this.refreshSaveQueue(key)
    if ((this.id || this._id) && autoSave) {
      this.__saving = true
      this.__saveDebounced()
    }
    this.__v++
  }

  updateAll (value, autoSave = true) {
    for (const key in value) {
      this.update(key, value[key], autoSave)
    }
    return this
  }

  delete (key, autoSave = true) {
    this.update(key, undefined, autoSave)
  }

  async create (options) {
    this.__saveQueue = {}
    this.__saving = true
    options.data = options.data || this.toJSON()
    try {
      const object = await this.request(options)
      if (object._id) this._id = object._id
      this.assign(object)
      return this
    } finally {
      this.__saving = false
    }
  }

  _filter (object) {
    return _omit(object, [
      'xhrID',
      '__v',
      '__saveQueue',
      '__saving',
      '__backup',
      '__saveDebounced',
      '__error',
      '__disable_websocket',
      '__cmp_token_injector'
    ])
  }

  assign (object) {
    Object.assign(this, object)
  }

  async save (options, returnResponse = false) {
    const saveQueue = this.__saveQueue
    this.__saveQueue = {}
    this.__saving = true
    options.data = options.data || this._filter(saveQueue)
    try {
      const object = await this.request(options)
      this.__error = false

      if (returnResponse) this.assign(object)

      return this
    } catch (err) {
      this.__saveQueue = saveQueue
      this.__error = err
      throw err
    } finally {
      this.__saving = false
    }
  }

  toJSON () {
    return this._filter(this)
  }

  toFormData () {
    const data = this.toJSON()
    const formData = jsonToFormData(data, {
      initialFormData: new FormData(),
      showLeafArrayIndexes: true,
      includeNullValues: false,
      mapping: function (value) {
        return value
      }
    })
    return formData
  }

  backup () {
    this.__backup = _cloneDeep(this._filter(this))
    return this.__backup
  }

  restore () {
    this.assign(this.__backup)
    this.__saveQueue = {}
  }
}

export default API
export {
  APIObject,
  API
}
