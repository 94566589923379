<template lang="pug">
.query-order
  .order-container(
    v-if="orderList.length"
    v-for="(o, i) in orderList"
  )
    .text {{ i === 0 ? $t('query.order.by') : $t('query.order.then') }}
    fpui-input-select.order-select(
      :clearable="false",
      :value="o.attribute"
      :options="attributesOptions"
      :disabled="readOnly"
      :width-fit="false"
      @input="v => update(i, v, 'attribute')"
    )
    type-selector(
      :value="o.order"
      @input="v => update(i, v, 'order')"
      :types="conds"
      :disabled="readOnly"
    )
    i.fp4.fp4-trash-can(@click="remove(i)")
  fpui-button.add-attribute(
    color="grey"
    @click="addOrder()"
    reverse
    icon-left
    noshadow
    unbordered
    autoWidth
    icon="fp4 fp4-plus"
    :disabled="!attributesAvailable.length || readOnly"
  ) {{ $t('query.order.add') }}
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import TypeSelector from './Selector/TypeSelector'

export default {
  components: { TypeSelector },
  props: {
    order: { type: Object, default: () => { return {} } },
    scales: { type: Array, default: () => [] },
    data: { type: Array, default: () => [] },
    attributes: { type: Array, default: () => [] },
    readOnly: { type: Boolean, default: false }
  },
  data () {
    return {
      conds: ['asc', 'desc']
    }
  },
  computed: {
    attributesOptions () {
      return this.attributesFromDataAndScale.map(a => ({ label: a, value: a, disabled: Object.keys(this.order).includes(a) }))
    },
    attributesFromDataAndScale () {
      return this.attributes.filter(a => this.data.includes(a) || this.scales.includes(a)).sort()
    },
    attributesAvailable () {
      return this.attributesFromDataAndScale.filter(a => !Object.keys(this.order).includes(a))
    },
    orderList () {
      const orderList = []
      for (const key in this.order) {
        orderList.push({
          attribute: key,
          order: this.order[key]
        })
      }

      return orderList
    }
  },
  methods: {
    update (index, value, type) {
      const newOrderList = _cloneDeep(this.orderList)
      newOrderList[index][type] = value

      const newOrder = {}
      newOrderList.forEach(o => {
        newOrder[o.attribute] = o.order
      })
      this.$emit('update', { key: 'order', value: newOrder })
    },
    addOrder () {
      const newOrderList = _cloneDeep(this.orderList)
      newOrderList.push({
        attribute: this.attributesAvailable[0],
        order: this.conds[0]
      })

      const newOrder = {}
      newOrderList.forEach(o => {
        newOrder[o.attribute] = o.order
      })
      this.$emit('update', { key: 'order', value: newOrder })
    },
    remove (index) {
      if (this.readOnly) return

      const newOrderList = _cloneDeep(this.orderList)
      newOrderList.splice(index, 1)

      const newOrder = {}
      newOrderList.forEach(o => {
        newOrder[o.attribute] = o.order
      })
      this.$emit('update', { key: 'order', value: newOrder })
    }
  }
}
</script>
<style lang="less">
.query-order {
  .order-container {
    display: flex;
    align-items: center;
    padding-bottom: 7px;
    .text {
      width: 40px;
      margin-right: 10px;
      color: @grey-chart;
      font-size: 14px;
    }
    .order-select {
      width: 100%;
      min-width: 150px;
      .dropdown {
        ul {
          max-height: 200px;
        }
      }
    }
    .type-selector {
      min-width: 60px;
      margin-right: 10px;
    }
    .fp4-trash-can {
      font-size: 20px;
      color: #B2BECA;
      cursor: pointer;
      &:hover {
        color: @red;
      }
      &.disabled {
        cursor: not-allowed;
        color: #dedede;
      }
    }
  }

  @media (min-width: 1350px) and (max-width: 1400px) {
    .order-container {
      .order-select {
        max-width: 150px;
      }
    }
  }
}
</style>
