<template lang="pug">
.dataplant-creation-step-footer
  .fpui-steps-footer
    .button-previous
      fpui-button.previous(
        v-if="!isFirst"
        auto-width
        color='white'
        icon="fp4 fp4-angle-left"
        icon-left
        type="button"
        @click="$emit('previous')"
      ) {{ $t('button.previous') }}
    .stepper
      .step(
        v-for="step in steps"
        :class="{ active: isActive(step) }"
      )
    fpui-button.next(
      v-if="!isLast"
      type="button"
      color="blue-flash"
      :disabled="disabledNext"
      @click="$emit('next')"
    ) {{ $t('button.next') }}
    fpui-button.send(
      v-if="isLast"
      auto-width
      color='green'
      icon="fp4 fp4-circle-check"
      icon-left
      type="button"
      :disabled="disabledNext"
      @click="$emit('next', isLast)"
    ) {{ $t('button.create_project') }}
</template>

<script>

export default {
  props: {
    disabledNext: {
      type: Boolean,
      default: false
    },
    // If enabled, it will display a footer dots bar
    stepper: {
      type: Boolean,
      default: true
    },
    steps: {
      type: Array,
      default: () => []
    },
    stepActive: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      loading: false,
      active: null // That internal object is use to store the active step object, it will be use for check activity
    }
  },
  computed: {
    /*
      Access to next step
      It will ignore a step if v-if set to false
      It will return null, if the last step is the active step
    */
    isLast () {
      return this.active === this.steps[this.steps.length - 1]
    },
    isFirst () {
      return this.active === 0
    }
  },
  mounted () {
    this.active = this.stepActive
  },
  methods: {
    // Accestor to check if a step is active
    isActive (step) {
      if (!step && step !== 0) return false
      if (!this.active && this.active !== 0) return false
      return this.active === step
    }
  }
}

</script>

<style lang="less">

.dataplant-creation-step-footer {

  > .fpui-steps-footer {
    display: flex;
    flex: 100%;
    height: 74px;
    width: 100%;
    border-top: 1px solid #E4E7EC;
    margin-top: 20px;
    padding: 20px;
    justify-content: space-between;

    .stepper {
      display:flex;
      margin: 20px 0;
      .step {
        background: #E4E8EC;
        width: 7px;
        height: 7px;
        margin-right: 10px;
        border-radius: 100%;
        cursor:pointer;
        &.disabled {
          cursor: not-allowed;
        }
        &.active {
          background: #00CCF9;
        }
      }
    }
  }
}

</style>
