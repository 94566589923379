<template lang='pug'>
  .fpui-tab-wrapper(v-show='active')
    .fpui-tab-content
      slot
</template>

<script>

export default {
  props: {
    header: { type: String, required: true },
    conditional: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    hide: { type: Boolean, default: false }
  },
  data () {
    return {
      active: false
    }
  }
}
</script>
