<template lang="pug">
.fp-container
  .fp-column
    .fp-box
      .box-title Filters

</template>

<script>

export default {
  data () {
    return {
      UserFilter: '{{ "5e05d00cc87205e201243fe5" | iamUser }}'
    }
  }
}
</script>

<style lang="less">

</style>
