import { render, staticRenderFns } from "./DataProjectTemplates.vue?vue&type=template&id=57be46c5&lang=pug&"
import script from "./DataProjectTemplates.vue?vue&type=script&lang=js&"
export * from "./DataProjectTemplates.vue?vue&type=script&lang=js&"
import style0 from "./DataProjectTemplates.vue?vue&type=style&index=0&id=57be46c5&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports