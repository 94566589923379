<template lang="pug">
  .auto-form-display
    fpui-input-label {{$t(label)}}
    .value(v-if="type!=='password'") {{value}}
    .value(v-else) *********
</template>

<script>
export default {
  props: {
    label: { type: String, default: '' },
    value: { type: [String, Boolean, Number, Object, Array], default: '' },
    type: { type: String, default: 'text' }
  },
  mounted () {
  }
}
</script>

<style lang="less">
.auto-form-display {
  .value {
    font-size: 11px;
    font-weight: 600;
    color: #97A7B7;
  }
}
</style>
