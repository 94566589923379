/* istanbul ignore file */
import API from '@/shared/plugins/Api/API'
import Config from '@/shared/Config'

class DWH extends API {
  async metas () {
    const config = await Config()
    return this.request({
      url: 'metas',
      params: {
        mode: 'full',
        dwhName: config.FPRN.replace('-', '_')
      }
    })
      .then(data => {
        const metas = data.metas
        const databases = {}
        const originalNames = []
        Object.keys(metas.databases).forEach(dbName => {
          const database = metas.databases[dbName]
          const name = `data_${database.level}`
          if (['prim', 'mart', 'ml'].includes(database.level)) {
            if (!databases[name]) {
              databases[name] = {
                ...database,
                name,
                database_name: name
              }
            }
            originalNames.push({ originalName: database.name, name })
          } else {
            databases[dbName] = database
          }
        })
        const tables = {}
        Object.keys(metas.tables).forEach(tableName => {
          const table = metas.tables[tableName]
          const dbName = tableName.split('/')[0]
          const remap = originalNames.find(originalName => originalName.originalName === dbName)
          if (remap) {
            const name = tableName.replace(dbName, remap.name)
            tables[name] = {
              ...table,
              name,
              database: table.database.replace(dbName, remap.name),
              database_name: remap.name
            }
          } else {
            tables[tableName] = table
          }
        })
        return {
          ...metas,
          databases,
          tables
        }
      })
  }

  async configurationList () {
    return await this.request({
      url: 'resources/list'
    }).then(data => data.results)
  }
}
export default DWH
