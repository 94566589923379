<template lang='pug'>
iframe(
  :src="url"
  :style="style"
  @load="iframeLoaded()"
)
</template>

<script>
import _uniqueId from 'lodash/uniqueId'
import _get from 'lodash/get'
import _debounce from 'lodash/debounce'
import qs from 'qs'

export default {
  props: {
    language: {
      type: String,
      default: 'json'
    },
    value: {
      required: true,
      type: String
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    focusOnLoaded: {
      type: Boolean,
      default: false
    },
    minimap: {
      type: Boolean,
      default: true
    },
    lineNumbers: {
      type: String,
      default: 'on'
    },
    wordWrap: {
      type: String,
      default: 'off'
    },
    codeCopyMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      uniqueId: _uniqueId()
    }
  },
  computed: {
    style () {
      if (this.$store.getters.RESIZING) {
        return {
          'pointer-events': 'none'
        }
      }
      return {}
    },
    queryString () {
      return {
        uniqueId: this.uniqueId,
        lang: this.$i18n.locale(),
        language: this.language,
        readOnly: this.readOnly,
        minimap: this.minimap,
        lineNumbers: this.lineNumbers,
        wordWrap: this.wordWrap,
        codeCopyMode: this.codeCopyMode
      }
    },
    url () {
      return `vs/Iframe.html?${qs.stringify(this.queryString)}`
    }
  },
  watch: {
    value (val) {
      this.updateIframe(val)
    }
  },
  created () {
    this.updateIframe = _debounce(this.updateIframe, 100)
    window.addEventListener('message', (event) => {
      if (_get(event, 'data.type') !== 'MonacoIframe') return
      if (_get(event, 'data.id') !== this.uniqueId) return

      // Custom binding cmd/Ctrl + Enter
      if (event.data.name === 'custom-run') {
        this.$emit('customRun')
        return
      }

      this[event.data.name](event.data)
    }, false)
  },
  methods: {
    iframeLoaded () {
      this.$emit('loaded')
      if (this.focusOnLoaded) this.focus()
    },
    focus () {
      this.$el.contentWindow.focus()
      this.$el.contentWindow.postMessage({ event: 'focus' }, window.location.origin)
    },
    updateIframe (val) {
      if (this.$el.contentWindow) {
        this.$el.contentWindow.postMessage({ event: 'content', content: val }, window.location.origin)
      }
    },
    ready () {
      this.updateIframe(this.value)
    },
    input (event) {
      this.$emit('input', event.data)
    }
  }
}
</script>

<style lang='less'>
iframe {
  width:100%;
  border:0;
  height: 100%;
}
</style>
