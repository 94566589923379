import { API, APIObject } from '@/shared/plugins/Api/API'
import _cloneDeep from 'lodash/cloneDeep'
import _omit from 'lodash/omit'
import _set from 'lodash/set'
import Vue from 'vue'
class Notebook extends APIObject {
  static list
  constructor (options) {
    // Init
    super('ML', options)

    // Default variables
    this.__lastStart = null
    this.tags = this.tags || {}
    this.description = this.description || ''
    this.tags.path = this.tags.path || ''
    this.tags.tags = this.tags.tags || []
    this.display_name = this.display_name || ''
    this.modules = this.modules || []
    this.starting = this.starting || false
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      '__lastStart',
      '__socketId',
      'tags.table',
      'tags.notebook_builder',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
      'starting',
      'build',
      'fppm',
      'path',
      'level',
      'cron',
      'type'
    ])
    return obj
  }

  clone () {
    return new Notebook(_cloneDeep(this.toJSON()))
  }

  async create () {
    return super.create({
      method: 'POST',
      url: 'v2/notebooks'
    })
  }

  async save () {
    return super.save({
      method: 'PUT',
      url: `v2/notebooks/${this._id}`
    })
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v2/notebooks/${this._id}`
    })
  }

  async start () {
    try {
      this.starting = true
      await this.request({
        method: 'POST',
        url: `v2/notebooks/${this._id}/start`
      })

      Vue.$analytics.track('Launch notebook job start', {
        instance_id: this._id,
        resources_allocated: this.fpu_configurations.instances * this.fpu_configurations.size,
        resources_type: 'DPU-S'
      })
    } catch (err) {
      // We handle 409, cause it should push the request into "started state"
      if (err.status !== 409) {
        throw err
      }
    } finally {
      this.starting = false
    }
  }

  async stop () {
    this.__lastStart = null
    Vue.$analytics.track('Launch notebook job end', {
      instance_id: this._id,
      execution_status: this.object?.fppm?.status,
      execution_time: this.object?.fppm?.status?.end_at ? this.object?.fppm?.status?.end_at - this.object?.fppm?.status?.start_at : 0
    })
    return this.request({
      method: 'DELETE',
      url: `v2/notebooks/${this._id}/stop`
    })
  }
}

class Notebooks extends API {
  list () {
    return this.request({
      method: 'get',
      url: 'v2/notebooks',
      params: {
        full: true
      }
    }).then(notebooks => {
      return notebooks.map(notebook => {
        try {
          return new Notebook(notebook)
        } catch (err) {
          console.error(err.stack)
          return null
        }
      }).filter(i => i)
    })
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Notebook(item)
  }
}
export {
  Notebook,
  Notebooks
}

export default Notebooks
