/* eslint-disable camelcase */
const Data = require('./JSONQuery/Data')
const Scale = require('./JSONQuery/Scale')
const Filter = require('./JSONQuery/Filter')
const Order = require('./JSONQuery/Order')
const Query = require('./Query')

class JSONQuery extends Query {
  constructor (options = {}) {
    super()
    this._ = {}
    this.data = options.data
    this.scale = options.scale
    this.filter = options.filter
    this.order = { orders: options.order, data: this.data }
    this.table_name = options.table_name
    this.query_id = options.query_id
    this.dashboard_id = options.dashboard_id
    this.source = options.source
    this.limit = options.limit
  }

  get sql () {
    return [
      this.data.toSQLStart(this.scale),
      `FROM ${this.table_name}`,
      this.filter.toSQL(),
      this.scale.toSQL(),
      this.order.toSQL(),
      this.data.toSQLEnd()
    ]
      .filter(e => e)
      .join('\n')
  }

  get table_name () {
    return this._.table_name
  }

  set table_name (value) {
    this._.table_name = value
  }

  get data () {
    return this._.data
  }

  set data (value) {
    this._.data = new Data(value)
  }

  get scale () {
    return this._.scale
  }

  set scale (value) {
    this._.scale = new Scale(value)
  }

  get filter () {
    return this._.filter
  }

  set filter (value) {
    this._.filter = new Filter(value)
  }

  get order () {
    return this._.order
  }

  set order (value) {
    this._.order = new Order(value)
  }

  get attributes () {
    return Object.keys(this.data.fields)
      .concat(this.scale.fields)
      .concat(this.filter.fields)
      .concat(this.order.fields)
  }

  async metas (context) {
    await context.Metas.load()
    // QB-1032
    // Virtual attributes have the priority over the Physical attributes
    // so `virtual_attributes` are placed at the begining of the final Array.
    this.filter.setAttributesMetas([...context.Metas.virtual_attributes, ...context.Metas.attributes])
    if (!this.table_name) {
      this.table_name = await context.Metas.findBestTableByAttributes(this.attributes).name
    }
  }

  async run (context, options = {}) {
    await this.metas(context)
    return await super.run(context, options)
  }

  toJSON () {
    return {
      data: this.data.toJSON(),
      scale: this.scale.toJSON(),
      order: this.order.toJSON(),
      filter: this.filter.toJSON(),
      table_name: this.table_name || undefined
    }
  }

  display () {
    return {
      sql: this.sql.replace(/(\r\n|\n|\r)/gm, ' '),
      tables: [this.table_name],
      results: this._data,
      query_params: this.toJSON(),
      table_name: this.table_name
    }
  }

  formatRow (data) {
    const res = {
      scales: {},
      data: {}
    }
    for (const field of this.scale.fields) {
      const idx = this.columns.findIndex(c => c.name === field || c.name === `v_${field}`)
      res.scales[field] = data[idx]
    }
    for (const field in this.data.fields) {
      for (const computeMode of this.data.fields[field]) {
        const idx = this.columns.findIndex(c => c.name === `${computeMode}_${field}`)
        res.data[field] = res.data[field] || {}
        res.data[field][computeMode] = [{
          ...res.scales,
          value: data[idx],
          type: this.columns[idx]?.typeSignature?.rawType
        }]
      }
    }
    return res
  }
}
module.exports = JSONQuery
