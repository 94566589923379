import API from '@/shared/plugins/Api/API'
import Assistants from './Assistants'
import KnowledgeBases from './KnowledgeBases'

class GAB extends API {
  constructor (id) {
    super(id, true)
    this.assistants = new Assistants(id)
    this.knowledgeBases = new KnowledgeBases(id)
  }

  async ready (url = null) {
    const baseURL = url ? `https://${url}/gab-control-plane/` : null
    // "READY" || "DEPLOYING"  || "NOT_DEPLOYED" || "UNKNOWN"
    return this.request({
      method: 'GET',
      url: 'v1/ready',
      retries: 0,
      baseURL
    })
  }
}

export default GAB
