<template lang="pug">
.dataplant-creation__modal-fullscreen
  fp-loader(v-show="loading || initialLoading")
  .fpui-modal(v-show="!initialLoading")
    fpui-steps(
      ref="dataplantCreation"
      :cancel="() => $emit('close')"
      :display-footer="false"
    )

      //- Template
      fpui-step(
        :description='$t("dataplant.creation.steps.provider.description")'
        :validate='() => validateTemplateSelection'
      )
        fpui-modal-header(
          :title="$t('home.dataplant.label.template_title')"
          @close='$emit("close")'
          closeTopButton
          unbordered
        )
          .subtitle
            span {{ $t('dataplant.creation.template.title') }}
        fpui-modal-body
          provider-selection-map(:options='options')
          project-template-selector(
            :options='options'
            :organization-id='organizationId'
            :disabled-next='!validateTemplateSelection'
            @update='updateValue'
            @previous="previous"
            @next="next"
          )

      //- Provider
      fpui-step(
        :description='$t("dataplant.creation.steps.provider.description")'
        :validate='() => validateProviderSelection'
      )
        fpui-modal-header(
          :title="$t('home.dataplant.label.new_dataplant')"
          @close='$emit("close")'
          closeTopButton
          unbordered
        )
          .subtitle
            span {{ $t('dataplant.creation.provider.title') }}
        fpui-modal-body
          provider-selection-map(
            v-if='options.providers.length'
            :options='options'
            @update='updateValue'
          )
          provider-selection-choices(
            v-if="displayProviderChoices"
            :options='options'
            :organization-id='organizationId'
            @update='updateValue'
          )
          provider-selection-personalize(
            :options='options'
            :organization-id='organizationId'
            :disabled-next='!validateProviderSelection'
            @update='updateValue'
            @previous="previous"
            @next="next"
          )

      //- Summary
      fpui-step(
        :description='$t("dataplant.creation.steps.summary.description")'
      )
        fpui-modal-header(
          :title="$t('home.dataplant.label.new_dataplant')"
          @close='$emit("close")'
          closeTopButton
          unbordered
        )
          .subtitle
            span {{ $t('dataplant.creation.summary.title') }}
        fpui-modal-body
          provider-selection-map(:options='options' :read-only='true')
          dataplant-creation-summary(
            :options='options'
            @previous="previous"
            @next="onComplete"
          )

</template>

<script>
import _get from 'lodash/get'
import _set from 'lodash/set'
import _find from 'lodash/find'
import Config from '@/shared/Config'
// Template component
import ProjectTemplateSelector from '@/shared/components/project/DataplantCreationFullscreen/ProjectTemplateSelector.vue'
// ProviderSelection components
import ProviderSelectionChoices from '@/shared/components/project/DataplantCreationFullscreen/ProviderSelection/ProviderSelectionChoices'
import ProviderSelectionMap from '@/shared/components/project/DataplantCreationFullscreen/ProviderSelection/ProviderSelectionMap'
import ProviderSelectionPersonalize from '@/shared/components/project/DataplantCreationFullscreen/ProviderSelection/ProviderSelectionPersonalize'
// Summary components
import DataplantCreationSummary from '@/shared/components/project/DataplantCreationFullscreen/Summary'

export default {
  components: {
    ProjectTemplateSelector,
    ProviderSelectionChoices,
    ProviderSelectionMap,
    ProviderSelectionPersonalize,
    DataplantCreationSummary
  },
  props: {
    organizationId: { type: String, required: true },
    afterConfirm: { type: Function, default: null },
    template: { type: Object, default: () => {} },
    presetOptions: { type: Object, default: () => {} }
  },
  data () {
    return {
      loading: false,
      initialLoading: true,
      options: {
        providers: [],
        engines: ['shared', 'default'],
        instances: [],
        domainAvailable: null,
        defaultStorageEngineConfigurations: {
          default: {},
          shared: {
            data_mart: 'shared',
            data_prim: 'shared',
            data_ml: 'shared'
          }
        },
        configuration: {
          storageEngine: {
            engine: 'default',
            instance: null,
            configuration: null
          },
          dataplant: {
            name: '',
            description: '',
            tags: [],
            domain: '',
            subdomain: '',
            provider: null,
            region: null
          }
        },
        template: { id: '' },
        module: ''
      },
      displayProviderChoices: true
    }
  },
  computed: {
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    subscription () {
      return this.organization.subscription
    },
    isFreeTrial () {
      return this.subscription?.status === 'in_trial' && this.subscription?.scheduled_status === 'cancelled'
    },
    isStandardPlan () {
      return this.subscription?.plan === 'standard'
    },
    canUseCurrentProvider () {
      const provider = this.options.configuration.dataplant.provider
      if (provider !== 'automatic' && (this.isFreeTrial || this.isStandardPlan)) return false
      return true
    },
    validateTemplateSelection () {
      return this.options.template.id !== ''
    },
    validateProviderSelection () {
      const conf = this.options.configuration.dataplant
      return conf.name !== '' &&
        conf.domain &&
        conf.subdomain.length > 2 &&
        conf.provider &&
        this.options.domainAvailable &&
        this.canUseCurrentProvider &&
        (conf.provider === 'automatic' || conf.region)
    }
  },
  beforeMount () {
    if (this.presetOptions) {
      this.options = this.presetOptions
    }
  },
  async mounted () {
    const config = await Config()
    try {
      await Promise.allSettled([
        this.$store.dispatch('REFRESH_STORAGE_CONFIGURATIONS_BY_ORGANIZATION', this.organizationId),
        this.$store.dispatch('REFRESH_STORAGE_ENGINES_BY_ORGANIZATION', this.organizationId),
        this.$store.dispatch('REFRESH_STORAGE_INSTANCES_BY_ORGANIZATION', this.organizationId),
        this.$store.dispatch('REFRESH_PROVIDERS_BY_ORGANIZATION', this.organizationId),
        this.$store.dispatch('LOAD_OFFERS')
      ].map(async action => {
        await action
      }))

      this.options.providers = this.$store.getters.PROVIDERS_BY_ORGANIZATION_ID(this.organizationId)

      if (!config?.DATA_PLATFORM_ACCESS) {
        // Automatic provider for free trial and standard plan
        // Unshift is used to add elements to the beginning of an array
        this.options.providers.unshift({
          active: true,
          display_options: {
            display_name: this.$t('dataplant.creation.automatic_provider')
          },
          name: 'automatic',
          options: null,
          status: 'available',
          regions: []
        })
      } else {
        this.displayProviderChoices = false
      }

      this.options.instances = this.$store.getters.STORAGE_INSTANCES_BY_ORGANIZATION_ID(this.organizationId)
      const storageConfigurations = this.$store.getters.STORAGE_CONFIGURATIONS
      const storageEngineConfiguration = storageConfigurations.find(sc => sc?.organization_id === this.organizationId)
      this.options.defaultStorageEngineConfigurations.default = storageEngineConfiguration?.configuration || {}
      this.options.configuration.storageEngine.configuration = storageEngineConfiguration?.configuration || {}

      await this.organization.loadSubscription(true)
      // Needed in the case transfered here through quickProjectCreation so that the chosen region isn't reset
      if (!this.presetOptions) {
        // Defaults to automatic provider when in free trial or paid standard plan
        if ((this.subscription?.status === 'in_trial' && this.subscription?.scheduled_status === 'cancelled') || (this.subscription?.plan === 'standard')) {
          this.options.configuration.dataplant.provider = 'automatic'
        } else {
          const ovhProvider = this.options.providers.find(p => p.name === 'ovh')
          const ovhRegion = _get(ovhProvider, 'regions[0]')
          if (ovhProvider && ovhRegion) {
            this.options.configuration.dataplant.provider = 'ovh'

            this.options.configuration.dataplant.region = {
              id: ovhRegion.name,
              provider: ovhRegion.provider,
              providerName: ovhProvider.display_options?.display_name,
              regionName: ovhRegion.display_options.display_name
            }
          } else {
            this.options.configuration.dataplant.provider = 'automatic'
          }
        }
      }
      this.options.configuration.storageEngine.engine = 'shared'
      this.initialLoading = false
      if (this.template) {
        this.updateValue({ template: this.template })
        this.next()
      }
    } catch (err) {
      this.$fpuiMessageBlock.error(err)
      console.error(err)
    }
  },
  methods: {
    // Creates the project with the clients inputed data
    async onComplete () {
      try {
        this.loading = true
        if (!this.subscription || this.subscription.error) throw new Error(this.$t('dataplant.creation.no_sub'))

        if (this.options.configuration.storageEngine.instance) {
          // When the user has created a storage engine instance from the Dataplant Creation UI,
          // begin the creation on Dataplant creation confirmation
          if (this.options.configuration.storageEngine.instance.startsWith('tmp_instance-')) {
            const instanceFromList = _find(this.options.instances, { tmpId: this.options.configuration.storageEngine.instance })
            const createdInstance = await instanceFromList.create()
            this.$analytics.track('Storage Engine Created', {
              instance_id: createdInstance.id,
              cloud_provider_name: createdInstance.provider,
              cloud_provider_location: createdInstance.region,
              resources_allocated: createdInstance.fpu,
              resources_type: createdInstance.fpu_type,
              engine: createdInstance.engine,
              origin: 'Dataplant',
              high_availability: createdInstance.ha
            })
            this.updateValue({
              'configuration.storageEngine.configuration': {
                data_mart: createdInstance.id,
                data_prim: createdInstance.id,
                data_ml: createdInstance.id
              }
            })
          }
        }

        const provider = this.options.configuration.dataplant.provider
        const config = await Config()
        const storePackageUrls = [`${config.STORE}/v1/packages/project/templates/${this.options.template.id}/latest`]

        // Regroups all the data to create the project
        const newProject = {
          organization_id: this.organization._id,
          name: this.options.configuration.dataplant.name,
          description: this.options.configuration.dataplant.description,
          domain: this.options.configuration.dataplant.domain,
          subdomain: this.options.configuration.dataplant.subdomain,
          provider: provider === 'automatic' ? this.$store.getters.OFFERS.standard.provider : provider,
          region: provider === 'automatic' ? this.$store.getters.OFFERS.standard.region : this.options.configuration.dataplant.region?.id,
          storageEngine: this.options.configuration.storageEngine.configuration,
          plan: this.subscription.plan,
          datatank: 50,
          store_package_urls: storePackageUrls,
          tags: this.options.configuration.dataplant.tags
        }
        // Serves to track the clients use of the project creation steps
        this.$analytics.track('Dataplant creation start', {
          provider: newProject.provider,
          region: newProject.region,
          default_storage_engine_config: this.options.configuration.storageEngine.engine === 'default'
        })
        await this.$api.FPAPI.dataplants.create(newProject)
        await this.$store.dispatch('REFRESH_DATAPLANT_BY_ORGANIZATION_ID', { organizationId: this.organization._id, ignoreCache: true })
        // this.$fpuiMessageBlock.pop('success', 'Dataplant is being created')
        if (this.afterConfirm) this.afterConfirm()
        this.$emit('close')
      } catch (err) {
        this.$fpuiMessageBlock.pop('error', err)
      } finally {
        this.loading = false
      }
    },
    // Updates the parameters of this.options
    updateValue (obj) {
      for (const [key, value] of Object.entries(obj)) {
        _set(this.options, key, value)
      }
    },
    // Goes to the previous step
    previous () {
      if (this.$refs && this.$refs.dataplantCreation) {
        this.$refs.dataplantCreation.previous()
      }
    },
    // Goes to the next step
    next () {
      if (this.$refs && this.$refs.dataplantCreation) {
        this.$refs.dataplantCreation.next()
      }
    }
  }
}
</script>

<style lang="less">
  .dataplant-creation__modal-fullscreen {
    height: 100vh;
    .fpui-modal {
      border-radius: 0;
    }
    .modal-header {
      height: auto;
      border-bottom: none;
      text-align: center;
      position: absolute;
      h3 {
        color: #00CCF9;
        justify-content: center;
        .modal-title {
          font-size: 14px;
          letter-spacing: 0;
          line-height: 20px;
        }
      }
      .actions {
        i {
          color: white;
        }
      }
      .subtitle {
        color: white;
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 40px;
        text-align: center;
        margin: auto;
      }
      .description {
        margin-top: 0;
        color: white;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
      }
    }
    .modal-body {
      padding: 100px 0 0 0;
      background: radial-gradient(circle at center calc(~"50% + 100px"), #00BEEE 0%, #035599 80%);
      height: 100vh;
      overflow: auto;
    }
    .modal-footer {
      padding: 0;
    }
    .fpui-steps {
      .fpui-steps-footer {
        position: relative;

        .actions {
          display: flex;
          justify-content: space-between;
          padding: 0 20px;
          .next {
            &:only-child {
              margin-left: auto;
            }
            button:not(.reverse) {
              background-color: #00CCF9;
            }
          }
        }
        .stepper {
          position: absolute;
          bottom: 5px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          align-items: center;
          .step {
            opacity: 0.5;
            background: #00CCF9;
            width: 5px;
            height: 5px;
            margin: 0 6px;
            &.active {
              width: 11px;
              height: 11px;
              opacity: 1;
            }
          }
        }
      }
    }
  }
</style>
