import { API, APIObject } from '@/shared/plugins/Api/API'
class Account extends APIObject {
  constructor (options) {
    super('BILLING', options, true)
    this.defaultOptions()
  }

  defaultOptions () {
    this.first_name = this.first_name || ''
    this.last_name = this.last_name || ''
    this.picture = this.picture || ''
    this.email = this.email || ''
    this.company = this.company || ''
    this.phone = this.phone || ''
    this.line1 = this.line1 || ''
    this.line2 = this.line2 || ''
    this.line3 = this.line3 || ''
    this.zip = this.zip || ''
    this.city = this.city || ''
    this.country = this.country || ''
    this.billing_address = this.billing_address || {}
  }

  async create () {
    return super.create({
      method: 'POST',
      url: 'v1/customer_accounts'
    })
  }

  async remove () {
    return await this.request({
      method: 'DELETE',
      url: `/v1/customer_accounts/${this._id}`
    })
  }

  async save () {
    return super.save({
      method: 'PUT',
      url: `v1/customer_accounts/${this._id}`
    })
  }

  async changeOwner (data) {
    return await this.request({
      method: 'PUT',
      url: `v1/customer_accounts/${this._id}/owner`,
      data
    })
  }

  async getPaymentSources () {
    return await this.request({
      method: 'GET',
      url: `v1/customer_accounts/${this._id}/payment_sources`
    })
  }

  async setPaymentPrimary (paymentId) {
    return await this.request({
      method: 'PUT',
      url: `v1/customer_accounts/${this._id}/payment_sources/${paymentId}/primary`
    })
  }

  async removePaymentSource (paymentId) {
    return await this.request({
      method: 'DELETE',
      url: `v1/customer_accounts/${this._id}/payment_sources/${paymentId}`
    })
  }

  async createCard (token) {
    return await this.request({
      method: 'POST',
      url: `v1/customer_accounts/${this._id}/payment_sources`,
      data: { token: token }
    })
  }

  async getInvoices () {
    return await this.request({
      method: 'GET',
      url: `v1/customer_accounts/${this._id}/invoices`
    })
  }

  async getSubscriptions () {
    return await this.request({
      method: 'GET',
      url: `v1/customer_accounts/${this._id}/subscriptions`
    })
  }
}

class Accounts extends API {
  constructor (ID) {
    super(ID, true)
  }

  async list () {
    const accounts = await this.request({
      method: 'GET',
      url: 'v1/customer_accounts'
    })
    return accounts.map(account => new Account(account))
  }

  async allSubscriptions () {
    const subsciptions = await this.request({
      method: 'GET',
      url: 'v1/customer_accounts/all_subscriptions'
    })
    return subsciptions
  }
}

export default Accounts
export {
  Account,
  Accounts
}
