import _flatten from 'lodash/flatten'
// import _flattenDeep from 'lodash/flattenDeep'
import _sum from 'lodash/sum'
import _get from 'lodash/get'

// const errorInResponses = (responses, value = 'N/A') => {
//   const errorDataplantIds = []
//   responses.forEach(res => {
//     if (responses.status === 'rejected') errorDataplantIds.push(res.reason?.dataplant_id)
//   })

//   if (errorDataplantIds.length) {
//     return {
//       value: [],
//       errorDataplantIds
//     }
//   }
// }

const filterResponses = (responses) => {
  return responses.filter(res => res.status === 'fulfilled').map(v => v.value)
}

const getSingleValues = responses => {
  const values = mergeResponses(responses, true)

  return values.map(val => {
    if (typeof val === 'object') return val.value[1]
    return val
  })
}

const responsesByDataplantId = (responses) => {
  // errorInResponses(responses, [])

  const filterresponses = filterResponses(responses)
  const itemsByDataplant = filterresponses.reduce((result, currentValue) => {
    const data = currentValue.data.data.length && currentValue.data.data.length === 1 ? currentValue.data.data[0] : { values: currentValue.data.data.flat() }
    result[currentValue.dataplant_id] = { ...data, dataplantId: currentValue.dataplant_id }
    return result
  }, {})

  return Object.values(itemsByDataplant).flat()
}

const mergeResponses = (responses, full = false) => {
  // errorInResponses(responses, [])

  const values = filterResponses(responses).map(res => _flatten(_get(res, 'data.data')))

  if (!full) return _flatten(values).map(v => v.values)
  return _flatten(values)
}

const sum = (responses) => {
  // errorInResponses(responses)

  const values = getSingleValues(responses)
  return _sum(values.map(v => parseFloat(v)))
}

const average = responses => {
  // errorInResponses(responses)

  const values = getSingleValues(responses)

  if (!values.length) return '-'

  const average = values.map(v => parseFloat(v)).reduce((a, b) => a + b, 0) / values.length
  return average
}

export default {
  responsesByDataplantId,
  filterResponses,
  mergeResponses,
  sum,
  average
}
