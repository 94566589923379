<template lang="pug">
.general-panel
  .fp-box
    .box-title {{ $t('query.settings.general.title') }}
    .box-subtitle(
      v-if="displayAspect"
    ) {{ $t('query.settings.general.subtitle.aspect') }}
    //- Area & Bar
    .box-line(
      v-if="['area', 'bar'].includes(type)"
    )
      fpui-input-toggle(
        :label="$t('query.settings.general.options.stacked')"
        :value="options.series.stack"
        @input="val => $emit('update', 'series.stack', val)"
      )
    //- Bar
    .box-line(
      v-if="['bar'].includes(type)"
    )
      fpui-input-select(
        :value='options.orientation'
        :options='orientationOptions'
        :label="$t('query.settings.general.orientation')"
        :clearable="false"
        direction="bottom"
        @input="val => updateOrientation(val)"
      )
    //- Pie
    .box-line(
      v-if="['pie'].includes(type)"
    )
      fpui-input-toggle(
        :label="$t('query.settings.general.options.donut')"
        :value="options.donut"
        @input="val => $emit('update', 'donut', val)"
      )

    //- Only scatter chart
    .box-subtitle(
      v-if="['scatter'].includes(type)"
    ) {{ $t('query.settings.general.subtitle.trendline') }}
    .box-line(v-if="['scatter'].includes(type)")
      fpui-input-toggle(
        :label="$t('query.settings.general.options.trendline')"
        :value="!!options.trendline"
        @input="val => $emit('update', 'trendline', val)"
      )

    //- Progress bar config
    .box-line(v-if="type === 'gauge'")
      fpui-input-number(
        :label="$t('query.settings.general.target')"
        :value='options.target'
        @input="val => $emit('update', 'target', val)"
      )

    //- All except Table, big number and progress bar
    .box-subtitle(
      v-if="!['table', 'big-number'].includes(type) && !fromDashboardEdit"
    ) {{ $t('query.settings.general.subtitle.colors') }}
    .box-line(v-if="!['table', 'big-number'].includes(type) && !fromDashboardEdit")
      fpui-input-select(
        :value='options.color'
        :options='colorsOptions'
        :clearable="false"
        direction="bottom"
        @input="val => $emit('update', 'color', val)"
      )

    //- Big Number config
    .box-line(v-if="type === 'big-number'")
      fpui-input-toggle(
        :label="$t('query.settings.general.options.compare_to_previous')"
        :value="options.compareToPrevious"
        popover
        :popover-text="$t('query.settings.general.big_number.tooltip_content')"
        :popover-link="$t('query.settings.general.big_number.tooltip_link')"
        :popover-action="openLink"
        @input="val => $emit('update', 'compareToPrevious', val)"
      )
    .box-line(
      v-if="type === 'big-number' && options.compareToPrevious"
    )
      fpui-input-toggle(
        :label="$t('query.settings.general.options.display_as_percentage')"
        :value="options.percent"
        @input="val => $emit('update', 'percent', val)"
      )
    .box-line(
      v-if="type === 'big-number' && options.compareToPrevious"
    )
      fpui-input-toggle(
        :label="$t('query.settings.general.options.switch_colors')"
        :value="options.invertedColor"
        @input="val => $emit('update', 'invertedColor', val)"
      )
    .box-line(v-if="type === 'big-number'")
      fpui-input-toggle(
        :label="$t('query.settings.general.options.show_dimensions')"
        :value="options.dimensions"
        @input="val => $emit('update', 'dimensions', val)"
      )

    //- Table config
    .box-line(v-if="type === 'table'")
      fpui-input-toggle(
        :label="$t('query.settings.general.options.pagination')"
        :value="options.pagination"
        :disabled="options.json"
        @input="val => $emit('update', 'pagination', val)"
      )
    .box-line(v-if="type === 'table' && options.pagination")
      fpui-input-select(
        :label="$t('query.settings.general.options.pagination_number')"
        :value='options.paginationNumber'
        :options='paginationOptions'
        :clearable="false"
        :disabled="options.json"
        direction="bottom"
        @input="val => $emit('update', 'paginationNumber', val)"
      )
</template>

<script>
export default {
  props: {
    type: { type: String, default: '' },
    options: { type: Object, default: () => ({}) },
    fromDashboardEdit: { type: Boolean, default: false }
  },
  computed: {
    paginationOptions () {
      return [{
        label: '5',
        value: 5
      }, {
        label: '10',
        value: 10
      }, {
        label: '25',
        value: 25
      }, {
        label: '50',
        value: 50
      }]
    },
    orientationOptions () {
      return [{
        label: this.$t('query.settings.general.orientation.horizontal'),
        value: 'horizontal'
      }, {
        label: this.$t('query.settings.general.orientation.vertical'),
        value: 'vertical'
      }]
    },
    colorsOptions () {
      const colors = this.$store.getters.DASHBOARD_COLORS_OPTIONS
      return Object.keys(colors).map(color => {
        return {
          label: this.$t(`dashboard.color_palette.${color}`),
          value: colors[color]
        }
      })
    },
    displayAspect () {
      return ['area', 'bar', 'pie'].includes(this.type)
    }
  },
  methods: {
    update (key, val) {
      this.$emit('update', key, val.target.value)
    },
    updateOrientation (value) {
      if (value === 'vertical') {
        const yAxisType = this.options.yAxis.type
        const xAxisMin = this.options.xAxis.min
        const xAxisMax = this.options.xAxis.max
        this.$emit('update', 'yAxis.type', this.options.xAxis.type || 'value')
        this.$emit('update', 'yAxis.min', xAxisMin)
        this.$emit('update', 'yAxis.max', xAxisMax)
        this.$emit('update', 'xAxis.type', yAxisType || 'category')
        this.$emit('update', 'xAxis.min', 'dataMin')
        this.$emit('update', 'xAxis.max', 'dataMax')
      }
      if (value === 'horizontal') {
        const yAxisType = this.options.yAxis.type
        const yAxisMin = this.options.yAxis.min
        const yAxisMax = this.options.yAxis.max
        this.$emit('update', 'yAxis.type', this.options.xAxis.type || 'category')
        this.$emit('update', 'yAxis.min', 'dataMin')
        this.$emit('update', 'yAxis.max', 'dataMax')
        this.$emit('update', 'xAxis.type', yAxisType || 'value')
        this.$emit('update', 'xAxis.min', yAxisMin)
        this.$emit('update', 'xAxis.max', yAxisMax)
      }
      this.$emit('update', 'orientation', value)
    },
    openLink () {
      window.open('https://docs.forepaas.io/#/en/product/am/queries/charts?id=use-a-metric', '_blank')
    }
  }
}
</script>

<style lang="less">
.general-panel {
  .fp-box {
    padding-bottom: 10px;
    .box-line {
      margin-bottom: 10px;
    }
  }
}
</style>
