<template lang='pug'>
  .modal-body
    slot
</template>

<script>

export default {
  props: {
  }
}
</script>

<style lang='less'>
  .modal-body {
    position: relative;
    padding: 20px 29px;
  }
</style>
