import { API, APIObject } from '@/shared/plugins/Api/API'

class Engine extends APIObject {
  constructor (options) {
    // Init
    super('STORAGE', options, true, true)
  }
}

class Engines extends API {
  constructor (ID) {
    super(ID, true, true)
  }

  // List engines by organization_id
  async listByOrganization (organizationId) {
    const engines = await this.request({
      method: 'get',
      url: '/engines',
      params: {
        organization_id: organizationId
      }
    })

    return {
      engines: engines
        .filter(e => e.active)
        .map(e => {
          try {
            return new Engine(e)
          } catch (err) {
            console.error(err.stack)
            return null
          }
        })
        .filter(i => i),
      organizationId: organizationId
    }
  }
}

export default Engines
export {
  Engine,
  Engines
}
