<template lang='pug'>
  .fp-modal-message
    h3(v-if='title') {{ title | translate }}
    span(v-if='content') {{ content | translate }}
    .button-wrapper
      .button-container
        fpui-button(
          v-for='button, index in buttons'
          type='button',
          color='blue',
          reverse,
          @click='handler(button)'
          :key='index'
        ) {{ button.text | translate }}
</template>

<script>

export default {
  props: {
    content: { type: String, default: '' },
    title: { type: String, default: '' },
    buttons: { type: Array, required: true }
  },
  methods: {
    handler (button) {
      this.$emit('close')
      button.handler()
    }
  }
}
</script>

<style lang='less'>
  .fp-modal-message {
    position: relative;
    padding: 20px;
    height: 100%;
    h3 {
      width: 100%;
      font-weight: 400!important;
      font-size: 25px !important;
      margin: 0 0 4px 0;
    }
    .button-wrapper {
      height: 40px;
      width: 100%;
      .button-container {
        position: absolute;
        right: 10px;
        bottom: 10px;
        .fpui-button {
          display: inline-block;
          min-width: 60px;
          text-align: center;
          margin-right: 10px;
        }
      }
    }
  }
</style>
