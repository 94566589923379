<template lang="pug">
.labels-and-legends-panel
  .fp-box
    .box-title {{ $t('query.settings.labels_and_legends.title') }}
    .box-subtitle(v-if="!['scatter', 'combo-chart'].includes(type)") {{ $t('query.settings.labels_and_legends.subtitle.data_label') }}

    //- Pie only
    .box-line(v-if="['pie'].includes(type)")
      fpui-input-toggle(
        :label="$t('query.settings.labels_and_legends.show_category')"
        :value="options.series.label.formatter.includes('{b}')"
        @input="val => updateShowCategory('category', val)"
      )
    .box-line(v-if="['pie'].includes(type)")
      fpui-input-toggle(
        :label="$t('query.settings.labels_and_legends.category')"
        :value="options.series.label.formatter.includes('{c}')"
        @input="val => updateShowCategory('values', val)"
      )
    .box-line(v-if="['pie'].includes(type)")
      fpui-input-toggle(
        :label="$t('query.settings.labels_and_legends.percent')"
        :value="options.series.label.formatter.includes('{d}')"
        @input="val => updateShowCategory('percent', val)"
      )

    //- Not Pie
    .box-line(v-if="!['pie', 'scatter', 'combo-chart'].includes(type)")
      fpui-input-toggle(
        :label="$t('query.settings.labels_and_legends.category')"
        :value="options.series.label.show_values"
        @input="val => $emit('update', 'series.label.show_values', val)"
      )

    .box-line(v-if="!['pie', 'scatter', 'combo-chart'].includes(type)")
      fpui-input-toggle(
        :label="$t('query.settings.labels_and_legends.percent')"
        :value="options.series.label.show_percent"
        @input="val => $emit('update', 'series.label.show_percent', val)"
      )

    .box-line(v-if="!['scatter', 'combo-chart'].includes(type)")
      fpui-input-select(
        :label="$t('query.settings.labels_and_legends.labels_position')"
        :value='options.series.label.position',
        :options='labelsPositionOptions',
        :clearable="false"
        direction="bottom"
        @input="val => $emit('update', 'series.label.position', val)"
      )
    .box-subtitle {{ $t('query.settings.labels_and_legends.subtitle.legend') }}
    .box-line
      fpui-input-toggle(
        :label="$t('query.settings.labels_and_legends.hide_legend')"
        :value="!options.legend.show"
        @input="val => $emit('update', 'legend.show', !val)"
      )
    .box-line
      fpui-input-select(
        :label="$t('query.settings.labels_and_legends.legend_position')"
        :value='`${options.legend.top}-${options.legend.left}`',
        :options='legendPositionOptions',
        :clearable="false"
        direction="bottom"
        @input="val => updateLegendPosition(val)"
      )
</template>

<script>
export default {
  props: {
    type: { type: String, default: '' },
    options: { type: Object, default: () => ({}) }
  },
  computed: {
    legendPositionOptions () {
      return [{
        label: this.$t('query.settings.labels_and_legends.legend_position.top_right'),
        value: 'top-right'
      }, {
        label: this.$t('query.settings.labels_and_legends.legend_position.top_center'),
        value: 'top-center'
      }, {
        label: this.$t('query.settings.labels_and_legends.legend_position.top_left'),
        value: 'top-left'
      }, {
        label: this.$t('query.settings.labels_and_legends.legend_position.bottom_right'),
        value: 'bottom-right'
      }, {
        label: this.$t('query.settings.labels_and_legends.legend_position.bottom_center'),
        value: 'bottom-center'
      }, {
        label: this.$t('query.settings.labels_and_legends.legend_position.bottom_left'),
        value: 'bottom-left'
      }]
    },
    labelsPositionOptions () {
      if (this.type === 'pie') {
        return [{
          label: this.$t('query.settings.labels_and_legends.labels_position.outside'),
          value: 'outside'
        }, {
          label: this.$t('query.settings.labels_and_legends.labels_position.inside'),
          value: 'inside'
        }]
      }
      return [
        {
          label: this.$t('query.settings.labels_and_legends.labels_position.top'),
          value: 'top'
        }, {
          label: this.$t('query.settings.labels_and_legends.labels_position.left'),
          value: 'left'
        }, {
          label: this.$t('query.settings.labels_and_legends.labels_position.right'),
          value: 'right'
        }, {
          label: this.$t('query.settings.labels_and_legends.labels_position.bottom'),
          value: 'bottom'
        }, {
          label: this.$t('query.settings.labels_and_legends.labels_position.inside'),
          value: 'inside'
        }, {
          label: this.$t('query.settings.labels_and_legends.labels_position.insideLeft'),
          value: 'insideLeft'
        }, {
          label: this.$t('query.settings.labels_and_legends.labels_position.insideRight'),
          value: 'insideRight'
        }, {
          label: this.$t('query.settings.labels_and_legends.labels_position.insideTop'),
          value: 'insideTop'
        }, {
          label: this.$t('query.settings.labels_and_legends.labels_position.insideBottom'),
          value: 'insideBottom'
        }, {
          label: this.$t('query.settings.labels_and_legends.labels_position.insideTopLeft'),
          value: 'insideTopLeft'
        }, {
          label: this.$t('query.settings.labels_and_legends.labels_position.insideBottomLeft'),
          value: 'insideBottomLeft'
        }, {
          label: this.$t('query.settings.labels_and_legends.labels_position.insideTopRight'),
          value: 'insideTopRight'
        }, {
          label: this.$t('query.settings.labels_and_legends.labels_position.insideBottomRight'),
          value: 'insideBottomRight'
        }
      ]
    }
  },
  methods: {
    updateLegendPosition (value) {
      const top = value.split('-')[0]
      const left = value.split('-')[1]
      this.$emit('update', 'legend.top', top)
      this.$emit('update', 'legend.left', left)
    },
    updateShowCategory (type, value) {
      const object = {
        values: this.options.series.label.formatter.includes('{c}'),
        percent: this.options.series.label.formatter.includes('{d}'),
        category: this.options.series.label.formatter.includes('{b}')
      }
      object[type] = value

      let newValue = ''
      if (object.category && object.values && object.percent) {
        newValue = '{b}\n{c} ({d}%)'
      } else if (object.category && object.values && !object.percent) {
        newValue = '{b}\n{c}'
      } else if (object.category && !object.values && object.percent) {
        newValue = '{b}\n{d}%'
      } else if (object.category && !object.values && !object.percent) {
        newValue = '{b}'
      } else if (!object.category && object.values && object.percent) {
        newValue = '{c} ({d}%)'
      } else if (!object.category && object.values && !object.percent) {
        newValue = '{c}'
      } else if (!object.category && !object.values && object.percent) {
        newValue = '{d}%'
      }

      this.$emit('update', 'series.label.formatter', newValue)
      this.$emit('update', 'series.label.show', !!newValue)
    }
  }
}
</script>

<style lang="less">
.labels-and-legends-panel {
  margin-top: 10px;
  .fp-box {
    padding-bottom: 3px;
    .box-line {
      margin-bottom: 10px;
    }
  }
}
</style>
