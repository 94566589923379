import API from '@/shared/plugins/Api/API'
import Actions from './Actions'
import Deployments from './Deployments'
import { environmentModel, Environments } from './Environments'
import Events from './Events'
import Jobs from './Jobs'
import Quotas from './Quotas'
import Repositories from './Repositories'
import Workflows from './Workflows'

class DPE extends API {
  constructor (id) {
    super(id)
    this.actions = new Actions(id)
    this.deployments = new Deployments(id)
    this.repositories = new Repositories(id)
    this.environments = new Environments(id)
    this.environmentModel = environmentModel
    this.events = new Events(id)
    this.workflows = new Workflows(id)
    this.jobs = new Jobs(id)
    this.quotas = new Quotas(id)
  }

  templatesActionsAggregate (source, destination, action) {
    return this.request({
      method: 'GET',
      url: '/v3/templates/actions/aggregate',
      params: {
        source_db: source.database_name,
        source: source.table_name,
        destination_db: destination.database_name,
        destination: destination.table_name,
        action: action
      }
    })
  }

  fetchRepositoryBranches (url) {
    return this.request({
      method: 'GET',
      url: '/v3/git/branches/list',
      params: {
        url: url
      }
    })
  }

  fetchPublicKey () {
    return this.request({
      method: 'GET',
      url: '/v3/git/public_key'
    })
  }

  // Action boilerplates
  customActionBoilerplate (language = 'python') {
    return this.request({
      method: 'GET',
      url: '/v3/templates/actions/custom',
      params: {
        language: language
      }
    })
  }

  async import (item) {
    return this.request({
      method: 'POST',
      url: 'v4/backup/import',
      data: item,
      timeout: 60 * 10 * 1000
    })
  }
}
export default DPE
