import Date from '@/shared/filters/Date'
import { API, APIObject } from '@/shared/plugins/Api/API'
import _cloneDeep from 'lodash/cloneDeep'
import _omit from 'lodash/omit'
import Vue from 'vue'

class VirtualAttribute extends APIObject {
  constructor (options = {}) {
    // Init
    super('DM', options)

    this._type = 'virtual'
    // Default variables
    this.name = this.name || ''
    this.sql = this.sql || ''
    this.display_name = this.name
    this.tags = this.tags || {}
    this.description = this.description || ''
    this.path = this.path || ''
    this.tags.path = this.tags.path || ''
    this.tags.tags = this.tags.tags || []
    if (!Array.isArray(this.tags.tags)) this.tags.tags = []
    this.is_dimension = 'is_dimension' in options ? options.is_dimension : true
    this.is_measure = 'is_measure' in options ? options.is_measure : false
    this.is_virtual = true
    this.required_attributes_names = this.required_attributes_names || []
  }

  /**
  * Use APIObject assign function and extend it by setting created_at and updated_at value.
  *
  * @param  {Object}  object VirtualAttribute object.
  */
  assign (object) {
    super.assign(object)
    this.created_at = this.created_at ? Date(this.created_at) : null
    this.updated_at = this.updated_at ? Date(this.updated_at) : null
  }

  /**
  * Clone virtualAttribute and return a new object.
  *
  * @return {Object} VirtualAttribute object
  */
  clone () {
    const clone = new VirtualAttribute(_cloneDeep(this._filter(this)))
    return clone
  }

  /**
  * Return the virtualAttribute without the keys listed.
  * Use APIObject _filter function
  *
  * @param  {Object}  object VirtualAttribute object.
  * @return {Object} VirtualAttribute object
  */
  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      '_type',
      '__socketId',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
      'children'
    ])
    return obj
  }

  /**
  * Create a new virtualAttribute in database and return it.
  * Use APIObject create function
  *
  * @return {Object} VirtualAttribute object
  */
  async create () {
    this.display_name = this.name
    return super.create({
      method: 'POST',
      url: 'v4/virtual/attributes'
    })
  }

  /**
  * Save virtualAttribute in database and return it.
  * Use APIObject save function
  *
  * @return {Object} VirtualAttribute object
  */
  async save () {
    this.display_name = this.name
    return super.save({
      method: 'PUT',
      url: `v4/virtual/attributes/${this._id}`
    })
  }

  /**
  * Remove virtualAttribute from database and return response.
  *
  * @return {Object} response from endpoint
  */
  async remove () {
    const res = await this.request({
      method: 'DELETE',
      url: `v4/virtual/attributes/${this._id}`
    })
    this._id = null
    return res
  }
}
class VirtualAttributes extends API {
  /**
  * Create a new virtualAttribute and return it.
  *
  * @param {Object} opts Options to init the virtualAttribute
  * @return {Object} VirtualAttribute object
  */
  new (opts) {
    return new VirtualAttribute(opts)
  }

  /**
  * List all virtualAttribute from database and return them after adding key is_virtual and cleaning list.
  *
  * @return {Array} VirtualAttribute array
  */
  async list () {
    const attributes = await this.request({
      method: 'get',
      url: 'v4/virtual/attributes'
    }).catch(err => {
      Vue.fpuiMessageBlock.error(err)
      return []
    })
    return attributes.map(attribute => {
      try {
        attribute.is_virtual = true
        return new VirtualAttribute(attribute)
      } catch (err) /* istanbul ignore next */ {
        console.error(err.stack)
        return null
      }
    }).filter(v => v)
  }
}

export default VirtualAttributes
export {
  VirtualAttribute,
  VirtualAttributes
}
