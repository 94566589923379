<template lang="pug">
.vertical-resize-container
  .resize-bar
    .resize-cursor
      i.fp4.fp4-double-arrow
    .add-cursor(
      @click="addChart"
    )
      i.fp4.fp4-plus
</template>

<script>
export default {
  methods: {
    resizeChart () {
      this.$emit('resize-chart')
    },
    addChart () {
      this.$emit('add-chart')
    }
  }
}
</script>

<style lang="less">
.vertical-resize-container {
  height: 100%;
  position: relative;
  .resize-bar {
    width: 100%;
    height: 6px;
    position: absolute;
    bottom: 0;
    background: #E9ECF0;
    border-radius: 1px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    display: flex;
    justify-content: center;

    .resize-cursor {
      background: #CBD3DB;
      border-radius: 1px;
      transform: matrix(-1, 0, 0, 1, 0, 0);
      width: 45px;
      height: 14px;
      margin-top: -4px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      cursor: ns-resize;

      &:hover {
        background: #97A7B7;
      }
    }

    .add-cursor {
      background: #CBD3DB;
      color: white;
      border-radius: 1px;
      transform: matrix(-1, 0, 0, 1, 0, 0);
      width: 25px;
      height: 14px;
      margin-top: -4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      position: absolute;
      right: calc(~"50% + 30px");

      &:hover {
        background: #97A7B7;
      }
    }
  }
}
</style>
