<template lang='pug'>
  img.fp-loading.rotating(:src="spinner",:style="style")
</template>

<script>

export default {
  props: {
    size: {
      type: Number,
      default: 30
    }
  },
  computed: {
    style () {
      return {
        height: `${this.size}px`,
        width: `${this.size}px`
      }
    },
    spinner () {
      return require('./ic_spinner.png')
    }
  }
}
</script>
<style lang="less">
@keyframes rotating {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

.rotating {
    animation: rotating 1s linear infinite;
}
</style>
