<template lang="pug">
  .fpui-table_tr
    .fpui-table_th(
      v-for="(column, idx) in columns",
      :class="{ 'sortable': !!column.sortable, 'sort-active': sort.sortOn === column, first:idx === 0 }"
      @click="sortColumn(column)"
      :style="column.cellStyle"
    )
      component(
        :is="getComponent(column)"
        v-bind='getBind(column)'
        v-on='getEvents(column)'
        :key="idx"
        :column="column"
      )
      i.fp4(
        v-if="sort.sortOn === column"
        :class="{ 'fp4-chevron-up': !sort.reverse, 'fp4-chevron-down': sort.reverse }"
      )
</template>
<script>
import FpuiTableHeaderCell from './FpuiTableHeaderCell'
export default {
  props: {
    columns: { type: Array, default: () => [] },
    sort: {
      type: Object,
      default: () => ({
        sortOn: '',
        reverse: false
      })
    }
  },
  methods: {
    getComponent (column) {
      if (column.headerComponent) return column.headerComponent
      return FpuiTableHeaderCell
    },
    getBind (column) {
      if (column.headerComponent) {
        return column.headerProps ?? null
      }
      return null
    },
    getEvents (column) {
      if (column.headerComponent && column.headerEvents) {
        return Object.fromEntries(
          Object.entries(column.headerEvents).map(([eventName, eventFunction]) => [
            eventName,
            value => eventFunction(value, this.column)
          ])
        )
      }
      return null
    },
    /**
   * Sorts the column from the parameter. To use with the sort functionality
   * @param {object} column
   */
    sortColumn (column) {
      if (!column.sortable) return
      this.$emit('sortEvent', column)
    }
  }
}
</script>
<style lang="less">
</style>
