<template lang='pug'>
  .fpui-input-number
    .fpui-input-label-container(
      v-if='label'
    )
      span {{ label | translate }}
      .required-container(v-if='required') *
      fpui-helper(
        v-if='helper'
        :text="helper | translate"
        :title="helperTitle ? $t(helperTitle) : ''"
      )
    .fpui-input-number-content
      .fpui-input-text-error(v-show="error" v-tooltip="errorTooltip") {{ $t(errorMessage) }}
      input(
        v-model='model'
        ref='input'
        :placeholder="placeholder | translate"
        type="number"
        :disabled="disabled"
        :min="min"
        :max="max"
        :class="className"
      )
</template>

<script>

export default {
  props: {
    value: { type: [String, Number], default: '' },
    color: { type: String, default: 'blue' },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' },
    errorTooltip: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    label: { type: String, default: '' },
    required: { type: Boolean, default: false },
    helper: { type: String, default: '' },
    helperTitle: { type: String, default: '' },
    min: { type: Number, default: null },
    max: { type: Number, default: null }
  },
  computed: {
    model: {
      get () {
        if (this.max !== null && parseFloat(this.value) > this.max) return this.max
        if (this.min !== null && parseFloat(this.value) < this.min) return this.min
        return this.value
      },
      set (val) {
        if (this.max !== null && parseFloat(val) > this.max) val = this.max
        if (this.min !== null && parseFloat(val) < this.min) val = this.min
        this.$emit('input', parseFloat(val))
      }
    },
    className () {
      const className = [`fpui-input-color-${this.color}`]
      if (this.disabled) className.push('disabled')
      if (this.error) className.push('error')
      return className.join(' ')
    }
  }
}
</script>

<style lang='less'>
@import "~@/shared/styles/_variables.less";

.fpui-input-number {
  position:relative;
  .fpui-input-label-container {
    padding-left: 0;
    font-size: 12px !important;
    margin-bottom: 5px;
    color: #748294;
    text-transform: capitalize;
    cursor: pointer;
    .required-container {
      display: inline;
      vertical-align: middle;
      font-size: 22px;
      line-height:18px;
      text-align: center;
      border-radius: 50%;
    }
    .pointer {
      margin-top: -2px;
    }
  }
  .fpui-input-number-content input::placeholder {
    color: rgba(62,69,80,0.35) !important;
  }
  .fpui-input-number-content:not(.with-padding) {
    padding: 0;
  }
  .fpui-input-text-error {
    color:#F62172;
    position: absolute;
    line-height: 15px;
    font-size: 12px;
    right:5px;
    top: 5px;
  }
  input {
    width: 100%;
    height: 34px;
    border-radius: 3px;
    background-color: rgba(151,167,183,0.06);
    padding: 8px;
    padding-left: 11px;
    border: 1px solid @grey_dark;
    font-size: 14px;
    font-weight: 300;
    outline: none;
    font-weight: 400;
    color: #3E4550;
    font-family: @global-font-family;
    font-weight: 400;
    letter-spacing: normal;
    &:disabled {
      border: unset;
      background-color: rgba(151,167,183,0.06);
    }
    .input-transition();
    &.fpui-input-color-blue:not(.error):focus {
      border-color: @blue;
    }
    &.fpui-input-color-green:not(.error):focus {
      border-color: @green;
    }
    &.fpui-input-color-red:focus, &.error {
      border-color: @red;
    }
    &.fpui-input-color-yellow:not(.error):focus {
      border-color: @yellow;
    }
    &.fpui-input-color-red:focus, &.error {
      border-color: @red;
    }
  }
  .autocomplete {
    padding: 0;
    margin: 0;
    list-style: none;
    position:absolute;
    left: 0;
    width: 100%;
    z-index: 1000;
    opacity: 1;
    background-color: white;
    display: block;
    li {
      padding: 7px;
      cursor: pointer;
      &:hover, &.selected {
        background-color: @grey_dark;
      }
    }
    &.autocomplete-top {
      bottom: 40px;
      overflow-y: scroll;
    }
    &.hide-autocomplete {
      display: none;
    }
  }
  .clear-input {
    position:absolute;
    right: 5px;
    top: 0px;
    color: @blue;
    cursor:pointer;
    font-size: 15px;
  }
}
</style>
