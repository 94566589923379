<template lang='pug'>
.fpui-color-picker(:style="{ 'width': width ? width : '' }")
  .label-container
    span.label {{ $t(label) }}
  .section(@click="dispatchEvent()" :class="{ 'disabled' : disabled }")
    .color-text {{ color }}
    .preview-color(:style="{ background: color }")
      input(
        ref="color-input"
        type="color"
        v-model="model"
        :disabled="disabled"
        :class="{ 'disabled' : disabled }"
      )
    i.fp4.fp4-angle-down

</template>

<script>

export default {
  props: {
    label: { type: String, default: '' },
    color: { type: String, default: '' },
    width: { type: String, default: '' },
    disabled: { type: Boolean, default: false }
  },
  data () {
    return {
    }
  },
  computed: {
    model: {
      get () { return this.color },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    dispatchEvent () {
      if (!this.disabled) this.$refs['color-input'].click()
    }
  }
}
</script>

<style lang='less'>
.fpui-color-picker {
  .label-container {
    font-size: 12px !important;
    line-height: 15px;
    margin-bottom: 5px;
    color: #748294;
    cursor: pointer;
  }
  .section {
    display: flex;
    align-items: baseline;
    position: relative;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed !important;
      .color-text {
        color: rgba(62, 69, 80, 0.65) !important;
      }
      i.fp4-angle-down {
        color: rgba(62, 69, 80, 0.65) !important;
      }
    }
    .color-text {
      padding-left: 32px !important;
      letter-spacing: -0.14px;
      color: #3E4550;

      vertical-align: middle;
      width: 100%;
      border-radius: 3px;

      height: 34px;
      background-color: rgba(151,167,183,0.06);
      padding: 8px;
      border: 1px solid @grey_dark;
      font-size: 14px;
      outline: none;
      font-weight: 400;
      font-family: @global-font-family;
      font-weight: 400;
    }
    .preview-color {
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 3px;
      top: 10px;
      left: 8px;
      display: flex;
      input {
        cursor: pointer;
        opacity: 0;
        &.disabled {
          cursor: not-allowed !important;
        }
      }
    }
    i.fp4-angle-down {
      position: absolute;
      top: 8px;
      right: 8px;
      font-size: 20px;
    }
  }
}
</style>
