<template lang="pug">
.chart-progress-bar
  .bar-container(
    v-if="!valueIsNaN"
  )
    .target-bar.bar(
      :style="targetStyle"
    )
      .value-bar.bar(
        :style="valueStyle"
      )
        .percent-value(
          v-if="valuePercent > 3 && valuePercent < 100"
        ) {{ `${valuePercent}%` }}

    .text.origin-text 0
    .text.target-text(
      ref="target-text"
      :style="{ left: valuePercent > 100 && targetPercent > 5 ? `calc(${targetPercent}% - ${targetTextWidth / 2}px)` : 'auto', right: valuePercent > 100 && targetPercent > 5 ? 'auto' : 0 }"
    ) {{ targetValue.toLocaleString() }}
    .text.value-text(
      ref="value-text"
      :style="valueTextStyle"
    ) {{ valueToDisplay.toLocaleString() }}
    .text.target-reached(
      v-if="valuePercent >= 100"
    ) {{ $t(valuePercent > 100 ? 'query.preview.result.progress_bar_target_exceeded' : 'query.preview.result.progress_bar_target_reached') }}


  .error-container(
    v-else
  )
    img(
      src='@/qb/assets/img/placeholder_preview_chart@2x.png'
    )
    .message {{ $t('query.preview.result.progress_bar_not_a_number') }}
</template>

<script>
import _get from 'lodash/get'

export default {
  props: {
    value: { type: Object, default: () => {} },
    sql: { type: Boolean, default: false }
  },
  data () {
    return {
      valueTextWidth: 0,
      targetTextWidth: 0
    }
  },
  computed: {
    options () {
      return this.value?.options || {}
    },
    attribute () {
      if (!this.sql && this.value?.query_params?.data?.fields) {
        return Object.keys(this.value?.query_params?.data?.fields)[0]
      }
      return null
    },
    cm () {
      if (!this.sql && this.value?.query_params?.data?.fields) {
        return this.value?.query_params?.data?.fields[this.attribute][0]
      }
      return null
    },
    valueToDisplay () {
      let value = null
      if (!this.sql) {
        if (this.value?.query_params?.data?.fields) {
          value = _get(this.value?.results, `[0].data.${this.attribute}.${this.cm}[0].value`)
        }
      } else {
        value = _get(this.value?.results, '[0][0]')
      }

      if (isNaN(value)) return 'NaN'

      return value !== null ? (Math.round(value * 100) / 100) : 0
    },
    valueIsNaN () {
      return this.valueToDisplay === 'NaN'
    },
    valuePercent () {
      const val = this.valueToDisplay / (this.targetValue || 1) * 100
      if (val > 100) return Math.ceil(val)
      return Math.floor(val)
    },
    targetPercent () {
      return Math.floor(this.targetValue / (this.valueToDisplay || 1) * 100)
    },
    valueWidth () {
      if (this.valuePercent >= 0 && this.valuePercent <= 100) return `${this.valuePercent}%`
      if (this.valuePercent === 100) return '100%'
      if (this.valuePercent > 100) return `${100 - this.targetPercent}%`
      return '0%'
    },
    valueStyle () {
      return {
        width: this.valueWidth,
        background: this.options.color[0] || '#39d2f5',
        'border-top-right-radius': this.valuePercent >= 100 ? '3px' : 0,
        'border-bottom-right-radius': this.valuePercent >= 100 ? '3px' : 0,
        'border-top-left-radius': this.valuePercent > 100 ? 0 : '3px',
        'border-bottom-left-radius': this.valuePercent > 100 ? 0 : '3px',
        right: this.valuePercent > 100 ? 0 : 'auto',
        left: this.valuePercent > 100 ? 'auto' : 0
      }
    },
    targetValue () {
      return this.options.target || 1
    },
    targetStyle () {
      return {
        background: this.hexToRGBA(this.options.color[0] || '#39d2f5', this.valueToDisplay <= this.targetValue ? 0.1 : 0.4),
        border: `1px solid ${this.options.color[0]}`
      }
    },
    valueTextStyle () {
      return {
        left: this.valuePercent < 100 ? `calc(${this.valuePercent}% - ${this.valueTextWidth / 2}px)` : 'auto',
        right: this.valuePercent < 100 ? 'auto' : 0,
        background: this.hexToRGBA(this.options.color[0] || '#39d2f5', this.valuePercent < 100 ? 1 : 0.1),
        color: this.valuePercent < 100 ? '#FFF' : this.options.color[0] || '#39d2f5'
      }
    }
  },
  watch: {
    targetValue: {
      handler () {
        this.targetTextWidth = this.$refs?.['target-text']?.clientWidth
        this.valueTextWidth = this.$refs?.['value-text']?.clientWidth
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.targetTextWidth = this.$refs?.['target-text']?.clientWidth
      this.valueTextWidth = this.$refs?.['value-text']?.clientWidth
    })
  },
  methods: {
    hexToRGBA (hexCode, opacity = 1) {
      let hex = hexCode.replace('#', '')

      if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
      }

      const r = parseInt(hex.substring(0, 2), 16)
      const g = parseInt(hex.substring(2, 4), 16)
      const b = parseInt(hex.substring(4, 6), 16)

      /* Backward compatibility for whole number based opacity values. */
      if (opacity > 1 && opacity <= 100) {
        opacity = opacity / 100
      }

      return `rgba(${r},${g},${b},${opacity})`
    }
  }
}
</script>

<style lang="less">
.chart-progress-bar {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #3E4550;
  font-size: 16px;

  .bar-container {
    position: relative;
    width: 80%;
    height: 89px;
    border: 2px solid #E4E7EC;
    border-radius: 8px;
    padding: 6px;

    .bar {
      position: relative;
      width: 100%;
      height: 73px;
      border-radius: 4px;

      &.target-bar {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .value-bar {
          position: absolute;
          height: 71px;
          display: flex;
          justify-content: center;
          align-items: center;

          .percent-value {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #FFFFFF;
          }
        }
      }
    }

    .text {
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.01em;
      color: #748294;
      position: absolute;
      &.origin-text {
        left: 0;
        bottom: -22px;

      }
      &.target-text {
        right: 0;
        bottom: -22px;
      }
      &.value-text {
        right: 0;
        top: -30px;
        border-radius: 3px;
        padding: 2px 8px;
      }
      &.target-reached {
        left: 0;
        top: -30px;
      }
    }
  }

  .error-container {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      height: calc(~"100% - 80px");
      max-height: 180px;
      margin-bottom: 30px;
    }
    .message {
      font-size: 14px;
      line-height: 18px;
      color: @grey-chart;
    }
  }
}
</style>
