<template lang="pug">
.axis
  .fp-box
    .box-title {{ $t('query.settings.axis.title') }}
    .box-subtitle {{ $t('query.settings.axis.subtitle.x_axis') }}
    //- Area & Line
    .box-line(
      v-if="['area', 'line'].includes(type) || isBarChartHorizontal"
    )
      fpui-input-select(
        :label="$t('query.settings.axis.scale')"
        :value='options.xAxis.type'
        :options='isBarChartHorizontal ? yScaleOptions : xScaleOptions'
        :clearable="false"
        direction="bottom"
        @input="val => update('xAxis.type', val)"
      )
    .box-line(v-if="['scatter'].includes(type)")
      fpui-input-select(
        :label="$t('query.settings.axis.attribute')"
        :value='options.xAxisDetails',
        :options='scatterAxisOptions',
        :clearable="false"
        @input="val => update('xAxisDetails', val)"
      )
    //- Area & Line & Scatter
    .box-line.inline(
      v-if="['area', 'line', 'scatter'].includes(type) && ['value', 'log'].includes(options.xAxis.type) || isBarChartHorizontal"
    )
      fpui-input-number(
        :label="$t('query.settings.axis.min')"
        :value='options.xAxis.min'
        @input="val => update('xAxis.min', val)"
      )
      fpui-input-number(
        :label="$t('query.settings.axis.max')"
        :value='options.xAxis.max'
        @input="val => update('xAxis.max', val)"
      )
    .box-line(v-if="['scatter'].includes(type)")
      fpui-input-toggle(
        :label="$t('query.settings.general.options.axis_name')"
        :value="!!options.xAxis.name"
        @input="val => updateAxisScatterName('x', val)"
      )
    .box-line
      fpui-input-toggle(
        :label="$t('query.settings.axis.show_grid_values')"
        :value="options.xAxis.axisLabel.show"
        @input="val => update('xAxis.axisLabel.show', val)"
      )
    .box-line(v-if="!isBarChartHorizontal && type !== 'scatter'")
      fpui-input-select(
        :label="$t('query.settings.axis.attributes')"
        :value='options.xAxis.attributes'
        :options='axisAttributesOptions'
        :placeholder="$t('query.settings.axis.automatic_axis')"
        multiple
        @input="val => update('xAxis.attributes', val)"
      )
    .box-subtitle {{ $t('query.settings.axis.subtitle.y_axis') }}
    //- Scatter
    .box-line(v-if="['scatter'].includes(type)")
      fpui-input-select(
        :label="$t('query.settings.axis.attribute')"
        :value='options.yAxisDetails'
        :options='scatterAxisOptions'
        :clearable="false"
        @input="val => update('yAxisDetails', val)"
      )
    //- All except bar horizontal, pie and scatter
    .box-line(v-if="!isBarChartHorizontal && !['scatter', 'pie'].includes(type)")
      fpui-input-select(
        :label="$t('query.settings.axis.scale')"
        :value='options.yAxis.type'
        :options='yScaleOptions'
        :clearable="false"
        @input="val => update('yAxis.type', val)"
      )
    .box-line.inline(v-if="['value', 'log'].includes(options.yAxis.type)")
      fpui-input-number(
        :label="$t('query.settings.axis.min')"
        :value='options.yAxis.min'
        @input="val => update('yAxis.min', val)"
      )
      fpui-input-number(
        :label="$t('query.settings.axis.max')"
        :value='options.yAxis.max'
        @input="val => update('yAxis.max', val)"
      )
    .box-line(v-if="['scatter'].includes(type)")
      fpui-input-toggle(
        :label="$t('query.settings.general.options.axis_name')"
        :value="!!options.yAxis.name"
        @input="val => updateAxisScatterName('y', val)"
      )
    .box-line
      fpui-input-toggle(
        :label="$t('query.settings.axis.show_grid_values')"
        :value="options.yAxis.axisLabel.show"
        @input="val => update('yAxis.axisLabel.show', val)"
      )
    //- All except bar vertical, pie and scatter
    .box-line(v-if="isBarChartHorizontal")
      fpui-input-select(
        :label="$t('query.settings.axis.attributes')"
        :value='options.xAxis.attributes'
        :options='axisAttributesOptions'
        :placeholder="$t('query.settings.axis.automatic_axis')"
        multiple
        @input="val => update('xAxis.attributes', val)"
      )
</template>

<script>
import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'
import _isEqual from 'lodash/isEqual'

export default {
  props: {
    type: { type: String, default: '' },
    options: { type: Object, default: () => ({}) },
    result: { type: Object, default: () => ({}) },
    item: { type: Object, default: () => ({}) },
    readOnly: { type: Boolean, default: false }
  },
  computed: {
    scatterAxisOptions () {
      const options = []

      const dataFields = this.result?.query_params?.data?.fields || {}
      Object.keys(dataFields).forEach(k => {
        dataFields[k].forEach(cm => {
          const opts = {
            label: `${cm} ${k}`,
            value: {
              attribute: k,
              type: 'data',
              compute: cm
            }
          }

          if (!options.find(o => o.label === opts.label)) options.push(opts)
        })
      })

      this.result?.query_params?.scale?.fields?.forEach(f => {
        const opts = {
          label: f,
          value: {
            attribute: f,
            type: 'scale',
            compute: null
          }
        }
        if (!options.find(o => o.label === opts.label)) options.push(opts)
      })

      return _sortBy(options, ['label'])
    },
    axisAttributesOptions () { // Used to managed group in axis
      return this.item?.configuration?.scale?.fields?.map(att => {
        return {
          label: att,
          value: att
        }
      })
    },
    xScaleOptions () {
      return [{
        label: this.$t('query.settings.axis.scale.linear'),
        value: 'value'
      }, {
        label: this.$t('query.settings.axis.scale.category'),
        value: 'category'
      }, {
        label: this.$t('query.settings.axis.scale.time'),
        value: 'time'
      }, {
        label: this.$t('query.settings.axis.scale.logarithmic'),
        value: 'log'
      }]
    },
    yScaleOptions () {
      const options = [{
        label: this.$t('query.settings.axis.scale.linear'),
        value: 'value'
      }, {
        label: this.$t('query.settings.axis.scale.logarithmic'),
        value: 'log'
      }]

      if (this.type === 'scatter') {
        options.push({
          label: this.$t('query.settings.axis.scale.category'),
          value: 'category'
        })
      }
      return options
    },
    isBarChartHorizontal () {
      // We check that because in that case, we inverse xAxis Options and yAxis Options
      return this.type === 'bar' && this.options.orientation === 'horizontal'
    }
  },
  watch: {
    'item.configuration.scale.fields': {
      handler (val, oldVal) {
        if (!oldVal?.length && val && val.length) {
          Object.keys(this.item.chart_settings).forEach(key => {
            if (!['table', 'pie', 'scatter'].includes(key) && (!this.item.chart_settings[key]?.xAxis?.attributes || !this.item.chart_settings[key]?.xAxis?.attributes?.length)) {
              this.item.update(`chart_settings.${key}.xAxis.attributes`, val, !this.readOnly)
            }
          })
        }
        if ((!val || !val.length)) {
          Object.keys(this.item.chart_settings).forEach(key => {
            if (!['table', 'pie', 'scatter'].includes(key) && this.item.chart_settings[key]?.xAxis?.attributes && this.item.chart_settings[key]?.xAxis?.attributes?.length) {
              this.item.update(`chart_settings.${key}.xAxis.attributes`, [], !this.readOnly)
            }
          })
        }
      }
    }
  },
  mounted () {
    const xAxisNotInOptions = !this.scatterAxisOptions.find(o => _isEqual(o.value, this.options?.xAxisDetails))
    const yAxisNotInOptions = !this.scatterAxisOptions.find(o => _isEqual(o.value, this.options?.yAxisDetails))

    if ((!this.options.xAxisDetails || xAxisNotInOptions) && _get(this.scatterAxisOptions, '[0].value')) {
      this.update('xAxisDetails', this.scatterAxisOptions[0].value)
    }
    const yDef = _get(this.scatterAxisOptions, '[1].value', _get(this.scatterAxisOptions, '[0].value'))
    if ((!this.options.yAxisDetails || yAxisNotInOptions) && yDef) {
      this.update('yAxisDetails', yDef)
    }
  },
  methods: {
    update (key, value) {
      let newValue = value

      if (['xAxis.min', 'yAxis.min'].includes(key)) newValue = value || value === 0 ? newValue : 'dataMin'
      if (['xAxis.max', 'yAxis.max'].includes(key)) newValue = value || value === 0 ? newValue : 'dataMax'

      this.$emit('update', key, newValue)
      // Update the labels on the axis in case of attribut change
      if (key === 'xAxisDetails' && this.options.xAxis.name) { this.updateAxisScatterName('x', true) }
      if (key === 'yAxisDetails' && this.options.yAxis.name) { this.updateAxisScatterName('y', true) }
    },
    updateAxisScatterName (axis, val) {
      const attributeGetter = axis === 'x' ? 'xAxisDetails' : 'yAxisDetails'
      const valueSetter = axis === 'x' ? 'xAxis' : 'yAxis'
      if (val) {
        const attributeDetails = this.options[attributeGetter]
        const attribute = `${attributeDetails?.type === 'data' ? `${attributeDetails?.compute} ` : ''}${attributeDetails.attribute}`
        this.update(`${valueSetter}.name`, attribute)
      } else {
        this.update(`${valueSetter}.name`, '')
      }
    }
  }
}
</script>

<style lang="less">
.axis {
  margin-top: 10px;
  .fp-box {
    padding-bottom: 3px;
    .box-line {
      margin-bottom: 10px;

      &.inline {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .fpui-input-number:first-of-type {
        margin-right: 20px;
      }
    }
  }
}
</style>
