import API from '@/shared/plugins/Api/API'

import Buckets from './Buckets'

class DS extends API {
  constructor (id) {
    super(id)
    this.buckets = new Buckets(id)
  }
}
export default DS
