<template lang="pug">
.fpui-table_row
  .fpui-table_cell(
    v-for="(column, idx) in columns"
    :class="{first:idx === 0}"
    v-tooltip="tooltip(column)"
    :style="column.cellStyle"
    @click="handleEventClick(column, $event)"
  )
    component(
      :is="getComponent(column)"
      v-bind='getBind(column)'
      v-on='getEvents(column)'
      :key="idx"
      :row="row"
      :column="column"
      :row-index="index"
      :class="{'fpui-table_cell-raw': column.component}"
    )
  .fpui-table_row-actions(v-if="filteredActions.length")
    .fpui-table_row-action(
      v-for='(action, idx) in filteredActions'
      :class="actionClasses(action, idx)"
      :key='action.id || idx'
    )
      fp-loading(
        v-if="actionLoading(action)"
        v-tooltip='actionTooltip(action)'
      )
      i(
        :class='action.icon'
        v-tooltip='actionTooltip(action)'
        v-if='(!actionLoading(action) && !action.link) || isActionDisabled(action, idx)'
        @click='event => clickAction(event, action, idx)'
      )
      a(
        v-if="action.link && !isActionDisabled(action, idx) && !actionLoading(action)"
        :class='action.icon'
        v-tooltip='actionTooltip(action)'
        :href="action.link(row)"
      )
</template>

<script>
import FpuiTableCell from './FpuiTableCell'

export default {
  name: 'FpuiTableRow',
  props: {
    columns: {
      type: Array,
      required: true
    },
    row: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    actions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    filteredActions () {
      return this.actions.filter(action => {
        if (action.if === undefined) return true
        if (typeof action.if === 'function') { return action.if(this.row, this.index) }
        return action.if
      })
    }
  },
  methods: {
    handleEventClick (column, e) {
      if (column.stopPropagation) e.stopPropagation()
    },
    getComponent (column) {
      if (column.component) return column.component
      return FpuiTableCell
    },
    getBind (column) {
      if (column.component) {
        if (typeof column.target === 'function') { return column.target(this.row, this.index) }
      }
      return null
    },
    getEvents (column) {
      if (column.component && column.events) {
        return Object.fromEntries(
          Object.entries(column.events).map(([eventName, eventFunction]) => [
            column.useKey ? `${eventName}-${this.$vnode.key}` : eventName,
            value => eventFunction(value, this.row, this.index)
          ])
        )
      }
      return null
    },
    // Display a tooltip when the option is present for a dynamic component.
    // Please see FpuiTableCell for the classic HTML and text tooltip
    tooltip (column) {
      if (!column.component) return null
      switch (typeof column.tooltip) {
        case 'string':
          return column.tooltip
        case 'function':
          return column.tooltip(this.row)
      }
      return null
    },
    actionTooltip (action) {
      if (action.tooltip === false) return null
      switch (typeof (action.tooltip)) {
        case 'string':
          return action.tooltip
        case 'function':
          return action.tooltip(this.row)
      }
      return ''
    },
    actionLoading (action) {
      if (action.loading === true) return true
      if (typeof (action.loading) === 'function') {
        return action.loading(this.row)
      }
      return false
    },
    // Triggers action `click` event
    clickAction (event, action, idx) {
      if (this.isActionDisabled(action, idx)) return false
      action.click(this.row, this.index, event)
      event.stopPropagation()
    },
    // Returns boolean if provided action is disabled
    isActionDisabled (action, idx) {
      if (action.disabled !== undefined) {
        switch (typeof action.disabled) {
          case 'function':
            return action.disabled(this.row, this.index, idx, action)
          case 'boolean':
            return action.disabled
        }
      }
      return false
    },
    // Returns array of string
    actionClasses (action, idx) {
      const classes = [`row-action-${action.id || idx}`]
      if (this.isActionDisabled(action, idx)) {
        classes.push('disabled')
      }
      if (this.actionLoading(action)) {
        classes.push('loading')
      }
      return classes
    }
  }
}
</script>
