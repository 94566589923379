import Vue from 'vue'
Vue.directive('fpClickOutside', {
  bind (el, { value }, vNode) {
    const handler = (e) => {
      if (!vNode.context) return
      const elements = e.path || (e.composedPath && e.composedPath())
      elements && elements.length > 0 && elements.unshift(e.target)
      if (el.contains(e.target)) return
      el.fpClickOutside.callback(e)
    }
    el.fpClickOutside = {
      handler: handler,
      callback: value
    }
    document.addEventListener('click', handler)
  },
  unbind (el) {
    document.removeEventListener('click', el.fpClickOutside.handler)
    delete el.fpClickOutside
  },
  update (el, { value }) { el.fpClickOutside.callback = value }
})
