<template lang='pug'>
  .fpui-input-phone
    fpui-input-label(
      v-if="label"
      :helper="helper"
      :helper-title="helperTitle"
    ) {{label}}
    .phone-number-container
      .flag-container(
        :style="{ cursor: disabled ? 'not-allowed' : 'pointer' }"
        @click="disabled ? hideSubMenu : hideSubMenu = !hideSubMenu"
        ref="flagContainer"
        :disabled="disabled"
        id='flagContainer'
      )
        .iti-flag(:class='currentCountry.code')
        .arrow {{ "▼" }}
        .country-code {{ `+${currentCountry.dialCode}` }}

      .phone-number
        input(
          :style="{ cursor: disabled ? 'not-allowed' : 'text' }"
          v-model="computedValue"
          :placeholder="`e.g. ${currentCountry.phoneFormat}`"
          :disabled="disabled"
        )
        span.optional(v-if="optional")
          i {{ $t('fpui-input.optional')}}
      .error-text(
        v-if="showErrorMessage && hasError"
      ) {{ $t('fpui-input-phone.error')}}

    ul(v-show="!hideSubMenu")
      li(
        v-for="country in preferedCountriesArray"
        :key="country.id"
        @click="changeCountryCode(country.code)"
      )
        .iti-flag(:class='country.code')
        .name {{country.name }}
        .country-code {{ `+${country.dialCode}` }}
      .divider
      li(
        v-for="country in countriesArray"
        :key="country.id"
        @click="changeCountryCode(country.code)"
      )
        .iti-flag(:class='country.code')
        .name {{country.name }}
        .country-code {{ `+${country.dialCode}` }}

</template>

<script>
import countries from './countryList'
import PhoneNumber from 'awesome-phonenumber'

export default {
  props: {
    label: { type: String, default: null },
    value: { type: String, default: '' },
    helper: { type: String, default: '' },
    helperTitle: { type: String, default: '' },
    defaultCountryCode: { type: String, default: 'fr' },
    preferedCountries: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    showErrorMessage: { type: Boolean, default: true },
    optional: { type: Boolean, default: false }
  },
  data () {
    return {
      hideSubMenu: true,
      hasError: false,
      currentCode: PhoneNumber(this.value || '').getRegionCode()?.toLowerCase() || this.defaultCountryCode
    }
  },
  computed: {
    computedValue: {
      get () {
        if (this.value && this.value[0] === '+') {
          return PhoneNumber(this.value || '').getNumber('significant')
        }
        return this.value
      },
      set (val) {
        if (val && val[0] === '+') {
          const inputCode = PhoneNumber(val || '')?.getRegionCode()?.toLowerCase()
          if (inputCode) {
            this.currentCode = inputCode
            const formatNumber = PhoneNumber(val || '').getNumber()
            this.updatePhone(formatNumber)
          }
        } else {
          const formatNumber = new PhoneNumber(val || '', this.currentCode.toUpperCase()).getNumber()
          this.updatePhone(formatNumber)
        }
      }
    },
    currentCountry () {
      return countries[this.currentCode] || {}
    },
    preferedCountriesArray () {
      // Get prefered countries to be showede in front
      const preferedCountriesCodes = {}
      this.preferedCountries.forEach(code => {
        const stringCode = code.trim().toLowerCase()
        const country = countries[stringCode]
        if (country) {
          preferedCountriesCodes[stringCode] = country
        }
      })
      return preferedCountriesCodes
    },
    countriesArray () {
      // The rest country without preferedCountriesArray
      const countryCopie = { ...countries }
      if (this.preferedCountries.length > 0) {
        this.preferedCountries.forEach((code) => {
          delete countryCopie[code.trim().toLowerCase()]
        })
      }
      return countryCopie
    }
  },
  mounted () {
    document.addEventListener('click', this.onClickOutside)
  },
  destroyed () {
    document.removeEventListener('click', this.onClickOutside)
  },
  methods: {
    isParent (target) {
      if (target === this.$refs.flagContainer) return true
      if (target.parentNode) return this.isParent(target.parentNode)
      return false
    },
    checkValid (phone) {
      if (!phone || PhoneNumber(phone || '', this.currentCode).isValid()) {
        this.hasError = false
        this.$emit('hasError', this.hasError)
      } else {
        this.hasError = true
        this.$emit('hasError', this.hasError)
      }
    },
    changeCountryCode (code) {
      this.hideSubMenu = true
      this.currentCode = code
      const formatNumber = new PhoneNumber(this.computedValue || '', this.currentCode.toUpperCase()).getNumber()
      this.updatePhone(formatNumber)
    },
    updatePhone (phone) {
      this.checkValid(phone)
      this.$emit('input', phone)
    },
    onClickOutside (event) {
      if (!this.isParent(event.target)) {
        this.hideSubMenu = true
      }
    }
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";

.fpui-input-phone {
  position: relative;
  padding-right: 15px;
  font-size: 14px;
  font-weight: 400;
  width: 330px;
  .phone-number-container {
    display: flex;
    align-items: center;
    .flag-container {
      padding-left: 5px;
      padding-right: 10px;
      display: flex;
      align-items: center;

      .iti-flag {

      }
      .arrow {
        font-size: 10px;
        padding-left: 5px;
        padding-right: 10px;
      }
      .country-code  {
        width: 25px;

      }
    }

    .phone-number {
      width: 100%;
      position: relative;
      input {
        width: 100%;
        height: 34px;
        border-radius: 3px;
        padding: 8px 8px 8px 11px;
        border: 1px solid @grey_dark;
        background-color: rgba(151,167,183,0.06);
      }
      input::placeholder {
        color: rgba(62,69,80,0.35);
      }
      .error {
        border: 1px solid @pink;
      }
      .optional {
        position: absolute;
        right: 15px;
        top: 0px;
        color: rgba(62,69,80,.25);
        line-height: 34px;
        font-size: 13px;
      }
    }
  }
  .error-text {
    margin-top: 5px;
    font-size: 12px;
    color: @pink;
    position: absolute;
    top: 0;
    right: 0;
  }

  ul {
    list-style-type: none;
    border: solid 1px @grey_dark;
    background-color: @white;
    border-radius: 3px;
    width: auto;
    max-height: 200px;
    overflow-y: scroll;
    padding-left: 0px;
    position: absolute;
    z-index: 5;
    top: 58%;
    left: 0%;
    li {
      font-size: 12px;
      display: flex;
      height: 30px;
      align-items: center;
      padding-left: 20px;
      cursor: pointer;
      .name {
        padding-left: 5px;
        padding-right: 5px;
        white-space:nowrap;
        width: auto;
      }
    }
    .divider {
      border-bottom: solid 1px @grey_dark;
      width: 100%;
    }
  }
}
</style>
