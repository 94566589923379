<template lang='pug'>
  .fpui-input-search(
    :class='`fpui-input-color-${color}`'
    @click="setInputFocus"
  )
    i.fp4.fp4-magnifying-glass(:class='{ right: icon === "right" }')
    fpui-input-text(
      :id="id"
      :class='{ right: icon === "right" }'
      v-model='model'
      v-bind='props',
      :placeholder='placeholder || "input-search.placeholder" | translate',
      :autofocus='autofocus',
      :autocomplete='autocomplete'
      :modalSearch='modalSearch'
      @enter='$emit("enter", value)'
      @keyup='evt=>$emit("keyup",evt)'
    )
</template>

<script>

export default {
  props: {
    name: { type: String, default: '' },
    icon: { type: String, default: 'left' },
    value: { type: String, required: true, default: '' },
    color: { type: String, default: 'blue' },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    autocomplete: { type: Array, default: () => [] },
    type: { type: String, default: 'text' },
    autofocus: { type: Boolean, default: false },
    modalSearch: { type: Boolean },
    keyId: { type: String, default: null }
  },
  computed: {
    model: {
      get () { return this.value },
      set (val) { return this.$emit('input', val) }
    },
    props () {
      return {
        name: this.name,
        value: this.value,
        color: this.color,
        disabled: this.disabled,
        error: this.error,
        placeholder: this.placeholder,
        autocomplete: this.autocomplete,
        type: this.type,
        autofocus: this.autofocus
      }
    },
    id () {
      return `fpui-input-${this.keyId || this.uniqid()}`
    }
  },
  methods: {
    uniqid (prefix = '', random = false) {
      const sec = Date.now() * 1000 + Math.random() * 1000
      const id = sec.toString(16).replace(/\./g, '').padEnd(14, '0')
      return `${prefix}${id}${random ? `.${Math.trunc(Math.random() * 100000000)}` : ''}`
    },
    setInputFocus () {
      document.getElementById(this.id).focus()
    }
  }
}
</script>

<style lang='less'>
@import "~@/shared/styles/_variables.less";

.fpui-input-search {
  width: 100%;
  position: relative;
  .fp4-magnifying-glass:before {
    color: #97A7B7;
    font-size: 14px;
    line-height: 14px;
    top: -3px !important;
    position: relative !important;
  }

  i.fp4 {
    z-index: 10;
    position: absolute;
    left: 0;
    height: 35px;
    width: 35px;
    line-height: 35px;
    font-size: 20px;
    text-align: center;
    &.right {
      right: 0;
      left: initial;
    }
  }

  .fpui-input-text {
    li {
      background-color: #FFFFFF;
      &.selected {
        background-color: rgba(151,167,183,0.06) !important;
      }
      &:hover {
        border-radius: 4px;
        background-color: rgba(151,167,183,0.06) !important;
      }
    }
    .fpui-input-text-content  {
      opacity: 1;
      border-radius: 6px;
      background-color: rgba(151,167,183,0.06);
    }
    .fpui-input-text-content.vcenter input::placeholder  {
      font-size: 14px;
      line-height: 16px;
      color: rgba(62,69,80,0.35);
    }
    input {
      padding-left: 35px !important;
      height: 34px;
      border-radius: 3px;
      border: 1px solid @grey_dark;
      background-color: rgba(151,167,183,0.06);
      &.fpui-input-color-blue:not(.error):focus {
        border-color: @blue;
      }
      &.fpui-input-color-green:not(.error):focus {
        border-color: @green;
      }
      &.fpui-input-color-red:focus, &.error {
        border-color: @red;
      }
      &.fpui-input-color-yellow:not(.error):focus {
        border-color: @yellow;
      }
      &.fpui-input-color-grey i {
        color: @grey;
      }
    }
    &.right {
      input {
        padding-left: 12px;
        padding-right: 35px;
      }
    }
  }
}
</style>
