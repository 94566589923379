<template lang='pug'>
  .modal-search
    .modal-search-container
      .modal-search-wrapper
        fpui-button(color="white" autoWidth icon="fpui fpui-plus", icon-left, @click.stop="showModal = true") {{ buttonText || 'add'|translate }}
        .fpui-input-text-content(@keyup='onKeyUp' v-if="showModal")
          input(
            ref='input',
            :placeholder='placeholder',
            v-model='model',
            :class="className",
            @focus='onFocus',
            @focusout='onFocusOut',
            @click="$event.stopPropagation()"
          )
          ul.autocomplete(
            v-if='options && inAutocomplete.length >0'
          )
            li(
              v-for='(auto, index) in inAutocomplete'
              :class='{selected: auto.active}',
              @click="onAutocomplete(auto.value)"
            ) {{ auto.value.label }}
          i.fp4.fp4-xmark(@click="showModal = false" type='button')
</template>

<script>

export default {
  props: {
    value: { type: String, required: true },
    options: { type: Array, required: true },
    placeholder: { type: String, required: false, default: null },
    buttonText: { type: String, required: false, default: null }
  },
  data () {
    return {
      inAutocomplete: [],
      focused: false,
      showModal: false
    }
  },
  computed: {
    model: {
      get () { return this.value },
      set (val) {
        if (this.options) {
          this.updateAutocomplete(val)
        }
        this.$emit('input', val)
      }
    },
    className () {
      const className = [`fpui-input-color-${this.color}`]
      if (this.disabled) className.push('disabled')
      if (this.error) className.push('error')
      return className.join(' ')
    },
    themeClass () {
      const className = [this.theme]
      if (this.focused) className.push('focused')
      if (this.error) className.push('error')
      return className.join(' ')
    }
  },
  watch: {
    options () {
      this.updateAutocomplete(this.model)
    },
    showModal (val) {
      if (val === true) {
        this.$nextTick(() => {
          this.focus()
        })
      }
    }
  },
  mounted () {
    window.addEventListener('click', this.close)
    this.onKeyUp(false)
    this.updateAutocomplete()
    this.focus()
  },
  destroyed () {
    window.removeEventListener('click', this.close)
  },
  methods: {
    onFocusOut () {
      this.focused = false
    },
    onFocus (e) {
      this.focused = true
      if (this.selectAllOnFocus) {
        e.target.select()
      }
    },
    onAutocomplete (val) {
      if (this.options.indexOf(val) !== -1) {
        this.model = ''
        this.$emit('add', val)
        this.showModal = false
      }
    },
    onKeyUp (e) {
      let index = 0
      for (let i = 0; i < this.inAutocomplete.length; i++) {
        const auto = this.inAutocomplete[i]
        if (auto.active) { index = i }
      }
      // Enter key
      if (e.keyCode === 13) {
        this.onAutocomplete((this.inAutocomplete[index] && this.inAutocomplete[index].value) || e.target.value)
        return
      }
      // Down key
      if (e.keyCode === 40) {
        this.inAutocomplete[index].active = false
        index = index === (this.inAutocomplete.length - 1) ? 0 : index + 1
        this.inAutocomplete[index].active = true
      }
      // Up key
      if (e.keyCode === 38) {
        this.inAutocomplete[index].active = false
        index = index === 0 ? this.inAutocomplete.length - 1 : index - 1
        this.inAutocomplete[index].active = true
      }
      if (!this.value && this.modalSearch) {
        let index = 0
        this.inAutocomplete = this.options.map((auto, index) => {
          if (index === 0) { return { value: auto, active: true } }
          return { value: auto, active: false }
        })
        for (let i = 0; i < this.inAutocomplete.length; i++) {
          const auto = this.inAutocomplete[i]
          if (auto.active) { index = i }
        }
        this.inAutocomplete[index].active = false
        index = index === (this.inAutocomplete.length - 1) ? 0 : index + 1
        this.inAutocomplete[index].active = true
      }
      this.$emit('keyup', e)
    },
    focus () {
      this.$refs.input && this.$refs.input.focus()
    },
    updateAutocomplete (val) {
      if (val && val.length > 0) {
        const tmp = this.options.filter(auto => {
          try {
            return new RegExp(val, 'i').test(auto.name)
          } catch (err) {
            return false
          }
        })
        this.inAutocomplete = tmp.map((auto, index) => {
          if (index === 0) { return { value: auto, active: true } }
          return { value: auto, active: false }
        })
      } else if (!val) {
        const tmp = this.options.filter(auto => {
          try {
            // eslint-disable-next-line prefer-regex-literals
            return new RegExp('', 'i').test(auto)
          } catch (err) {
            return false
          }
        })
        this.inAutocomplete = tmp.map((auto, index) => {
          return { value: auto, active: false }
        })
      }
    },
    close () {
      this.showModal = false
    }
  }
}
</script>

<style lang='less'>
@import "~@/shared/styles/_variables.less";

.modal-search {
  .modal-search-container {
    height: 34px;
    position: relative;
    .modal-search-wrapper {
      margin-bottom: 15px;
      position: absolute;
      top: 0;
      left: 0;
      width: 479px;
      border-radius: 10px;
      background-color: #FFFFFF;
      z-index: 1000;
      // padding: 9px 7px 29px 10px;
      height: auto;
      // box-shadow: 0 -12px 35px 0 rgba(62,69,80,0.55);
      li {
        background-color: #FFFFFF;
        &.selected {
          background-color: rgba(0,204,249,0.08) !important;
        }
        &:hover {
          border-radius: 4px;
          background-color: rgba(0,204,249,0.08) !important;
        }
      }
      .fpui-input-text-content {
        position: absolute;
        -webkit-box-shadow: 0 2px 10px 0 rgba(62, 69, 80, 0.25);
        box-shadow: 0 2px 10px 0 rgba(62, 69, 80, 0.25);
        background-color: #FFFFFF;
        border-radius: 5px;
        left: 0;
        right: 0;
        top: 0;
        z-index: 10;
      }
      .fpui-input-text-content input::placeholder {
        color: rgba(62,69,80,0.25) !important;
      }
      .fpui-input-text-content:not(.with-padding) {
        padding: 0;
      }
      input {
        width: 100%;
        height: 49px;
        border-radius: 3px;
        padding-left: 48px;
        background-color: #FFFFFF;
        border: 1px solid @grey_dark;
        font-size: 14px;
        font-weight: 300;
        outline: none;
        font-weight: 400;
        color: #3E4550;
        font-family: @global-font-family;
        font-weight: 400;
        letter-spacing: normal;
        &:disabled {
          border: unset;
          background-color: rgba(151,167,183,0.06);
        }
        .input-transition();
        &.fpui-input-color-blue:not(.error):focus {
          border-color: @blue;
        }
        &.fpui-input-color-green:not(.error):focus {
          border-color: @green;
        }
        &.fpui-input-color-red:focus, &.error {
          border-color: @red;
        }
        &.fpui-input-color-yellow:not(.error):focus {
          border-color: @yellow;
        }
      }
      .autocomplete {
        padding: 0;
        margin: 0;
        padding: 8px 0;
        list-style: none;
        overflow: auto;
        overflow: overlay;
        height: 100%;
        width: 100%;
        z-index: 1000;
        opacity: 1;
        background-color: #FFFFFF;
        display: block;
        li {
          height: 43px;
          margin-left: 10px;
          border-radius: 10px;
          margin-right: 14px;
          padding-left: 34px;
          padding-top: 12px;
          font-size: 14px;
          margin-bottom: 3px;
          cursor: pointer;
          &:hover, &.selected {
            background-color: @grey_dark;
          }
        }
        &.autocomplete-top {
          bottom: 40px;
          overflow-y: scroll;
        }
        &.hide-autocomplete {
          display: none;
        }
      }
      i.fp4-xmark {
        position: absolute;
        right: 15px;
        top: 15px;
        color: #97A7B7;
        font-size: 20px;
        z-index: 10;
      }
    }
  }
}
</style>
