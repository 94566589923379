<template lang='pug'>
.fpui-table(
  :style="tableStyle"
  v-resize
  @resize="onResize"
)
  section.fpui-table_thead(
    v-if='emptyState.header'
    :ref='tableHeadRef'
  )
    fpui-table-header(
      :columns="columnsWithWidth"
      :sort="sort"
      @sortEvent="sortColumn($event)"
    )
  section.fpui-table_body(
    v-if="currentPageRows.length && !loading && (!virtualScroll || (virtualScroll && !disabledPagination))"
    :style="bodyStyle"
    :class="bodyClass"
    :ref='tableBodyRef'
  )
    fpui-table-row(
      v-for="(row, index) in currentPageRows"
      :key='getKey(row)'
      :columns="columnsWithWidth"
      :row='row'
      :index='index'
      :actions='actions'
      :class="[onClick && 'selectable', (getKey(activeRow)=== getKey(row)) && activeRowClassname, (boldRows.includes(row)) && 'bold']"
      @click.native="onRowClick(row)"
    )

  section.fpui-table_body(
    v-if="currentPageRows.length && !loading && virtualScroll && disabledPagination"
    :style="bodyStyle"
    :class="bodyClass"
    :ref='tableBodyRef'
  )
    recycle-scroller(
      class="scroller"
      :items="currentPageRows"
      :item-size="32"
      key-field="id"
    )
      template(v-slot="{ item, index }")
        fpui-table-row(
          :key='getKey(item)'
          :columns="columnsWithWidth"
          :row='item'
          :index='index'
          :actions='actions'
          :class="[onClick && 'selectable', (getKey(activeRow)=== getKey(item)) && activeRowClassname, (boldRows.includes(item)) && 'bold']"
          @click.native="onRowClick(item)"
        )

  section.fpui-table_empty-body(v-if="!currentPageRows.length && !loading")
    slot(
      v-if="$scopedSlots.empty"
      name="empty"
    )
    div(v-else)
      i(v-if='emptyState.icon !== "" && emptyState.icon' :class='emptyState.icon')
      div(
        v-if='emptyState.html'
        v-html='$sanitize(emptyState.html)'
      )
      span(v-else) {{ emptyState.text | translate }}

  section.fpui-table_empty-body.loading(v-if="(!currentPageRows.length || forceLoader) && loading")
      fp-loader(type="chart")
  section.fpui-table_footer(v-if='currentPageRows.length')
    ul.fpui-table_pagination(v-if='!disabledPagination')
      li.fpui-table_page-link.previous.link-arrows(:class='{"hide-link": currentPage == 1}' @click="currentPage = currentPage - 1")
        i.fp4.fp4-chevron-right-light
      li.fpui-table_page-link(
        v-for='page in paginationList'
        :class='{"active": page == currentPage, "none": page == "…"}'
        @click="currentPage = page"
      )
        span {{ page }}
      li.fpui-table_page-link.next.link-arrows(:class='{"hide-link": (pages == 1) || (pages == currentPage)}' @click="currentPage = currentPage + 1")
        i.fp4.fp4-chevron-right-light
</template>

<script>
import _debounce from 'lodash/debounce'
import _get from 'lodash/get'
import _find from 'lodash/find'
import _sum from 'lodash/sum'
import _sumBy from 'lodash/sumBy'
import RecycleScroller from 'vue-virtual-scroller/src/components/RecycleScroller.vue'

export default {
  name: 'FpuiTable',
  components: {
    RecycleScroller
  },
  props: {
    loading: { type: Boolean, default: false },
    forceLoader: { type: Boolean, default: false },
    data: { type: Array, default: () => [] },
    columns: { type: Array, required: true },
    height: { type: String, default: 'auto' },
    actions: { type: Array, default: () => [] },
    pagination: { type: Object, default: () => ({ perPage: 25, disabled: false }) },
    rowKey: { type: String, default: 'id' },
    empty: { type: Object, default: () => ({ text: 'fpui-table.empty.text', icon: 'fp4 fp4-circle-info', html: null, header: true }) },
    onClick: { type: Function, default: null },
    defaultActiveRow: { type: Object, default: () => { } },
    currentActiveRow: { type: Object, default: () => null },
    activeRowClassname: { type: String, default: 'active' },
    sortOn: { type: String, default: null },
    sortReverse: { type: Boolean, default: false },
    boldRows: { type: Array, default: () => [] },
    alternativeRows: { type: Boolean, default: true },
    virtualScroll: { type: Boolean, default: false },
    disabledSort: { type: Boolean, default: false }
  },

  data () {
    return {
      windowWidth: 0,
      width: 0,
      parentWidth: 0,
      localCurrentPage: 1,
      localActiveRow: null,
      sort: {
        sortOn: this.columns.find(c => !!c.sortable), // Default column to sort
        reverse: this.columns.find(c => !!c.sortable)?.reverse || false
      },
      needScrollHorizontal: false
    }
  },
  computed: {
    /**
     * Filtered array containing columns without `if` === false
     * @return {array}
     */
    filteredColumns () {
      return this.columns.filter(column => !column.if || column.if(this.windowWidth))
    },
    /**
     * Computing the columns width with the grow system
     * width, minWidth, maxWidth -> Can be number or sync function returning a number
     * grow : Compute from the empty space, if no empty space, there is still a minimum to 50px
     * paddingLeft, paddingRight : Default to 0
     * if no max or no min, width is taken to fill the value
     * default : { width: null, maxWidth: null, minWidth: null, grow: 1 }
     * @return {array}
     */
    columnsWithWidth () {
      const totalGrow = _sum(this.filteredColumns.map(c => c.width ? 0 : c.grow || 1))
      const fixedWidth = _sum(this.filteredColumns.map(c => {
        if (typeof c.width === 'function') return c.width()
        return c.width || 0
      }))
      const emptyWidth = this.width - fixedWidth
      const columns = this.filteredColumns.map(c => {
        c.grow = c.grow || 1
        let width = c.width
        if (typeof width === 'function') width = width()
        if (typeof width === 'undefined') width = emptyWidth * c.grow / totalGrow
        if (width <= 0) width = 50

        let minWidth = c.minWidth
        if (typeof minWidth === 'function') minWidth = minWidth()
        if (typeof width === 'undefined') minWidth = width

        let maxWidth = c.maxWidth
        if (typeof maxWidth === 'function') maxWidth = maxWidth()
        if (typeof width === 'undefined') maxWidth = width

        const cellStyle = {
          width: `${width}px`,
          minWidth: `${minWidth}px`,
          maxWidth: `${maxWidth}px`
        }

        if (c.paddingLeft || c.paddingLeft === 0) cellStyle.paddingLeft = `${c.paddingLeft}px`
        if (c.paddingRight || c.paddingRight === 0) cellStyle.paddingRight = `${c.paddingRight}px`

        return {
          ...c,
          width,
          cellStyle
        }
      })
      return columns
    },
    /**
     * Returns raw data, that is just for renaming purpose
     * @return {array}
     */
    rawRows () {
      return this.data || []
    },
    /**
     * Returns sorted raw data
     * @return {array}
     */
    sortedRows () {
      const sortedRows = this.rawRows || []
      if (!this.sort?.sortOn || this.disabledSort) return sortedRows

      sortedRows.sort((a, b) => {
        // Handle real sortable
        const aValue = this.sortedValue(a, this.sort?.sortOn, b)
        const bValue = this.sortedValue(b, this.sort?.sortOn, a)
        if (aValue < bValue) {
          return this.sort?.reverse ? 1 : -1
        }
        if (aValue > bValue) {
          return this.sort?.reverse ? -1 : 1
        }

        return 0
      })

      return sortedRows
    },
    /**
     * Get table current page
     * @return {number}
     * Set table current page
     * @param {number} page
     */
    currentPage: {
      get () {
        if (this.localCurrentPage > this.pages) return 1
        return this.localCurrentPage
      },
      set (page) {
        if (page < 1 || page > this.pages || page === '…') return
        this.localCurrentPage = page
      }
    },
    /**
     * Returns how many rows per page the table contains
     * @return {number}
     */
    perPage () {
      return this.pagination?.perPage || 15
    },
    /**
     * Get table current page
     * @return {boolean}
     */
    disabledPagination () {
      if (this.pages < 2) return true
      return this.pagination?.disabled || false
    },
    /**
     * Get number of pages
     * @return {number}
     */
    pages () {
      return Math.ceil(this.rawRows?.length / this.perPage)
    },
    /**
     * Get rows computed from `pages` and `currentPage`
     * @return {array}
     */
    currentPageRows () {
      if (this.disabledPagination) return this.sortedRows
      const begin = (this.currentPage === 1) ? 0 : (this.currentPage * this.perPage) - this.perPage
      const end = (this.currentPage === 1) ? this.perPage : this.currentPage * this.perPage
      return this.sortedRows.slice(begin, end)
    },
    /**
     * Get pagination list
     * @return {array} [1, 2, 3, 4, 5 '…', 40]
     */
    paginationList () {
      if (this.pages > 0 && this.pages < 10) {
        return Array.from({ length: this.pages }, (v, k) => k + 1)
      } else {
        if (this.currentPage < 5) return [1, 2, 3, 4, 5, 6, 7, '…', this.pages]
        else if (this.currentPage > (this.pages - 5)) {
          return [1, '…', this.pages - 6, this.pages - 5, this.pages - 4, this.pages - 3, this.pages - 2, this.pages - 1, this.pages]
        } else {
          return [1, '…', this.currentPage - 2, this.currentPage - 1, this.currentPage, this.currentPage + 1, this.currentPage + 2, '…', this.pages]
        }
      }
    },
    /**
     * Get table current active row
     * @return {number}
     * Set table current active row
     * @param {number} rowkey
     */
    activeRow: {
      get () {
        return this.currentActiveRow || this.localActiveRow || this.defaultActiveRow
      },
      set (row) {
        this.localActiveRow = row
      }
    },
    /**
     * Empty state defaults
     */
    emptyState () {
      const noData = !this.currentPageRows?.length
      const header = ((noData && this.empty?.header !== undefined) ? this.empty?.header : true)
      const text = (this.empty.text !== undefined) ? this.empty.text : 'fpui-table.empty.text'
      const icon = (this.empty.icon !== undefined) ? this.empty.icon : 'fp4 fp4-circle-info'
      const html = this.empty.html || null
      return {
        text: text,
        icon: icon,
        html: html,
        header: header
      }
    },
    /**
     * Get the style of the body
     * In case of not auto height, we want it with a scroll in case of too many line
     */
    bodyStyle () {
      if (this.height === 'auto') return {}
      return {
        height: 'calc(100% - 32px)'
      }
    },
    bodyClass () {
      const classes = []
      if (this.height !== 'auto') classes.push('overflow-body')
      if (this.alternativeRows) classes.push('alternative-rows')
      return classes
    },
    /**
     * Table Head VueJS ref using component _uid
     */
    tableHeadRef () {
      return `fpui-table-head-${this._uid}`
    },
    /**
     * Table Body VueJS ref using component _uid
     */
    tableBodyRef () {
      return `fpui-table-body-${this._uid}`
    },
    tableStyle () {
      const style = {
        height: this.height
      }

      if (this.needScrollHorizontal) {
        style.width = 'fit-content'
      }
      if (this.virtualScroll && this.disabledPagination) {
        style['overflow-x'] = 'auto'
        style['overflow-y'] = 'hidden'
      }

      return style
    }
  },
  watch: {
    parentWidth: {
      handler () {
        this.needScrollHorizontal = this.needScrollHorizontalFunc()
      }
    }
  },
  mounted () {
    this.onResize()
    setTimeout(_ => {
      this.onResize()
    }, 50)
    this.onResize = _debounce(this.onResize.bind(this), 300)
    window.addEventListener('resize', this.onResize)
    if (this.sortOn) {
      const parsedSort = parseInt(this.sortOn)
      if (isNaN(parsedSort)) {
        this.sort.sortOn = _find(this.columns, { name: this.sortOn }) || this.columns.find(c => !!c.sortable)
        this.sort.reverse = this.sort.sortOn.reverse || false
      } else {
        this.sort.sortOn = this.columns[parsedSort]
      }
    }
    if (this.$refs[this.tableBodyRef]) {
      this.$refs[this.tableBodyRef].addEventListener('scroll', this.onScroll)
    }

    this.needScrollHorizontal = this.needScrollHorizontalFunc()
  },
  destroyed () {
    // On destroy, remove resize and scroll event listeners
    window.removeEventListener('resize', this.onResize)
    if (this.$refs[this.tableBodyRef]) this.$refs[this.tableBodyRef].removeEventListener('scroll', this.onScroll)
  },
  methods: {
    /**
     * Sets the window width from user's window inner width
     */
    onResize () {
      this.windowWidth = window.innerWidth
      this.width = this.$el.clientWidth
      this.parentWidth = this.$parent?.$el?.clientWidth
    },
    needScrollHorizontalFunc () {
      if (!this.virtualScroll) return false

      const columnTotalWidth = _sumBy(this.columnsWithWidth, (c) => {
        let minWidth = c.minWidth
        if (typeof minWidth === 'function') minWidth = c.minWidth()

        if (c.width < minWidth) return minWidth
        return c.width
      })

      return this.parentWidth && columnTotalWidth > this.parentWidth
    },
    /**
     * Synchronize header and body scroll when fullWidth is set to true
     */
    onScroll (event) {
      if (this.$refs[this.tableHeadRef]) {
        this.$refs[this.tableHeadRef].scrollLeft = event?.target?.scrollLeft
      }
    },
    /**
     * Sorts the value from the parameter. To use with the sort functionality
     * @param {object} row
     * @param {object} column
     * @param {object} compareWith
     * @return {any}
     */
    sortedValue (row, column, compareWith) {
      if (!column?.sortable) return
      let columnTarget = column.target
      if (column.sortable !== true) columnTarget = column.sortable
      if (typeof (columnTarget) === 'string') return _get(row, columnTarget) || '-'
      if (typeof (columnTarget) === 'function') {
        const parametersNumber = columnTarget?.length
        switch (parametersNumber) {
          case 1:
            return columnTarget(row)
          case 2:
            return columnTarget(row, compareWith)
          case 3:
            return columnTarget(row, compareWith, this.sort.reverse)
        }
      }
      return ''
    },
    /**
     * Retrieve key from the row parameter
     * @param {object} row
     * @return {string}
     */
    getKey (row) {
      return _get(row, this.rowKey, Math.random().toString(36).substring(7))
    },
    /**
     * Triggers on click method
     * @param {object} row
     */
    onRowClick (row) {
      if (this.onClick) {
        this.onClick(row)
        this.activeRow = row
      }
    },
    /**
   * Sorts the column from the parameter. To use with the sort functionality
   * @param {object} column
   */
    sortColumn (column) {
      if (!column.sortable) return
      if (this.sort.sortOn === column) {
        this.sort.reverse = !this.sort.reverse
        return
      }
      this.sort.sortOn = column
      this.sort.reverse = false
    }
  }
}
</script>
