<template lang="pug">
.subscription-new-step-confirm
  .header
    h4 {{ 'subscription.new.order' | translate }}
    p(v-if="!fromTrial") {{ `subscription.new.order.details.${plan}` | translate }}
    p(v-else) {{ `subscription.new.order.details.fromTrial` | translate }}

  .subscription-panel
    label.panel-title {{ 'subscription.new.billing_info' | translate }}
    .line(v-if="customerAccount")
      .panel-category {{ 'accounts.company' | translate }}
      .panel-value.company-name {{ customerAccount.billing_address.company }}
    .line(v-if="paymentSource.card")
      span.panel-category {{ 'subscription.new.order.payment_method' | translate }}
      .panel-value.payment-informations
        .big.payment-type {{ 'subscription.new.order.credit_card' | translate }}
        .little.card-number XXXX XXXX XXXX {{ paymentSource.card.last4 }}
        img.icon.card-type(:src="require(`@/core/assets/img/cards/${paymentSource.card.brand}.png`)")
  .subscription-panel.pricing
    .line.right
      .panel-category
        label.panel-title {{ 'subscription.new.order.subscription' | translate }}
      .panel-value.fixed-size
        .billing-period
          fpui-input-radio(
            :options="[{label:$t('subscription.new.billing_period.month'),value:'month'},{label:$t('subscription.new.billing_period.year'),value:'year'}]"
            :value="billingPeriod"
            @input="v=>$emit('billing-period',v)"
          )
    .line.right
      .panel-category {{ 'subscription.new.order.subscription_plan' | translate }}
      .panel-value.fixed-size
        div.large(v-if="plan")
          .plan
            img.icon(:src="require(`@/core/assets/img/plan/${plan}.png`)")
            span.plan {{ plan }}
        div.large(
          :class="{ inactive: billingPeriod === 'year' }"
        )
          .recurrence.monthly(v-if="planMonth")
            span {{planMonth.price | price(currencyCode,0) }}
        div.large(
          :class="{ inactive: billingPeriod === 'month' }"
        )
          .recurrence.yearly(v-if="planYear")
            span {{planYear.price | price(currencyCode,0) }}
            span.discount -{{ discountYear }}%

    .line.right
      .panel-category {{ 'subscription.new.order.support' | translate }}
      .panel-value.fixed-size
        div.large
          span {{ 'subscription.new.custom.support.' + support | translate }}
        div.large(
          :class="{ inactive: billingPeriod === 'year' }"
        )
          .recurrence.monthly(v-if="supportMonth")
            span {{ supportMonth.price | price(currencyCode,0) }}
        div.large(
          :class="{ inactive: billingPeriod === 'month' }"
        )
          .recurrence.yearly(v-if="supportYear")
            span {{ supportYear.price | price(currencyCode,0) }}


  .subscription-panel.committed-resources(v-if="committeds.length")
    .line.right
      .panel-category
        label.panel-title {{ $t('committeds.resources') }}
    .line.right(v-for='(commit,k) in committeds')
      .panel-category.less
        .provider(v-if="commit.provider == 'gcp'")
          img(:src="require('@/core/assets/img/providers/gcp.png')")
          span Google Cloud Platform
        .provider(v-if="commit.provider == 'azure'")
          img(:src="require('@/core/assets/img/providers/azure.png')")
          span Azure
        .provider(v-if="commit.provider == 'aws'")
          img(:src="require('@/core/assets/img/providers/aws.png')")
          span Amazon Web Services
        .provider(v-if="commit.provider == 'ovh'")
          img(:src="require('@/core/assets/img/providers/ovh.png')")
          span OVH
        .commitment {{ $t('committeds.term_unit.' + commit.term_commitment) }} {{ $t('committeds.term_commitment') }}
      .panel-value.fixed-size
        div.large
          span.big {{ commit.quantity }} DPU-S
        div.large
          div.committed-price(v-if='commit.billing_period_unit == "monthly"' v-html="$sanitize(committedPrice(commit))")
          div.committed-price(v-if='commit.billing_period_unit != "monthly"') -
        div.large
          div.committed-price(v-if='commit.billing_period_unit == "yearly"' v-html="$sanitize(committedPrice(commit))")
          div.committed-price(v-if='commit.billing_period_unit != "yearly"') -

  .subscription-panel.total-month(v-if="committeds.length")
    .line.right
      .panel-category
        label.panel-title Total
      .panel-value.fixed-size
        div(v-html="$sanitize(totalPrice)")

  .subscription-panel.today
    .line
      .panel-category
        label.panel-title(v-if="plan == 'standard' && !fromTrial") {{ 'subscription.new.order.after_trial_total' | translate }}
        label.panel-title(v-if="plan != 'standard' || fromTrial") {{ 'subscription.new.order.today_total' | translate }}
      .panel-value.fixed-size
        div.large
          span &nbsp;
        div.large
          span.total-price(v-html="$sanitize(todayPrice)")


</template>
<script>
import Price from '@/shared/filters/Price'

export default {
  props: {
    committeds: {
      type: Array,
      required: true
    },
    support: {
      type: String,
      required: true
    },
    paymentSource: {
      type: Object,
      required: true
    },
    billingPeriod: {
      type: String,
      required: true
    },
    customerAccountId: {
      type: String,
      required: false,
      default: null
    },
    plan: {
      type: String,
      required: true
    },
    fromTrial: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    planMonth () {
      return this.$store.getters.PLAN_BY_ID(`${this.plan}-monthly-${this.currencyCode.toLowerCase()}`)
    },
    planYear () {
      return this.$store.getters.PLAN_BY_ID(`${this.plan}-yearly-${this.currencyCode.toLowerCase()}`)
    },
    supportMonth () {
      const id = `support-${this.support}-monthly-${this.currencyCode.toLowerCase()}`
      return this.$store.getters.ADDON_BY_ID(id)
    },
    supportYear () {
      const id = `support-${this.support}-yearly-${this.currencyCode.toLowerCase()}`
      return this.$store.getters.ADDON_BY_ID(id)
    },
    discountYear () {
      const monthly = this.planMonth?.price || 0
      const yearly = this.planYear?.price || 0
      return (100 * monthly * 12 / yearly - 100).toFixed(0)
    },
    currencyCode () {
      return this.$store.getters.SESSION.preferences?.currency || 'EUR'
    },
    billingPeriodId () {
      return this.billingPeriod === 'year' ? 'yearly' : 'monthly'
    },
    customerAccount () {
      if (this.customerAccountId === null) return null
      return this.$store.getters.ACCOUNT_BY_ID(this.customerAccountId)
    },
    totalPrice () {
      const { month, year } = this.totalPriceCompute()
      const yearHtml = !year ? '<div class="large by_year"></div>' : `<div class="large by_year"><span class="big-price">${Price(year, this.currencyCode)}</span> / ${this.$t('committeds.year')}</div>`
      const monthHtml = !month ? '<div class="large by_month"></div>' : `<div class="large by_month"><span class="big-price">${Price(month, this.currencyCode)}</span> / ${this.$t('committeds.month')}</div>`
      const separator = month && year ? '<div class="separator">+</div>' : '<div class="separator"></div>'
      const priceHtml = `${monthHtml}${separator}${yearHtml}`
      return priceHtml
    },
    todayPrice () {
      const { month, year } = this.totalPriceCompute()
      return Price(month + year, this.currencyCode, 0)
    }
  },
  methods: {
    getPlan () {
      return this.$store.getters.PLAN_BY_ID(`${this.plan}-${this.billingPeriodId}-${this.currencyCode.toLowerCase()}`)
    },
    getSupport () {
      return this.$store.getters.ADDON_BY_ID(`support-${this.support}-${this.billingPeriodId}-${this.currencyCode.toLowerCase()}`)
    },
    totalPriceCompute () {
      let { month, year } = this.committedsPrice()
      const planObject = this.getPlan()

      if (planObject) {
        if (this.billingPeriod === 'month') month += planObject?.price || 0
        if (this.billingPeriod === 'year') year += planObject?.price || 0
      }

      const supportObject = this.getSupport()
      if (supportObject) {
        if (this.billingPeriod === 'month') month += supportObject?.price || 0
        if (this.billingPeriod === 'year') year += supportObject?.price || 0
      }

      return {
        year,
        month
      }
    },
    committedsPrice () {
      let byYear = 0
      let byMonth = 0
      for (const committed of this.committeds) {
        const product = this.getCommittedPlan(committed.provider, committed.billing_period_unit)
        if (!product) continue
        let price = this.computePrice(product, committed.quantity)
        if (!price) continue
        price = this.computeDiscount(price, committed.term_commitment)
        if (committed.billing_period_unit === 'yearly') {
          byYear += price
        } else if (committed.billing_period_unit === 'monthly') {
          byMonth += price
        }
      }
      return {
        year: byYear,
        month: byMonth
      }
    },
    getCouponDiscount (termCommitment = '1y') {
      let coupon
      switch (termCommitment) {
        case '2y':
          coupon = this.$store.getters.COUPON_BY_ID('committed-2years-discount')
          break
        case '3y':
          coupon = this.$store.getters.COUPON_BY_ID('committed-3years-discount')
          break
      }
      return coupon ? coupon.discount_percentage : 0
    },


    getCommittedPlan (provider, billingPeriod) {
      return this.$store.getters.PLAN_BY_ID(`committed-fpu-s-${provider}-${billingPeriod}-${this.currencyCode.toLowerCase()}`)
    },
    computePrice (planItem, qty = 0) {
      const unitPrice = this.computeUnitPrice(planItem, qty)
      if (!unitPrice) return unitPrice
      return unitPrice * qty
    },
    computeUnitPrice (plan, qty = 0) {
      if (!plan.tiers) return plan.price
      for (const tier of plan.tiers) {
        if (qty >= tier.starting_unit && (!tier.ending_unit || qty <= tier.ending_unit)) {
          return tier.price
        }
      }
      return null
    },

    computeDiscount (price, term_commitment = '1y') {
      const couponDiscount = this.getCouponDiscount(term_commitment)
      return ((100 - couponDiscount) * price) / 100
    },
    committedPrice (committed) {
      const product = this.getCommittedPlan(committed.provider, committed.billing_period_unit)
      if (!product) return null
      const price = this.computePrice(product, committed.quantity || 1)
      return Price(this.computeDiscount(price, committed.term_commitment), this.currencyCode, 0)
    }

  }
}
</script>
<style lang="less">
.subscription-new-step-confirm {
  padding: 20px 0;

  hr {
    margin: 10px 0;
  }

  .subscription-panel {
    text-align      : left;
    padding         : 13px 35px 10px 28px;
    width           : 686px;
    border          : 0.5px solid rgba(151, 167, 183, 0.21);
    margin          : 13px auto 13px;
    border-radius   : 5px;
    background-color: #FFFFFF;

    label.panel-title {
      color         : #0089C0;
      font-weight   : 600;
      font-variant  : small-caps;
      text-transform: uppercase;
      font-size     : 13px;
    }

    .billing-period {
      .fpui-input-radio {
        width: 33.33333%;
      }
      label {
        span {
          display:none
        }
      }
    }

    .line {
      display        : flex;
      flex-direction : row;
      flex-wrap      : nowrap;
      justify-content: space-between;
      align-content  : stretch;
      align-items    : center;
      line-height    : 28px;

      .panel-value {
        vertical-align: top;

        &.plans {
          display: flex;
        }

      }

      .payment-informations {
        display       : flex;
        flex-direction: row;
        flex-wrap     : nowrap;
        align-content : stretch;
        align-items   : center;

        >div:not(:first-child) {
          padding-left: 15px;
        }
      }

      .payment-type {
        font-weight: 700;
      }

      .card-number {
        color         : #3E4550;
        font-style    : italic;
        font-size     : 12px;
        letter-spacing: 0;
        line-height   : 28px;
      }

      .card-type {
        height     : 25px;
        margin-left: 10px;
      }

      .company-name {
        font-weight: 700;
      }
    }

    &.pricing,
    &.committed-resources,
    &.total-month,
    &.total-year,
    &.today {
      .line {
        display: block;

        .big {
          font-weight: 700;
        }

        &.right {
          .plan, .large {
            text-align: left;
          }
        }

        .panel-category {
          display       : inline-block;
          width         : 30%;
          vertical-align: top;

          .provider {
            display        : flex;
            flex-direction : row;
            flex-wrap      : nowrap;
            justify-content: flex-start;
            align-content  : stretch;
            align-items    : center;

            img {
              height      : 15px;
              margin-right: 4px;
            }
          }

          &.less {
            line-height  : 12px;
            margin-bottom: 10px;
          }

          .commitment {
            color: #CBD3DB;
          }
        }

        .panel-value {
          display   : inline-block;
          width     : 70%;
          text-align: right;
          font-size : 12px;

          .separator {
            display    : inline-block;
            position   : relative;
            left       : -33px;
            width      : 0;
            font-size  : 18px;
            font-weight: bold;
          }

          span.big-price {
            font-size  : 18px;
            font-weight: 600;
          }

          &.fixed-size {
            .large {
              display: inline-block;
              width  : 33.33%;
              &.inactive {
                color: #cbd3db;
                .fpui-input-radio {
                  label {
                    &:before {
                      border-color: #dedede;
                    }
                  }
                }
              }



              .recurrence {
                .big {
                  font-weight: 700;
                  font-size  : 18px;
                }

                &:not(.big) {
                  font-weight: 600;
                }

                &.yearly {
                  .discount {
                    color: #9EDF10;
                    font-size: 12px;
                    margin-left: 8px;
                  }
                }
              }

              .plan {
                img {
                  width       : 17px;
                  margin-right: 5px;
                  margin-top  : -2px;
                }

                span {
                  font-weight   : 700;
                  text-transform: capitalize;
                }
              }
            }
          }
        }

        .inner-price {

          .by_year,
          .by_month {
            font-weight: 600;

            span {
              font-weight: 700;
              font-size  : 24px;
            }
          }

          .separator {
            margin: 0 10px;
          }

          >div {
            display: inline-block;
          }
        }

        .total-price {
          font-size  : 24px;
          font-weight: 700;
        }

        .committed-price {

          .unit-price,
          .saved {
            display: none;
          }

          font-weight: 600;

          span {
            font-weight: 700;
            font-size  : 18px;
          }
        }

        .center-empty {
          color     : #CBD3DB;
          text-align: center;
        }
      }
    }

    &.today {
      .line {
        .panel-category {
          width         : 50%;
        }
        .panel-value {
          width     : 50%;
          .large {
            width: 50% !important;
            text-align: left;
            .total-price {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .total {
    text-align      : left;
    padding         : 5px 34px 5px 32px;
    width           : 600px;
    border          : 0.5px solid rgba(151, 167, 183, 0.21);
    margin          : 0 auto;
    border-radius   : 5px;
    background-color: #FFFFFF;

    .line {
      margin : 0;
      display: flex;

      .left {
        flex-grow     : 1;
        color         : #3E4550;
        font-size     : 12px;
        letter-spacing: 0;
        line-height   : 28px;
      }
    }
  }
}

</style>
