import { API } from '@/shared/plugins/Api/API'
import Certificates from './Certificates'
import Dataplants from './Dataplants'
import Prices from './Prices'
class FPAPI extends API {
  constructor (id) {
    super(id, true)
    this.dataplants = new Dataplants(id)
    this.prices = new Prices(id)
    this.certificate = new Certificates(id)
  }

  // Get default configuration
  async offers () {
    const offers = await this.request({
      method: 'get',
      url: '/offers'
    })

    return offers
  }

  // Get default configuration
  async configuration (organizationId) {
    const configuration = await this.request({
      method: 'get',
      url: `/configurations/${organizationId}`
    })

    return configuration
  }

  // Check domain availability
  async checkDomainAvailability (subdomain, domain = 'forepaas.io', organizationId) {
    const availability = await this.request({
      method: 'GET',
      url: `check_domain/${subdomain}/${domain}`,
      params: {
        organization_id: organizationId
      }
    })

    return availability
  }

  async preferences () {
    return this.request({
      url: 'iam/v4/preferences/hq'
    })
  }
}

export default FPAPI
