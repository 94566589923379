<template lang='pug'>
.advanced-search
  fpui-input-tags(
    v-model="model"
    :placeholder="$t('advanced-search.placeholder')"
    color="blue"
    icon="fp4 fp4-magnifying-glass"
    :autocomplete="autocomplete"
  )
    div.search-item(
      slot="tag-center"
      slot-scope="props"
      v-html="$sanitize(formatDisplay(props.tag.text))"
    )
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    autocomplete () {
      const res = []
      for (const key in this.options) {
        const values = this.options[key].values || []
        for (const v of values) {
          res.push({ text: `${key}=${v}` })
        }
      }
      return res
    },
    model: {
      get () {
        const value = []
        for (const key in this.value) {
          for (const v of this.value[key]) {
            for (const displayKey in this.options) {
              if (this.options[displayKey].field === key) {
                value.push(`${displayKey}=${v}`)
              }
            }
          }
        }
        return value
      },
      set (values) {
        const res = {}
        for (const val of values) {
          let [displayKey, v] = val.split('=')
          if (!v) {
            v = displayKey
            displayKey = 'Default'
          }
          const key = this.getOptionKey(displayKey).field
          res[key] = res[key] || []
          res[key].push(v)
        }
        this.$emit('input', res)
      }
    }
  },
  methods: {
    formatDisplay (value) {
      const [displayKey, v] = value.split('=')
      if (displayKey === 'Default') return v
      return `<span class="type">${displayKey}</span><span>${v}</span>`
    },
    getOptionKey (displayKey) {
      for (const dp in this.options) {
        if (dp.toLowerCase() === displayKey.toLowerCase()) return this.options[dp]
      }
    }
  }
}
</script>

<style lang="less">
.advanced-search {

  .fpui-input-tags.blue {
    li.ti-tag {
      height: 24px;
      background-color: #0089C0;
      border-radius: 12px;
      padding: 0 10px 0 15px;
      color: white;
      font-size: 13px;
      .search-item {
        font-weight: bold;
        .type {
          font-weight: normal;
          color: rgba(255,255,255,0.7);
          margin-right: 5px;
        }
      }
    }
    input {
      background: transparent
    }
    i.ti-icon-close:before {
      color: rgba(255,255,255,0.5);
    }
    ul.ti-tags {
      padding-left: 30px;
      display: flex;
      align-items: center;
    }

  }
}
</style>
