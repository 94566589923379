import Vue from 'vue'

export default {
  state: {},
  mutations: {
    SET_ACL (state, { key, value }) { // Not WS
      Vue.set(state, key, value)
    }
  },
  actions: {
    async LOAD_ALL_ACLS ({ commit }) {
      Promise.all([
        // Add list of acl use in UI here
      ].map(async acl => {
        try {
          const data = await Vue.api.IAM.can(acl.service, acl.resource, acl.action, acl.object)
          commit('SET_ACL', { key: acl.key, value: data })
        } catch (err) {}
      }))
    }
  },
  getters: {
    ACL (state) {
      return key => key in state ? state[key] : true
    }
  }
}

