<template lang='pug'>
  .fpui-helper.pointer
    i.fp4.fp4-circle-info(
      @click="$emit('click')"
      :class='className'
      v-tooltip="options"
      :style='style'
    )
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    debug: {
      type: Boolean
    },
    text: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'grey'
    },
    position: {
      type: String,
      default: 'right-end'
    },
    type: {
      type: String,
      default: ''
    },
    tooltipClass: {
      type: String,
      default: ''
    },
    hexColor: {
      type: String,
      validator: (hex) => {
        return /^#?[A-Fa-f\d]{3,6}$/gm.test(hex)
      },
      default: null
    },
    delay: { type: Number, default: 0 }
  },
  computed: {
    content () {
      if (this.title) {
        return `
          <div class="helper-title">${this.title}</div>
          <div class="separator"></div>
          <span>${this.text}</span>
        `
      }
      return this.text
    },
    options () {
      const ret = {
        content: this.content,
        html: true,
        placement: this.position,
        offset: 10,
        classes: []
      }
      if (this.type) ret.classes.push(this.type)
      if (this.tooltipClass) ret.classes.push(this.tooltipClass)
      if (this.delay !== null) ret.delay = { show: this.delay }
      if (this.debug) ret.delay = { hide: 100000 }
      return ret
    },
    className () {
      const className = []
      if (!this.hexColor) className.push('text-default')
      return className.join(' ')
    },
    style () {
      const style = {}
      if (this.hexColor) style.color = this.hexColor
      return style
    }
  }
}
</script>

<style lang='less'>
@import "~@/shared/styles/_variables.less";

.fpui-helper {
  display: inline-block;
  margin-left: 10px;
  margin-top: 0px;
  vertical-align: middle;
  i {
    font-size: 16px;
  }
  > i.fp4.text-default {
    color: #CBD3DB;
  }
  &:hover {
    > i.fp4.text-default {
      color: @blue_flash !important;
    }
  }
}
</style>
