import { API } from '@/shared/plugins/Api/API'

class Metrics extends API {
  list () {
    return this.request({
      method: 'get',
      url: 'v4/dashboard'
    })
  }
}

export default Metrics

export {
  Metrics
}
