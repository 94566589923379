<template lang="pug">
.tile-options-container
  i.fp4.fp4-ellipsis(
    :style="{ cursor: loading ? 'not-allowed' : 'pointer' }"
    @click="open"
  )
  .tile-options(
    v-if="showOptions"
    :style="{ right: positionRight }"
  )
    .chart-mode-selection-container(
      v-if="visualMode"
    )
      .chart-mode-selection
        fpui-input-select(
          :label="$t('dashboard.tile_options.visualize_as')"
          :value='displayChartMode'
          :options='modeOptions'
          :width-fit="false"
          :clearable="!!tileChartMode && tileChartMode !== chartMode"
          @input="(v) => switchChartMode(v)"
        )
      fpui-button(
        icon-only
        auto-width
        color="white"
        icon="fp4 fp4-gears"
        :disabled="!canEditQuery"
        v-tooltip="!canEditQuery ? { content: $t('cannot_edit'), delay: 0 } : ''"
        @click="displayTileSettings"
      )

    .options-list
      ul
        li(
          v-for="opt in options"
          v-if="!opt.hidden"
          :class="{ disabled: opt.disabled }"
          :key="opt.label"
          @click="() => opt.action()"
        ) {{ opt.label }}
</template>

<script>
import ConfirmModal from '@/shared/components/modals/Confirm'

export default {
  props: {
    loading: { type: Boolean, default: false },
    query: { type: Object, default: () => ({}) },
    tile: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      showOptions: false,
      elementLeft: 0,
      elementRight: 0,
      positionRight: '0'
    }
  },
  computed: {
    canEditQuery () {
      return this.$store.getters.ACL('canAmQueryWrite') && !this.readOnly
    },
    chartMode () {
      return this.query.chart_active || 'table'
    },
    modeOptions () {
      return [{
        icon: 'fp4 fp4-table',
        label: this.$t('dashboard.tile_options.table'),
        value: 'table'
      }, {
        icon: 'fp4 fp4-chart-line',
        label: this.$t('dashboard.tile_options.line'),
        value: 'line'
      }, {
        icon: 'fp4 fp4-chart-area',
        label: this.$t('dashboard.tile_options.area'),
        value: 'area'
      }, {
        icon: 'fp4 fp4-chart-bar',
        label: this.$t('dashboard.tile_options.bar'),
        value: 'bar'
      }, {
        icon: 'fp4 fp4-chart-scatter',
        label: this.$t('dashboard.tile_options.scatter'),
        value: 'scatter'
      }, {
        icon: 'fp4 fp4-chart-pie',
        label: this.$t('dashboard.tile_options.pie'),
        value: 'pie'
      }, {
        icon: 'fp4 fp4-chart-number',
        label: this.$t('dashboard.tile_options.big_number'),
        value: 'big-number'
      }, {
        icon: 'fp4 fp4-chart-gauge',
        label: this.$t('dashboard.tile_options.progress_bar'),
        value: 'gauge'
      // }, {
      //   icon: 'fp4 fp4-chart-multi',
      //   label: this.$t('dashboard.tile_options.combo_chart'),
      //   value: 'combo-chart'
      }]
    },
    tileChartMode () {
      return this.tile?.override_parameters?.chart_active
    },
    displayChartMode () {
      return this.tileChartMode || this.chartMode
    },
    visualMode () {
      return this.query.type === 'forepaas'
    },
    dashboardId () {
      return this.$route.params?.id
    },
    options () {
      return [
        {
          label: this.$t('dashboard.edit_tile.edit_tile'),
          action: () => {
            this.$router.push(`/${this.$route.params.dataplantId}/query/${this.query._id}?dashboardId=${this.dashboardId}&editTile=${this.tile.i}`)
            this.showOptions = false
          }
        },
        {
          label: this.$t('dashboard.edit_tile.replace_tile'),
          action: () => {
            this.$emit('replace-query')
            this.showOptions = false
          }
        },
        {
          label: this.$t('dashboard.edit_tile.create_alert'),
          action: () => {
            this.showOptions = false
          },
          hidden: true
        },
        {
          label: this.$t('dashboard.edit_tile.download_content'),
          action: () => {
            this.$emit('download-content')
            this.showOptions = false
          }
        },
        {
          label: this.$t('dashboard.edit_tile.duplicate_tile'),
          action: () => {
            this.$emit('duplicate-tile')
            this.showOptions = false
          }
        },
        {
          label: this.$t('dashboard.edit_tile.delete_tile'),
          action: async () => {
            const icon = 'fp4 fp4-triangle-exclamation-small color-yellow'
            const title = this.$t('treeview.tile.remove.modal.title')
            let content = []
            content.push(`<span style="font-size:14px">${this.$t('treeview.remove.modal.content_head')} <span style="font-weight:600"> ${this.tile.title}</span>${this.$t('treeview.remove.modal.question_mark')}<br />${this.$t('treeview.remove.modal.content_tail')} </span>`)
            content = ['<div>', ...content, '</div>']
            content = content.join('')
            const contentHtml = true

            const confirm = await ConfirmModal(this, {
              icon,
              title,
              content,
              contentHtml,
              yes: {
                text: this.$t('fpui-modal-confirm.remove'),
                color: 'red'
              }
            })
            if (confirm) this.$emit('delete-tile')
            this.showOptions = false
          }
        }
      ]
    },
    editChartSettings () {
      return this.$store.getters.DASHBOARD_QB_EDIT_TILE_CHART_SETTINGS
    }
  },
  watch: {
    'tile.width': {
      handler () {
        this.onResize()
      }
    },
    showOptions: {
      handler (val) {
        if (val) this.$emit('active-tile')
        else this.$emit('deactive-tile')
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.close)
    window.addEventListener('resize', this.onResize)
    process.nextTick(() => {
      this.onResize()
    })
  },
  destroyed () {
    document.removeEventListener('click', this.close)
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    open () {
      if (this.loading) return
      this.showOptions = !this.showOptions
    },
    switchChartMode (mode) {
      this.$analytics.track('Edit chart configuration', {
        shared_id: this.tile.query,
        chart_type: mode,
        origin: 'dashboard'
      })

      this.$emit('update-tile', { chart_active: mode })
      if (this.editChartSettings) this.$store.commit('FOREPAAS_QB_EDIT_TILE_CHART_SETTINGS', { query: this.query, type: mode || this.chartMode, tile: this.tile })
    },
    isParent (target) {
      if (target === this.$el) return true
      if (target.parentNode) return this.isParent(target.parentNode)
      return false
    },
    close (event) {
      if (event && this.isParent(event.target)) return
      this.showOptions = false
    },
    filtersClasses () {
      const view = document.querySelector('.view')?.getBoundingClientRect()
      if (!view) return

      const width = 275

      if (this.elementRight - view.left < width) this.positionRight = Math.round(-(width - (this.elementRight - view.left - 30))) + 'px'
      else this.positionRight = '0'
    },
    onResize () {
      this.elementLeft = this.$el?.getBoundingClientRect()?.left
      this.elementRight = this.$el?.getBoundingClientRect()?.right
      this.direction = this.filtersClasses()
    },
    displayTileSettings () {
      this.$store.commit('FOREPAAS_QB_EDIT_TILE_CHART_SETTINGS', { query: this.query, type: this.displayChartMode, tile: this.tile })
      this.close()
    }
  }
}
</script>

<style lang="less">
  .tile-options-container {
    .fp4-ellipsis {
      font-size: 20px;
      color: #CBD3DB;
      cursor: pointer;

      &:hover {
        color: #0089c0;
      }
    }
    .tile-options {
      width: 275px;
      background: white;
      box-shadow: 0px 3px 13px rgba(151, 167, 183, 0.3);
      border-radius: 7px;
      position: absolute;
      top: 18px;
      right: 0;
      z-index: 10;

      .chart-mode-selection-container {
        display: flex;
        align-items: center;
        padding: 0 20px;
        height: 82px;
        border-bottom: 1px solid #E4E7EC;
        position: relative;

        .chart-mode-selection {
          width: 193px;

          .fpui-input-select-container {
            i.icon {
              font-size: 20px;
              margin-right: 10px;
              color: #97A7B7;
            }
          }
        }

        .button-container {
          position: absolute;
          right: 20px;
          bottom: 14px;

          .fpui-button {
            padding-left: 7px;
            padding-right: 7px;

            i {
              font-size: 22px !important;
            }
          }
        }
      }

      .options-list {
        ul {
          padding: 0;
          margin: 0;
          li {
            list-style: none;
            padding: 12px 20px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #3E4550;
            cursor: pointer;

            &:hover {
              background: rgba(246, 249, 252);
            }

            &:last-of-type {
              border-bottom-left-radius: 7px;
              border-bottom-right-radius: 7px;
            }

            &.disabled {
              border: unset !important;
              cursor: not-allowed !important;
              color: rgba(62,69,80,0.65);
            }
          }
        }
      }
    }
  }
</style>
