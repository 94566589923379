import Pipelines from './Pipelines'
import Repositories from './Repositories'
import { Environments, environmentModel } from './Environments'
import Events from './Events'
import Notebooks from './Notebooks'
import Models from './Models'
import Jobs from './Jobs'
import Quotas from './Quotas'
import API from '@/shared/plugins/Api/API'

class ML extends API {
  constructor (id) {
    super(id)
    this.pipelines = new Pipelines(id)
    this.repositories = new Repositories(id)
    this.environments = new Environments(id)
    this.environmentModel = environmentModel
    this.events = new Events(id)
    this.notebooks = new Notebooks(id)
    this.models = new Models(id)
    this.jobs = new Jobs(id)
    this.quotas = new Quotas(id)
  }

  templatesPipelinesAggregate (source, destination) {
    return this.request({
      method: 'GET',
      url: '/v2/templates/pipelines/aggregate',
      params: {
        source_db: source.database_name,
        source: source.table_name,
        destination_db: destination.database_name,
        destination: destination.table_name
      }
    })
  }

  fetchRepositoryBranches (url) {
    return this.request({
      method: 'GET',
      url: '/v2/git/branches/list',
      params: {
        url: url
      }
    })
  }

  fetchPublicKey () {
    return this.request({
      method: 'GET',
      url: '/v2/git/public_key'
    })
  }

  // Used to get the bucket name of ML
  getConfigurations () {
    return this.request({
      method: 'GET',
      url: '/v2/configurations'
    })
  }

  // Pipeline boilerplates
  customPipelineBoilerplate (language = 'python') {
    return this.request({
      method: 'GET',
      url: '/v2/templates/pipelines/custom',
      params: {
        language: language
      }
    })
  }

  async import (item) {
    return this.request({
      method: 'POST',
      url: 'v4/backup/import',
      data: item,
      timeout: 60 * 10 * 1000
    })
  }
}
export default ML
