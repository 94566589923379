<template lang="pug">
  auto-form-object.auto-form(
    @input="update"
    :options="options"
    :value="value"
    :disabled="disabled"
    :display-only="displayOnly"
    :class="{'display-only':displayOnly}"
    :autoi18n='autoi18n'
  )
</template>

<script>
export default {
  props: {
    options: { type: Object, default: () => {} },
    displayOnly: { type: Boolean, default: false },
    value: { type: Object, default: () => {} },
    autoi18n: Boolean, // Automatically translate tooltip & label
    disabled: Boolean
  },
  methods: {
    update (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="less">
</style>
