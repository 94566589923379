const checkSaveMessages = (messages) => Object.keys(messages).some(key => messages[key].length > 0)

export default {
  html (saveMessages, elem = 'main') {
    const iconTypes = {
      success: 'fp4 fp4-circle-check-small',
      info: 'fp4 fp4-circle-info',
      warning: 'fp4 fp4-triangle-exclamation-small',
      error: 'fp4 fp4-circle-exclamation'
    }
    return `${Object.keys(saveMessages).map(type => {
      const list = saveMessages[type]
      const className = ['message-block', type]
      if (saveMessages[type].length > 0) className.push('show')
      return `<div class="${className.join(' ')}">
          <div class="flexy">
            <div class="icon-container text-center">
              <i class="${iconTypes[type]}"></i>
            </div>
            <div class="message-content">
              ${list.map((message) => `<p><span>${message.message}</span></p>`).join('')}
            </div>
            <div class="icon-container close-icon text-center">
              <i onclick="fpuiMessageBlockRemove('${type}', null, '${elem}')" id='${this.idRemove}-${type}' class="fp4 fp4-xmark"></i>
            </div>
          </div>
        </div>`
    }).join('')}`
  },
  update (saveMessages, elem = 'main') {
    const messageElem = document.getElementById(this.id(elem))
    if (messageElem) {
      messageElem.innerHTML = this.html(saveMessages, elem)
      return messageElem
    }
    if (!messageElem && checkSaveMessages(saveMessages)) {
      return this.create(saveMessages, elem)
    }
  },
  create (saveMessages, elem = 'main') {
    let main = document.querySelector(elem)
    if (!main) {
      elem = 'main'
      main = document.querySelector(elem)
    }
    const el = document.createElement('div')
    el.id = this.id(elem)
    el.classList.add('message-box')
    el.innerHTML = this.html(saveMessages, elem)
    if (main) main.appendChild(el)
    return el
  },
  id (elem = 'main') { return `fpuiMessageBox-${elem}` },
  idRemove: 'message-box-remove'
}
