<template lang='pug'>
  .fp-loader.fp-loader-container(:class='{ "with-text": text }',:style="{ background: containerBackground }")
    .fp-loader-forepaas.loader(:style="{background:background}")
      img(:src="require(`@/shared/assets/loaders/${url}`)",v-if="ext==='gif'")
      object(v-if="ext==='svg'",type="image/svg+xml",:data="require(`@/shared/assets/loaders/${url}`)")
    span.fp-loader-text(v-if='text') {{ text }}
</template>

<script>

export default {
  props: {
    type: {
      type: String,
      default: 'default'
    },
    text: { type: String, default: '' }
  },
  computed: {
    list () {
      return {
        default: {
          id: 'circle3',
          containerBackground: 'rgba(0, 0, 0, 0.03)',
          ext: 'svg'
        },
        page: {
          id: 'circle3',
          ext: 'svg'
        },
        chart: {
          id: 'dots3',
          ext: 'svg'
        }
      }
    },
    containerBackground () {
      return this.list[this.type].containerBackground
    },
    background () {
      return this.list[this.type].background
    },
    ext () {
      return this.list[this.type].ext
    },
    url () {
      return `${this.list[this.type].id}.${this.list[this.type].ext}`
    }
  }
}
</script>

<style lang='less'>
  .fp-loader-container {
    position:absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    z-index: 999;
    .loader {
      top: 50%;
      left: 50%;
      position:absolute;
      padding: 20px;
      border-radius: 100px;
      width: 120px;
      height: 120px;
      margin-left:-60px;
      margin-top:-60px;
      img {
        position: relative;
        width:40px;
        margin-top:-20px;
        margin-left:-20px;
        top: 50%;
        left: 50%;
      }
    }
    &.with-text {
      .loader {
        top: 45% !important;
      }
      .fp-loader-text {
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 18px;
        text-align: center;
        z-index: 2147483647;
      }
    }
  }
</style>
