/* eslint-disable camelcase */
const findAttributeMeta = (attributeName, metas, supportedStorage, dbName = null) => {
  let attribute = null

  for (const tableKey in metas.tables) {
    const table = metas.tables[tableKey]
    let databaseName = table.database

    if (databaseName.includes('.')) {
      databaseName = databaseName.split('.')[1]
    }

    const dbms = metas.databases[databaseName]?.dbms

    if (supportedStorage.includes(dbms)) {
      for (const attrKey in table.attributes) {
        const attr = table.attributes[attrKey]

        if (dbName === null) {
          if (attrKey === attributeName) {
            attribute = attr
            break
          }
        } else {
          if (attrKey === attributeName && dbName === databaseName) {
            attribute = attr
            break
          }
        }
      }
    }

    if (attribute !== null) {
      break
    }
  }

  return attribute
}

const bucketMetaSchema = (datawarehouse, supportedStorage = ['mysql', 'postgresql', 'snowflake', 'trino']) => {
  const bucketData = {}
  const bucketFilter = {}
  const bucketScales = {}
  const bucketEvols = ['weekday', 'week', 'month', 'year']
  const bucketComputes = {
    max: 'MAX([x])',
    min: 'MIN([x])',
    count: 'COUNT([x])',
    sum: 'SUM([x])',
    avg: 'AVG([x])',
    select: '[x]',
    select_distinct: 'DISTINCT [x]',
    count_distinct: 'COUNT(DISTINCT [x])'
  }
  const bucketOrders = {}
  const bucketTranslation = {}
  let bucketDiamonds = []

  const diamondMetas = datawarehouse.diamonds || []
  let is_measure = 1
  let diamonds = null
  let meta = null
  let d = []
  let translation = ''
  let i = 0

  bucketDiamonds = diamondMetas

  if (!datawarehouse.tables || Object.keys(datawarehouse.tables).length === 0) {
    return {
      bucketData,
      bucketFilter,
      bucketScales,
      bucketEvols,
      bucketComputes,
      bucketDiamonds,
      bucketOrders,
      bucketTranslation
    }
  }

  datawarehouse.aliases = datawarehouse.aliases || {}

  for (const alias in datawarehouse.aliases) {
    is_measure = 1
    diamonds = null
    meta = null
    d = []
    translation = ''

    datawarehouse.aliases[alias].forEach((attr) => {
      const attribute = attr
      meta = findAttributeMeta(attribute, datawarehouse, supportedStorage)
      if (meta) {
        is_measure = meta.is_measure || 0
      }

      bucketDiamonds.forEach((diamond) => {
        if (
          (diamond.measures.includes(attr) || diamond.dimensions.includes(attr)) && !d.includes(diamond.name)) {
          d.push(diamond.name)
        }
      })

      if (datawarehouse.aliases[alias].length === 1) {
        bucketTranslation[alias] = datawarehouse.aliases[alias]
      }
    })

    if (d.length > 0) {
      diamonds = d
    }

    if (is_measure === 1) {
      let parameter = null
      if (datawarehouse.aliases[alias].length === 1) {
        try {
          parameter = meta.parameters
        } catch (t) {
          // TODO: handle error
        }
      }
      bucketData[alias] = {
        name: alias,
        functions: ['sum'],
        diamonds,
        parameter,
        default: 'sum'
      }
    }
    bucketScales[alias] = alias
    bucketOrders[alias] = alias
    bucketTranslation['range_' + alias] = datawarehouse.aliases[alias]
    translation = 'range_' + alias
    bucketFilter['range_' + alias] = alias + " BETWEEN '[d1]' AND '[d2]'"
    bucketFilter[alias] = alias

    while (i < diamondMetas.length) {
      if (d.includes(diamondMetas[i].name)) {
        if (is_measure === 1) {
          diamondMetas[i].measures.push(alias)
        } else {
          diamondMetas[i].dimensions.push(alias)
          if (translation !== '') {
            diamondMetas[i].dimensions.push(translation)
          }
        }
      }
      i++
    }
  }

  let is_active = true
  let parameters = null
  let count = 0
  datawarehouse.virtual_attributes = datawarehouse.virtual_attributes || {}

  for (const attribute in datawarehouse.virtual_attributes) {
    is_active = datawarehouse.virtual_attributes[attribute].is_active || true
    if (is_active) {
      diamonds = null
      i = 0
      d = []
      count = datawarehouse.virtual_attributes[attribute].required_attributes_names.length
      parameters = datawarehouse.virtual_attributes[attribute].parameters

      while (i < diamondMetas.length) {
        let increment = 0
        datawarehouse.virtual_attributes[attribute].required_attributes_names.forEach((attr) => {
          if (
            diamondMetas[i].measures.includes(attr) ||
                        diamondMetas[i].dimensions.includes(attr)
          ) {
            increment += 1
          }
        })

        if (count === increment) {
          if (datawarehouse.virtual_attributes[attribute].is_measure) {
            diamondMetas[i].measures.push(attribute)
          } else {
            diamondMetas[i].dimensions.push(attribute)
          }
          d.push(diamondMetas[i].name)
        }
        i++
      }

      if (d.length > 0) {
        diamonds = d
      }

      if (datawarehouse.virtual_attributes[attribute].is_measure) {
        let compute = 'select'
        if (datawarehouse.virtual_attributes[attribute].is_summable) {
          compute = 'sum'
        }
        bucketData[attribute] = {
          name: attribute,
          functions: [compute],
          diamonds,
          parameters,
          default: compute
        }
      } else {
        bucketScales[attribute] = datawarehouse.virtual_attributes[attribute].sql
        bucketOrders[attribute] = datawarehouse.virtual_attributes[attribute].sql
        bucketFilter[attribute] = datawarehouse.virtual_attributes[attribute].sql
      }
    }
  }

  let dbName = ''
  let attribute_name = ''
  const source_transform = ''
  let is_summable = true
  let computes = ['sum']
  let defaultVal = null

  Object.values(datawarehouse.tables).forEach((table) => {
    dbName = table.database
    if (dbName.includes('.')) {
      dbName = dbName.split('.')[1]
    }
    const dbms = datawarehouse.databases[dbName].dbms

    if (supportedStorage.includes(dbms)) {
      for (const attribute in table.attributes) {
        attribute_name = table.attributes[attribute].name
        is_summable = table.attributes[attribute].is_summable || true
        computes = ['sum']
        defaultVal = 'sum'
        is_measure = table.attributes[attribute].is_measure || 0
        diamonds = null
        translation = ''
        d = []

        bucketDiamonds.forEach((diamond) => {
          if (
            (diamond.measures.includes(attribute_name) ||
                            diamond.dimensions.includes(attribute_name)) &&
                        !d.includes(diamond.name)
          ) {
            d.push(diamond.name)
          }
        })

        if (d.length > 0) {
          diamonds = d
        }

        if (is_measure === 1) {
          if (source_transform.includes('/') || !is_summable) {
            computes = ['select']
            defaultVal = 'select'
          }
          bucketData[attribute_name] = {
            name: attribute_name,
            functions: computes,
            diamonds,
            parameters: table.attributes[attribute].parameters,
            default: defaultVal
          }
        }

        bucketScales[attribute_name] = attribute_name
        bucketOrders[attribute_name] = attribute_name
        bucketFilter['range_' + attribute_name] = attribute_name + " BETWEEN '[d1]' AND '[d2]'"
        bucketTranslation['range_' + attribute_name] = [attribute_name]
        translation = 'range_' + attribute_name
        bucketFilter[attribute_name] = attribute_name

        let i = 0
        while (i < diamondMetas.length) {
          if (d.includes(diamondMetas[i].name)) {
            if (is_measure === 0 && translation !== '' && !diamondMetas[i].dimensions.includes(translation)) {
              diamondMetas[i].dimensions.push(translation)
            }
          }
          i++
        }
      }
    }
  })

  bucketDiamonds = diamondMetas

  return {
    bucketData,
    bucketFilter,
    bucketScales,
    bucketEvols,
    bucketComputes,
    bucketDiamonds,
    bucketOrders,
    bucketTranslation
  }
}

module.exports = bucketMetaSchema
