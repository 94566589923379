<template lang="pug">
.tile-filters-options-container(
  :class="{ 'tile-is-small': tileIsSmall }"
)
  .tile-filters-options-applied(
    :style="{ cursor: editing ? 'pointer' : 'default', 'padding-right': ignoredFiltersFiltered.length ? '3px' : '6px', background: tileIsSmall && !filtersIrrelevant.length && !ignoredFiltersFiltered.length ? 'none' : '#F6F9FC' }"
    v-tooltip="{ content: !editing && ignoredFiltersFiltered.length ? ignoredFiltersTooltip : '', delay: 0 }"
    @click="open"
  )
    span(v-if="!ignoredFiltersFiltered.length && filtersIrrelevant.length")
      span(
        v-if="!tileIsSmall"
      ) {{ $t('dashboard.filter_irrelevant_count', [filtersIrrelevant.length], filtersIrrelevant.length) }}
      i.fp4.fp4-triangle-exclamation(
        v-tooltip="{ content: tileIsSmall ? $t('dashboard.filter_irrelevant_count', [filtersIrrelevant.length], filtersIrrelevant.length) : '', delay: 0 }"
      )

    span(v-else-if="ignoredFiltersFiltered.length")
      span(
        v-if="!tileIsSmall"
      ) {{ $t('dashboard.no_filter_applied_count', [ignoredFiltersFiltered.length], ignoredFiltersFiltered.length) }}
      i.fp4.fp4-circle-exclamation(
        v-if="editing"
        v-tooltip="{ content: tileIsSmall ? $t('dashboard.no_filter_applied_count', [ignoredFiltersFiltered.length], ignoredFiltersFiltered.length) : '', delay: 0 }"
      )
      i.fp4.fp4-circle-info(
        v-if="!editing"
        v-tooltip="{ content: tileIsSmall ? $t('dashboard.no_filter_applied_count', [ignoredFiltersFiltered.length], ignoredFiltersFiltered.length) : '', delay: 0 }"
      )

    span(v-else-if="!tileIsSmall") {{ $t('dashboard.all_filters_applied') }}

  .tile-filters-options(
    v-if="showFiltersOptions"
    :style="{ height: options.length < 6 ? 'auto' : '264px', 'overflow-y': options.length < 6 ? 'hidden' : 'auto', right: positionRight }"
  )
    .title {{ $t('dashboard.filter.select_filters_to_ignore') }}
    .filters-list
      ul
        li(
          v-for="opt in options"
          :key="opt"
          @click="updateIgnoredFiltersList(opt)"
        )
          fpui-input-checkbox(
            :value="ignoredFiltersList.includes(opt)"
            :disabled="!editing"
            @input="updateIgnoredFiltersList(opt)"
          ) {{ opt }}
          i.fp4.fp4-triangle-exclamation(
            v-if="filtersIrrelevant.includes(opt)"
            v-tooltip="{ content: $t('dashboard.filter_irrelevant_tooltip'), delay: 0 }"
          )

    .apply-button(
      v-if="editing"
    )
      fpui-button(
        color="blue-flash"
        @click="applyIgnoredFilters"
      ) {{ $t('dashboard.filter.apply') }}
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'

export default {
  props: {
    dashboard: { type: Object, default: () => ({}) },
    tile: { type: Object, default: () => ({}) },
    filtersIrrelevant: { type: Array, default: () => [] },
    editing: { type: Boolean, default: false },
    tileIsSmall: { type: Boolean, default: false }
  },
  data () {
    return {
      showFiltersOptions: false,
      ignoredFiltersList: [],
      elementLeft: 0,
      elementRight: 0,
      positionRight: '0'
    }
  },
  computed: {
    filters () {
      return Object.keys(this.dashboard.filter || {})
    },
    ignoredFilters () {
      return this.tile.ignored_filters || []
    },
    ignoredFiltersFiltered () {
      return this.ignoredFilters.map(f => {
        if (this.filters.includes(f)) return f
        return null
      }).filter(f => f)
    },
    options () {
      if (!this.editing) return this.ignoredFiltersList

      return [...this.filters].sort()
    },
    ignoredFiltersTooltip () {
      if (!this.ignoredFiltersFiltered.length) return ''
      return `${this.$t('dashboard.filter.not_applied_deploy')} <strong>${this.ignoredFiltersFiltered.join(', ')}</strong>`
    }
  },
  watch: {
    'tile.ignored_filters': {
      handler (val) {
        this.ignoredFiltersList = _cloneDeep(val)
      }
    },
    'tile.width': {
      handler () {
        this.onResize()
      }
    },
    showFiltersOptions: {
      handler (val) {
        if (val) this.$emit('active-tile')
        else this.$emit('deactive-tile')
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.close)

    if (this.ignoredFiltersFiltered.length) this.ignoredFiltersList = this.ignoredFiltersFiltered

    window.addEventListener('resize', this.onResize)
    process.nextTick(() => {
      this.onResize()
    })
  },
  destroyed () {
    document.removeEventListener('click', this.close)
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    open () {
      if (!this.editing) return
      this.showFiltersOptions = !this.showFiltersOptions
    },
    isParent (target) {
      if (target === this.$el) return true
      if (target.parentNode) return this.isParent(target.parentNode)
      return false
    },
    close (event) {
      if (event && this.isParent(event.target)) return
      this.showFiltersOptions = false
    },
    updateIgnoredFiltersList (filter) {
      if (!this.editing) return

      if (this.ignoredFiltersList.includes(filter)) this.ignoredFiltersList = this.ignoredFiltersList.filter(f => f !== filter)
      else this.ignoredFiltersList.push(filter)
    },
    applyIgnoredFilters () {
      this.close()
      this.$emit('ignored-filters-updated', _cloneDeep(this.ignoredFiltersList))
    },
    filtersClasses () {
      const view = document.querySelector('.view')?.getBoundingClientRect()
      if (!view) return

      const width = 294

      if (this.elementRight - view.left < width) this.positionRight = Math.round(-(width - (this.elementRight - view.left - 30))) + 'px'
      else this.positionRight = '0'
    },
    onResize () {
      this.elementLeft = this.$el?.getBoundingClientRect()?.left
      this.elementRight = this.$el?.getBoundingClientRect()?.right
      this.direction = this.filtersClasses()
    }
  }
}
</script>

<style lang="less">
  .tile-filters-options-container {
    .tile-filters-options-applied {
      height: 20px;
      padding: 0 3px 0 6px;
      background: #F6F9FC;
      border-radius: 4px;

      span {
        font-weight: 400;
        font-size: 9px;
        line-height: 20px;
        color: #B2BECA;
        display: flex;
        align-items: center;
        height: 20px;

        .fp4-triangle-exclamation, .fp4-circle-exclamation, .fp4-circle-info {
          margin-left: 3px;
          font-size: 12px;
        }
        .fp4-triangle-exclamation {
          color: #FFA312;
        }

        .fpui-helper {
          display: flex;
        }
      }
    }

    .tile-filters-options {
      width: 294px;
      background: white;
      box-shadow: 0px 3px 13px rgba(151, 167, 183, 0.3);
      border-radius: 7px;
      position: absolute;
      top: 25px;
      right: 30px;
      z-index: 10;

      .title {
        padding: 10px 20px 5px;
        height: 28px;
        font-weight: 600;
        font-size: 10px;
        line-height: 13px;
        color: #97A7B7;
      }

      .filters-list {
        ul {
          padding: 0;
          margin: 10px 0 0 0;
          li {
            list-style: none;
            padding: 12px 20px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #3E4550;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:hover {
              background: rgba(246, 249, 252);
            }

            .fpui-input-checkbox {
              margin-right: 0;
              cursor: pointer;

              .check {
                background: white;
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.03);
              }

              .content-checkbox {
                width: 200px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }

              &.disabled {
                .check {
                  display: none;
                }
              }
            }

            .fp4-triangle-exclamation {
              margin-top: 4px;
              color: #FFA312;
            }
          }
        }
      }

      .apply-button {
        width: 100%;
        height: 52px;
        padding: 10px;

        .fpui-button {
          width: 100%;
        }
      }
    }

    &.tile-is-small {
      .tile-filters-options-applied {
        padding: 0 3px;

        .fp4-triangle-exclamation, .fp4-circle-exclamation, .fp4-circle-info {
          margin-left: 0;
        }
      }
    }
  }
</style>
