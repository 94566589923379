<template lang="pug">
 fpui-modal
    fpui-modal-header(
        :title="$t(title)"
        closeTopButton
        @close='$emit("close")'
      )
    monaco-editor(:value='value', :language='language', :readOnly='readOnly')
</template>
<script>
import MonacoEditor from '../../editors/MonacoEditor.vue'
export default {
  components: {
    MonacoEditor
  },
  props: {
    title: {
      type: String,
      default: 'fpui-dropzone.editfile'
    },
    language: {
      type: String,
      default: 'python'
    },
    value: {
      required: true,
      type: String
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="less" scoped>
.modal-header {
    display: flex;
}
</style>
