<template lang="pug">
.subscription-new-step-plan
  .header
    h4 {{ 'subscription.new.plan' | translate }}
    p {{ 'subscription.new.plan.details' | translate }}


  .request-subscription(v-if="contacted") {{'subscription.new.contacted'|translate}}

  div(v-if="!contacted")
    .billing-periods
      .month.billing-period(:class="{ active: billingPeriod === 'month' }" @click="setPeriod('month')") {{'subscription.new.billing_period.month'|translate}}
      .year.billing-period(:class="{ active: billingPeriod === 'year' }" @click="setPeriod('year')") {{'subscription.new.billing_period.year'|translate}}

    .plans
      .plan
        .plan-content
          img(:src="require(`@/core/assets/img/plan/standard.png`)")
          .name {{'subscription.new.standard.title'|translate}}
          .description {{'subscription.new.standard.description'|translate}}
          .price(v-if="standardPlan")
            .big {{ standardPlan.price | price(currencyCode) }}&nbsp;
              .trial(v-if="standardPlan.trial_period && !trial") {{'subscription.new.trial'|translate([standardPlan.trial_period,standardPlan.trial_period_unit])}}
            .desc
              .description {{ `organization.plan.by_${billingPeriod}` | translate }}
              .description2 {{'subscription.new.starting_at'|translate}}
          .price(v-else)
          .discount(v-if="billingPeriod === 'year' && discount('standard')") -{{ discount('standard') }}% {{ 'committeds.saved' | translate }}
          .features
            .feature
              i.fp4.fp4-circle-check
              span {{'subscription.new.standard.feature.1'|translate}}
            .feature
              i.fp4.fp4-circle-check
              span {{'subscription.new.standard.feature.2'|translate}}
            .feature
              i.fp4.fp4-circle-check
              span {{'subscription.new.standard.feature.3'|translate}}

          .text-center.select-btn
            fpui-button(v-if="!standardPlan" color="blue-flash" auto-width @click="contactUs('standard')") {{'subscription.new.contact-us'|translate}}
            fpui-button(v-else color="blue-flash" auto-width @click="selectPlan('standard')") {{'subscription.new.select'|translate}}


      .plan
        .plan-content
          img(:src="require(`@/core/assets/img/plan/premium.png`)")
          .name {{'subscription.new.premium.title'|translate}}
          .description {{'subscription.new.premium.description'|translate}}
          .price(v-if="premiumPlan")
            .big {{ premiumPlan.price | price(currencyCode) }}&nbsp;
              .trial(v-if="premiumPlan.trial_period && !trial") {{'subscription.new.trial'|translate([premiumPlan.trial_period,premiumPlan.trial_period_unit])}}
            .desc
              .description {{ `organization.plan.by_${billingPeriod}` | translate }}
              .description2 {{'subscription.new.starting_at'|translate}}
          .price(v-else)
          .discount(v-if="billingPeriod === 'year' && discount('premium')") -{{ discount('premium') }}% {{ 'committeds.saved' | translate }}
          .features
            .feature
              i.fp4.fp4-circle-check
              span {{'subscription.new.premium.feature.1'|translate}}
            .feature
              i.fp4.fp4-circle-check
              span {{'subscription.new.premium.feature.2'|translate}}
            .feature
              i.fp4.fp4-circle-check
              span {{'subscription.new.premium.feature.3'|translate}}
          .text-center.select-btn
            fpui-button(v-if="!premiumPlan" color="blue-flash" auto-width @click="contactUs('premium')") {{'subscription.new.contact-us'|translate}}
            fpui-button(v-else color="blue-flash" auto-width @click="selectPlan('premium')") {{'subscription.new.select'|translate}}

      .plan
        .plan-content
          img(:src="require(`@/core/assets/img/plan/dedicated.png`)")
          .name {{'subscription.new.dedicated.title'|translate}}
          .description {{'subscription.new.dedicated.description'|translate}}
          .price
            .big {{'subscription.new.dedicated.price'|translate}}
              //- .trial {{getPlanTrial('dedicated')}}
            .desc
              .description {{'subscription.new.dedicated.price.description'|translate}}
          .features
            .feature
              i.fp4.fp4-circle-check
              span {{'subscription.new.dedicated.feature.1'|translate}}
            .feature
              i.fp4.fp4-circle-check
              span {{'subscription.new.dedicated.feature.2'|translate}}
            .feature
              i.fp4.fp4-circle-check
              span {{'subscription.new.dedicated.feature.3'|translate}}
          .text-center.select-btn
            fpui-button(color="blue-flash" auto-width @click="contactUs('dedicated')") {{'subscription.new.contact-us'|translate}}

</template>
<script>

export default {
  props: {
    plan: {
      type: String,
      required: false,
      default: null
    },
    billingPeriod: {
      type: String,
      required: true
    },
    trial: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      contacted: false
    }
  },
  computed: {
    billingPeriodId () {
      return this.billingPeriod === 'year' ? 'yearly' : 'monthly'
    },
    currencyCode () {
      return this.$store.getters.SESSION.preferences?.currency || 'EUR'
    },
    standardPlan () {
      return this.$store.getters.PLAN_BY_ID(`standard-${this.billingPeriodId}-${this.currencyCode.toLowerCase()}`)
    },
    premiumPlan () {
      return this.$store.getters.PLAN_BY_ID(`premium-${this.billingPeriodId}-${this.currencyCode.toLowerCase()}`)
    },
    dedicatedPlan () {
      return this.$store.getters.PLAN_BY_ID(`dedicated-${this.billingPeriodId}-${this.currencyCode.toLowerCase()}`)
    }
  },
  mounted () {
    this.$emit('plan', '')
  },
  methods: {
    discount (plan) {
      if (this.billingPeriod === 'month') return null
      const yearly = this.$store.getters.PLAN_BY_ID(`${plan}-yearly-${this.currencyCode.toLowerCase()}`)
      const monthly = this.$store.getters.PLAN_BY_ID(`${plan}-monthly-${this.currencyCode.toLowerCase()}`)
      if (!yearly || !monthly) return null
      return (100 * monthly.price * 12 / yearly.price - 100).toFixed(0)
    },
    setPeriod (period) {
      this.$emit('billing-period', period)
    },
    selectPlan (plan) {
      this.$emit('plan', plan)
      this.$parent.$parent.next()
    },
    contactUs (plan) {
      this.$analytics.track('Subscription Requested', {
        plan
      })
      this.contacted = true
    }
  }
}
</script>
<style lang="less">

.billing-periods {
  display: flex;
  width: 150px;
  margin: 0 auto;
  .billing-period {
    border: 1px solid rgba(151, 167, 183, 0.21);
    flex: 1;
    height: 25px;
    text-align: center;
    line-height: 22px;
    font-size: 14px;
    font-weight: 600;
    &.month {
      border-radius: 5px 0 0 5px;
    }
    &.year {
      border-radius: 0 5px 5px 0;
    }
    cursor: pointer;
    &.active {
      background-color: #00CCF9;
      color: white;
    }
  }
}
.subscription-new-step-plan {
  .request-subscription {
    font-size: 20px;
    margin   : 100px;
    text-align: center;
  }


  .plans {
    margin-top: 40px;
    display   : flex;

    .plan {
      height    : 380px;
      flex-grow : 1;
      padding   : 0 16px;
      text-align: left;

      &.standard, &.premium {
        cursor: pointer;
      }

      button:first-child {
        margin: 0 !important;
      }

      .plan-content {
        width           : 255px;
        overflow        : hidden;
        padding         : 24px 15px 20px 21px;
        border          : 0.5px solid rgba(151, 167, 183, 0.21);
        border-radius   : 5px;
        background-color: rgba(255, 255, 255, 0.9);
        transition      : all 0.3s;
        position: relative;

        .icon {
          height: 34px;
        }

        .name {
          color         : #3E4550;
          font-size     : 22px;
          font-weight   : bold;
          margin-top    : 18px;
          letter-spacing: 0;
          line-height   : 28px;
        }

        >.description {
          color         : #B2BECA;
          font-size     : 12px;
          margin-top    : 6px;
          height        : 45px;
          letter-spacing: 0;
          line-height   : 16px;
        }

        .price {
          height    : 55px;
          margin-top: 0px;

          .desc {
            display: flex;
          }

          .big {
            color         : #3E4550;
            font-size     : 32px;
            font-weight   : bold;
            letter-spacing: 0;
            line-height   : 45px;
          }

          .trial {
            color         : #00CCF9;
            display       : inline-block;
            margin-left   : 0px;
            font-size     : 14px;
            font-weight   : bold;
            letter-spacing: 0;
            line-height   : 20px;
          }

          .description {
            color         : #B2BECA;
            font-size     : 12px;
            font-weight   : bold;
            letter-spacing: 0;
            line-height   : 15px;
            margin-right  : 5px;
          }

          .description2 {
            color         : #97A7B7;
            font-size     : 12px;
            letter-spacing: 0;
            line-height   : 15px;
          }
        }

        .discount {
          position: absolute;
          color: #9EDF10;
          font-size: 12px;
          right: 40px;
          top: 200px;
          font-weight: bold;
        }

        .features {
          margin-top: 15px;

          .feature {
            color         : #3E4550;
            font-size     : 12px;
            white-space   : nowrap;
            letter-spacing: 0;
            line-height   : 28px;

            i {
              color         : #9EDF10;
              font-size     : 13px;
              margin-right  : 5px;
              vertical-align: middle;
            }

            span {
              display       : inline-block;
              vertical-align: middle;
            }
          }
        }

        height: 330px;

        .select-btn {
          height       : 0;
          margin-top   : 22px;
          margin-bottom: 5px;
          button {
            margin: 0 auto!important;
          }
        }

        &:hover {
          height          : 390px;
          margin-top      : -25px;
          border          : 0.5px solid rgba(151, 167, 183, 0.21);
          border-radius   : 5px;
          background-color: #FFFFFF;
          box-shadow      : 0 20px 30px 0 rgba(52, 65, 78, 0.3);
        }
      }
    }
  }
}
</style>
