import { API, APIObject } from '@/shared/plugins/Api/API'
import _omit from 'lodash/omit'
import _set from 'lodash/set'

class AuthenticationProvider extends APIObject {
  constructor (options) {
    super('IAM', options)

    this._id = this._id || ''
    this.display_name = this.display_name || ''
    this.name = this.name || ''
    this.description = this.description || ''
    this.tags = this.tags || []
    this.type = this.type || ''
    this.directory = this.directory || ''
    this.active = this.active || false
    this.global = this.global || false

    this.configuration = this.configuration || {}
    this.configuration.logo = this.configuration.logo || null
    this.configuration.color = this.configuration.color || ''
    this.configuration.debug = this.configuration.debug || false

    this.configuration.roles = this.configuration.roles || []
    this.configuration.groups = this.configuration.groups || []

    if (!['login_password', 'forepaas', 'apikey'].includes(this.type)) {
      this.configuration.bindings = this.configuration.bindings || {}
      this.configuration.bindings.login = this.configuration.bindings.login || ''
      this.configuration.bindings.email = this.configuration.bindings.email || ''
      this.configuration.bindings.pronoun = this.configuration.bindings.pronoun || ''
      this.configuration.bindings.firstname = this.configuration.bindings.firstname || ''
      this.configuration.bindings.lastname = this.configuration.bindings.lastname || ''
      this.configuration.autoprovisioning = this.configuration.autoprovisioning !== false
    }

    if (['saml'].includes(this.type)) {
      this.configuration.force_reauth = this.configuration.force_reauth || false
      this.configuration.encrypted_assert = this.configuration.encrypted_assert || false
    }

    if (['openid', 'openid_openam'].includes(this.type)) {
      this.configuration.authentication_method = this.configuration.authentication_method || 'post'
    }

    if (['openid', 'openid_openam', 'openid_azure', 'openid_auth0', 'openid_okta', 'openid_google'].includes(this.type)) {
      this.configuration.client_secret = this.configuration.client_secret || ''
    }

    if (['login_password', 'apikey'].includes(this.type)) {
      this.configuration.anti_spam = this.configuration.anti_spam || {}
      this.configuration.anti_spam.failed_authentication = this.configuration.anti_spam.failed_authentication && typeof this.configuration.anti_spam.failed_authentication !== 'undefined' ? this.configuration.anti_spam.failed_authentication : '0'
      this.configuration.anti_spam.attempt_duration = this.configuration.anti_spam.attempt_duration || '1h'
      this.configuration.anti_spam.suspend_duration = this.configuration.anti_spam.suspend_duration || '1h'
      this.configuration.anti_spam.notify_by_mail = typeof this.configuration.anti_spam.notify_by_mail !== 'undefined' ? this.configuration.anti_spam.notify_by_mail : true
    }
    if (this.type === 'login_password') {
      this.configuration.anti_spam.spam_password_renewal = this.configuration.anti_spam.spam_password_renewal && typeof this.configuration.anti_spam.spam_password_renewal !== 'undefined' ? this.configuration.anti_spam.spam_password_renewal : '0'
      this.configuration.password_policies = this.configuration.password_policies || {}
      this.configuration.password_policies.nb_cchar = this.configuration.password_policies.nb_cchar || 1
      this.configuration.password_policies.nb_schar = this.configuration.password_policies.nb_schar || 1
      this.configuration.password_policies.nb_int = this.configuration.password_policies.nb_int || 1
      this.configuration.password_policies.nb_char = this.configuration.password_policies.nb_char || 10
      this.configuration.password_policies.password_expire = this.configuration.password_policies.password_expire || 180
      this.configuration.password_policies.password_value = this.configuration.password_policies.password_value || 0
      this.configuration.password_policies.password_renewal_by_users = this.configuration.password_policies.password_renewal_by_users || false
    }
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      'createdAt',
      'updatedAt',
      'createdBy',
      'updatedBy',
      'name',
      'history'
    ])
    return obj
  }

  async create () {
    return super.create({
      method: 'POST',
      url: 'v4/authentication_providers'
    })
  }

  async save () {
    return super.save({
      method: 'PUT',
      url: `v4/authentication_providers/${this._id}`
    })
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v4/authentication_providers/${this._id}`
    })
  }

  async load () {
    const authenticationProvider = await this.request({
      method: 'get',
      url: `v4/authentication_providers/${this._id}`
    })
    this.assign(authenticationProvider)
    if (!['login_password', 'forepaas', 'apikey'].includes(this.type)) {
      this.configuration.autoprovisioning = this.configuration.autoprovisioning !== false
    }
  }
}

class AuthenticationProviders extends API {
  list () {
    return this.paginateListing({
      method: 'get',
      url: 'v4/authentication_providers'
    })
      .then(data => {
        return data.map(authenticationProvider => new AuthenticationProvider(authenticationProvider))
      })
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new AuthenticationProvider(item)
  }
}

export default AuthenticationProviders
export {
  AuthenticationProvider,
  AuthenticationProviders
}
