<template lang="pug">
.tile-drilldown-container(
  :style="{ right: positionRight }"
)
  p toto
</template>

<script>
// import ConfirmModal from '@/shared/components/modals/Confirm'

export default {
  props: {
    tile: { type: Object, default: () => ({}) },
    isOpened: { type: Boolean, default: false }
  },
  data () {
    return {
      showDrilldown: false,
      elementLeft: 0,
      elementRight: 0,
      positionRight: '0'
    }
  },
  computed: {

  },
  watch: {
    'tile.width': {
      handler () {
        this.onResize()
      }
    },
    showDrilldown: {
      handler (val) {
        if (val) this.$emit('active-tile')
        else this.$emit('deactive-tile')
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.close)
    window.addEventListener('resize', this.onResize)
    process.nextTick(() => {
      this.onResize()
    })
  },
  destroyed () {
    document.removeEventListener('click', this.close)
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    open () {
      if (this.loading) return
      this.showDrilldown = !this.showDrilldown
    },
    isParent (target) {
      if (target === this.$el) return true
      if (target.parentNode) return this.isParent(target.parentNode)
      return false
    },
    close (event) {
      if (!this.isOpened) return
      if (event && this.isParent(event.target)) return
      this.showDrilldown = false
      this.$emit('close', false)
    },
    filtersClasses () {
      const view = document.querySelector('.view')?.getBoundingClientRect()
      if (!view) return

      const width = 209

      if (this.elementRight - view.left < width) this.positionRight = Math.round(-(width - (this.elementRight - view.left - 30))) + 'px'
      else this.positionRight = '0'
    },
    onResize () {
      this.elementLeft = this.$el?.getBoundingClientRect()?.left
      this.elementRight = this.$el?.getBoundingClientRect()?.right
      this.direction = this.filtersClasses()
    }
  }
}
</script>

<style lang="less">
  .tile-drilldown-container {
    position: absolute;
    width: 209px;
    height: 302px;
    background: white;
    box-shadow: 0px 3px 13px rgba(151, 167, 183, 0.3);
    border-radius: 7px;
    position: absolute;
    z-index: 10;
  }
</style>
