<template lang="pug">
.query-scale
  .current-data(
    @click="open"
    :key="changed"
    ref="currentData"
    :class="{ disabled: readOnly }"
  )
    .placeholder(v-if="fields.length === 0") {{ $t('query.fields.scale.placeholder') }}
    .data-container(
      v-if="fields.length > 0"
      v-for="key in fields"
    )
      .text {{ key }}
      i.fp4.fp4-circle-xmark(@click="remove(key)")
  .data-values(
    v-if="state"
    :style="{ width: currentDataWidth }"
  )
    fpui-input-search.search(
      :key-id="'search-scale'"
      v-model="search"
      autofocus
    )
    ul(
      :style="{ height: `${(scaleOptions.length > 7 ? 7 * 43 : (scaleOptions.length || 1) * 43) + 20}px` }"
    )
      .li-container(v-if="scaleOptions.length")
        li.value(v-for="scale in scaleOptions" @click="select(scale)")
          .li-value-container
            span {{ scale }}
      .li-container.no-attribute(v-else)
        span {{ $t('query.fields.no_attributes') }}
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'

export default {
  props: {
    fields: { type: Array, default: () => { return [] } },
    data: { type: Array, default: () => { return [] } },
    order: { type: Object, default: () => { return {} } },
    attributes: { type: Array, default: () => { return [] } },
    readOnly: { type: Boolean, default: false }
  },
  data () {
    return {
      changed: false,
      state: false,
      search: ''
    }
  },
  computed: {
    currentDataWidth () {
      return this.$refs.currentData.offsetWidth + 'px'
    },
    scaleOptions () {
      return this.attributes.filter(scale => scale.toLowerCase().includes(this.search.toLowerCase()) && !this.fields.includes(scale))
    }
  },
  mounted () {
    window.addEventListener('click', this.close)
  },
  destroyed () {
    window.removeEventListener('click', this.close)
  },
  methods: {
    select (scale) {
      const newFields = [...this.fields]
      newFields.push(scale)
      this.state = false
      this.search = ''
      // Update CurrentQueries in store
      this.$emit('update', { key: 'scale', value: { fields: newFields } })
    },
    remove (key) {
      if (this.readOnly) return

      const newFields = this.fields.filter(scale => scale !== key)
      // Update CurrentQueries in store
      this.$emit('update', { key: 'scale', value: { fields: newFields } })

      // if we remove an attribute from data or scale and it is used in order, we remove it from order
      if (Object.keys(this.order).includes(key) && !Object.keys(this.data).includes(key)) {
        const newOrder = _cloneDeep(this.order)
        delete newOrder[key]

        this.$emit('update', { key: 'order', value: newOrder })
      }

      this.changed = !this.changed
    },
    async open () {
      if (this.readOnly) return
      this.state = !this.state
      if (!this.state) this.search = ''
      await this.$nextTick()
    },
    close ($event) {
      if ($event && this.isParent($event.target)) return
      this.state = false
      this.search = ''
    },
    isParent (target) {
      if (target === this.$el) return true
      if (target.parentNode) return this.isParent(target.parentNode)
      return false
    }
  }
}
</script>
<style lang="less">
.query-scale {
  position: relative;
  .current-data {
    box-sizing: border-box;
    display: flex;
    flex-flow:row wrap;
    align-items: center;
    min-height: 37px;
    padding: 0px 15px 6px 15px;
    border: 1px solid rgba(151,167,183,0.21);
    border-radius: 3px;
    background-color: rgba(151,167,183,0.06);
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }
    .placeholder {
      margin-top: 6px;
      color: rgba(62,69,80,0.25);
      font-size: 14px;
      line-height: 18px;
    }
    .data-container {
      display: flex;
      align-items: center;
      margin-right: 5px;
      margin-top: 6px;
      padding: 3px 9px;
      border-radius: 12px;
      background-color: #E4E7EC;
      box-shadow: inset 0 -1px 0 0 rgba(62,69,80,0.1), inset 0 1px 0 0 rgba(255,255,255,0.3);
      .text {
        margin-right: 5px;
        color: rgba(62,69,80,0.65);
        font-family: "Source Sans Pro";
        font-size: 13px;
        letter-spacing: 0;
        line-height: 17px;
      }
      .fp4-circle-xmark {
        font-size: 14px;
        color: #B2BECA;
      }
    }
  }
  .data-values {
    max-height: 375px;
    position     : absolute;
    background   : white;
    border-radius: 9px;
    box-shadow   : 0 2px 10px 0 rgba(62, 69, 80, 0.25);
    padding-top  : 5px;
    z-index      : 2;
    margin       : 0;
    .search {
      input {
        height      : 50px;
        border-left : 0;
        border-right: 0;
        border-top  : 0;
        background  : white;
        border-radius: 0px;
      }
      i.fp4 {
        top: 8px;
      }
    }
    ul {
      padding: 10px;
      overflow-y: auto;
      height: calc(~"100% - 50px");
      margin-top: 0;
      margin-bottom: 0;
      .li-container {
        li {
          color      : #3E4550;
          line-height: 18px;
          cursor     : pointer;

          &:hover,
          &.active {
            color           : #0089C0;
            background-color: rgba(0, 204, 249, 0.08);
          }

          font-size    : 14px;
          list-style   : none;
          padding      : 0 12px;
          border-radius: 6px;

          .li-value-container {
            display: flex;
            align-items: center;
            height: 43px;
            padding: 6px 19px;
          }
        }

        &.no-attribute {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 12px;
          color: #97a7b7;
          font-style: italic;
        }
      }
    }
  }
}
</style>
