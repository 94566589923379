<template lang='pug'>
.td.fp-tree-cell.fp-tree-footer
  fpui-button(
    icon='fp4 fp4-plus'
    icon-left
    toolbar
    noshadow
    @click='options.action.click()'
    v-tooltip="options.action.disabled ? $t('action-header.acl_error') : ''"
    :disabled="options.action.disabled"
  ) {{ 'action.new_action' | translate }}

</template>

<script>

export default {
  props: {
    options: {
      type: Object,
      required: false,
      default: () => {
        return {
          if: false,
          disabled: true,
          action: {
            if: false,
            disabled: true,
            click: () => null
          }
        }
      }
    }
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";

.fp-tree-footer {
  margin-top: 5px;
  .button-container.fpui-button {
    button {
      padding-left: 0;
      color: @blue_flash !important;
      &.blue:active:not(.disabled), &.blue:focus:not(.disabled) {
        background-color: transparent !important;
        box-shadow: none;
      }
      &.blue:active:not(.disabled) {
        transform: translate3d(2px, 1px, 0);
      }
      i, span {
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
      }
    }
  }
}
</style>
