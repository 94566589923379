<template lang="pug">
  .error Error type invalid
</template>

<script>
export default {
  props: {
    value: { type: Object, default: () => {} }
  },
  mounted () {
  }
}
</script>

<style lang="less">
</style>
