import Vue from 'vue'
import iam from './iam'
import acl from './acl'
import gab from './gab'
import ds from './ds'

export default {
  modules: { iam, acl, gab, ds },
  state: {
    ready: {
      root: false,
      iam_users: false,
      iam_service_accounts: false,
      acl: false,
      gab_assistants: false,
      gab_kb: false
    }
  },
  mutations: {
    SET_READY (state, { key, value }) { state.ready[key] = value } // Not WS
  },
  actions: {
    async LOGIN_DONE ({ dispatch, commit }) {
      commit('SET_READY', { key: 'root', value: true })
      Promise.all([
        {
          key: 'iam_users',
          name: 'REFRESH_IAM_USERS'
        },
        {
          key: 'iam_service_accounts',
          name: 'REFRESH_SERVICE_ACCOUNTS'
        },
        {
          key: 'gab_assistants',
          name: 'REFRESH_GAB_ASSISTANTS'
          // TODO access
        },
        {
          key: 'gab_kb',
          name: 'REFRESH_GAB_KB'
          // TODO access
        },
        {
          key: 'ds_buckets',
          name: 'REFRESH_DS_BUCKETS',
          access: Vue.api.IAM.can('datastore', 'bucket', 'read')
        },
        {
          key: 'acl',
          name: 'LOAD_ALL_ACLS'
        }
      ].map(async action => {
        try {
          if (
            typeof (action.access) === 'undefined' ||
            (typeof (action.access) === 'function' && await action.access()) ||
            await action.access
          ) await dispatch(action.name)
        } catch (err) {
          if (err.message.includes('Error: timeout of')) {
            err.message = `ServerError:Timeout:${action.name}`
          }
          Vue.fpuiMessageBlock.error(err)
          console.error(err)
        } finally {
          commit('SET_READY', { key: action.key, value: true })
        }
      }))
    }
  },
  getters: {
    READY (state) { return (key = 'root') => state.ready[key] }
  }
}
