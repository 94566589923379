<template lang="pug">
  .cell-image-text(
    @click="select"
    v-tooltip="$t(text)"
  )
    img(v-if="image" :src='image')
    .text {{ $t(text) }}
</template>

<script>
import _get from 'lodash/get'

export default {
  props: {
    value: { type: Object, default: () => {} },
    imageKey: { type: String, default: '' },
    imagePath: { type: String, default: '' },
    textKey: { type: String, default: '' },
    imageBig: { type: Boolean, default: false }
  },
  data () {
    return {
      image: null,
      text: null
    }
  },
  mounted () {
    this.text = _get(this.value.object, this.textKey, '')
    if (this.text === 'unknown') this.text = '-'
    const imgValue = _get(this.value.object, this.imageKey, '')
    try {
      this.image = require(`@/ml/assets/img/${this.imagePath}${imgValue}${this.imageBig ? '@2x' : ''}.png`)
    } catch (err) {
      this.image = null
    }
  },
  methods: {
    select ($event) {
      this.$emit('select', $event)
    }
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";
  .cell-image-text {
    display: flex !important;
    align-items: center;
    padding-left: 10px;
    img {
      margin-right: 5px;
      width: 25px;
    }
    .text {
      text-transform: capitalize;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>
