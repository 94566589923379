<template lang="pug">
.subscription-new-step-payment
  .header
    h4 {{ $t('subscription.new.payment') }}
    p(v-if="!fromTrial") {{ `subscription.new.payment.details.${plan}` | translate }}
    p(v-else) {{ 'subscription.new.payment.details.from_trial' | translate }}

  fp-loader(v-if="loading")
  .content(v-else-if="paymentSource")
    .text-center
      fpui-input-radio(
        :value="paymentMethod",
        :options="paymentMethodsDisabled",
        @input="v=>$emit('payment-method',v)"
      )
    .payment-card(v-if="paymentMethod==='card'")
      .card-cvc
        label.label {{'subscription.card_cvv'|translate}}
        .input ***
      .card-number
        label.label {{'subscription.card_number'|translate}}
        .input {{paymentSource.card.masked_number}}
      .card-expiry
        label.label {{'subscription.card_expiracy'|translate}}
        .input {{paymentSource.card.expiry_month}}/{{paymentSource.card.expiry_year}}
    .payment-bank-account(v-if="paymentMethod==='bank_account'")
      .number .......{{ paymentSource.last4 }}
      .name {{ paymentSource.name_on_account }}
  .content(v-else)
    .text-center
      fpui-input-radio(
        :value="paymentMethod",
        :options="paymentMethods",
        @input="v=>$emit('payment-method',v)"
      )
    .payment-card(v-if="paymentMethod==='card'")
      card-component(
        ref="cardComponent"
        :classes="classes"
        :styles="styles"
      )
        .card-cvc
          label.label {{'subscription.card_cvv'|translate}}
          card-cvv.input
        .card-number
          label.label {{'subscription.card_number'|translate}}
          card-number.input
        .card-expiry
          label.label {{'subscription.card_expiracy'|translate}}
          card-expiry.input

</template>
<script>
import { CardComponent, CardNumber, CardExpiry, CardCvv } from '@chargebee/chargebee-js-vue-wrapper'
export default {
  components: { CardComponent, CardNumber, CardExpiry, CardCvv },
  props: {
    plan: {
      type: String,
      required: true
    },
    paymentSource: {
      type: Object,
      required: false,
      default () { return null }
    },
    customerAccountId: {
      type: String,
      required: true
    },
    fromTrial: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      loading: true,
      paymentMethod: 'card',
      classes: {
        focus: 'focus',
        invalid: 'invalid',
        empty: 'empty',
        complete: 'complete'
      },
      styles: {
        base: {
          color: '#fff',
          fontSize: '14px'
        }
      }
    }
  },
  computed: {
    account () {
      return this.$store.getters.ACCOUNT_BY_ID(this.customerAccountId)
    },
    paymentMethodsDisabled () {
      return [{
        disabled: true,
        label: this.$t('subscription.card'),
        value: 'card'
      }, {
        disabled: true,
        label: this.$t('subscription.bank_account'),
        value: 'bank_account'
      }]
    },
    paymentMethods () {
      return [{
        label: this.$t('subscription.card'),
        value: 'card'
      }, {
        disabled: true,
        label: this.$t('subscription.bank_account'),
        value: 'bank_account'
      }]
    }
  },
  async mounted () {
    this.loading = true

    await this.$store.dispatch('INIT_CHARGEBEE', this.account?.preferred_currency_code)

    if (this.customerAccountId === 'create') {
      const customerAccount = await this.$store.dispatch('CREATE_ACCOUNT')
      this.$emit('customer-account-id', customerAccount._id)
    }
    process.nextTick(async () => {
      await this.loadPaymentSource()
      this.loading = false
    })
  },
  methods: {
    async loadPaymentSource () {
      const paymentSources = await this.account.getPaymentSources()
      if (paymentSources.length) {
        const primary = paymentSources.find(ps => ps.primary)
        this.$emit('payment-source', primary)
        this.paymentMethod = primary.type
      }
    },
    async onDone () {
      if (this.paymentSource) return true
      try {
        const { token } = await this.$refs.cardComponent.tokenize()
        await this.account.createCard(token)
        await this.loadPaymentSource()
      } catch (err) {
        console.error(err)
        this.$fpuiMessageBlock.error(err)
        throw err
      }
    }
  }
}
</script>
<style lang="less">
.subscription-new-step-payment {
  .payment-card {
    height          : 220px;
    width           : 356px;
    background-image: url('~../assets/credit_card_background.png');
    margin          : 25px auto;
    border-radius   : 5px;
    padding         : 5px 27px;
    position        : relative;
    text-align      : left;

    label {
      margin-bottom : 5px;
      padding-left  : 0;
      display       : block;
      text-align    : left;
      color         : #97A7B7;
      font-size     : 12px;
      letter-spacing: 0;
      line-height   : 15px;
      font-weight   : normal;
    }

    .card-cvc {
      position: absolute;
      right   : 27px;
      top     : 41px;
      width   : 100px;
    }

    .card-number {
      position: absolute;
      left    : 27px;
      top     : 110px;
      width   : 181px;
    }

    .card-expiry {
      position: absolute;
      right   : 27px;
      top     : 110px;
      width   : 100px;
    }

    .input {
      border          : 1px solid #748294;
      height          : 34px;
      padding         : 7px 12px 7px 10px;
      border-radius   : 3px;
      background-color: rgba(255, 255, 255, 0.06);
      color           : #FFFFFF;
      font-family     : "Source Sans Pro";
      font-size       : 14px;
    }

    .card-name {
      position      : absolute;
      left          : 27px;
      top           : 143px;
      width         : 301px;
      color         : #FFFFFF;
      font-size     : 14px;
      letter-spacing: 0;
      line-height   : 18px;

      input {
        border          : 1px solid #748294;
        border-radius   : 3px;
        background-color: rgba(255, 255, 255, 0.06)
      }
    }
  }
}

</style>
