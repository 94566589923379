<template lang="pug">
.dataplant-creation__summary
  .left-map-container
    .provider-location-informations
      .project-name-label {{ $t('dataplant.creation.summary.your_project') }}
      .project-name {{ dataplantName }}
      .provider-logo
        img(:src='providerImage')
      .provider-name {{ providerName }}
      .provider-region {{ regionName }}

  .dp-personalize-container
    .projects-prices
      .projects-prices-container
        .actives-services-label {{ $t('dataplant.creation.summary.active_seervice_start') }}
        .services-prices(
          v-for="service in servicesWithDCAndLM"
          v-if="service.hidden !== true"
        )
          .services-logos
            i.fp4(
              v-if="service.icon"
              :class="service.icon"
            )
            img(
              v-if="service.image"
              :src="service.image"
            )
          .services-names {{ $t(service.service) }}
          .borders
            .dashes
          .prices {{ `${currencyCode}0.00` }}
    .total-price-container
      .total-price-label {{ $t('dataplant.creation.summary.total_cost') }}
      .total-price {{ `${currencyCode}0.00` }}
    dataplant-creation-step-footer(
      :steps="[0, 1, 2]"
      :step-active="2"
      :disabled-next="disabledNext"
      @previous="$emit('previous')"
      @next="$emit('next')"
    )
</template>

<script>
import _find from 'lodash/find'
import DataplantCreationStepFooter from '@/shared/components/project/DataplantCreationFullscreen/DataplantCreationStepFooter.vue'
import Services from '@/core/helpers/services'

export default {
  components: {
    DataplantCreationStepFooter
  },
  props: {
    disabledNext: {
      type: Boolean,
      default: false
    },
    options: { type: Object, required: true }
  },
  data () {
    return {
      instance: null,
      servicesList: []
    }
  },
  computed: {
    // provider-related
    providerName () {
      return _find(this.options.providers, { name: this.options.configuration.dataplant.provider })?.display_options?.display_name
    },
    providerImage () {
      try {
        return require(`@/core/assets/img/providers/${this.options.configuration.dataplant.provider}@2x.png`)
      } catch (err) {
        return require('@/core/assets/img/providers/standard.png')
      }
    },
    regionName () {
      return this.options.configuration.dataplant.region?.regionName || '-'
    },
    // dataplant-related
    dataplantName () {
      return this.options.configuration.dataplant.name
    },
    dataplantDescription () {
      return this.options.configuration.dataplant.description || '-'
    },
    dataplantTags () {
      return this.options.configuration.dataplant.tags
    },
    dataplantURI () {
      return `https://${this.options.configuration.dataplant.subdomain}.${this.options.configuration.dataplant.domain}`
    },
    // storage engine-related
    storageEngine () {
      return this.options.configuration.storageEngine.engine
    },
    storageEngineInstanceName () {
      switch (this.options.configuration.storageEngine.engine) {
        case 'shared':
          return this.$t('dataplant.creation.engine.shared')
        case 'default':
          return this.$t('dataplant.creation.engine.summary.default')
        default:
          return this.instance?.display_name || ''
      }
    },
    storageEngineFPU () {
      return this.instance?.fpu ? `${this.instance.desired_state?.instance_count || this.instance.instance_count} x ${this.instance.desired_state?.fpu || this.instance?.fpu}` : null
    },
    storageEngineCost () {
      return 0.05
    },
    currencyCode () {
      const currency = this.$store.getters.SESSION.preferences?.currency || 'EUR'
      if (currency === 'EUR') return '€'
      else return '$'
    },
    servicesWithDCAndLM () {
      let services = [...this.options.template.services]
      const dmService = services.find(s => s.id === 'dm')
      if (dmService) {
        services.push({ id: 'data-catalog', img: dmService.img, service: 'Data Catalog' })
        services.push({ id: 'lakehouse', img: dmService.img, service: 'Lakehouse Manager' })
        services = services.filter(s => s.id !== 'dm')
      }

      return services
    }
  },
  mounted () {
    this.instance = {}
    if (['default', 'shared'].includes(this.options.configuration.storageEngine.engine)) {
      this.instance = false
    } else {
      this.instance = _find(this.options.instances, (instance) => {
        return this.options.configuration.storageEngine.instance === (instance.id || instance.tmpId)
      })
    }

    this.servicesList = Services.flatMap(categories =>
      categories.services.filter(service => !Object.prototype.hasOwnProperty.call('hiden') || service.hiden === false)
    )
  },
  methods: {
    engineImage (engine) {
      try {
        return require(`@/core/assets/img/engines/${engine}@2x.png`)
      } catch (err) {
        return require('@/core/assets/img/engines/default.png')
      }
    }
  }
}
</script>

<style lang="less">
  .dataplant-creation__summary {
    display: flex;
    position: absolute;
    top: 173px;
    left: 78px;
    right: 78px;
    margin: 0 auto;
    z-index: 1;

    .left-map-container {
        flex: 5;
        margin-right: 16px;
        min-height: 650px;
        background: rgba(255, 255, 255, 0.3);
        border: 1px solid #E4E7EC;
        border-radius: 11px;


        > .provider-location-informations {
          display: grid;
          grid-template: 1fr 1fr / 1fr min-content max-content;
          height: 60px;
          position: relative;
          margin: 20px 20px 0 20px;
          padding: 10px  22px 10px 10px;
          background: rgba(255, 255, 255, 0.8);
          backdrop-filter: blur(5px);
          border-radius: 8px;


          > .project-name-label {
            grid-column: 1;
            grid-row: 1;
            align-self: center;
            justify-self: start;
            font-weight: 600;
            font-size: 12px;
            color: #0089C0;
          }

          > .project-name {
            grid-column: 1;
            grid-row: 2;
            justify-self: start;
            font-weight: 600;
            font-size: 16px;
            color: #3E4550;
          }

          > .provider-logo {
            grid-column: 2;
            grid-row: 1 / span 2;
            margin-right: 8px;
            height: 37px;
            img {
              height: 37px;
            }
          }

          > .provider-name {
            justify-self: start;
            color: #3E4550;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
          }

          > .provider-region {
            font-weight: 400;
            font-size: 11px;
            line-height: 14px;
            color: #748294;
          }
        }
      }

    .dp-personalize-container {
      flex: 7;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      z-index: 2;
      background-color: white;
      border-radius: 11px;
      box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.17);
      opacity: 1;

      .projects-prices {
        padding: 20px;
        background: #F6F9FC;
        border-radius: 11px 11px 0 0;

        .projects-prices-container{
          min-height: 450px;
          border: 1px solid #E4E7EC;
          border-radius: 8px;
          background-color: white;
          padding: 20px 26px 18px 36px;

          .actives-services-label {
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            color: #0089C0;
            margin-bottom: 18px;
          }

          .services-prices{
            display: grid;
            grid-template-columns: min-content max-content 1fr max-content ;
            margin-bottom: 26px;
            align-items: center;

            &:last-of-type{
              margin: 0px;
            }

            .services-logos {
              img {
                height: 24px;
              }
            }

            .services-names {
              margin-left: 13px;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #3E4550;
            }

            > .borders {
              display: flex;
              align-items: center;

              > .dashes {
                width: 100%;
                margin: 0 10px;
                border: 1px dashed #E4E7EC;
              }
            }

            .prices {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #3E4550;
            }
          }
        }
      }

      .total-price-container {
        border-top: 1px solid #E4E7EC;
        text-align: center;

        .total-price-label {
          margin-top: 10px;
          font-weight: 600;
          font-size: 12px;
          line-height: 24px;
          text-transform: uppercase;
          color: #97A7B7;
        }

        .total-price {
          font-weight: 600;
          font-size: 24px;
          line-height: 24px;
          color: #0089C0;
        }
      }
    }
  }
</style>
