<template lang="pug">
  .authentication
    div(:class="{ background: !hasBackground, 'background-mask': hasBackground }")
      img(:src="background")
    .content(:style="{ left: hasBackground ? '55%' : '45%' }")
      logout(v-if="logout")
      div(v-else-if="!$store.getters.SESSION")
        login-all-in-one(v-if="allInOne")
        login-selector(
          v-else-if="!mode"
          @select="(value)=>mode=value"
        )
        login(
          v-else="mode"
          @close="mode=null"
          :mode="mode"
        )
      mfa(v-else-if="needMfa")
      password-renew(v-else-if="needRenew")
</template>

<script>
import defaultBackgroundImage from '@/shared/assets/img/sign_up_background.png'
import Login from './Login.vue'
import Logout from './Logout.vue'
import LoginSelector from './LoginSelector.vue'
import LoginAllInOne from './LoginAllInOne.vue'
import Mfa from './MFA'
const PasswordRenew = () => import('./PasswordRenew.vue')

export default {
  components: { LoginAllInOne, LoginSelector, Login, PasswordRenew, Logout, Mfa },
  data () {
    return {
      mode: null
    }
  },
  computed: {
    allInOne () {
      return this.authModes.length > 1
    },
    authModes () {
      return this.$store.getters.PREFERENCES.auth_mode.filter(authMode => {
        return authMode.visible && !authMode.hidden
      })
    },
    needRenew () {
      return this.$store.getters.SESSION?.password_renew
    },
    needMfa () {
      if (!this.$store.getters.SESSION) return false
      return this.$store.getters.SESSION.acr === 'aal1'
    },
    logout () {
      return typeof (this.$route.query.logout) !== 'undefined'
    },
    hasBackground () { return this.$store.getters.PREFERENCES?.background },
    background () {
      return this.$store.getters.PREFERENCES?.background || defaultBackgroundImage
    },
    logo () {
      return this.$store.getters.PREFERENCES?.logo || ''
    },
    title () {
      return this.$store.getters.PREFERENCES.title || this.$store.getters.PREFERENCES.name
    },
    message () {
      return this.$store.getters.PREFERENCES.message || this.$store.getters.PREFERENCES.description
    }
  }
}
</script>

<style lang="less">
  .authentication {
    position:fixed;
    display: flex;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: white;
    .content {
      background:white;
      overflow: auto;
      overflow: overlay;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      padding:40px 70px;
      h1 {
        margin: 10px 0 5px;
        font-weight: 700;
        color: #3e4550;
        margin-bottom: 0;
        line-height: 54px;
        padding-top: 15px;
        font-size: 54px;
      }
      p {
        margin-top: 20px;
        font-size: 14px;
        line-height: 28px;
        color: #3e4550;
        max-width: 550px;
      }
      .inner-content {
        margin:50px 0;
        max-width: 600px;
      }
    }
    .background {
      height: 100%;
      width: 30%;
      position: absolute;
      left: 0;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .background-mask {
      height: 100%;
      width: 55%;
      position: absolute;
      left:0 ;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: -1px;
        bottom: 0;
        width: 190px;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTM5cHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAxMzkgODAwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1NS4xICg3ODEzNikgLSBodHRwczovL3NrZXRjaGFwcC5jb20gLS0+CiAgICA8dGl0bGU+bG9naW5fc2hhcGU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iREFTSEJPQVJEX0NMSUVOVCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IjA0XzBfZGFzaGJvYXJkX1NLX2xvZ2luLUNvcHkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02MzIuMDAwMDAwLCAwLjAwMDAwMCkiIGZpbGw9IiNGRkZGRkYiPgogICAgICAgICAgICA8ZyBpZD0ibG9naW5fc2hhcGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYzMi4wMDAwMDAsIDAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iUmVjdGFuZ2xlLTkiIG9wYWNpdHk9IjAuOTQxNTQ1NzU5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2OS41MDAwMDAsIDQwMC4wMDAwMDApIHNjYWxlKC0xLCAxKSB0cmFuc2xhdGUoLTY5LjUwMDAwMCwgLTQwMC4wMDAwMDApICIgcG9pbnRzPSIyLjU4NDg3Njc2ZS0xMyAwIDEzOSA4MDAgMCA4MDAiPjwvcG9seWdvbj4KICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJSZWN0YW5nbGUtOSIgb3BhY2l0eT0iMC40MzEwMzYwODYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDY5LjUwMDAwMCwgNDAwLjAwMDAwMCkgc2NhbGUoLTEsIC0xKSB0cmFuc2xhdGUoLTY5LjUwMDAwMCwgLTQwMC4wMDAwMDApICIgcG9pbnRzPSI1LjIxNzk3ODgzZS0xMyAwIDEzOSA4MDAgMCA4MDAiPjwvcG9seWdvbj4KICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoLUNvcHkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEwNC4yNTAwMDAsIDQwMC4wMDAwMDApIHNjYWxlKC0xLCAxKSB0cmFuc2xhdGUoLTEwNC4yNTAwMDAsIC00MDAuMDAwMDAwKSAiIHBvaW50cz0iMTM5IDQwMCA2OS41IDgwMCA2OS41IDAiPjwvcG9seWdvbj4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+');
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: right;
      }
    }
    .logo {
      width: 150px;
    }
  }
  @media (max-width: 1279px) {
    .authentication {
      .background {
        width:35%;
      }
      .content {
        left:35%;
      }
    }
  }
  @media (max-width: 600px) {
    .authentication {
      .background {
        display:none;
      }
      .content {
        left:0%;
      }
    }
  }
</style>
