<template lang="pug">
.gab-cell-status.td.fp-tree-cell(:class="{ 'from-dashboard': fromDashboard }")
  i.fp4.fp4-circle-check-small.is-deployed(v-if="isDeployed")
  fp-loading(v-else-if="isBuilding")
  i.fp4.fp4-circle-xmark.is-error(v-else-if="isError")
  i.fp4.fp4-circle-xmark(v-else)
</template>

<script>
export default {
  props: {
    value: { type: Object, default: () => { } },
    fromDashboard: { type: Boolean, default: false }
  },
  computed: {
    item () {
      return this.value?.object || this.value
    },
    isDeployed () {
      return ['SUCCESS', 'DEPLOYED'].includes(this.item.status)
    },
    isBuilding () {
      // Knowledge Base
      return ['SUBMITTED', 'QUEUED', 'BUILDING', 'PROVISIONING', 'PROCESSING'].includes(this.item.status)
    },
    isError () {
      if (this.item.knowledge_base) return null
      return ['FAILED', 'TIMEOUT'].includes(this.item.status)
    }
  }
}
</script>

<style lang="less">
.gab-cell-status {
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    line-height: 45px;
    display: inline-block;
    vertical-align: top;
    color: #cbd3db;
    font-size: 22px;
    margin-right: 7px;
    &.is-deployed {
      color: @green;
    }
    &.is-error {
      color: @red;
    }
  }
  &.from-dashboard {
    justify-content: flex-end;
    i {
      line-height: 20px;
      font-size: 16px;
    }
  }
}
</style>
