<template lang='pug'>
.fpui-input-text.fpui-textarea
  .fpui-input-label-container(v-if='label')
    span {{ label | translate }}
    fpui-helper(
      v-if='helper'
      :text="helper | translate"
      :title="helperTitle ? helperTitle : ''"
    )
  .fpui-input-content
    textarea(
      :rows="rows"
      @keydown="$emit('keydown',$event)"
      :value="value"
      @input="update"
      ref="textarea"
      :class='{ error: error }'
      :placeholder='placeholder | translate'
      :disabled="disabled"
    )
    slot(
      v-if="($scopedSlots.content)"
      name="content",
      :value="value",
      :open="open"
    )
</template>

<script>

export default {
  props: {
    value: { type: String, default: '' },
    rows: { type: Number, default: null },
    error: { type: Boolean },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    helper: { type: String, default: '' },
    helperTitle: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false }
  },
  mounted () {
    this.onResize()
    window.addEventListener('click', this.onResize)
    if (this.autofocus) this.focus()
  },
  destroyed () {
    window.removeEventListener('click', this.onResize)
  },
  methods: {
    update ($event) {
      this.$emit('input', $event.target.value)
      this.onResize()
    },
    onResize () {
      this.$refs.textarea.style.height = 'inherit'
      let height = this.$refs.textarea.scrollHeight + 2
      if (height > 200) height = 200
      this.$refs.textarea.style.height = height + 'px'
    },
    focus () { this.$refs.textarea && this.$refs.textarea.focus() }
  }
}
</script>

<style lang='less'>
@import "~@/shared/styles/_variables.less";

.fpui-textarea {
  position:relative;
  .fpui-input-label-container {
    padding-left: 0;
    font-size: 12px !important;
    margin-bottom: 5px;
    color: #748294;
    cursor: pointer;
  }
  .fpui-input-content {
    &:not(.with-padding) {
      padding: 0;
    }
    textarea {
      width: 100%;
      border-radius: 3px;
      resize: none;
      overflow: auto;
      overflow: overlay;
      background: rgba(151, 167, 183, 0.06);
      padding:8px;
      max-width:100%;
      min-height: 50px;
      border: 1px solid rgba(151,167,183,0.21);
      min-width:100%;
      line-height: 16px;
      font-weight: 300;
      letter-spacing: 1px;
      outline: none;
      border: 1px solid rgba(151, 167, 183, 0.21);
      font-size: 14px;
      color: #3e4550;
      font-weight: 400;
      font-family: @global-font-family;
      letter-spacing: normal;
      &:focus {
        border-color: @blue;
      }
      &:not(.disabled) {
        transition: border 200ms linear;
      }
      &.error {
        border-color: @red;
      }
      &::placeholder {
        color: rgba(62,69,80,0.35);
      }
    }
  }
}
</style>
