<template lang="pug">
  .router-wrapper
    fp-loader(v-if="!ready",type="page")
    router-view.router-view(v-if="ready")
</template>

<script>
import Config from '@/shared/Config'
export default {
  data () {
    return {
      config: null,
      readyMounted: false
    }
  },
  computed: {
    intercom () {
      return this.$route.meta.intercom
    },
    ready () {
      if (!this.readyMounted) return false
      if (this.$route.meta.isReady && !this.$route.meta.isReady.bind(this)()) return false
      const ready = this.$route.meta.ready || []
      for (const index in ready) {
        const event = ready[index]
        if (!this.$store.getters.READY(event)) return false
      }
      return true
    }
  },
  watch: {
    intercom () {
      this.toggleIntercom()
    },
    async $route () {
      let readyDone = false
      if (this.ready) {
        if (this.$route.meta.onReady) await this.$route.meta.onReady.bind(this)()
        this.refreshTitle()
        readyDone = true
      }
      this.$route.meta.onMounted = this.$route.meta.onMounted || function () {}
      await this.$route.meta.onMounted.bind(this)()
      if (this.ready && !readyDone) {
        if (this.$route.meta.onReady) await this.$route.meta.onReady.bind(this)()
        this.refreshTitle()
      }
    },
    async ready () {
      if (this.ready) {
        if (this.$route.meta.onReady) await this.$route.meta.onReady.bind(this)()
        this.refreshTitle()
      }
    }
  },
  async mounted () {
    this.$route.meta.onMounted = this.$route.meta.onMounted || function () {}
    await this.$route.meta.onMounted.bind(this)()
    this.readyMounted = true
    this.config = await Config()
    if (this.ready) this.refreshTitle()
    this.toggleIntercom()
  },
  methods: {
    toggleIntercom () {
      if (this.intercom) {
        document.querySelector('body').classList.add('intercom')
      } else {
        document.querySelector('body').classList.remove('intercom')
      }
    },
    defaultTitle ($route, title = true) {
      // For Control Center
      if (this.config?.TITLE === 'CC') {
        const dp = this.$store.getters.DATAPLANT_BY_ID ? this.$store.getters.DATAPLANT_BY_ID($route.params.dataplantId) : null
        if (!title && dp) return dp.name
        if (dp) return `${this.$t(this.$t(this.config.TITLE))} - ${dp.name}`

        const org = this.$store.getters.ORGANIZATION_BY_ID ? this.$store.getters.ORGANIZATION_BY_ID($route.params.organizationId) : null
        if (!title && org) return org.name
        if (org) return `${this.$t(this.config.TITLE)} - ${org.name}`
        return this.$t(this.config.TITLE)
      }

      // For other services
      if (this.config?.TITLE) {
        if (!title && this.config.PROJECT_NAME) return this.config.PROJECT_NAME
        if (this.config.PROJECT_NAME) return `${this.$t(this.config.TITLE)} - ${this.config.PROJECT_NAME}`
        return this.$t(this.config.TITLE)
      }

      // For dataplant home
      if ($route.params.dataplantId) {
        const dp = this.$store.getters.DATAPLANT_BY_ID ? this.$store.getters.DATAPLANT_BY_ID($route.params.dataplantId) : null
        if (dp) {
          if (dp.organization_id) {
            const org = this.$store.getters.ORGANIZATION_BY_ID ? this.$store.getters.ORGANIZATION_BY_ID(dp.organization_id) : null
            if (org) {
              return `${dp.name} - ${org.name}`
            }
          }
          return dp.name
        }
      }

      // For organization home
      if ($route.params.organizationId) {
        const org = this.$store.getters.ORGANIZATION_BY_ID ? this.$store.getters.ORGANIZATION_BY_ID($route.params.organizationId) : null
        if (org) return org.name
      }
      return 'Data Platform'
    },
    refreshTitle () {
      if (typeof (this.$route.meta?.title) === 'string') {
        document.title = this.$route.meta?.title
        return
      }
      const titleFn = this.$route.meta?.title || this.defaultTitle
      document.title = titleFn(this.$route, this.defaultTitle)
    }
  }
}
</script>

<style lang="less">
.router-wrapper {
  height:100%;
  overflow: hidden;
  line-height: 0;
  .router-view {
    line-height: normal;
  }
}
.intercom-lightweight-app {
  display: none;
}
.intercom .intercom-lightweight-app {
  display: block;
}
</style>
