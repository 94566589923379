import { API, APIObject } from '@/shared/plugins/Api/API'
import _omit from 'lodash/omit'
import _set from 'lodash/set'

class Application extends APIObject {
  constructor (options) {
    super('IAM', options)

    this._id = this._id || ''
    this.display_name = this.display_name || ''
    this.name = this.name || ''
    this.description = this.description || ''
    this.version = this.version || ''
    this.admin = this.admin || false
    this.global = this.global || false
    this.url = this.url || ''
    this.display_url = this.display_url || ''
    this.tags = this.tags || []
    this.authentication_providers = this.authentication_providers || []
    this.configuration = this.configuration || {}
    this.redirect_uris = this.redirect_uris || []
    this.configuration.logo = this.configuration.logo || ''
    this.configuration.color = this.configuration.color || '#00CCF9'
    this.configuration.background = this.configuration.background || ''
    this.configuration.title = this.configuration.title || ''
    this.configuration.message = this.configuration.message || ''
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      'createdAt',
      'updatedAt',
      'createdBy',
      'updatedBy',
      'secrets',
      'name'
    ])
    return obj
  }

  async create () {
    return super.create({
      method: 'POST',
      url: 'v4/applications'
    })
  }

  async createConsumer () {
    return super.create({
      method: 'POST',
      url: `v4/applications/install/${this.type}`
    })
  }

  async generateSecret (data) {
    const secret = await this.request({
      method: 'PUT',
      url: `applications/${this._id}/secret`,
      data
    })
    const secrets = [...this.secrets]
    secrets.push(secret)
    this.update('secrets', secrets, false)
    return secret
  }

  async deleteSecret (id) {
    await this.request({
      method: 'DELETE',
      url: `applications/${this._id}/secret/${id}`
    })
    const secrets = [...this.secrets]
    const idx = secrets.findIndex(s => s.id === id)
    if (idx !== -1) {
      secrets.splice(idx, 1)
      this.update('secrets', secrets, false)
    }
    return id
  }

  async save () {
    return super.save({
      method: 'PUT',
      url: `applications/${this._id}`
    })
  }

  async remove () {
    if (this.tags.includes('appmanager')) return
    return this.request({
      method: 'DELETE',
      url: `applications/${this._id}`
    })
  }

  share (data = {}) {
    return this.request({
      method: 'POST',
      url: `applications/${this._id}/share`,
      data
    })
  }
}

class Applications extends API {
  list () {
    return this.paginateListing({
      method: 'get',
      url: 'v4/applications'
    })
      .then(data => {
        return data.map(application => new Application(application))
      })
  }

  share (data = {}) {
    return this.request({
      method: 'POST',
      url: 'applications/share',
      data
    })
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }

    // Init message of application
    item.configuration = {
      message: 'Choose an authentication method below to login to this app.'
    }

    return new Application(item)
  }
}

export default Applications
export {
  Application,
  Applications
}
