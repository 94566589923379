<template lang="pug">
  .logout
    .logo
      img(:src="require('@/shared/assets/img/ovhcloud_logo_blue.png')", width="101")
    .dots
      img(:src="require('@/shared/assets/img/dots.png')", width="126")
    h2 {{$t('authentication.logout.title')}}
    p {{$t('authentication.logout.message')}}
    fpui-button(auto-width,color="white",@click="reconnect") {{$t('authentication.logout.reconnect')}}

</template>

<script>
import _omit from 'lodash/omit'
export default {
  methods: {
    reconnect () {
      this.$router.push({ path: this.$route.path, query: _omit(this.$route.query, ['logout']) })
    }
  }
}
</script>

<style lang="less">
.logout {
  .dots {
    margin-top: 65px;
    margin-bottom: 40px;
  }
  h2 {
    font-family: Source Sans Pro;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 10px;
  }
  p {
    margin-top: 10px!important;
    font-family: Source Sans Pro;
    font-size: 16px!important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px!important;
    letter-spacing: 0px;
    text-align: left;
  }
  .button-container {
    margin-top: 40px;
  }
}
</style>
