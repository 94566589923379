import Vue from 'vue'

import FpApp from './FpApp'
import FpAside from './FpAside'
import FpHeader from './Header/FpHeader'
import FpSplashError from './FpSplashError'
import FpTitle from './FpTitle'
import FpSidebar from './FpSidebar'

Vue.component('FpApp', FpApp)
Vue.component('FpAside', FpAside)
Vue.component('FpHeader', FpHeader)
Vue.component('FpSplashError', FpSplashError)
Vue.component('FpTitle', FpTitle)
Vue.component('FpSidebar', FpSidebar)
