<template lang="pug">
.button-container(:type='type', :disabled='disabled')
  button.btn.fpui-button(
    :class='className'
    :type="type"
    :style="buttonStyle"
    @click='onClick'
  )
    i(v-if="icon" :class="iconClass" :style="{ 'font-size': iconFontSize }")
    span.btn-content(v-if="haveContent && !iconOnly")
      slot
    i.fp4.fp4-chevron-down.left-icon(v-if="dropdownIcon")
    i.fp4.left-icon(
      v-if="customIconRightIfLeft"
      :class="customIconRightIfLeft"
    )
    .clearfix
  .loading-container(v-if="loading")
    .loading-wrapper
      .loading
        .bg(:class='className')
</template>

<script>
import _debounce from 'lodash/debounce'
export default {
  props: {
    icon: { type: String, default: '' },
    iconOnly: { type: Boolean, default: false },
    iconLeft: { type: Boolean, default: false },
    iconCenter: { type: Boolean, default: false },
    iconFontSize: { type: String, default: '15px ' },
    unbordered: { type: Boolean, default: false },
    noshadow: { type: Boolean, default: false },
    color: { type: String, default: 'blue' },
    disabled: { type: Boolean, default: false },
    reverse: { type: Boolean, default: false },
    capitalize: { type: Boolean, default: false },
    center: { type: Boolean, default: false },
    type: { type: String, default: 'submit' },
    toolbar: { type: Boolean, default: false },
    theme: { type: String, default: 'v4' },
    autoWidth: { type: Boolean, default: false },
    defer: { type: Number, default: 0 },
    loading: { type: Boolean, default: false },
    dropdownIcon: { type: Boolean, default: false },
    customIconRightIfLeft: { type: String, default: '' },
    buttonStyle: { type: Object, default: null }
  },
  computed: {
    haveContent () {
      return this.$slots.default ? this.$slots.default[0].text !== '' : false
    },
    iconClass () {
      const iconClass = [this.icon]
      if (this.iconLeft) iconClass.push('pull-left')
      if (!this.iconLeft && this.iconCenter) iconClass.push('pull-center')
      if (!this.iconLeft && !this.iconCenter) iconClass.push('pull-right')
      return iconClass.join(' ')
    },
    className () {
      const className = [this.color]
      if (this.toolbar) {
        className.push('toolbar')
        className.push('reverse')
        className.push('unbordered')
      }
      if (this.iconOnly) {
        className.push('icon-only')
      }
      if (this.disabled) className.push('disabled')
      if (this.noshadow) className.push('noshadow')
      if (this.reverse) className.push('reverse')
      if (this.unbordered) className.push('unbordered')
      if (this.capitalize) className.push('capitalize')
      if (this.center) className.push('text-center')
      if (this.autoWidth) className.push('auto-width')
      if (this.icon) className.push('icon')
      if (!this.iconLeft && !this.iconCenter) className.push('icon-right')
      if (!this.iconLeft && this.iconCenter) className.push('text-center')
      if (this.iconLeft) className.push('icon-left')
      className.push(this.theme)
      return className.join(' ')
    }
  },
  methods: {
    onClick (e) {
      if (this.defer === null || this.defer) return this.onClickDefer(e)
      if (!this.disabled && !this.defer) this.$emit('click', e)
    },
    onClickDeferFn () {
      if (!this.disabled) this.$emit('click')
    },
    onClickDefer () {
      const defer = parseInt(this.defer) || 500
      if (!this.test) this.test = _debounce(this.onClickDeferFn, defer, { leading: true, trailing: false, maxWait: defer })
      this.test()
    }
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";

.button-container.fpui-button {
  position: relative;
  display: inline-block;
}
.button-container {
  position: relative;
  .btn-content {
    font-family: @global-font-family;
    line-height: 26px;
  }
  .left-icon {
    padding-left: 5px;
  }
}
.btn.fpui-button.v4 {
  user-select: none;
  height: 34px;
  border-radius: 5px;
  border: 0;
  padding: 3px 10px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  width: 124px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  line-height: 15px;
  outline: none !important;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.07),
    0 3px 6px 0 rgba(0, 0, 0, 0.14);
  text-align: center;
  cursor: pointer;
  &.capitalize {
    text-transform: uppercase;
  }
  .input-transition();
  i.fa {
    line-height: 18px;
  }
  i {
    display: inline-block;
    margin: 0;
  }
  i.pull-left + span {
    margin-left: 5px;
  }
  i.pull-right {
    order: 1;
  }
  i.pull-right + span {
    margin-right: 10px;
  }
  i.pull-center + span {
    margin: 0 auto;
  }
  i.fp,
  i.fpui,
  i.fp4 {
    line-height: 18px;
    font-size: 15px;
  }
  &.pull-left i,
  i.pull-left {
    padding: 0;
    line-height: 18px;
  }
  &.icon {
    justify-content: flex-start;
    &.icon-right {
      justify-content: flex-end;
    }
  }
  &.text-center {
    justify-content: center;
  }
  // &:focus:not(.disabled),
  // &:active:not(.disabled) {
  //   box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.07);
  // }
  button:active:disabled {
    border-style: none;
  }
  &.black {
    border-color: @black;
    background-color: @black;
    color: @white;
    &:hover:not(.disabled) {
      background-color: darken(@black, 2.5%) !important;
    }
    &:active:not(.disabled) {
      background-color: darken(@black, 10%) !important;
    }
    &.reverse {
      background-color: transparent;
      color: @black;
      &:not(.unbordered)&:hover {
        color: @white;
      }
    }
    .input-button-disabled();
  }
  &.green {
    background-color: @green;
    color: @white;
    border-color: @green;
    &:hover:not(.disabled) {
      background-color: darken(@green, 2.5%) !important;
    }
    &:active:not(.disabled) {
      background-color: darken(@green, 10%) !important;
    }
    &.reverse {
      background-color: transparent;
      color: @green;
      &:not(.unbordered)&:hover {
        color: @white;
      }
    }
    .input-button-disabled();
  }
  &.grey {
    border-color: @grey_dark;
    background-color: @grey_dark;
    color: #000;
    &:hover:not(.disabled) {
      background-color: darken(@grey_dark, 30%) !important;
    }
    &:active:not(.disabled) {
      background-color: darken(@grey_dark, 50%) !important;
    }
    &.reverse {
      background-color: transparent;
      color: @grey;
      &:hover:not(.disabled) {
        color: #748294;
        i {
          color: #748294 !important;
        }
      }
      &:not(.unbordered)&:hover {
        color: #F6F9FC;
        i {
          color: #F6F9FC !important;
        }
      }
    }
    &.disabled {
      color: #E9ECF0;
      cursor: not-allowed;
    }
  }
  &.red {
    background-color: @red;
    color: @white;
    border-color: @red;
    &:hover:not(.disabled) {
      background-color: darken(@red, 2.5%) !important;
    }
    &:active:not(.disabled) {
      background-color: darken(@red, 10%) !important;
    }
    &.reverse {
      background-color: transparent;
      color: @red;
      &:not(.unbordered)&:hover:not(.disabled) {
        color: @white;
      }
    }
    .input-button-disabled();
  }
  &.pink {
    border-color: @pink;
    background-color: @pink;
    color: @white;
    &:hover:not(.disabled) {
      background-color: darken(@pink, 2.5%) !important;
    }
    &:hover.disabled {
      color: @red !important;
    }
    &:active:not(.disabled) {
      background-color: darken(@pink, 10%) !important;
    }
    &.reverse {
      background-color: transparent;
      color: @pink;
      &:not(.unbordered)&:hover {
        color: @white;
      }
    }
    .input-button-disabled();
  }
  &.blue {
    border-color: @blue;
    background-color: @blue;
    color: @white;
    &:hover:not(.disabled) {
      background-color: darken(@blue, 2.5%) !important;
    }
    &:active:not(.disabled) {
      background-color: darken(@blue, 10%) !important;
    }
    &.reverse {
      background-color: transparent;
      color: @blue;
      &:not(.unbordered)&:hover {
        color: @white;
      }
    }
    .input-button-disabled();
  }
  &.blue-flash {
    background-color: @blue_flash;
    color: @white;
    border-color: @blue_flash;
    &:hover:not(.disabled) {
      background-color: darken(@blue_flash, 2.5%) !important;
    }
    &:active:not(.disabled) {
      background-color: darken(@blue_flash, 10%) !important;
    }
    &.reverse {
      background-color: transparent;
      color: @blue_flash;
      &:not(.unbordered)&:not(.disabled)&:hover {
        color: @white;
      }
    }
    .input-button-disabled();
  }
  &.yellow {
    border-color: @yellow;
    background-color: @yellow;
    color: @white;
    &:hover:not(.disabled) {
      background-color: darken(@yellow, 8%) !important;
    }
    &:active:not(.disabled) {
      background-color: darken(@yellow, 15%) !important;
    }
    &.reverse {
      background-color: transparent;
      color: @yellow;
      &:not(.unbordered)&:hover {
        color: @white;
      }
    }
    .input-button-disabled();
  }
  &.white {
    border: 1px solid #e9ecf0;
    border-radius: 5px;
    background-color: #ffffff;
    color: #97a7b7;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.03);
    &:hover:not(.disabled) {
      background-color: darken(@white, 2.5%) !important;
    }
    &:active:not(.disabled) {
      background-color: darken(@white, 10%) !important;
    }
    &:hover:not(.disabled) {
      background-color: #f9fafb;
    }
    .input-button-disabled();
  }
  &.custom {
    &:hover:not(.disabled) {
      filter: brightness(97%);
    }
    &:active:not(.disabled) {
      filter: brightness(90%);
    }
    .input-button-disabled();
  }
  &.transparent {
    border: none;
    padding: 0;
    background-color: transparent;
    &:hover:not(.disabled) {
      background-color: transparent;
    }
  }
  &.unbordered {
    border: 0;
    text-transform: none;
    &:hover:not(.disabled) {
      background-color: transparent !important;
      &.green {
        color: @green;
      }
      &.blue {
        color: @blue;
      }
      &.red {
        color: @red;
      }
      &.pink {
        color: @pink;
      }
      &.yellow {
        color: @yellow;
      }
    }
    i {
      font-size: 17px;
    }
  }
  &.noshadow {
    box-shadow: none;
  }
  &.toolbar.reverse {
    color: #cbd3db;
    i {
      font-size: 20px;
    }
    span.btn-content {
      margin-left: 0;
    }
  }
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  &.auto-width,&.icon-only {
    width: auto;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
  }
  &.icon-only {
    padding: 7px 27px 8px 27px;
  }
  &.icon-only {
    padding-left:12px;
    padding-right:12px;
  }
}
.loading-container {
  position: absolute;
  top: 0;
  width: 97%;
  z-index: -1;
  .loading-wrapper {
    width: 95%;
    position: relative;
    height: 34px;
    top: 0;
    left: 5px;
    border-radius: 5px;
    overflow: hidden;
    .loading {
      left: -65px;
      width: 195px;
      height: 100%;
      position: absolute;
      .bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        opacity: 0.6;
        overflow: hidden;
        cursor: pointer;

        &.green {
          background: -webkit-repeating-linear-gradient(
          145deg,
          rgba(0,0,0,0) 1px,
          darken(fade(@green, 30%), 15%) 2px,
          darken(fade(@green, 30%), 15%) 2px,
          rgba(0,0,0,0) 12px,
          rgba(0,0,0,0) 20px
        );
        background: repeating-linear-gradient(
          -55deg,
          rgba(0,0,0,0) 1px,
          darken(fade(@green, 30%), 15%) 2px,
          darken(fade(@green, 30%), 15%) 11px,
          rgba(0,0,0,0) 12px,
          rgba(0,0,0,0) 20px
        );
        }
        &.blue {
          background: -webkit-repeating-linear-gradient(
          145deg,
          rgba(0,0,0,0) 1px,
          darken(fade(@blue, 30%), 15%) 2px,
          darken(fade(@blue, 30%), 15%) 2px,
          rgba(0,0,0,0) 12px,
          rgba(0,0,0,0) 20px
        );
        background: repeating-linear-gradient(
          -55deg,
          rgba(0,0,0,0) 1px,
          darken(fade(@blue, 30%), 15%) 2px,
          darken(fade(@blue, 30%), 15%) 11px,
          rgba(0,0,0,0) 12px,
          rgba(0,0,0,0) 20px
        );
        }
        &.red {
          background: -webkit-repeating-linear-gradient(
          145deg,
          rgba(0,0,0,0) 1px,
          darken(fade(@red, 30%), 15%) 2px,
          darken(fade(@red, 30%), 15%) 2px,
          rgba(0,0,0,0) 12px,
          rgba(0,0,0,0) 20px
        );
        background: repeating-linear-gradient(
          -55deg,
          rgba(0,0,0,0) 1px,
          darken(fade(@red, 30%), 15%) 2px,
          darken(fade(@red, 30%), 15%) 11px,
          rgba(0,0,0,0) 12px,
          rgba(0,0,0,0) 20px
        );
        }
        &.pink {
          background: -webkit-repeating-linear-gradient(
          145deg,
          rgba(0,0,0,0) 1px,
          darken(fade(@pink, 30%), 15%) 2px,
          darken(fade(@pink, 30%), 15%) 2px,
          rgba(0,0,0,0) 12px,
          rgba(0,0,0,0) 20px
        );
        background: repeating-linear-gradient(
          -55deg,
          rgba(0,0,0,0) 1px,
          darken(fade(@pink, 30%), 15%) 2px,
          darken(fade(@pink, 30%), 15%) 11px,
          rgba(0,0,0,0) 12px,
          rgba(0,0,0,0) 20px
        );
        }
        &.yellow {
          background: -webkit-repeating-linear-gradient(
          145deg,
          rgba(0,0,0,0) 1px,
          darken(fade(@yellow, 30%), 15%) 2px,
          darken(fade(@yellow, 30%), 15%) 2px,
          rgba(0,0,0,0) 12px,
          rgba(0,0,0,0) 20px
        );
        background: repeating-linear-gradient(
          -55deg,
          rgba(0,0,0,0) 1px,
          darken(fade(@yellow, 30%), 15%) 2px,
          darken(fade(@yellow, 30%), 15%) 11px,
          rgba(0,0,0,0) 12px,
          rgba(0,0,0,0) 20px
        );
        }

      -webkit-animation-name: MOVE-BG;
      -webkit-animation-duration: 1.5s;
      -webkit-animation-timing-function: linear;
      -webkit-animation-iteration-count: infinite;

      animation-name: MOVE-BG;
      animation-duration: 1.5s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;

      @-webkit-keyframes MOVE-BG {
      from {
        -webkit-transform: translateX(0);
      }
      to {
        -webkit-transform: translateX(46px);
      }
      }
      @keyframes MOVE-BG {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(46px);
      }
      }
      }
    }
  }
}
</style>
