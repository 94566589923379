<template lang="pug">
  .array(v-if="value")
    .fpui-input-label-container(
        v-if='label'
      )
        span {{ label | translate }}
        .required-container(v-if='required') *
        fpui-helper(v-if='helper', :text="helper | translate")
    .array-content
      .item(
        @mouseenter="hover=true",
        @mouseleave="hover=false",
        :style="CSSObject(options,'.item',{hover:hover})",
        :key="key",
        v-for="(v,key) in value"
      )
        component.item(
          :is="getComponent(options.type)",
          :disabled="disabled",
          :display-only="displayOnly"
          :value="v",
          @input="v=>update(key,v)",
          v-bind="options"
        )
        fpui-button(@click="removeItem(key)",icon=" fp4 fp4-trash-can",auto-width, color="red",:disabled="disabled")
      br
      fpui-button.pull-right(@click="newItem",icon="fp4 fp4-plus",auto-width,:disabled="disabled") {{'auto-form.array.add_item' | translate}}
</template>

<script>
export default {
  props: {
    displayOnly: { type: Boolean, default: false },
    value: { type: Array, default: () => [] },
    label: { type: String, default: '' },
    required: Boolean,
    disabled: Boolean,
    helper: { type: String, default: '' },
    options: { type: Object, default: () => {} }
  },
  data () {
    return { hover: false }
  },
  mounted () {
    if (!this.value || !this.value.length) this.$emit('input', [null])
  },
  methods: {
    update (key, v) {
      const newValue = [...this.value]
      newValue[key] = v
      this.$emit('input', newValue)
    },
    newItem () {
      const newValue = [...this.value]
      newValue.push(null)
      this.$emit('input', newValue)
    },
    removeItem (index) {
      const newValue = [...this.value]
      newValue.splice(index, 1)
      this.$emit('input', newValue)
    },
    getComponent (type) {
      return `auto-form-${type}`
    }
  }
}
</script>

<style lang="less">
  .array {
    .fpui-input-label-container {
      padding-left: 0;
      font-size: 12px !important;
      margin-bottom: 5px;
      color: #748294;
      cursor: pointer;
      .required-container {
        display: inline;
        vertical-align: middle;
        font-size: 22px;
        line-height:18px;
        text-align: center;
        border-radius: 50%;
      }
    }
    .array-content > .item {
      margin-bottom:0;
      .fpui-input-text {
        display: inline-block;
        width: calc(~"100% - 80px");
        vertical-align: top;
        input {
          border-radius: 3px 0 0 3px!important;
        }
      }
      button {
        display:inline-block!important;
        vertical-align: top;
        height:34px!important;
        width:80px!important;
        border-radius: 0 5px 5px 0!important;
      }
    }
  }
</style>
