import AutoForm from './AutoForm.vue'
import Vue from 'vue'
import _merge from 'lodash/merge'
import Components from './components'

for (const componentName in Components) {
  Vue.component(componentName, Components[componentName])
}

// We set a global accessor to CSSObject, to made easier the call
Vue.mixin({
  methods: {
    CSSObject ({ css }, cls, opts) {
      opts = opts || {}
      const itemCss = css ? css[cls] : {}
      if (opts.hover) {
        return itemCss ? _merge({}, itemCss.default, itemCss.hover) : {}
      }
      return itemCss ? itemCss.default : {}
    }
  }
})

export default AutoForm
