import axios from 'axios'
import _get from 'lodash/get'
import _merge from 'lodash/merge'
import Vue from 'vue'

class I18N {
  get languages () {
    return ['en', 'fr', 'jp']
  }

  async load (moduleName) {
    if (process.env.VUE_APP_I18N_OTA === 'true' || process.env.VUE_APP_I18N_OTA === true) {
      const { data } = await axios.get(`${moduleName}/i18n.json`)
      this.languages.forEach(lang => Vue.i18n.add(lang, _get(data, lang)))
    } else {
      this.languages.forEach(lang => {
        try {
          const i18n = _merge(require(`@/i18n/src/shared/${lang}`), require(`@/i18n/src/store/${lang}`), require(`@/i18n/src/${moduleName}/${lang}`))
          Vue.i18n.add(lang, i18n)
        } catch (e) {
          console.warn(`Could not load language ${lang} for ${moduleName}`)
        }
      })
    }
  }
}

export default new I18N()
