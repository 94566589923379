import Date from '@/shared/filters/Date'
import { API, APIObject } from '@/shared/plugins/Api/API'
import _set from 'lodash/set'
import moment from 'moment'

class QueryHistory extends APIObject {
  constructor (options) {
    // Init
    super('QUERY_ADMIN', options)

    // Default variables
    this.created_by = this.created_by || null
    // this.created_at = this.created_at || '-'
    this.query_id = this.query_id || null
    this.rows_returned = this.rows_returned || 0
    this.runtime = this.runtime || 0
    this.origin = this.origin || ''
    this.status = this.status || ''
    this.queried_tables = this.queried_tables || []
    this.sql = this.sql || ''
  }

  assign (object) {
    super.assign(object)
    this.created_at = Date(this.created_at) || null
    this.updated_at = Date(this.updated_at) || null
  }

  get isActive () {
    return ['SUBMITTED', 'QUEUED', 'BUILDING', 'PROVISIONING', 'PROCESSING'].includes(this.status)
  }

  get stopped () {
    return this.status === 'STOPPED'
  }

  get queued () {
    return ['SUBMITTED', 'QUEUED'].includes(this.status)
  }

  // Possible return : SUCCESS, FAILED, STOPPED, TIMEOUT, PENDING, PROCESSING, PROVISIONING, QUEUED, SUBMITTED, BUILDING
  get state () {
    if (this.isActive) return 'RUNNING'
    return this.status
  }

  // Determines the job nature if it's running, in queue or done (past).
  get nature () {
    if (['QUEUED', 'SUBMITTED'].includes(this.status)) return 'queued'
    if (this.isActive) return 'running'
    return 'past'
  }
}

const cache = {}
class QueriesHistory extends API {
  async list (noCache = false, origin = null) {
    // We do this as there is no WS to avoir 2nd call when open AM
    if (!noCache && cache.value && moment().diff(cache.date, 'seconds') < 30) return cache.value

    const jobs = await this.request({
      method: 'get',
      url: 'v3/history',
      params: {
        'query-origin': origin || undefined
      }
    })

    cache.date = moment()
    cache.value = jobs.map(job => {
      try {
        return new QueryHistory(job)
      } catch (err) {
        console.error(err.stack)
        return null
      }
    }).filter(i => i)

    return cache.value
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new QueryHistory(item)
  }
}

export default QueriesHistory
export {
  QueryHistory,
  QueriesHistory
}
