<template lang="pug">
.chart-big-number
  .value {{ isNaN(valueToDisplay) ? valueToDisplay : valueToDisplay.toLocaleString() }}
  .compare-value(
    v-if="options.compareToPrevious && hasSecondRow && (compareValue || compareValue === 0)"
    :style="{ color: compareValueColor }"
  ) {{ `${compareValue > 0 ? '+' : ''} ${isNaN(compareValue) ? compareValue : compareValue.toLocaleString()}${options.percent ? '%' : ''}` }}
  .compare-value(v-if="options.dimensions") {{ dimensions }}
</template>

<script>
import _get from 'lodash/get'

export default {
  props: {
    value: { type: Object, default: () => {} },
    sql: { type: Boolean, default: false }
  },
  computed: {
    options () {
      return this.value?.options || {}
    },
    attribute () {
      if (!this.sql && this.value?.query_params?.data?.fields) {
        return Object.keys(this.value?.query_params?.data?.fields)[0]
      }
      return null
    },
    cm () {
      if (!this.sql && this.value?.query_params?.data?.fields) {
        return this.value?.query_params?.data?.fields[this.attribute][0]
      }
      return null
    },
    valueToDisplay () {
      let value = null
      if (!this.sql) {
        if (this.value?.query_params?.data?.fields) {
          value = _get(this.value?.results, `[0].data.${this.attribute}.${this.cm}[0].value`)
        }
      } else {
        value = _get(this.value?.results, '[0][0]')
      }

      if (isNaN(value)) return value

      return value !== null ? (Math.round(value * 100) / 100) : ''
    },
    hasSecondRow () {
      if (!this.sql) {
        if (this.value?.query_params?.data?.fields) {
          return !!_get(this.value?.results, `[1].data.${this.attribute}.${this.cm}[0].value`)
        }
      } else {
        return !!_get(this.value?.results, '[0][1]')
      }

      return null
    },
    compareValue () {
      let previousVal = this.valueToDisplay

      if (!this.options?.compareToPrevious || isNaN(previousVal)) return ''
      if (!this.sql) {
        if (this.value?.query_params?.data?.fields) {
          previousVal = _get(this.value?.results, `[1].data.${this.attribute}.${this.cm}[0].value`) || 0
        }
      } else {
        previousVal = _get(this.value?.results, '[0][1]') || 0
      }

      if (isNaN(previousVal)) previousVal = 0

      if (this.options?.percent) {
        return previousVal ? Math.round(((this.valueToDisplay - previousVal) / previousVal * 100) * 100) / 100 : 100
      }

      return (Math.round((this.valueToDisplay - previousVal) * 100) / 100)
    },
    compareValueColor () {
      // Returns the inversion of colors for the progression or regression depending on the GeneralPanel.vue toggle
      if (!this.options.invertedColor) {
        if (this.compareValue > 0) return '#9EDF10'
        if (this.compareValue < 0) return '#F62172'
      } else {
        if (this.compareValue > 0) return '#F62172'
        if (this.compareValue < 0) return '#9EDF10'
      }
      return '#97A7B7'
    },
    dimensions () {
      // Gets, if possible, the first scale and the second one, otherwise return only the first scale
      if (this.value.query_params.scale.fields.length > 0) {
        const res0 = this.value.results[0].scales[this.value.query_params.scale.fields[0]]
        const res1 = this.value.results[1]?.scales[this.value.query_params.scale.fields[0]]
        const dim = this.value.results.length > 1 ? `${res0} vs. ${res1}` : res0
        return `(${dim})`
      }
      return ''
    }
  }
}
</script>

<style lang="less">
.chart-big-number {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #3E4550;
  font-size: 36px;

  .value {
    text-align: center;
    font-weight: 700;
  }
  .compare-value {
    margin-top: 5px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #97A7B7;
  }
}
</style>
