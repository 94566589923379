<template lang="pug">
.quick-project-creation
  fp-loader(v-show="loading")
  fpui-modal-header(
    :title="$t('dataplant.creation.quick_creation')"
    @close='$emit("close")'
    closeTopButton
  )
  fpui-modal-body
    .quick-project-tip {{ $t('dataplant.creation.dataplant_details.quick_creation.tip') }}
    .quick-project-infos
      fpui-input-text(
        v-model="dataplantName"
        :label="$t('dataplant.creation.dataplant_details.form.name.label')"
        :placeholder="$t('dataplant.creation.dataplant_details.form.name.placeholder')"
      )
      fpui-input-select(
        v-model="dataplantRegion",
        name='id'
        :width-fit="false"
        :clearable="false"
        :label="$t('dataplant.creation.dataplant_details.form.region.label')"
        :options='regions.map( r => ({ label: r.id, value: r, image: providerImage(r.provider) }))'
      )
      fpui-input-tags(
        v-model="options.configuration.dataplant.tags"
        :label="$t('dataplant.creation.dataplant_details.form.tags.label')"
        :placeholder="$t('dataplant.creation.dataplant_details.form.tags.placeholder')"
      )
      .domain-row
        img.loading(
          src='@/core/assets/img/ic_spinner.png'
          :class="{ 'is-loading': checkingDomain }"
        )
        .subdomain
          fpui-input-text(
            v-model="dataplantSubdomain"
            :label="$t('dataplant.creation.dataplant_details.form.subdomain.label')"
            :placeholder="$t('dataplant.creation.dataplant_details.form.subdomain.placeholder')"
            :error="!!errorDomain"
          )
        .subdomain
          fpui-input-text(
            v-if="availableDomains.length < 2"
            v-model="options.configuration.dataplant.domain"
            :label="$t('dataplant.creation.dataplant_details.form.domain.label')"
            disabled
          )
          fpui-input-select(
            v-else
            v-model='options.configuration.dataplant.domain'
            name='domain'
            direction='top'
            :options='availableDomains.map(ad => ({ label: ad.domain, value: ad.domain }))'
            :clearable='false'
            :label="$t('dataplant.creation.dataplant_details.form.domain.label')"
          )
        .error-placeholder(:class="{ error: errorDomain }")
          span {{ errorDomain }}
  fpui-modal-footer(:buttons="buttons")
    fpui-button(
      color="white"
      icon="fp4 fp4-top-right-in-square"
      icon-left
      auto-width
      @click="redirectToCreationFullscreen"
    ) {{ $t('button.create_in_full_screen_mode') }}
</template>

<script>
import _debounce from 'lodash/debounce'
import _find from 'lodash/find'
import Config from '@/shared/Config'
import DataplantCreationFullscreen from '@/shared/components/project/DataplantCreationFullscreen/DataplantCreationFullscreen.vue'
import { tsvFormatValue } from 'd3'
import SubdomainName from '@/shared/filters/SubdomainName'

export default {
  props: {
    organizationId: { type: String, required: true },
    module: { type: String, required: true },
    afterConfirm: { type: Function, default: null }
  },
  data () {
    return {
      checkingDomain: false,
      config: {},
      buttons: [
        {
          label: this.$t('button.create'),
          color: 'green',
          disabled: () => {
            return this.dataplantName === '' ||
            this.dataplantSubdomain === '' ||
            !this.dataplantRegion
          },
          handler: () => {
            this.createProject()
          }
        }
      ],
      loading: false,
      options: {
        providers: [],
        engines: ['shared', 'default'],
        instances: [],
        domainAvailable: null,
        defaultStorageEngineConfigurations: {
          default: {},
          shared: {
            data_mart: 'shared',
            data_prim: 'shared',
            data_ml: 'shared'
          }
        },
        configuration: {
          storageEngine: {
            engine: 'default',
            instance: null,
            configuration: null
          },
          dataplant: {
            name: '',
            description: '',
            tags: [],
            domain: '',
            subdomain: '',
            provider: null,
            region: null
          }
        },
        template: { id: '' }
      }
    }
  },
  computed: {
    dataplantName: {
      get () {
        return this.options.configuration.dataplant.name
      },
      set (value) {
        this.options.configuration.dataplant.name = value
        this.options.configuration.dataplant.subdomain = SubdomainName(value)
        this.checkDomain()
      }
    },
    dataplantSubdomain: {
      get () {
        return this.options.configuration.dataplant.subdomain
      },
      set (value) {
        this.options.configuration.dataplant.subdomain = SubdomainName(value)
        this.checkDomain()
      }
    },
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    subscription () {
      return this.organization.subscription
    },
    isFreeTrial () {
      return this.subscription?.status === 'in_trial' && this.subscription?.scheduled_status === 'cancelled'
    },
    isStandardPlan () {
      return this.subscription?.plan === 'standard'
    },
    canUseCurrentProvider () {
      const provider = this.options.configuration.dataplant.provider
      if (provider !== 'automatic' && (this.isFreeTrial || this.isStandardPlan)) return false
      return true
    },
    providerName () {
      if (!this.options.configuration.dataplant.provider) return null
      return _find(this.options.providers, { name: this.options.configuration.dataplant.provider })?.display_options?.display_name
    },
    dataplantRegion: {
      get () {
        return this.options.configuration.dataplant.region
      },
      set (value) {
        this.options.configuration.dataplant.region = value
        this.options.configuration.dataplant.provider = value.provider
      }
    },
    regionName () {
      return this.options.configuration.dataplant.region?.regionName || ''
    },
    regions () {
      // TODO To optimize later
      const regions = this.options.providers.map((p) => {
        return Array.isArray(p.regions)
          ? p.regions.map((r) => {
            return {
              id: r?.name,
              provider: r?.provider,
              providerName: p?.display_options?.display_name,
              regionName: r?.display_options.display_name
            }
          })
          : []
      })
      regions.unshift([{
        id: 'automatic',
        provider: 'automatic',
        regionName: this.$t('dataplant.creation.automatic_provider.description')
      }])
      return regions.reduce((a, b) => a.concat(b), [])
    },
    availableDomains () {
      const authorizedDomains = this.organization.authorized_domain || []
      return authorizedDomains.filter(ad => ad.forepaas)
    },
    errorDomain () {
      if (!this.options.configuration.dataplant.subdomain) return false
      if (this.options.configuration.dataplant.subdomain.length < 3) return this.$t('dataplant.creation.details.subdomain.error_length')
      if (!this.options.domainAvailable && !this.checkingDomain) return this.$t('dataplant.creation.details.subdomain.error_exists')
      return false
    },
    subscriptionStatus () {
      if (this.organization.bypass_billing) return 'active'
      if (!this.subscription) return null
      return this.subscription?.status
    }
  },
  async mounted () {
    try {
      this.config = await Config()
      this.options.module = this.module
      if (!this.options.configuration.dataplant.domain && this.availableDomains.length) this.options.configuration.dataplant.domain = this.availableDomains[0].domain
      await this.$store.dispatch('REFRESH_PROVIDERS_BY_ORGANIZATION', this.organizationId)
      await this.$store.dispatch('LOAD_OFFERS')
      this.options.providers = this.$store.getters.PROVIDERS_BY_ORGANIZATION_ID(this.organizationId)
      // Automatic provider for free trial and standard plan
      this.options.providers.unshift({
        active: true,
        display_options: {
          display_name: this.$t('dataplant.creation.automatic_provider')
        },
        name: 'automatic',
        options: null,
        status: 'available',
        regions: []
      })
      this.options.instances = this.$store.getters.STORAGE_INSTANCES_BY_ORGANIZATION_ID(this.organizationId)
      // Default storage engine
      const storageConfigurations = [
        {
          organization_id: 'fpdevenv',
          configuration: {
            data_mart: 'shared',
            data_ml: 'shared',
            data_prim: 'shared'
          },
          creator_id: 'nlecomte@forepaas.com',
          created_at: '2022-09-09T09:57:17Z',
          updated_at: '2023-04-26T21:39:08.634302Z',
          deleted_at: null
        }
      ]
      this.options.defaultStorageEngineConfigurations.default = storageConfigurations[0].configuration
      this.options.configuration.storageEngine.configuration = storageConfigurations[0].configuration
      // When testing a new dataplant the loadSubscription must be commented to be testable
      // await this.organization.loadSubscription(true)
      // Defaults to automatic provider when in free trial or paid standard plan
      if ((this.subscription?.status === 'in_trial' && this.subscription?.scheduled_status === 'cancelled') || (this.subscription?.plan === 'standard')) {
        this.options.configuration.dataplant.provider = 'automatic'
        this.options.configuration.storageEngine.engine = 'shared'
      }
      if (this.template) {
        this.updateValue({ template: this.template })
        this.next()
      }
    } catch (err) {
      this.$fpuiMessageBlock.error(err)
      console.error(err)
    }

    const automatic = {
      id: 'automatic',
      provider: 'automatic',
      regionName: this.$t('dataplant.creation.automatic_provider.description')
    }
    if ((this.subscription?.status === 'in_trial' && this.subscription?.scheduled_status === 'cancelled') || (this.subscription?.plan === 'standard')) {
      this.dataplantRegion = automatic
    } else {
      const ovhProvider = this.regions.find(p => p.provider === 'ovh')
      ovhProvider ? this.dataplantRegion = ovhProvider : this.dataplantRegion = automatic
    }
  },
  methods: {
    checkDomain: _debounce(async function () {
      this.checkingDomain = tsvFormatValue
      if (this.options.configuration.dataplant.subdomain.length < 3) {
        this.checkingDomain = false
        this.options.domainAvailable = false
        return this.options.domainAvailable
      }
      // Normal 404 error return to let us know no domain has that name
      const checkDomain = await this.$api.FPAPI.checkDomainAvailability(this.dataplantSubdomain.toLowerCase(), this.options.configuration.dataplant.domain, this.organizationId)
      this.checkingDomain = false
      if (checkDomain.state === 404) this.options.domainAvailable = true
      else this.options.domainAvailable = false
    }, 300),
    async createProject () {
      if (!this.subscription) await this.organization.loadSubscription()
      if (
        (['active', 'non_renewing'].includes(this.subscriptionStatus)) ||
        (this.subscription?.status === 'in_trial' && this.subscription?.payment_valid && this.subscription?.scheduled_status !== 'cancelled')
      ) {
        if (!this.$acl(this.config.CMP ? this.organizationId : true).can('core', 'dataplants', 'create')) return
        try {
          this.loading = true
          if (!this.subscription || this.subscription.error) throw new Error(this.$t('dataplant.creation.no_sub'))

          if (this.options.configuration.storageEngine.instance) {
          // When the user has created a storage engine instance from the Dataplant Creation UI,
          // begin the creation on Dataplant creation confirmation
            if (this.options.configuration.storageEngine.instance.startsWith('tmp_instance-')) {
              const instanceFromList = _find(this.options.instances, { tmpId: this.options.configuration.storageEngine.instance })
              const createdInstance = await instanceFromList.create()
              this.$analytics.track('Storage Engine Created', {
                instance_id: createdInstance.id,
                cloud_provider_name: createdInstance.provider,
                cloud_provider_location: createdInstance.region,
                resources_allocated: createdInstance.fpu,
                resources_type: createdInstance.fpu_type,
                engine: createdInstance.engine,
                origin: 'Dataplant',
                high_availability: createdInstance.ha
              })
              this.updateValue({
                'configuration.storageEngine.configuration': {
                  data_mart: createdInstance.id,
                  data_prim: createdInstance.id,
                  data_ml: createdInstance.id
                }
              })
            }
          }

          const provider = this.options.configuration.dataplant.provider
          let service = this.options.module
          if (['api', 'app'].includes(this.options.module)) service = 'appservice'
          if (['lakehouse', 'data-catalog'].includes(this.options.module)) service = 'dm'
          if (['aab'].includes(this.options.module)) service = 'gab'

          const storePackageUrls = [`${this.config.STORE}/v1/packages/project/modules/${service}/latest`]

          // Regroups all the data to create the project
          const newProject = {
            organization_id: this.organization._id,
            name: this.options.configuration.dataplant.name,
            description: this.options.configuration.dataplant.description,
            domain: this.options.configuration.dataplant.domain,
            subdomain: this.options.configuration.dataplant.subdomain,
            provider: provider === 'automatic' ? this.$store.getters.OFFERS.standard.provider : provider,
            region: provider === 'automatic' ? this.$store.getters.OFFERS.standard.region : this.options.configuration.dataplant.region?.id,
            storageEngine: this.options.configuration.storageEngine.configuration,
            plan: 'custom',
            datatank: 50,
            store_package_urls: storePackageUrls,
            tags: this.options.configuration.dataplant.tags
          }
          // Serves to track the clients use of the project creation steps
          this.$analytics.track('Dataplant creation start', {
            provider: newProject.provider,
            region: newProject.region,
            default_storage_engine_config: this.options.configuration.storageEngine.engine === 'default'
          })
          await this.$api.FPAPI.dataplants.create(newProject)
          await this.$store.dispatch('REFRESH_DATAPLANT_BY_ORGANIZATION_ID', { organizationId: this.organization._id, ignoreCache: true })
          if (this.afterConfirm) this.afterConfirm()
          this.$emit('close')
        } catch (err) {
          this.$fpuiMessageBlock.pop('error', err)
        } finally {
          this.loading = false
        }
      } else {
        this.updateTrialToRealPlan()
      }
    },
    redirectToCreationFullscreen () {
      this.$analytics.track('Open new project funnel', { from: 'welcome page', channel: 'new project template' })
      this.$modal.show(DataplantCreationFullscreen, {
        organizationId: this.organizationId,
        presetOptions: this.options
      }, {
        height: '100%',
        width: '100%',
        adaptative: true,
        classes: []
      })
    },
    providerImage (provider) {
      try {
        return require(`@/core/assets/img/providers/${provider}@2x.png`)
      } catch (err) {
        return require('@/core/assets/img/providers/standard.png')
      }
    }
  }
}
</script>

<style lang="less">
.quick-project-creation{
  > .modal-header {
    height: 64px;

    > h3{
      padding-left: 40px;
    }
  }

  > .modal-body{
    padding: 10px 30px 20px 40px;

    .quick-project-tip {
      margin-bottom: 10px;
      padding: 5px;
      color: #0089C0;
      background: rgba(3, 85, 153, 0.1);
      border: 1px solid #0089C0;
      border-radius: 5px;
    }

    .quick-project-infos{
      > .fpui-input-text {
        margin-bottom: 20px;
      }
      > .fpui-input-tags {
        margin-bottom: 20px;
      }
      > .fpui-input-select-container {
        margin-bottom: 20px;
      }

      .domain-row {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        img.loading {
          animation: rotating 1s infinite;
          -webkit-animation: rotating 1s infinite;
          width: 20px;
          position: absolute;
          top: 312px;
          left: 272px;
          opacity: 0;
          transition: opacity 20ms linear;
          &.is-loading {
            opacity: 1;
          }
        }

        .subdomain {
          flex: 1;
        }

        .error-placeholder {
          display: flex;
          flex-basis: 100%;
          margin: 0;
          height: 0;
          opacity: 0;
          transition: opacity 100ms ease-out, padding-top 500ms ease-out, height 500ms ease-out;
          span {
            color: #c93256;
          }
          &.error {
            opacity: 1;
            padding-top: 5px;
            height: auto;
          }
        }
      }
    }
  }

  > .modal-footer-container .modal-footer {
      padding: 20px 40px;
  }
}
</style>
