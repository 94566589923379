/* istanbul ignore file */
const config = {
  APP_ID: 'dashboarding',
  FPAPI: process.env.VUE_APP_FPAPI || 'https://hq-api.forepaas.io',
  KING: process.env.VUE_APP_KING || 'https://hq-api.forepaas.io/iam'
}

if (process.env.VUE_APP_ENV === 'local') {
  config.FPAPI = 'https://hq-dev-api.forepaas.com'
  config.KING = 'https://hq-dev-api.forepaas.com/iam'
}

export default config
