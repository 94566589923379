import { APIObject } from '@/shared/plugins/Api/API'
import moment from 'moment'

class Metrics extends APIObject {
  constructor (options) {
    // Init
    super('CC', options)
  }

  async appsRps (type) {
    const response = await this.request(
      {
        method: 'get',
        url: '/v1/metrics/overview/apps/rps',
        params: {
          start: moment().subtract(1, 'hours').unix(),
          end: moment().unix(),
          type
        }
      }
    )

    return response.data?.[0]?.value?.[1]
  }

  async alertingTriggerings (alertId = null, startEnd) {
    const params = {
      ...startEnd
    }
    if (alertId) {
      params.alertId = alertId
    }

    const response = await this.request(
      {
        method: 'get',
        url: '/v1/metrics/overview/alerting/triggerings',
        params
      }
    )

    return response.data?.[0]?.value?.[1]
  }
}

export default Metrics
export {
  Metrics
}
