import Config from '@/shared/Config'
import ConfigDashboard from '@/dashboard/config'

const axios = require('axios')

// Custom binding class with Segment, and King for tracking
class FpAnalytics {
  constructor (router, category = 'FPUI') {
    // All usefull parameters can be found here
    this.router = router // Router will be use to watch routechange and send page event

    // That option can be override from FpAnalycs initialisation
    this.category = category

    this.queue = []

    // Get the correct key, null for local
    this.segmentKey = {
      local: 'YJcvnUwjEkRQ6R8sWTGh5JrxmrAiR0Q8',
      development: 'YJcvnUwjEkRQ6R8sWTGh5JrxmrAiR0Q8',
      production: 'bspzCt3SGwOGomfy1jFnEH0UnQXs5FWg'
    }[process.env.VUE_APP_ENV || 'production'] || null

    this.consentKey = {
      local: '623c83535a815de13463b8ca',
      development: '623c83535a815de13463b8ca',
      production: '623c83535a815de13463b8ca'
    }[process.env.VUE_APP_ENV || 'production'] || null

    this.consentVersion = {
      local: 'hq',
      development: 'hq',
      production: 'hq'
    }[process.env.VUE_APP_ENV || 'production'] || null

    // Page tracking, and current page track
    if (router !== undefined) {
      router.afterEach(this.routerCallback.bind(this))
    }
  }

  routerCallback (to, from) {
    this.page(to.name || '', {
      path: to.fullPath,
      referrer: from.fullPath
    })
  }

  get debug () {
    return !this.segmentKey
  }

  /* istanbul ignore next */
  async consent () {
    if (!this.consentKey) return {}

    window.axeptioSettings = {
      clientId: this.consentKey,
      cookiesVersion: this.consentVersion
    }
    const t = document.getElementsByTagName('script')[0]; const e = document.createElement('script')
    e.async = true
    e.src = '//static.axept.io/sdk.js'
    t.parentNode.insertBefore(e, t)

    return new Promise(resolve => {
      undefined === window._axcb && (window._axcb = [])
      window._axcb.push(function (axeptio) {
        axeptio.on('cookies:complete', function (choices) {
          resolve(choices)
        })
      })
    })
  }

  /* istanbul ignore next */
  load (options) {
    this.choices = options

    this.integrations = {
      Intercom: options.intercom,
      Mixpanel: options.Mixpanel,
      Hubspot: options.hubspot,
      'Google Analytics': options.google_analytics
    }

    // If no segmentKey bypass load
    if (this.debug) return

    // Init segment connector
    const analytics = window.analytics = window.analytics || []
    analytics.methods = ['trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready', 'alias', 'debug', 'page', 'once', 'off', 'on', 'addSourceMiddleware', 'addIntegrationMiddleware', 'setAnonymousId', 'addDestinationMiddleware']

    analytics.factory = function (t) {
      return function () {
        const e = Array.prototype.slice.call(arguments)
        e.unshift(t)
        analytics.push(e)
        return analytics
      }
    }
    for (let t = 0; t < analytics.methods.length; t++) {
      const e = analytics.methods[t]
      analytics[e] = analytics.factory(e)
    }

    analytics.load = function (t, e) {
      const n = document.createElement('script')
      n.type = 'text/javascript'
      n.async = !0
      n.src = 'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js'
      const a = document.getElementsByTagName('script')[0]
      a.parentNode.insertBefore(n, a)
      analytics._loadOptions = e
    }
    analytics.SNIPPET_VERSION = '4.1.0'
    analytics.load(this.segmentKey, { integrations: this.integrations })

    analytics.ready(async () => {
      for (const fn of this.queue) {
        await fn()
      }
    })
    window.analytics = analytics
  }

  /* istanbul ignore next */
  identify (email, props, conf) {
    console.info(`Analytics:Identify:${email}`, props, conf)
    if (this.debug) {
      return
    }
    if (!this.choices) {
      return this.queue.push(() => {
        return window.analytics.identify(email, props, conf)
      })
    }
    return window.analytics.identify(email, props, conf)
  }

  /* istanbul ignore next */
  async track (event, properties = {}) {
    const config = this.category === 'DASHBOARD' ? ConfigDashboard : await Config()
    properties.project_id = properties.project_id || config?.PROJECT_ID
    properties.organization_id = properties.organization_id || config?.ORGANIZATION_ID
    properties.window_size = { height: window.innerHeight, width: window.innerWidth }
    if (this.debug) return console.info(`Analytics:Track:${event}`, properties)
    if (!this.choices) return
    await this.requestKing({
      url: '/v4/fpt',
      method: 'POST',
      data: {
        event,
        category: this.category,
        properties,
        integrations: this.integrations
      }
    })
  }

  /* istanbul ignore next */
  async page (name, properties = {}) {
    if (this.debug) return console.info(`Analytics:Page:${this.category}:${name}`, properties)
    if (!this.choices) return

    if (properties?.path) properties.path = properties.path?.split('?')[0] || ''
    if (properties?.referrer) properties.referrer = properties.referrer?.split('?')[0] || ''

    await this.requestKing({
      url: '/v4/fpp',
      method: 'POST',
      data: {
        name,
        category: this.category,
        properties,
        integrations: this.integrations
      }
    })
  }

  /* istanbul ignore next */
  async requestKing (options = {}) {
    const config = this.category === 'DASHBOARD' ? ConfigDashboard : await Config()
    options.baseURL = config.KING
    options.withCredentials = true
    return axios(options)
  }

  /* istanbul ignore next */
  install (Vue) {
    Vue.$analytics = this
    Vue.prototype.$analytics = this
  }
}

export default FpAnalytics
