<template lang="pug">
.dashboarding-filters(
  :style="filtersStyle"
)
  filter-component(
    v-for="(filter, i) in filters"
    :key="i"
    :item="item"
    :filter="filter"
    :editing="editing"
    :from-dashboard="fromDashboard"
    :layout="layout"
    @open-add-filter="openAddFilter"
  )

  add-filter(
    ref="add-filter"
    v-if="editing"
    :item="item"
  )
</template>

<script>
import AddFilter from './AddFilter'
import FilterComponent from './FilterComponent'

export default {
  components: {
    AddFilter,
    FilterComponent
  },
  props: {
    item: { type: Object, default: () => ({}) },
    editing: { type: Boolean, default: false },
    fromDashboard: { type: Boolean, default: false },
    layout: { type: Object, default: () => ({}) }
  },
  computed: {
    filtersStyle () {
      return {
        width: this.editing ? 'calc(100% - 90px)' : 'calc(100% - 100px)'
      }
    },
    filters () {
      return Object.keys(this.item.filter).map(f => {
        return {
          ...this.item.filter[f],
          label: f
        }
      })
    }
  },
  methods: {
    openAddFilter () {
      if (this.$refs && this.$refs['add-filter']) {
        // Due to the click listener we need to delay the opening
        setTimeout(() => {
          this.$refs['add-filter'].open()
        }, 100)
      }
    }
  }
}
</script>

<style lang="less">
.dashboarding-filters {
  border: 1px solid #E4E7EC;
  border-radius: 5px;
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 5px;
  margin: 0 50px 20px;
}
</style>
