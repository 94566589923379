import { APIObject } from '@/shared/plugins/Api/API'
import Date from '@/shared/filters/Date'
import _omit from 'lodash/omit'

class Version extends APIObject {
  constructor (options) {
    // Init
    super('QUERY_ADMIN', options)
    options = options || {}

    this.name = this.name || ''
    this.display_name = this.display_name || ''
    this.active = this.active || false
    this.repository = this.repository || null
    this.description = this.description || ''
    this.params = this.params || {}
    this.tags = this.tags || {}
    this.created_by = this.created_by || null
    this.updated_by = this.updated_by || null
    this.from = this.from || null // only used when creating a new version
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      '__socketId',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
      'repository'
    ])
    return obj
  }

  assign (object) {
    super.assign(object)
    this.created_at = Date(this.created_at) || null
    this.updated_at = Date(this.updated_at) || null
  }

  async create () {
    return super.create({
      method: 'POST',
      timeout: 60000,
      url: `v3/repositories/${this.repository}/versions`,
      socketId: false
    })
  }

  async save () {
    return super.save({
      method: 'PUT',
      url: `v3/repositories/${this.repository}/versions/${this._id}`
    })
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v3/repositories/${this.repository}/versions/${this._id}`
    })
  }
}
export default Version

export { Version }
