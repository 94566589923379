<template lang="pug">
  .fp-input-dropzone
    .no-file-uploaded(v-if="!fileName")
      .title-container
        fpui-input-label.dropzone-title(v-if="title") {{ title }}
        .tooltip(v-if="titleTooltipObject && titleTooltipObject.content")
          v-popover(trigger='hover' placement='right')
            i.fp4.fp4-circle-info
            template(slot='popover')
              .tooltip-container
                .tooltip-title {{ titleTooltipObject.title }}
                .rectangle
                .tooltip-body {{ titleTooltipObject.content }}
                .tooltip-link(@click="openSite(titleTooltipObject.link)") {{titleTooltipObject.linkWord}}
      img.drag-logo(
        v-if="!(inputFileTypes.length === 1 && inputFileTypes.includes('py'))"
        src="./drag&drop@2x.png"
        draggable='false'
      )
      img.drag-logo(
        v-if="inputFileTypes.length === 1 && inputFileTypes.includes('py')"
        src="./ic_drop_file@2x.png"
        draggable='false'
      )
      .dropzone-box(
        v-dropzone="dropzoneOptions"
        @click="openFileWindow"
      ) {{ $t(placeholder) }}
        span.space  {{$t('fpui-dropzone.or')}}
        span.do-action(@click="doAction") {{ $t(action) }}
        span.text-tail(v-if="textTail") {{ $t(textTail) }}
        input(
          ref="input"
          type="file"
          :multiple="multiple"
          :key="refresh"
          @change="onDrop($event.target.files)"
          :accept='accept'
        )
      .link-container(v-if="link")
        span.space {{$t('fpui-dropzone.or')}}
        span.link(@click="redirect") {{$t('fpui-dropzone.redirect')}}
    .file-preview(v-else)
      .title-container
        fpui-input-label.dropzone-title(v-if="title") {{ title }}
        .tooltip(v-if="titleTooltipObject && titleTooltipObject.content")
          v-popover(trigger='hover' placement='right')
            i.fp4.fp4-circle-info
            template(slot='popover')
              .tooltip-container
                .tooltip-title {{ titleTooltipObject.title }}
                .rectangle
                .tooltip-body {{ titleTooltipObject.content }}
                .tooltip-link(@click="openSite(titleTooltipObject.link)") {{titleTooltipObject.linkWord}}
      .filename
        img(
          v-if="refresh && fileType === 'py'"
          src="./python.svg"
        )
        div.name {{fileName}}
        div.actions
          i.fp4.fp4-trash-can(
            @click="removeFile"
          )
          i.fp4.fp4-eye(
            @click="openEditor"
            v-if="showEditor && !loading"
          )
          fp-loading(v-if="loading")
      br
      .function-shape-container
        .function-name(v-if="showFunctionName" :style="{ width: getFunctionWidth }")
          fpui-input-text(:label="$t('fpui-dropzone.function-name')" v-model="func")
          .tooltip(
            v-if="functionTooltipObject && functionTooltipObject.content"
            :style="{ left: getFunctionTooltipLeft }"
          )
            v-popover(trigger='hover' placement='right')
              i.fp4.fp4-circle-info
              template(slot='popover')
                .tooltip-container
                  .tooltip-title {{ functionTooltipObject.title }}
                  .rectangle
                  .tooltip-body {{ functionTooltipObject.content }}
                  .tooltip-link(@click="openSite(functionTooltipObject.link)") {{functionTooltipObject.linkWord}}
        .input-shape(v-if="showInputShape")
          fpui-input-text(
            :label="$t('fpui-dropzone.input-shape')"
            v-model="inputShape"
            :placeholder="$t('fpui-dropzone.input-shape.placeholder')"
          )
          .tooltip(
            v-if="inputShapeTooltipObject && inputShapeTooltipObject.content"
            :style="{ left: getInputShapeTooltipLeft }"
          )
            v-popover(trigger='hover' placement='right')
              i.fp4.fp4-circle-info
              template(slot='popover')
                .tooltip-container
                  .tooltip-title {{ inputShapeTooltipObject.title }}
                  .rectangle
                  .tooltip-body {{ inputShapeTooltipObject.content }}
                  .tooltip-link(@click="openSite(inputShapeTooltipObject.link)") {{inputShapeTooltipObject.linkWord}}
    .error-message(v-if="error") {{error}}
</template>

<script>
import { saveAs } from 'file-saver'
import MonacoEditorModal from './MonacoEditorModal'

export default {
  components: {
    MonacoEditorModal
  },
  props: {
    multiple: { type: Boolean, default: false },
    showFunctionName: { type: Boolean, default: true },
    showInputShape: { type: Boolean, default: false },
    value: { type: Object, default: () => { } },
    title: { type: String, default: null },
    titleTooltipObject: { type: Object, default: () => { } },
    functionTooltipObject: { type: Object, default: () => { } },
    inputShapeTooltipObject: { type: Object, default: () => { } },
    placeholder: { type: String, default: 'fpui-dropzone.dropfilehere' },
    action: { type: String, default: 'fpui-dropzone.clickhere' },
    textTail: { type: String, default: '' },
    doAction: { type: Function, default: (e) => { } }, // File window is always opened unless e.stopPropagation() is called in doAction
    pathName: { type: String, default: '/' },
    showEditor: { type: Boolean, default: true },
    inputFileTypes: { type: Array, default: () => [] },
    link: { type: String, default: '' },
    getFileContentFunction: { type: Function, default: () => { } },
    accept: { type: Array, default: () => null } // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept
  },
  data () {
    return {
      error: null,
      content: null,
      loading: false,
      fileType: '',
      refresh: true
    }
  },
  computed: {
    getFunctionTooltipLeft () {
      if (this.showInputShape) {
        if (this.$t('fpui-dropzone.function-name') === 'Function name') return '33%'
        return '41%'
      } else {
        if (this.$t('fpui-dropzone.function-name') === 'Function name') return '16%'
        return '21%'
      }
    },
    getFunctionWidth () {
      if (this.showInputShape) return '49%'
      return '100%'
    },
    getInputShapeTooltipLeft () {
      if (this.$t('fpui-dropzone.input-shape') === 'Input shape') return '26%'
      return '34%'
    },
    dropzoneOptions () {
      return {
        onDrop: this.onDrop
      }
    },
    func: {
      get () {
        return this.value?.func
      },
      set (funcValue) {
        let newObj = {}
        if (this.showInputShape) {
          newObj = {
            filename: this.value?.filename,
            func: funcValue,
            input_shape: this.value?.input_shape
          }
        } else {
          newObj = {
            filename: this.value?.filename,
            func: funcValue
          }
        }
        this.$emit('updateParams', newObj)
      }
    },
    inputShape: {
      get () {
        return this.value?.input_shape
      },
      set (val) {
        this.$emit('updateParams', {
          filename: this.value?.filename,
          func: this.value?.func,
          input_shape: val
        })
      }
    },
    fileName () {
      return this.parseFilename(this.value?.filename)
    }
  },
  mounted () {
    if (this.value?.filename) {
      this.fileType = this.value?.filename.split('.').pop()
    }
  },
  methods: {
    openSite (link) {
      window.open(link, '_blank')
    },
    redirect () {
      return window.open(this.link, '_blank')
    },
    removeFile () {
      this.$emit('removeFile', this.value?.filename)
      this.content = null
    },
    async openEditor () {
      this.loading = true
      if (this.value?.filename) {
        this.content = await this.getFileContentFunction(this.value?.filename)
        this.$modal.show(MonacoEditorModal, { value: this.content, title: this.value.filename, language: 'python', readOnly: true }, {
          height: 600,
          width: 900
        })
      }
      this.loading = false
    },
    download () {
      if (this.content) {
        const blob = new Blob([this.content], {
          type: 'text/x-python;charset=utf-8;'
        })
        saveAs(blob, this.parseFilename(this.value.filename) + '.py')
      }
    },
    async onDrop (files) {
      this.error = null
      this.refresh = false
      if (!this.multiple) {
        const file = files[0]
        const nameSplited = file.name.split('.')
        this.fileType = nameSplited.length > 1 ? nameSplited.pop() : ''
        const formatFileValid = this.inputFileTypes.map(f => `.${f}`).join(' or ')
        if (this.inputFileTypes.length > 0 && this.inputFileTypes.indexOf(this.fileType) === -1) {
          this.refresh = true
          return this.$fpuiMessageBlock.error(new Error(this.$t('fpui-dropzone.fileformatnotgood') + `: ${this.fileType} for file ${file.name}. It must be format: ${formatFileValid}`))
        }
        if (file) {
          this.$emit('onDrop', file)
        }
      } else {
        if (files && files.length) {
          for (const file of files) {
            const extension = file.name.split('.').pop().toLowerCase()
            if (this.inputFileTypes.length && !this.inputFileTypes.includes(extension)) {
              this.error = this.$t('fpui-dropzone.not_alowed_extension', [file.name, this.inputFileTypes.join(', ')])
              return
            }
          }
          this.$emit('onDrop', files)
        }
      }
      this.refresh = true
    },
    openFileWindow () {
      this.$refs.input.click()
    },
    parseFilename (filename) {
      if (filename) {
        const regexp = new RegExp(`(${this.pathName})+(.+)`, 'g')
        const matches = [...filename.matchAll(regexp)][0]
        return matches && matches.length > 3 ? matches[2] : filename
      }
      return null
    }
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";

.fp-input-dropzone {
  .error-message {
    color: @red;
    padding: 10px;
    text-align: center;
  }
  .tooltip {
    display: flex;
    flex-grow: 1;
    height: 0;
    .v-popover {
      display: flex;
      align-items: center;
    }
    .trigger {
      display: inline-flex !important;
    }
    i {
      font-size: 16px;
      color: #CBD3DB;
      &:hover {
        color: #00CCF9;
      }
    }
  }

  input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 0;
    opacity: 0;
    cursor: pointer;
    &::-webkit-file-upload-button {
      cursor: pointer;
    }
  }
  .file-preview {
    .title-container {
      display: flex;
      .tooltip {
        margin-left: 5px;
        top: 12px;
      }
    }
    .filename {
      margin: 5px;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      img {
        height: 15px;
        margin-right: 10px;
      }
      .name {
        flex: 1 1 auto;
        font-size: 14px;
      }
      i {
        margin-left: 10px;
        font-size: 18px;
        cursor: pointer;
        color: #CBD3DB;
        &:hover {
          color: @red;
        }
      }
      img, span, i {
        display: inline-block;
        vertical-align: middle;
      }
      .fp-loading {
        height: 20px;
        width: 20px;
        margin-left: 6px;
        margin-right: 0;
      }
    }
    .function-shape-container {
      display: flex;
      justify-content: space-between;
      .function-name {
        .tooltip {
          top: -51px;
          margin-left: 3px;
          z-index: 2;
        }
      }
      .input-shape {
        width: 49%;
        .tooltip {
          top: -51px;
          margin-left: 3px;
          z-index: 2;
        }
      }
    }
  }
  .title-filepreview {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
    align-items: center;
  }
  .no-file-uploaded {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100px;
    .title-container {
      display: flex;
      .tooltip {
        margin-left: 5px;
        top: 12px;
      }
    }
    .dropzone-box {
      position: relative;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      border: 1px dashed #cbd3db;
      border-radius: 5px;
      font-size: 12px;
      cursor: pointer;
      flex: 1;
      color: #97a7b7;
      &.disabled {
        pointer-events: none;
        cursor: not-allowed !important;
      }
      &.dragover {
        color: #000;
        border-color: #000;
      }
      .space {
        margin-left: 4px;
      }
      .do-action {
        margin-left: 4px;
        text-decoration: underline;
        cursor: pointer;
        &:hover  {
          opacity: 0.75
        }
      }
      .text-tail {
        margin-left: 4px;
      }
    }
    .link-container {
      display: flex;
      justify-content: center;
      color: #97a7b7;
      font-size: 12px;
      padding-top: 5px;
      .space {
        margin-right: 4px;
      }
      .link {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .drag-logo {
      position: absolute;
      height: 100px;
      top: -23px;
      right: 0;
      z-index: 1;
    }
  }
}

.tooltip-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  .tooltip-title {
    word-wrap: break-word;
    color: #748294;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    margin-bottom: 15px;
    word-break: break-all;
  }
  .rectangle {
    width: 66px;
    height: 2px;
    transform: scaleY(-1);
    border-radius: 1px;
    background-color: #00CCF9;
    margin-bottom: 15px;
  }
  .tooltip-link {
    font-size: 16px;
    font-weight: 600;
    color: #00CCF9;
    cursor: pointer;
  }
}
</style>
