<template lang="pug">
.dashboarding-name-container(
  :class="{ 'not-editing': !editing }"
)
  .dashboarding-name.input
    span.input-mirror {{ name }}
    input(
      ref="input"
      type="text"
      :disabled="disableEdit"
      v-model="name"
      :class="{ 'error-name':!!error, 'not-editing': !editing }"
      @blur="setBlur()"
      @focus="setFocus()"
    )
    .error-text(v-if="error && !isFocus") {{ $t('dashboard.name_not_empty') }}

  .dashboarding-story.input
    span.input-mirror {{ storyText }}
    input(
      ref="input"
      type="textarea"
      :disabled="disableEdit"
      :value="storyText"
      :class="{ 'not-editing': !editing }"
      :placeholder="editing ? $t('dashboard.story.placeholder') : ''"
      @input="updateStoryText($event)"
      @blur="setBlur()"
      @focus="setFocus()"
    )
</template>

<script>
import Config from '@/shared/Config'
import _debounce from 'lodash/debounce'

export default {
  props: {
    editing: { type: Boolean, default: false },
    item: { type: Object, default: () => ({}) },
    story: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      isFocus: false,
      config: null
    }
  },
  computed: {
    name: {
      get () {
        return this.item?.display_name
      },
      set (val) {
        this.item.update('display_name', val)
      }
    },
    storyText () {
      return this.item?.description
    },
    disableEdit () {
      // TODO acls
      return !this.editing
    },
    error () {
      return !this.name
    }
  },
  async mounted () {
    this.config = await Config()
    this.name = this.item?.display_name
  },
  methods: {
    updateStoryText (event) {
      this.item.update('description', event?.target?.value)
      this.updateDebounced()
    },
    updateDebounced: _debounce(function () {
      this.$analytics.track('Edit chart or dashboard story', {
        organization_id: this.config.ORGANIZATION_ID,
        shared_id: this.item.shared_id,
        type: 'dashboard'
      })
    }, 500),
    setFocus () {
      this.isFocus = true
    },
    setBlur () {
      this.isFocus = false
    }
  }
}
</script>

<style lang="less">
  .dashboarding-name-container {
    max-width: 600px;
    min-height: 34px;
    padding: 0 7px;
    border-radius: 5px;
    position: relative;
    font-weight: 600;

    .dashboarding-name {
      position: relative;
      min-width: 5em;
      padding-right: 5px;
      margin-right: 30px;
      margin-bottom: 4px;
      text-overflow: ellipsis;
      white-space: nowrap;

      > .input-mirror {
        visibility: hidden;
        white-space: pre;
        margin-right: 2px;
      }
      > input {
        position: absolute;
        left: 0;
        width: 100%;
        border-color: transparent;
        background: transparent;
        color: #3E4550;
        &:disabled {
          cursor: not-allowed;
        }
        &.error-name {
          color: @red;
        }
        &.blur-error {
          &:not(:focus) {
            color: @red;
            border-color: @red;
          }
        }
        &.not-editing {
          cursor: default;
        }
      }
      > input, > .input-mirror {
        font-family: 'Source Sans Pro', sans-serif;
        padding: 0;
        font-size: 20px;
        line-height: 25px;
        font-weight: 600;
        color: @text-color;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        border: none;
      }
      .error-text {
        font-size: 11px;
        color: #FF6F90;
        line-height: 15px;
        margin-top: -27px;
        display: block;
      }
    }

    .dashboarding-story {
      position: relative;
      min-width: 5em;
      padding-right: 5px;
      margin-right: 30px;
      text-overflow: ellipsis;
      white-space: nowrap;

      > .input-mirror {
        visibility: hidden;
        white-space: pre;
        margin-right: 2px;
      }
      > input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-color: transparent;
        background: transparent;
        color: #3E4550;
        &:disabled {
          cursor: not-allowed;
        }
        &.blur-error {
          &:not(:focus) {
            color: @red;
            border-color: @red;
          }
        }
        &.not-editing {
          cursor: default;
        }
      }
      > input, > .input-mirror {
        font-family: 'Source Sans Pro', sans-serif;
        padding: 0;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #748294;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        border: none;
      }
    }

    &:hover:not(.not-editing) {
      background: #E9ECF0;
    }
  }
</style>
