import Date from '@/shared/filters/Date'
import { API, APIObject } from '@/shared/plugins/Api/API'

class LogicalReferential extends APIObject {
  constructor (options) {
    // Init
    super('DM', options)

    // Default variables
    this.name = this.name || ''
    this.display_name = this.display_name || this.name || ''
    this.tags = this.tags || {}
    this.description = this.description || null
    this.uid = this.uid || ''
    this.created_by = this.created_by || ''
    this.updated_by = this.updated_by || ''
    this.key = this.key || null
    this.label = this.label || ''
    this.table_id = this.table_id || ''
  }

  assign (object) {
    super.assign(object)
    this.created_at = Date(this.created_at) || null
    this.updated_at = Date(this.updated_at) || null
  }
}

class LogicalReferentials extends API {
  new (opts) {
    return new LogicalReferential(opts)
  }

  list () {
    return this.request({
      method: 'get',
      url: 'v4/logical/referentials'
    })
      .then(referentials => {
        return referentials.map(referential => {
          try {
            return new LogicalReferential(referential)
          } catch (err) {
            console.error(err.stack)
            return null
          }
        })
      })
  }
}

export default LogicalReferentials
export {
  LogicalReferential,
  LogicalReferentials
}
