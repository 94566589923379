<template lang='pug'>
  .fpui-input-radios(:class="theme")
    fpui-input-label(
      :helper="helper"
      v-if="label"
    ) {{ label }}
    .fpui-input-radio(
      :class='{ disabled: opt.disabled || disabled }'
      v-for="(opt, index) in optionsValues"
      v-tooltip='opt.tooltip'
    )
      input.checkbox(
        :disabled='opt.disabled || disabled'
        :id="opt.id"
        type="radio"
        :value="opt.value"
        @input="update"
        v-model="model"
        :class='{ active: isActive(index) }'
      )
      label(:for="opt.id")
        i(v-if="opt.icon" :class="opt.icon")
        span
          span.label-value(v-if="!opt.alpha" v-html="$sanitize($t(opt.label))")
          span.label-value(v-if="opt.alpha") {{ $t(opt.label) }}
            span.alpha ALPHA
          span.label-description(v-if="opt.description") {{opt.description | translate}}
</template>

<script>

export default {
  props: {
    label: { type: String, default: '' },
    disabled: { type: Boolean },
    helper: { type: String, default: null },
    value: { type: [String, Number, Boolean], required: true },
    options: {
      required: true,
      type: Array,
      default () {
        return []
      }
    },
    theme: { type: String, default: 'v4' }
  },
  data () {
    return {
      model: null,
      optionsValues: []
    }
  },
  watch: {
    value () {
      process.nextTick(() => {
        this.model = this.value
      })
    }
  },
  created () {
    this.optionsValues = this.options.map((opt) => {
      opt.id = '_' + Math.random().toString(36).substr(2, 9) + opt.value
      return opt
    })
  },
  mounted () {
    this.model = this.value
  },
  methods: {
    update (evt) {
      const prev = this.model
      const elem = this.options.find(v => v.value + '' === evt.target.value)
      this.$emit('input', elem.value)
      process.nextTick(() => {
        this.model = prev
      })
    },
    isActive (idx) {
      const index = this.options.findIndex(v => v.value === this.value)
      return index === idx
    }
  }
}
</script>

<style lang='less'>
@import "~@/shared/styles/_variables.less";

@fpui-input-radio-size: 12px;
@fpui-input-radio-checked-color: @blue;
@fpui-input-radio-checked-border-color: @blue;
@fpui-input-radio-checked-size: 8px;
@fpui-input-radio-border-color: @grey_dark;
@fpui-input-radio-border-color-disabled: @grey;

.fpui-input-radios.v4 {
  margin: 16px 0;
  .fpui-input-label {
    display: inline-block;
    margin-right: 20px;
    min-width : 200px;
  }
  .fpui-input-radio {
    display: inline-block;
    &.disabled {
      label {
        color: @grey;
        cursor: not-allowed;
        &:before {
          border: 1px solid @fpui-input-radio-border-color-disabled;
        }
      }
    }
  }
  .label-value {
    vertical-align: top;
    line-height: 20px !important;
  }
  .alpha {
    color: white !important;
    vertical-align: top;
    display: inline-block !important;
    padding: 0 5px;
    border-radius: 15px;
    margin-left: 10px;
    font-size: 10px !important;
    background: @yellow;
  }
  input[type="radio"] {
    display: none;
    &:checked + label:before {
      border-color: @fpui-input-radio-checked-border-color;
      animation: ripple 0.2s linear forwards;
    }
    &:checked + label:after {
      transform: scale(1);
    }
  }
  label {
    display: flex;
    align-items: center;
    height:@fpui-input-radio-size;
    line-height: @fpui-input-radio-size;
    position: relative;
    font-size: 14px;
    line-height: 17px;
    padding: 0 12px 0 16px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    &:before, &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all .3s ease;
      transition-property: transform, border-color;
    }
    &:before {
      left: 0;
      top: 0;
      width: @fpui-input-radio-size;
      height: @fpui-input-radio-size;
      border: 1px solid #0089C0;
    }
    &:after {
    //     top: @fpui-input-radio-size / 1 - @fpui-input-radio-checked-size / 1;
    //     left: @fpui-input-radio-size / 1 - @fpui-input-radio-checked-size / 1;
      top: 2px;
      left: 2px;
      width:@fpui-input-radio-checked-size;
      height:@fpui-input-radio-checked-size;
      transform: scale(0);
      background: #00CCF9;
      // border: 1px solid @fpui-input-radio-checked-border-color;
    }
  }
}
</style>
