<template lang="pug">
.fp-dashboarding-layout
  fp-loader(
    v-if="loading"
  )
  dashboarding-empty(
    v-if="!layout || !layout.tiles.length"
    :item="item"
    :editing="editing"
    :full-screen="fullScreen"
    @add-chart="addChart()"
  )
  .layout-container#layout-container(
    v-if="layout && layout.tiles.length"
  )
    dashboarding-header(
      :item="item"
      :editing="editing"
      :from-dashboard="fromDashboard"
      :full-screen="fullScreen"
      :show-filters="showFilters"
      @toggle-filters="toggleFilters"
    )

    dashboarding-filters(
      v-show="showFilters"
      :item="item"
      :editing="editing"
      :layout="layout"
      :from-dashboard="fromDashboard"
    )

    .grid-layout-container
      grid-layout(
        ref="grid-layout"
        :responsive="responsive"
        :col-num="colNum"
        :layout="layout"
        :is-draggable="true"
        :is-resizable="true"
        :editing="editing"
        :horizontal-compact="true"
        :use-css-transforms="true"
        :min-w="minW"
        :min-h="minH"
        :margin="margin"
        is-bounded
        @layout-updated="layoutUpdated"
      )
        grid-item(
          v-for="tile in layoutTiles"
          :key="tile.i"
          :x="tile.x"
          :y="tile.y"
          :w="tile.width"
          :h="tile.height"
          :i="tile.i"
          :min-h="minH"
          :min-w="minW"
          :max-w="colNum"
          :static="tile.static"
          :is-resizable="!['add-row', 'add-column'].includes(tile.type)"
          :is-draggable="!tile.edit"
          :edit="tile.edit"
          :vertical-resize="tile.type === 'vertical-resize'"
          :style-override="tile.styleOverride"
          :style="{ 'z-index': zIndex(tile) }"
          :editing="editing"
          @mouseover.native="tileHovered(tile)"
          @mouseleave.native="tileNotHovered(tile)"
        )
          component(
            v-if="!tile.edit"
            :tile="tile"
            :dashboard="item"
            :is="tile.component || component"
            :from-dashboard="fromDashboard"
            :editing="editing"
            @tile-updated="tileUpdated"
            @replace-query="replaceQuery"
            @duplicate-tile="duplicateTile"
            @delete-tile="deleteTile"
            @active-tile="activeTile"
            @deactive-tile="deactiveTile"
          )
          add-tile(
            v-if="tile.edit && ['add-row', 'add-column'].includes(tile.type)"
            :disabled="addTileDisabled(tile.y)"
            @add-chart="addChart(tile)"
          )
          vertical-resize-bar(
            v-if="tile.edit && ['vertical-resize'].includes(tile.type) && verticalBarDisplayed.includes(tile.y)"
            @add-chart="addChart(tile)"
          )
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
// import _isEqual from 'lodash/isEqual'

import Config from '@/shared/Config'
import { GridLayout, GridItem } from './FpGridLayout' // https://jbaysolutions.github.io/vue-grid-layout/guide/
import { Tile, AddTile, VerticalResizeBar } from './Tiles'
import AddTileModal from './Modals/AddTileModal.vue'
import Layout from './FpGridLayout/helpers/layout'
import DashboardingEmpty from './DashboardingEmpty'
import DashboardingHeader from './DashboardingHeader.vue'
import DashboardingFilters from './Filters'


export default {
  components: {
    GridLayout,
    GridItem,
    Tile,
    AddTile,
    VerticalResizeBar,
    DashboardingEmpty,
    DashboardingHeader,
    DashboardingFilters
  },
  props: {
    item: { type: Object, required: true },
    editing: { type: Boolean, default: false },
    component: { type: String, default: 'default-tile' },
    responsive: { type: Boolean, default: false },
    colNum: { type: Number, default: 12 },
    minW: { type: Number, required: false, default: 1 },
    minH: { type: Number, required: false, default: 230 },
    margin: { type: Array, default: () => [10, 10] },
    preventCollision: { type: Boolean, default: false },
    fromDashboard: { type: Boolean, default: false },
    fullScreen: { type: Boolean, default: false }
  },
  data () {
    return {
      verticalBarDisplayed: [],
      layout: null,
      currentFilter: {},
      showFilters: true,
      loading: false,
      config: null,
      activeTiles: [],
      hoveredTiles: []
    }
  },
  computed: {
    tiles () {
      return this.item.tiles || []
    },
    layoutTiles () {
      if (!this.editing) return this.layout.cleanLayout
      return this.layout.tiles
    }
  },
  watch: {
    item: {
      deep: true,
      handler (value, old) {
        // if (value._id === old._id && !_isEqual(value.tiles, this.layout.originalTiles.filter(t => !t.edit))) {
        //   // TODO Check to not go there if your own changes
        //   this.layout.updateTiles(value.tiles)
        // }
        if (value._id !== old._id) {
          this.initLayout()
        }
      }
    },
    '$store.getters.QB_ADD_TILE_IN_DASHBOARD_ID': {
      handler (value, oldValue) {
        if (value !== oldValue) this.addChart({ y: this.layout.bottom })
      }
    }
  },
  async mounted () {
    if (this.editing) this.config = await Config()

    // Init Layout
    this.initLayout()
  },
  methods: {
    initLayout () {
      // Hide filters if not set when deployed
      if (!Object.keys(this.item.filter).length && !this.editing) this.showFilters = false

      const layout = {
        tiles: _cloneDeep(this.item.tiles),
        colNum: this.colNum,
        minWidth: this.minW,
        margin: this.margin,
        preventCollision: this.preventCollision
      }

      this.layout = new Layout(layout)
    },
    layoutUpdated () {
      const cleanLayout = this.layout.cleanLayout
      this.$emit('layout-updated', cleanLayout)
    },
    async addChart (tile) {
      // Check if can add Chart in row
      const tilesInRow = this.layout.getRowTiles(tile?.y)
      if (tilesInRow.length >= this.layout.maxTilesInRow && tile?.type !== 'vertical-resize') return

      await this.getNewQueryInfo(tile, true)
    },
    addTileDisabled (y) {
      return this.layout.getRowTiles(y).length >= this.layout.maxTilesInRow
    },
    tileHovered (tile) {
      if (!this.hoveredTiles.includes(tile.i) && !tile.edit) this.hoveredTiles.push(tile.i)
      if (!this.verticalBarDisplayed.includes(tile.y)) this.verticalBarDisplayed.push(tile.y)
    },
    tileNotHovered (tile) {
      if (this.hoveredTiles.includes(tile.i)) {
        this.hoveredTiles = this.hoveredTiles.filter(i => i !== tile.i)
      }
      if (this.verticalBarDisplayed.includes(tile.y)) {
        this.verticalBarDisplayed = this.verticalBarDisplayed.filter(y => y !== tile.y)
      }
    },
    tileUpdated (e) {
      this.layout.updateTile(e.tileId, e.key, e.value)
      this.layoutUpdated()
    },
    zIndex (tile) {
      if (tile.edit && tile.type !== 'vertical-resize') return 1
      if (tile.resizing || tile.moving || this.activeTiles.includes(tile.i)) return 999
      if (tile.resizing || tile.moving || this.hoveredTiles.includes(tile.i)) return 990

      return this.tiles.length - tile.y + 1
    },
    duplicateTile (tile) {
      this.layout.duplicateTile(tile)
      this.layoutUpdated()
    },
    deleteTile (tile) {
      this.layout.deleteTile(tile)
      this.layoutUpdated()

      this.$analytics.track('Delete dashboard tile', {
        organization_id: this.config.ORGANIZATION_ID,
        shared_id: this.item.shared_id
      })
    },
    async replaceQuery (tile) {
      await this.getNewQueryInfo(tile)
    },
    async getNewQueryInfo (tile, addTile = null) {
      return new Promise(resolve => {
        this.$modal.show(AddTileModal, {
          layout: this.layout,
          item: this.item,
          tile,
          noChange: () => {
            return resolve(false)
          },
          handleChange: async ({ query, newQuery }) => {
            let newTile = tile
            if (addTile) { // Add tile
              let origin = 'main button'
              if (tile?.type === 'add-row') origin = 'bottom-row button'
              if (tile?.type === 'add-column') origin = 'end-of-row button'
              if (tile?.type === 'vertical-resize') origin = 'new-row button'

              this.$analytics.track('Add dashboard tile', {
                organization_id: this.config.ORGANIZATION_ID,
                shared_id: this.item.shared_id,
                existing_chart: !newQuery,
                origin
              })

              newTile = tile || {
                x: 0,
                y: 0,
                width: this.colNum,
                height: 1
              }
              newTile.query = query.shared_id
              newTile.title = query.display_name

              newTile = this.layout.addTile(newTile, tile?.type)
            } else { // Replace query
              this.layout.updateTile(tile.i, 'title', query.display_name)
              this.layout.updateTile(tile.i, 'query', query.shared_id)
            }

            if (!newQuery) {
              this.layoutUpdated()
              this.$modal.hideAll()
            }

            if (newQuery) {
              this.loading = true
              this.$analytics.track('Create new chart from dashboard', {
                organization_id: this.config.ORGANIZATION_ID,
                shared_id: this.item?.shared_id
              })
              await this.createAndRedirect(newTile, query)
            }
          }
        }, {
          height: 'auto',
          width: 769
        })
      })
    },
    async createAndRedirect (tile, query) {
      try {
        let item = this.item
        if (!this.item?._id) { // Need to create dashboard before change route
          item = await this.item.create()
          this.$store.commit('FOREPAAS_QB_DASHBOARD_CREATE', item)
          item = this.$store.getters.QB_DASHBOARD_BY_ID(item._id) // Get item from store for reactivity
        }

        const cleanLayout = this.layout.cleanLayout
        item.update('tiles', _cloneDeep(cleanLayout), false)
        await item.save() // Manually save to change route after save is done

        // Redirect to query to edit the new query
        this.$router.push(`/${this.$route.params.dataplantId}/query/${query._id}?dashboardId=${item._id}&createTile=${tile.i}`)
      } catch (err) {
        console.error(err)
        this.$fpuiMessageBlock.error(err)
      }
    },
    toggleFilters () {
      this.showFilters = !this.showFilters
    },
    activeTile (t) {
      if (!this.activeTiles.includes(t.i) && !t.edit) this.activeTiles.push(t.i)
    },
    deactiveTile (t) {
      if (this.activeTiles.includes(t.i)) {
        this.activeTiles = this.activeTiles.filter(tile => tile !== t.i)
      }
    }
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";

.fp-dashboarding-layout {
  height: 100%;
  width: 100%;

  .layout-container {
    height: 100%;
    padding: 0 0 300px;
    overflow-y: auto;
    overflow-x: hidden;

    .grid-layout-container {
      padding: 0 40px;

      .vue-grid-layout {
        .vue-grid-item {
          &.vue-grid-placeholder {
            background: #85E9FF !important;
            border-radius: 5px;
          }
          &:not(.vue-grid-placeholder) {
            // background: #ccc;
            border: 1px solid transparent;
          }
          .resizing {
            opacity: 0.9;
          }
          .static {
            background: #cce;
          }
          .no-drag {
            height: 100%;
            width: 100%;
          }
          .minMax {
            // font-size: 12px;
          }
          .add {
            cursor: pointer;
          }
        }
        .vue-draggable-handle {
          position: absolute;
          width: 20px;
          height: 20px;
          top: 0;
          left: 0;
          background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
          background-position: bottom right;
          padding: 0 8px 8px 0;
          background-repeat: no-repeat;
          background-origin: content-box;
          box-sizing: border-box;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
