import { API, APIObject } from '@/shared/plugins/Api/API'
import _set from 'lodash/set'

class Configuration extends APIObject {
  constructor (options) {
    // Init
    super('STORAGE', options, true, true)

    this.organization_id = this.organization_id || ''
    this.configuration = this.configuration || {}
  }

  async create () {
    return super.create({
      method: 'POST',
      url: 'configurations'
    })
  }

  async save (organizationId = null) {
    return super.save({
      method: 'POST',
      url: 'configurations',
      data: {
        organization_id: organizationId || this.organization_id,
        configuration: this.configuration
      }
    })
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `configurations/${this.id}`
    })
  }
}

class Configurations extends API {
  constructor (ID) {
    super(ID, true, true)
  }

  // Get default configuration
  async getDefault () {
    const { configuration } = await this.request({
      method: 'get',
      url: '/configuration'
    })

    return configuration
  }

  // Get default configuration as object
  async getDefaultConfigurationObject () {
    const data = await this.request({
      method: 'get',
      url: '/configuration'
    })
    return new Configuration(data)
  }

  // Get instance from organization ID and if not get default one and create it in the orga
  async getByOrganization (organizationId) {
    const data = await this.request({
      method: 'get',
      url: `/configurations/${organizationId}`
    })

    return new Configuration(data)
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Configuration(item)
  }
}

export default Configurations
export {
  Configuration,
  Configurations
}
