<template lang="pug">
.fp-dashboarding-layout-lite

  //- slot empty TODO
  slot.dashboarding-header(
    v-if="$scopedSlots.header"
    name="header"
  )

  .layout-container#layout-container(
    v-if="layout && layout.tiles.length"
  )
    .grid-layout-container
      grid-layout(
        ref="grid-layout"
        :responsive="responsive"
        :col-num="colNum"
        :layout="layout"
        :is-draggable="true"
        :is-resizable="true"
        :editing="editing"
        :horizontal-compact="true"
        :use-css-transforms="true"
        :min-w="minW"
        :min-h="minH"
        :margin="margin"
        is-bounded
        lite
        @layout-updated="layoutUpdated"
      )
        grid-item(
          v-for="tile in layout.tiles"
          :key="tile.i"
          :x="tile.x"
          :y="tile.y"
          :w="tile.width"
          :h="tile.height"
          :i="tile.i"
          :min-h="minH"
          :min-w="minW"
          :max-w="colNum"
          :static="tile.static"
          :is-resizable="true"
          :is-draggable="true"
          :style-override="tile.styleOverride"
          :style="{ 'z-index': zIndex(tile) }"
          :editing="editing"
          lite
          @mouseover.native="tileHovered(tile)"
          @mouseleave.native="tileNotHovered(tile)"
        )
          slot(
            v-if="$scopedSlots.tile"
            name="tile"
            :tile="tile"
          )

          component(
            v-else
            :tile="tile"
            :dashboard="item"
            :is="tile.component || component"
            :editing="editing"
            @tile-updated="tileUpdated"
            @duplicate-tile="duplicateTile"
            @delete-tile="deleteTile"
          )
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'

import Config from '@/shared/Config'
import { GridLayout, GridItem } from './FpGridLayout' // https://jbaysolutions.github.io/vue-grid-layout/guide/
import Layout from './FpGridLayout/helpers/layout'


export default {
  components: {
    GridLayout,
    GridItem
  },
  props: {
    item: { type: Object, required: true },
    editing: { type: Boolean, default: false },
    component: { type: String, default: 'default-tile' },
    responsive: { type: Boolean, default: false },
    colNum: { type: Number, default: 12 },
    minW: { type: Number, required: false, default: 1 },
    minH: { type: Number, required: false, default: 230 },
    margin: { type: Array, default: () => [10, 10] },
    preventCollision: { type: Boolean, default: false },
    activeTiles: { type: Array, default: () => [] }
  },
  data () {
    return {
      layout: null,
      config: null,
      hoveredTiles: []
    }
  },
  computed: {
    tiles () {
      return this.item.tiles || []
    }
  },
  watch: {
    item: {
      deep: true,
      handler (value, old) {
        if (value._id !== old._id) {
          this.initLayout()
        }
      }
    }
  },
  async mounted () {
    if (this.editing) this.config = await Config()

    // Init Layout
    this.initLayout()
  },
  methods: {
    initLayout () {
      const layout = {
        tiles: _cloneDeep(this.item.tiles),
        colNum: this.colNum,
        minWidth: this.minW,
        margin: this.margin,
        preventCollision: this.preventCollision,
        lite: true
      }

      this.layout = new Layout(layout)
    },
    layoutUpdated () {
      this.$emit('layout-updated', this.layout?.tiles)
    },
    tileUpdated (e) {
      this.layout.updateTile(e.tileId, e.key, e.value)
      this.layoutUpdated()
    },
    tileHovered (tile) {
      if (!this.hoveredTiles.includes(tile.i) && !tile.edit) this.hoveredTiles.push(tile.i)
    },
    tileNotHovered (tile) {
      if (this.hoveredTiles.includes(tile.i)) {
        this.hoveredTiles = this.hoveredTiles.filter(i => i !== tile.i)
      }
    },
    zIndex (tile) {
      // Widget z-index change when hover or active when options
      if (tile.resizing || tile.moving || this.activeTiles.includes(tile.i)) return 999
      if (tile.resizing || tile.moving || this.hoveredTiles.includes(tile.i)) return 990

      return this.tiles.length - tile.y + 1
    },
    duplicateTile (tile) {
      this.layout.duplicateTile(tile)
      this.layoutUpdated()
    },
    deleteTile (tile) {
      this.layout.deleteTile(tile)
      this.layoutUpdated()
    },
    addTile (tile, type) {
      this.layout.addTile(tile, type)
      this.layoutUpdated()
    }
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";

.fp-dashboarding-layout-lite {
  height: 100%;
  width: 100%;

  .layout-container {
    padding: 0 0;
    .grid-layout-container {
      padding: 0 0;
      .vue-grid-layout {
        .vue-grid-item {
          &.vue-grid-placeholder {
            background: #85E9FF !important;
            border-radius: 5px !important;
          }
          &:not(.vue-grid-placeholder) {
            border: 1px solid transparent;
          }
          .resizing {
            opacity: 0.9;
          }
          .static {
            background: #cce;
          }
          .no-drag {
            height: 100%;
            width: 100%;
          }
          .minMax {
            // font-size: 12px;
          }
          .add {
            cursor: pointer;
          }
        }
        .vue-draggable-handle {
          position: absolute;
          width: 20px;
          height: 20px;
          top: 0;
          left: 0;
          background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
          background-position: bottom right;
          padding: 0 8px 8px 0;
          background-repeat: no-repeat;
          background-origin: content-box;
          box-sizing: border-box;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
