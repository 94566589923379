<template lang="pug">
.subscription-new-step-billing-contact
  .header(v-if="!condensed")
    h4 {{ $t('subscription.new.billing') }}
    p {{ $t('subscription.new.billing.details') }}

  .fp-card(v-if="account")
    .fp-card-title
      span {{ $t('subscription.new.billing_condensed') }}
    .fp-card-content
      .fp-column
        .box-line
          fpui-input-text(
            :label="$t('accounts.company')",
            :value="account.billing_address.company"
            @input="v=>account.update('billing_address.company',v,false)"
          )
        .box-line
          fpui-input-text(
            :label="$t('accounts.billing.info.vat_number')",
            :value="account.vat_number"
            @input="v=>account.update('vat_number',v,false)"
          )
        .box-line
          fpui-input-text(
            :label="$t('accounts.firstname')",
            :value="account.billing_address.first_name"
            @input="v=>account.update('billing_address.first_name',v,false)"
          )
          fpui-input-text(
            :label="$t('accounts.lastname')",
            :value="account.billing_address.last_name"
            @input="v=>account.update('billing_address.last_name',v,false)"
          )
        .box-line
          fpui-input-text(
            :label="$t('accounts.email')",
            :value="account.billing_address.email"
            @input="v=>account.update('billing_address.email',v,false)"
          )
        .box-line
          fpui-input-phone(
            :label="$t('accounts.phone')",
            :value="account.billing_address.phone"
            @input="v=>account.update('billing_address.phone',v,false)"
          )
      .fp-column
        .box-line
          fpui-input-text(
            :label="$t('accounts.line1')",
            :value="account.billing_address.line1"
            @input="v=>account.update('billing_address.line1',v,false)"
          )
        .box-line
          fpui-input-text(
            :label="$t('accounts.line2')",
            :value="account.billing_address.line2"
            @input="v=>account.update('billing_address.line2',v,false)"
          )
        .box-line
          fpui-input-text(
            :label="$t('accounts.city')",
            :value="account.billing_address.city"
            @input="v=>account.update('billing_address.city',v,false)"
          )
          fpui-input-text(
            :label="$t('accounts.zip')",
            :value="account.billing_address.zip"
            @input="v=>account.update('billing_address.zip',v,false)"
          )
        .box-line
          fpui-input-country(
            :label="$t('accounts.country')",
            :value="account.billing_address.country"
            @input="v=>account.update('billing_address.country',v,false)"
            :clearable="false"
          )
        .box-line
          fpui-input-state(
            :label="$t('accounts.state')",
            :value="account.billing_address.state"
            @input="v=>account.update('billing_address.state',v,false)"
            :country="account.billing_address.country"
            :clearable="false"
          )
</template>
<script>
export default {
  props: {
    customerAccountId: {
      type: String,
      required: true
    },
    condensed: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    account () {
      return this.$store.getters.ACCOUNT_BY_ID(this.customerAccountId)
    }
  },
  mounted () {
    this.account.backup()
  },
  destroyed () {
    this.account.restore()
  },
  methods: {
    async onDone () {
      if (this.account.hasChanges) {
        await this.account.save()
      }
      this.account.backup()
    }
  }
}
</script>
<style lang="less">
.subscription-new-step-billing-contact {
  .header-condensed {
    text-align: center;
    color: #B0BFCC;
    font-size: 12px;
  }
  .fp-card {
    border: 0.5px solid rgba(151, 167, 183, 0.21);
    width: 600px;
    margin: 32px auto;
    padding: 8px 20px 0 20px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.9);
    .fp-card-title {
      color: #B0BFCC;
      text-align: center;
      padding: 10px;
      font-size: 14px;
    }
    .fp-card-content {
      display: flex;
    }
    .fpui-input-phone {
      padding-right: 0;
    }
    > * + * {
      margin-left: 10px;
    }
    .fp-column {
      max-width: 50%;
      flex: 1;
      .box-line {
        display: flex;
        margin-bottom: 13px;
        > * {
          flex: 1;
        }
        > * + * {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
