<template lang='pug'>
.fpui-input-label
  .fpui-input-label-container
    slot
    span.asterisk(v-if="required") {{ '*' }}
    fpui-helper(
      v-if='helper'
      :text="helper | translate"
      :title="helperTitle ? helperTitle : ''"
    )
  .fpui-input-label-subtitle(v-if="subtitle") {{ subtitle }}
</template>

<script>

export default {
  props: {
    required: { type: Boolean, default: false },
    helper: { type: String, default: '' },
    helperTitle: { type: String, default: '' },
    subtitle: { type: String, default: null }
  }
}
</script>

<style lang='less'>
.fpui-input-label {
  position:relative;
  .fpui-input-label-container {
    padding-left: 0;
    font-size: 12px !important;
    line-height: 15px;
    margin-bottom: 5px;
    color: #748294;
    cursor: pointer;
    display: flex;
    align-items: center;
    .asterisk {
      font-size: 20px;
      margin-left: 1px;
    }
    .fpui-helper {
      margin-left: 5px;
      height: 20px;
    }
  }
  .fpui-input-label-subtitle {
    color: @grey;
    font-weight: 400;
    margin-bottom: 14px;
    margin-top: -5px;
  }
}
</style>
