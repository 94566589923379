
<template lang="pug">
.committeds-editor
  div(v-if="!standalone")
    .committed-body
      table.fp-table
        tbody(ref="bottom-scroll")
          tr(v-if="!committeds.length").placeholder
            td(colspan="7") {{ $t('committeds.placeholder') }}
          tr(
            :key="index"
            v-for="(committed,index) in committeds"
          )
            td.first
              fpui-input-select(
                v-model="committed.resource_type"
                :options="[{value:'fpu-s',label:'DPU-S'}]"
                :clearable="false"
                :disabled="true"
              )
            td
              fpui-input-select.providers(
                v-model="committed.provider"
                :clearable="false"
                :options="providers"
                :placeholder="$t('committeds.provider')"
              )
            td.qty
              fpui-input-qty(v-model="committed.quantity")
            td
              fpui-input-select.providers(
                v-model="committed.term_commitment"
                :options="termCommitments"
                :clearable="false"
                :placeholder="$t('committeds.term_commitment')"
              )
            td
              fpui-input-select.providers(
                v-model="committed.billing_period_unit"
                :options="billingPeriodUnits"
                :clearable="false"
                :placeholder="$t('committeds.term_commitment')"
              )
            td(v-html="$sanitize(commitedPriceRender(committed))")
            td
              i.fp4.fp4-trash-can(@click="remove(index)")
        thead
          th.first(width="15%") {{ 'committeds.resource_type' | translate }}
          th(width="23%") {{ 'committeds.provider' | translate }}
          th(width="15%") {{ 'committeds.amount' | translate }}
          th(width="14%") {{ 'committeds.term_commitment' | translate }}
          th(width="14%") {{ 'committeds.billing_period_unit' | translate }}
          th(width="14%") {{ 'committeds.effective_rate_by_unit' | translate }}
          th(width="5%")
      fpui-button.add(
        color="blue-flash"
        auto-width
        icon="fp4 fp4-plus"
        @click="add()"
      ) {{ 'committeds.new' | translate }}

    .committed-footer
      div(v-if="committeds.length")
        .title {{ 'committeds.calculated_cost' | translate }}
        .price(v-html="$sanitize(commitedsPriceRender())")
  fpui-steps(:cancel="cancel",@complete="$emit('complete')",v-else)
    fpui-step
      .committed-body
        table.fp-table
          tbody(ref="bottom-scroll")
            tr(
              :key="index"
              v-for="(committed,index) in committeds"
            )
              td.first
                fpui-input-select(
                  v-model="committed.resource_type"
                  :options="[{value:'fpu-s',label:'DPU-S'}]"
                  :clearable="false"
                  :disabled="true"
                )
              td
                fpui-input-select.providers(
                  v-model="committed.provider"
                  :clearable="false"
                  :options="providers"
                  :placeholder="$t('committeds.provider')"
                )
              td.qty
                fpui-input-qty(v-model="committed.quantity")
              td
                fpui-input-select.providers(
                  v-model="committed.term_commitment"
                  :options="termCommitments"
                  :clearable="false"
                  :placeholder="$t('committeds.term_commitment')"
                )
              td
                fpui-input-select.providers(
                  v-model="committed.billing_period_unit"
                  :options="billingPeriodUnits"
                  :clearable="false"
                  :placeholder="$t('committeds.term_commitment')"
                )
              td(v-html="$sanitize(commitedPriceRender(committed))")
              td
                i.fp4.fp4-trash-can(v-if="committeds.length>1",@click="remove(index)")
          thead
            th.first(width="15%") {{ 'committeds.resource_type' | translate }}
            th(width="23%") {{ 'committeds.provider' | translate }}
            th(width="15%") {{ 'committeds.amount' | translate }}
            th(width="14%") {{ 'committeds.term_commitment' | translate }}
            th(width="14%") {{ 'committeds.billing_period_unit' | translate }}
            th(width="14%") {{ 'committeds.effective_rate_by_unit' | translate }}
            th(width="5%")
        fpui-button.add(
          color="blue-flash"
          noshadow
          reverse
          auto-width
          icon-left
          icon="fp4 fp4-plus"
          @click="add()"
        ) {{ 'committeds.new' | translate }}

      .committed-footer
        div(v-if="committeds.length")
          .title {{ 'committeds.calculated_cost' | translate }}
          .price(v-html="$sanitize(commitedsPriceRender())")


    fpui-step(:disabled="!committeds.length")
      .committed-body.order
        .line(v-for="(committed,index) in committeds")
          hr.separator(v-if="index!=0")
          .sline.ressource-type
            .name {{ 'committeds.resource_type' | translate }}
            .value {{ committed.resource_type.toUpperCase() }}
          .sline.provider
            .name {{ 'committeds.provider' | translate }}
            .value {{ committed.provider | Capitalize }}
          .sline.term_commitment
            .name {{ 'committeds.term_commitment' | translate }}
            .value {{ 'committeds.term_unit.'+committed.term_commitment | translate }}
          .sline.price
            .name {{ 'committeds.price' | translate }}
            .value(v-html="$sanitize(committedPrice(committed))")
      .committed-footer
        .title {{ 'committeds.calculated_cost' | translate }}
        .price(v-html="$sanitize(commitedsPriceRender())")

</template>
<script>
import Price from '@/shared/filters/Price'
export default {
  props: {
    standalone: {
      type: Boolean,
      default: true
    },
    cancel: {
      type: Function,
      required: false,
      default: null
    },
    value: {
      type: Array,
      required: true
    }
  },
  computed: {
    committeds: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    currencyCode () {
      return this.$store.getters.SESSION.preferences?.currency || 'EUR'
    },
    billingPeriodUnits () {
      return [{
        value: 'monthly',
        label: `${this.$t('committeds.monthly')}`
      }, {
        value: 'yearly',
        label: `${this.$t('committeds.yearly')}`
      }]
    },
    termCommitments () {
      return [{
        value: '1y',
        label: `1 ${this.$t('committeds.year')}`
      }, {
        value: '2y',
        label: `2 ${this.$t('committeds.year')}`
      }, {
        value: '3y',
        label: `3 ${this.$t('committeds.year')}`
      }]
    },
    providers () {
      return [{
        value: 'gcp',
        image: require('@/core/assets/img/providers/gcp.png'),
        label: 'Google Cloud Platform'
      }, {
        value: 'azure',
        image: require('@/core/assets/img/providers/azure.png'),
        label: 'Azure'
      }, {
        value: 'aws',
        image: require('@/core/assets/img/providers/aws.png'),
        label: 'Amazon Web Services'
      }, {
        value: 'ovh',
        image: require('@/core/assets/img/providers/ovh.png'),
        label: 'OVH'
      }]
    }
  },
  methods: {
    getCouponDiscount (termCommitment = '1y') {
      let coupon
      switch (termCommitment) {
        case '2y':
          coupon = this.$store.getters.COUPON_BY_ID('committed-2years-discount')
          break
        case '3y':
          coupon = this.$store.getters.COUPON_BY_ID('committed-3years-discount')
          break
      }
      return coupon ? coupon.discount_percentage : 0
    },

    getCommittedPlan (provider, billingPeriod) {
      return this.$store.getters.PLAN_BY_ID(`committed-fpu-s-${provider}-${billingPeriod}-${this.currencyCode.toLowerCase()}`)
    },

    computeUnitPrice (plan, qty = 0) {
      if (!plan.tiers) return plan.price
      for (const tier of plan.tiers) {
        if (qty >= tier.starting_unit && (!tier.ending_unit || qty <= tier.ending_unit)) {
          return tier.price
        }
      }
      return null
    },

    computePrice (planItem, qty = 0) {
      const unitPrice = this.computeUnitPrice(planItem, qty)
      if (!unitPrice) return unitPrice
      return unitPrice * qty
    },

    computeDiscount (price, term_commitment = '1y') {
      const couponDiscount = this.getCouponDiscount(term_commitment)
      return ((100 - couponDiscount) * price) / 100
    },

    committedUnitPrice (committed) {
      const product = this.getCommittedPlan(committed.provider, committed.billing_period_unit)
      if (!product) return null
      const price = this.computeUnitPrice(product, committed.quantity || 1)
      return this.computeDiscount(price, committed.term_commitment)
    },

    committedPrice (committed) {
      const product = this.getCommittedPlan(committed.provider, committed.billing_period_unit)
      if (!product) return null
      const price = this.computePrice(product, committed.quantity || 1)
      return Price(this.computeDiscount(price, committed.term_commitment), this.currencyCode, 0)
    },


    committedRate (committed) {
      const product = this.getCommittedPlan(committed.provider, committed.billing_period_unit)
      const productHourly = this.$store.getters.ADDON_BY_ID(`fpu-s-${committed.provider}-hourly-${this.currencyCode.toLowerCase()}`)
      if (!product) return '-'
      let price = this.computeUnitPrice(product, committed.quantity || 1)
      if (!price) return '-'
      price = this.computeDiscount(price, committed.term_commitment)
      const hourlyPrice = this.computeUnitPrice(productHourly, committed.quantity || 1)
      const ratio = null
      if (committed.billing_period_unit === 'monthly' && hourlyPrice) {
        const monthlyHours = 365.25 / 12 * 24
        return 100 - (price * 100 / (hourlyPrice * monthlyHours))
      } else if (committed.billing_period_unit === 'yearly' && hourlyPrice) {
        const yearlyHours = 365.25 * 24
        return 100 - (price * 100 / (hourlyPrice * yearlyHours))
      }
      return ratio
    },


    computeUnitPriceHourly (periodUnit, unitPrice) {
      const monthlyHours = 365.25 / 12 * 24
      const yearlyHours = 365.25 * 24
      if (periodUnit === 'monthly') return unitPrice / monthlyHours
      return unitPrice / yearlyHours
    },




    commitedPriceRender (committed) {
      const unitPrice = this.committedUnitPrice(committed)
      const hourlyPrice = this.computeUnitPriceHourly(committed.billing_period_unit, unitPrice)
      if (!unitPrice) return '-'
      const ratio = this.committedRate(committed)
      const priceHtml = `
        <div class="unit-price">
          ${Price(hourlyPrice, this.currencyCode, 4)} / ${this.$t('committeds.unit.hourly')}
        </div>
      `
      if (!ratio) return priceHtml
      return `
          ${priceHtml}
          <div class="saved">~${Math.round(ratio)}% ${this.$t('committeds.saved')}</div>
        `
    },


    committedsRate () {
      let byYear = 0
      let byMonth = 0
      let byYearHourly = 0
      let byMonthHourly = 0
      const yearlyHours = 365.25 * 24
      const monthlyHours = 365.25 / 12 * 24
      for (const committed of this.committeds) {
        const product = this.getCommittedPlan(committed.provider, committed.billing_period_unit)
        const productHourly = this.$store.getters.ADDON_BY_ID(`fpu-s-${committed.provider}-hourly-${this.currencyCode.toLowerCase()}`)
        if (!product) continue
        let price = this.computePrice(product, committed.quantity)
        if (!price) continue
        price = this.computeDiscount(price, committed.term_commitment)
        const hourlyPrice = this.computePrice(productHourly, committed.quantity || 1)
        if (committed.billing_period_unit === 'yearly') {
          if (hourlyPrice) byYearHourly += hourlyPrice * yearlyHours
          byYear += price
        } else if (committed.billing_period_unit === 'monthly') {
          if (hourlyPrice) byMonthHourly += hourlyPrice * monthlyHours
          byMonth += price
        }
      }
      const yearPrice = byMonth * 12 + byYear
      const yearHourlyPrice = byMonthHourly * 12 + byYearHourly
      if (yearHourlyPrice) {
        return 100 - (yearPrice * 100 / (yearHourlyPrice))
      }
      return null
    },


    committedsPrice () {
      let byYear = 0
      let byMonth = 0
      for (const committed of this.committeds) {
        const product = this.getCommittedPlan(committed.provider, committed.billing_period_unit)
        if (!product) continue
        let price = this.computePrice(product, committed.quantity)
        if (!price) continue
        price = this.computeDiscount(price, committed.term_commitment)
        if (committed.billing_period_unit === 'yearly') {
          byYear += price
        } else if (committed.billing_period_unit === 'monthly') {
          byMonth += price
        }
      }
      return {
        year: byYear,
        month: byMonth
      }
    },

    commitedsPriceRender () {
      const ratio = this.committedsRate()
      const { month, year } = this.committedsPrice()
      let priceHtml = ''
      const ratioHtml = ratio ? `<div class="saved">~${Math.round(ratio)}% ${this.$t('committeds.estimated_savings')}</div>` : ''
      const yearHtml = `<div class="by_year"><span>${Price(year, this.currencyCode)}</span> / ${this.$t('committeds.year')}</div>`
      const monthHtml = `<div class="by_month"><span>${Price(month, this.currencyCode)}</span> / ${this.$t('committeds.month')}</div>`
      if (!month && !year) return '-'
      if (!month) priceHtml = yearHtml
      else if (!year) priceHtml = monthHtml
      else priceHtml = `${yearHtml}<div class="separator">+</div>${monthHtml}`
      return `<div class="inner-price">${priceHtml}</div>${ratioHtml}`
    },

    remove (index) {
      this.committeds.splice(index, 1)
    },
    add () {
      this.committeds.push({
        resource_type: 'fpu-s',
        quantity: 1,
        provider: 'gcp',
        term_commitment: '1y',
        billing_period_unit: 'monthly'
      })
      this.scrollToBottom()
    },
    scrollToBottom () {
      this.$nextTick(() => {
        if (this.$refs['bottom-scroll']) {
          this.$refs['bottom-scroll'].scrollTop = this.$refs['bottom-scroll'].scrollHeight
        }
      })
    }
  }
}
</script>
<style lang="less">
.committeds-editor {
  .committed-body {
    margin-top    : 60px;
    padding-bottom: 20px;
    position      : relative;
    background    : white;
    table-layout  : fixed;
    border        : 1px solid rgba(151, 167, 183, 0.21);
    opacity       : 0.9;
    border-radius : 5px;

    .fpui-input-select {
      border-radius: 5px;
      // background   : white !important;

      i.fp4.fp4-angle-down {
        color: #0089C0 !important;
      }
    }
    .first {
      padding-left: 20px;
    }

    tbody {
      display: block;
      overflow: auto;
      width: 840px;
      max-height: 230px;
    }

    tbody tr {
      height: 45px;
    }

    .placeholder {
      td {
        color: #CBD3DB;
        text-align: center;
        font-style: italic;
        margin-top: 33px;
      }
      background: white!important;
    }

    .price {
      text-align: left;

      span {
        font-weight: bold;
      }
    }

    .saved {
      text-align : left;
      color      : #9EDF10;
      font-size  : 12px;
      font-weight: 600 !important;
      line-height: 15px;
    }

    .fp4-trash-can {
      font-size: 18px;
      color    : #CBD3DB;
      cursor   : pointer;

      &:hover {
        color: #3E4550;
      }
    }

    thead th {
      text-align  : left;
      padding-left: 5px;
      top         : 0;
      overflow    : visible;
      background  : #F6F9FC;
      z-index     : 2;
    }

    tbody td {
      overflow: visible;
    }

    table {
      margin      : 0;
      table-layout: fixed;
    }
  }


  .add {
    margin-top: 25px;
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
  }

  .providers {
    img {
      width       : 22px;
      margin-right: 16px;
    }
  }

  .committed-footer {
    text-align: center;
    margin-top : 77px;
    background : white;
    width      : calc(~"100% + 50px");
    margin-left: -27px;
    border-top : 1px solid rgba(151, 167, 183, 0.21);
    height     : 140px;

    .title {
      font-size : 14px;
      color     : #B2BECA;
      margin-top: 20px;
    }

    .inner-price {
      display        : flex;
      font-size      : 18px;
      font-weight    : bold;
      align-items    : center;
      justify-content: center;
      margin-top     : 10px;

      span {
        font-size: 32px;
      }

      .separator {
        margin     : 0 10px;
        padding-top: 10px;
        font-size  : 18px;
      }
    }

    .saved {
      color    : @green;
      font-size: 18px;
    }

    .next {
      position: absolute;
      right   : 15px;
      bottom  : 15px;
    }

    .prev {
      position: absolute;
      left    : 15px;
      bottom  : 15px;
    }

  }

  .order {
    .line {
      box-sizing: border-box;
      margin    : 20px auto;
      width     : 500px;

      .sline {

        display: flex;
        padding: 5px 32px;

        .value,
        .name {
          flex-grow: 1;
        }

        .name {
          font-weight: normal;
          font-size  : 12px;
          color      : #3E4550;
          text-align : left;
        }

        .value {
          color      : #3E4550;
          font-size  : 14px;
          font-weight: 600;
          text-align : right;
        }
      }

      .saved {
        display: none;
      }

      .price {
        .value {

          .price,
          .unit-price {
            display    : inline-block;
            margin-left: 30px;
          }
        }
      }
    }
  }
}

</style>

