import Vue from 'vue'
import interact from 'interactjs'

Vue.directive('droppable', {
  bind (el, { value }) {
    const element = interact(el)
    element.dropzone({
      ondrop: async (event) => {
        value(event.relatedTarget.value)
      }
    })
  }
})
