<template lang='pug'>
  .fpui-input-image
    .row
      .fpui-input-label-container.vcenter(:class='labelClass', v-if='label')
        span {{ label | translate }}
        .required-container(v-if='required')
          i.fpui.fpui-required
        fpui-helper(
          v-if='helper'
          :text="helper | translate"
          :title="helperTitle ? helperTitle : ''"
        )
      .fpui-input-image-content.vcenter(:class='inputClass')
        .img-container
          .img-wrapper.edition
            input(@change='onChange', type="file" accept="image/*", :id="id")
            img(:src="src")
            .img-overlay
              label.fpui.fpui-pencil(:for="id")
</template>

<script>

export default {
  props: {
    value: { type: String, required: true },
    default: { type: String, default: 'missing' },
    id: { type: String, default: 'input-image' },
    label: { type: String, default: '' },
    labelSize: { type: Number, default: 3 },
    withPadding: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    maxWidth: { type: String, default: '' },
    maxHeight: { type: String, default: '' },
    helper: { type: String, default: '' },
    helperTitle: { type: String, default: '' }
  },
  computed: {
    src () { return (this.value || this.default) },
    inputClass () {
      const className = []
      let size = 12
      if (this.label) size -= this.labelSize
      className.push(`col-xs-${size}`)
      if (this.withPadding) className.push('with-padding')
      return className.join(' ')
    },
    labelClass () {
      const className = [`col-xs-${this.labelSize}`]
      if (this.withPadding) className.push('with-padding')
      return className.join(' ')
    }
  },
  methods: {
    async onChange (event) {
      const reader = new FileReader()
      let newImage = ''
      reader.onloadend = async (e) => {
        if (this.maxWidth && this.maxHeight) {
          newImage = await this.resizeImage(e.target.result, this.maxWidth, this.maxHeight)
          this.$emit('input', newImage)
        }
        if (!newImage) this.$emit('input', e.target.result)
      }
      reader.readAsDataURL(event.target.files[0])
    },
    async resizeImage (img, maxWidth, maxHeight) {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      const imageEncode = new Image()
      const imageOnLoad = () => {
        return new Promise((resolve) => {
          imageEncode.onload = () => {
            let newHeight = ''
            let newWidth = ''
            if (imageEncode.height > 600 || imageEncode.width > 600) {
              const heightWidthRatio = imageEncode.height / imageEncode.width
              if (heightWidthRatio > 1) {
                newHeight = maxHeight
                newWidth = maxHeight / heightWidthRatio
              } else {
                newWidth = maxWidth
                newHeight = maxHeight / (1 / heightWidthRatio)
              }
            }
            resolve({ height: parseInt(newHeight || imageEncode.height), width: parseInt(newWidth || imageEncode.width) })
          }
        })
      }
      imageEncode.src = img
      const sizes = await imageOnLoad()
      imageEncode.style.width = sizes.width + 'px'
      imageEncode.style.height = sizes.height + 'px'
      canvas.width = sizes.width
      canvas.height = sizes.height
      await ctx.drawImage(imageEncode, 0, 0, sizes.width, sizes.height)
      canvas.appendChild(imageEncode)
      // encode image to data-uri with base64 version of compressed image
      return canvas.toDataURL()
    }
  }
}
</script>

<style lang='less'>
@import "~@/shared/styles/_variables.less";

.fpui-input-image {
  .fpui-input-label-container {
    padding-left: 0;
    cursor: pointer;
    .required-container {
      display: inline;
      vertical-align: middle;
      font-size: 22px;
      line-height:18px;
      text-align: center;
      border-radius: 50%;
    }
  }
  .fpui-input-image-content {
    &:not(.with-padding) {
      padding: 0;
    }
    .img-container {
      max-width: 180px;
      background: white;
      max-height: 180px;
      position: relative;
      display: flex;
      align-items: center;
      text-align: center;
      overflow: hidden;
    }
    img {
      object-fit: cover;
      max-width: 100%;
      max-height: 100%;
      position: relative;
    }
    input {
      display: none;
    }
    &:hover .img-overlay {
      top: 0;
      bottom: 0;
    }
    .img-overlay {
      position: absolute;
      transition: all 300ms ease-in-out;
      top: 100%;
      bottom: -100%;
      left: 0;
      right: 0;
      background-color: @blueLight;
      color: @blue;
      label {
        position:absolute;
        margin:auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        font-size: 32px;
        width: 45px;
        line-height: 45px;
        height: 45px;
        display: block;
        cursor: pointer;
        text-align: center;
        transition: all 200ms ease-in-out;
        &:hover {
          background-color: @blue;
          color: #fff;
        }
      }
    }
  }
}
</style>
