<template lang="pug">
.query-preview
  fp-loader(type="chart" v-if="internalLoading || loadingExecution")
  .header-container
    .left-buttons-container
      a.button-settings(
        v-if="!fromExplorer"
        v-tooltip="$t('query.preview.toggle_chart_settings')"
        :class="{ active: isOpenSettings }"
        @click="switchSettings()"
      )
        i.fp4.fp4-gears
      .preview-switch-mode(
        v-if="!fromExplorer"
      )
        a.mode-button.mode-table(
          v-tooltip="{ content: $t('query.preview.table'), delay: 0 }"
          :class="{ active: previewMode === 'table' }"
          @click="switchPreviewMode('table')"
        )
          i.fp4.fp4-table
        a.mode-button.mode-line(
          v-if="visualMode"
          v-tooltip="{ content: $t('query.preview.line'), delay: 0 }"
          :class="{ active: previewMode === 'line' }"
          @click="switchPreviewMode('line')"
        )
          i.fp4.fp4-chart-line
        a.mode-button.mode-area(
          v-if="visualMode"
          v-tooltip="{ content: $t('query.preview.area'), delay: 0 }"
          :class="{ active: previewMode === 'area' }"
          @click="switchPreviewMode('area')"
        )
          i.fp4.fp4-chart-area
        a.mode-button.mode-bar(
          v-if="visualMode"
          v-tooltip="{ content: $t('query.preview.bar'), delay: 0 }"
          :class="{ active: previewMode === 'bar' }"
          @click="switchPreviewMode('bar')"
        )
          i.fp4.fp4-chart-bar
        a.mode-button.mode-scatter(
          v-if="visualMode"
          v-tooltip="{ content: $t('query.preview.scatter'), delay: 0 }"
          :class="{ active: previewMode === 'scatter' }"
          @click="switchPreviewMode('scatter')"
        )
          i.fp4.fp4-chart-scatter
        a.mode-button.mode-pie(
          v-if="visualMode"
          v-tooltip="{ content: $t('query.preview.pie'), delay: 0 }"
          :class="{ active: previewMode === 'pie' }"
          @click="switchPreviewMode('pie')"
        )
          i.fp4.fp4-chart-pie
        a.mode-button.mode-big-number(
          v-if="visualMode"
          v-tooltip="{ content: $t('query.preview.big_number'), delay: 0 }"
          :class="{ active: previewMode === 'big-number' }"
          @click="switchPreviewMode('big-number')"
        )
          i.fp4.fp4-chart-number
        a.mode-button.mode-gauge(
          v-if="visualMode"
          v-tooltip="{ content: $t('query.preview.progress_bar'), delay: 0 }"
          :class="{ active: previewMode === 'gauge' }"
          @click="switchPreviewMode('gauge')"
        )
          i.fp4.fp4-chart-gauge
        //- a.mode-button.mode-combo-chart(
        //-   v-if="visualMode"
        //-   v-tooltip="{ content: $t('query.preview.combo_chart'), delay: 0 }"
        //-   :class="{ active: previewMode === 'combo-chart' }"
        //-   @click="switchPreviewMode('combo-chart')"
        //- )
        //-   i.fp4.fp4-chart-multi
      .fpui-tabs.explorer(
        v-if="fromExplorer"
        ref="tabs"
      )
        ul.fpui-tabs-heads
          li.fpui-tabs-head(
            :title="$t('query.preview.tab_results')"
            :class="{ active: tabActive === 'results' }"
            @click="setActive('results')"
          )
            span(v-html="$t('query.preview.tab_results')")
            //- If var is put in v-html -> use $sanitize()

          li.fpui-tabs-head(
            :title="$t('query.preview.tab_history')"
            :class="{ active: tabActive === 'history' }"
            @click="setActive('history')"
          )
            span(v-html="$t('query.preview.tab_history')")
            //- If var is put in v-html -> use $sanitize()

    .right-buttons-container
      .estimator-container(
        v-if="!downloadLoading && !scanLoading && (queryScannedData || errorQueryConfiguration)"
        v-tooltip="{ content: errorQueryConfiguration ? errorMessage : '', delay: 0, classes: ['error-no-background'] }"
        :class="{ error: errorQueryConfiguration }"
      )
        i.fp4.fp4-circle-info
        span(v-if="!errorQueryConfiguration && queryScannedData") {{ $t('query.preview.scanned_data', [queryScannedData]) }}
        span(v-if="errorQueryConfiguration") {{ $t('query.preview.scanned_data_error') }}
      .scan-loading(v-if="scanLoading && !downloadLoading")
        fp-loading
        span {{ this.$t('query.preview.scanning_loader') }}
      .download-loading(v-if="downloadLoading")
        fp-loading
        span {{ this.$t('query.preview.exporting_loader') }}
      fpui-input-select.limit(
        v-if="!fromExplorer"
        v-model="limit"
        v-tooltip="$t('query.preview.limit_tooltip')"
        :options="limitOptions"
        :clearable="false"
        is-filter
      )
      fpui-button.run(
        v-tooltip="$t('query.preview.run')"
        color="green",
        icon='fp4 fp4-play'
        auto-width
        icon-left
        :disabled="internalLoading || !acl || item.saving"
        @click="run()",
      ) {{ $t('button.run') }}
      .actions-container(
        :class="{ 'from-explorer': fromExplorer }"
      )
        .action-button.download
          fpui-input-select-categories(
            is-filter
            :options="downloadOptions"
            :clearable="false"
            :searchable="false"
            :icon="fromExplorer ? '' : 'fp4-down-to-line'"
            :categories="downloadCategories"
            :value-tooltip="$t('query.preview.download_button')"
            :icon-only="!fromExplorer"
            @input="(v) => downloadPreview(v)"
          )
            template(
              v-if="fromExplorer"
              v-slot:content="slotProps"
            )
              fpui-button(
                color="white"
                autoWidth
                icon="fp4 fp4-down-to-line"
                @click="slotProps.open"
                icon-left
                dropdown-icon
              ) {{ $t('query.preview.download_button') }}

        a.action-button.share(
          v-if="!fromExplorer"
          v-tooltip.top-end="{ content: $t('query.preview.coming_soon'), delay: 0 }"
          :disabled='true || !this.result'
          :class='{ "disabled": true || !this.result }'
          @click="share()"
        )
          i.fp4.fp4-share
        //- a.action-button.code(@click="code()")
        //-   i.fp4.fp4-code
        //- a.action-button.qrcode(@click="qrcode()")
        //-   i.fp4.fp4-plus-grid-2
  .preview-data-container(v-if="!internalLoading && !loadingExecution")
    .explorer-history(
      v-if="fromExplorer && tabActive === 'history'"
    )
      history-table(
        type="past"
        :query-id="queryId"
        :from-explorer="true"
      )
    .results(
      v-if="(fromExplorer && tabActive === 'results') || !fromExplorer"
    )
      .settings-container(
        v-show="isOpenSettings && !error && !fromExplorer"
      )
        chart-settings(
          :type="previewMode"
          :item="item"
          :result="result"
          :read-only="readOnly"
        )
      .result-container(
        v-if="result && !error"
        :style="{ width: isOpenSettings ? 'calc(100% - 328px)' : '100%' }"
      )
        component(
          :is="chartDisplayed"
          :value="resultToDisplay"
          :sql="item && item.type === 'sql'"
          :key="previewMode"
        )
      .result-error(
        v-if="error"
      )
        .status.center
          i.fp4.fp4-circle-exclamation
        .text {{ $t('query.preview.result.error') }}
        .panel-error
          .summary
            .circle
            .summary-text {{ errorSummary }}
          .error-message {{ error }}
      .no-result(v-if="!result && !error")
        img(
          v-if="previewMode === 'table'"
          src='@/qb/assets/img/placeholder_preview.png'
        )
        img(
          v-else
          src='@/qb/assets/img/placeholder_preview_chart@2x.png'
        )
        .message {{ $t('query.preview.result.empty') }}
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'
import _debounce from 'lodash/debounce'
import pascalcase from 'pascalcase'

import HistoryTable from '@/shared/components/query-builder/HistoryTable'
import Config from '@/shared/Config'
import Bytes from '@/shared/filters/Bytes'
// import mockResults from './mock/mock_rides_per_stationName.js'
import ChartSettings from './Charts/ChartSettings/ChartSettings'
import Charts from './Charts'
import EchartsFormatter from './Charts/EchartsFormatter'

export default {
  components: {
    ...Charts,
    ChartSettings,
    HistoryTable
  },
  props: {
    item: { type: Object, default: () => { } },
    readOnly: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    fromExplorer: { type: Boolean, default: false },
    // Loading execution is used to display loader when user run from sql editor (Cmd + Enter) and the query is saving
    loadingExecution: { type: Boolean, default: false }
  },
  data () {
    return {
      canQueryRun: true,
      canQueryHistory: true,
      isOpenSettings: false,
      previewMode: 'table',
      limit: 5000,
      semiStructured: false,
      internalLoading: false,
      downloadLoading: false,
      result: null,
      error: null,
      errorSummary: null,
      errorQueryConfiguration: null,
      errorMessage: '',
      queryScannedData: null,
      scanLoading: false,
      tabActive: 'results',
      downloadCategories: [
        {
          label: this.$t('query.preview.download.results'),
          id: 'results'
        },
        {
          label: this.$t('query.preview.download.configuration'),
          id: 'configuration'
        }
      ],
      config: null
    }
  },
  computed: {
    limitOptions () {
      return [
        {
          label: this.$t('query.preview.limit.all'),
          value: 'all'
        }, {
          label: this.$t('query.preview.limit', [10]),
          value: 10
        }, {
          label: this.$t('query.preview.limit', [100]),
          value: 100
        }, {
          label: this.$t('query.preview.limit', [1000]),
          value: 1000
        }, {
          label: this.$t('query.preview.limit', [5000]),
          value: 5000
        }
      ]
    },
    downloadOptions () {
      const options = [
        {
          label: this.$t('query.preview.download.csv'),
          value: 'csv',
          disabled: !this.result,
          tooltip: !this.result ? this.$t('query.preview.download.copy_to_clipboard.tooltip_disabled') : '',
          category: 'results'
        }, {
          label: this.$t('query.preview.download.bucket_csv'),
          value: 'bucket-csv',
          disabled: !this.item._id || !this.result,
          tooltip: !this.item._id ? this.$t('query.preview.download.bucket_csv.tooltip_disabled') : !this.result ? this.$t('query.preview.download.copy_to_clipboard.tooltip_disabled') : '',
          category: 'results'
        },
        {
          label: this.$t('query.preview.download.copy_to_clipboard'),
          value: 'copy',
          disabled: !this.result,
          tooltip: !this.result ? this.$t('query.preview.download.copy_to_clipboard.tooltip_disabled') : '',
          category: 'results'
        }
      ]

      if (this.item?.type !== 'sql') {
        options.push({
          label: this.$t('query.preview.download.json'),
          value: 'json',
          category: 'configuration'
        })
      }

      return options
    },
    resultToDisplay () {
      if (['line', 'pie', 'bar', 'scatter', 'area', 'combo-chart'].includes(this.previewMode)) {
        const fullOptions = {
          ..._cloneDeep(this.result),
          options: this.item.chart_settings[this.previewMode]
        }

        // If 30+ data points, do not show label in pie chart
        if (this.result?.results?.length > 30 && this.previewMode === 'pie') {
          fullOptions.options.series.label.show = false
        }

        return new EchartsFormatter(fullOptions)
      }
      return {
        ...this.result,
        options: this.item.chart_settings[this.previewMode] || {}
      }
    },
    acl () {
      return this.canQueryRun
    },
    visualMode () {
      return this.item?.type === 'forepaas'
    },
    queryId () {
      return this.item?._id
    },
    evolDepth () {
      if (!this.result?.query_params?.evol || !this.result.query_params.evol.scale) return 0
      return this.result?.query_params?.evol.depth || 1
    },
    chartDisplayed () {
      const chart = `chart-${this.previewMode}`
      if (Object.keys(Charts).includes(pascalcase(chart))) return chart
      return 'chart-echarts'
    },
    trinoActive () {
      return !!this.$store.getters.QB_RESOURCES_ACTIVE
    },
    hasTrinoOrMutualized () {
      return !!this.$store.getters.QB_RESOURCES_ACTIVE_OR_MUTUALIZED
    },
    isAPHP () {
      return this.hasTrinoOrMutualized && this.config?.CLUSTER === 'k8s-aphp'
    },
    isDataPlatform () {
      return this.hasTrinoOrMutualized && this.config?.DATA_PLATFORM_ACCESS
    },
    isSql () {
      return this.item?.type === 'sql'
    },
    tables () {
      return this.$store.getters.DWH_METAS_TABLES
    },
    databases () {
      return this.$store.getters.DWH_METAS_DATABASES
    }
  },
  watch: {
    'item.configuration': {
      deep: true,
      handler (val) {
        this.getQueryInfosFromTrino(val)
      }
    }
  },
  async mounted () {
    this.config = await Config()

    if (this.item?.chart_active) this.previewMode = this.item.chart_active
    else this.item.update('chart_active', 'table')
    this.canQueryRun = await this.$api.IAM.can('am', 'query', 'run')
    this.canQueryHistory = await this.$api.IAM.can('am', 'query', 'history')
    this.getQueryInfosFromTrino(this.item?.configuration)

    if (this.$route.query?.editTile || this.$route.query?.queryFromDashboard) this.run()
  },
  methods: {
    switchSettings () {
      this.isOpenSettings = !this.isOpenSettings
    },
    switchPreviewMode (mode) {
      this.$analytics.track('Edit chart configuration', {
        shared_id: this.item.shared_id,
        chart_type: mode,
        origin: 'query'
      })
      this.previewMode = mode
      this.item.update('chart_active', mode, !this.readOnly)
    },
    async run () {
      this.$analytics.track('Run query', {
        shared_id: this.item.shared_id,
        nb_data: Object.keys(this.item.configuration?.data?.fields || {}).length,
        nb_scale: (this.item.configuration?.scale?.fields || []).length,
        filter: !!Object.keys(this.item.configuration?.filter || {}).length,
        order: !!Object.keys(this.item.configuration?.order || {}).length,
        query_mode: this.item?.type
      })
      try {
        this.internalLoading = true
        this.$emit('loading', true)

        let source = this.item._id ? 'Query' : 'Analytics Manager'
        if (this.fromExplorer) source = 'Explorer'

        this.result = await this.$api.QB.getResult(this.item, this.fromExplorer ? 'all' : this.limit, {
          legacy: this.fromExplorer || this.isAPHP || this.isDataPlatform ? false : !this.trinoActive,
          source
        })

        // If AM or Trino doesn't work -> uncomment this and the import
        // this.result = mockResults

        this.$emit('latest-used-table', this.result?.table_name)

        this.internalLoading = false
        this.$emit('loading', false)
        this.error = null
        this.errorSummary = null

        // To remove when WS active
        // Because there is no web socket, we refresh history here
        if (this.canQueryHistory && (this.fromExplorer || this.isAPHP || this.isDataPlatform)) await this.$store.dispatch('LOAD_QB_QUERIES_HISTORY', true)
      } catch (error) {
        this.error = error.message
        this.errorSummary = error.error_code

        if (error.status === 502) {
          this.error = this.trinoActive ? this.$t('query.preview.error_trino_activate_private') : this.$t('query.preview.error_trino_activate_mutualized')
          this.errorSummary = this.$t('query.preview.error_trino_initializing')
        }

        this.$emit('loading', false)
        this.internalLoading = false

        // To remove when WS active
        // Because there is no web socket, we refresh history here
        if (this.canQueryHistory && (this.fromExplorer || this.isAPHP || this.isDataPlatform)) await this.$store.dispatch('LOAD_QB_QUERIES_HISTORY', true)
      }
    },
    share () {
      return null
      // if (!this.result) return
      // this.$modal.show(ShareModal, {
      //   request: { id: 'todo' }
      // }, {
      //   height: 'auto',
      //   width: 560
      // })
    },
    code () {
      console.log('code')
    },
    qrcode () {
      console.log('qrcode')
    },
    formatResultToExport (format) { // Also used in Tile.vue
      let csvContent = ''
      const headerValues = []
      const limit = format === 'csv' ? 10_000_000 : 1_000_000
      let maxRows = null
      let rowsDownloaded = null

      // Get columns
      const columnsInfo = []
      if (this.visualMode) {
        if (this.result?.query_params?.scale?.fields) {
          this.result.query_params.scale.fields.forEach(scale => {
            headerValues.push(scale)
            columnsInfo.push({
              label: scale,
              target: `scales.${scale}`
            })
          })
        }
        if (this.result?.query_params?.data?.fields) {
          Object.keys(this.result.query_params.data.fields).forEach(data => {
            this.result.query_params.data.fields[data].forEach(cm => {
              for (let evol = 0; evol <= this.evolDepth; evol++) {
                headerValues.push(cm === 'select' ? data : cm + ' ' + data)
                columnsInfo.push({
                  label: cm === 'select' ? data : cm + ' ' + data,
                  target: `data.${data}.${cm}[${evol}].value`
                })
              }
            })
          })
        }
      } else {
        (this.result?.results?.columns || []).forEach(column => {
          headerValues.push(column)
          columnsInfo.push({
            label: column,
            target: column
          })
        })
      }
      csvContent += `${headerValues.join('\t')}\n`

      // Get rows
      let rows = this.result?.results
      maxRows = rows.length
      let estimateRowsToLimit = 0

      // Used for sql queries
      const { results, columns } = this.result?.results || {}
      if (!this.visualMode) {
        rows = new Array(results?.length || 0)
        maxRows = rows.length
      }

      for (let i = 0; i < rows.length; i++) {
        if (!this.visualMode) {
          // eslint-disable-next-line no-sequences
          rows[i] = results[i].reduce((acc, cur, i) => ((acc[columns[i]] = cur), acc), {})
        }

        const values = []
        columnsInfo.forEach(c => {
          let value = _get(rows[i], c.target)
          if (typeof value === 'string') value = value.replace(/(\r\n|\n|\r|\t)/gm, ' ')
          values.push(value)
        })

        const test = csvContent + `${values.join('\t')}\n`
        if (i === 0 || estimateRowsToLimit === limit) {
          const firstRowSize = new Blob([test]).size
          estimateRowsToLimit = Math.floor(limit / (firstRowSize || 1))
        }

        if (maxRows > estimateRowsToLimit && i % estimateRowsToLimit === 0 && new Blob([test]).size > limit) {
          maxRows = rows.length
          rowsDownloaded = i
          break
        }
        csvContent = test
      }

      const size = new Blob([csvContent]).size
      if (size && size > limit) {
        const csvContentArray = csvContent.split('\n')
        const ratio = size / limit
        const rowsToKeep = Math.floor(csvContentArray.length / ratio)

        csvContent = csvContentArray.slice(0, rowsToKeep).join('\n')
        // We do not count column name in line downloaded
        rowsDownloaded = rowsToKeep - 1
      }

      return {
        csvContent,
        maxRows,
        rowsDownloaded
      }
    },
    async downloadPreview (format) { // Also used in Tile.vue
      this.downloadLoading = true

      if (['copy', 'csv', 'json'].includes(format)) {
        await new Promise(resolve => {
          setTimeout(() => { // Put timeout to display 'loading' in UI
            if (format === 'json') {
              const blob = new Blob([JSON.stringify(this.item.configuration, null, 2)], { type: 'application/json' })
              const url = URL.createObjectURL(blob)
              const a = document.createElement('a')
              a.href = url
              a.download = this.item.display_name
              a.click()
              a.remove()
            }

            if (['copy', 'csv'].includes(format)) {
              if (!this.result) return
              this.$analytics.track('Download the query result', {
                shared_id: this.item.shared_id,
                format,
                from: 'Query edition'
              })

              try {
                let message = ''
                if (format === 'copy') {
                  const { csvContent, maxRows, rowsDownloaded } = this.formatResultToExport(format)
                  this.$copyText(csvContent)
                  message = rowsDownloaded === null ? this.$t('query.preview.download.copy_to_clipboard.all') : this.$t('query.preview.download.copy_to_clipboard.limit', [rowsDownloaded, maxRows])
                }

                if (format === 'csv') {
                  const { csvContent, maxRows, rowsDownloaded } = this.formatResultToExport(format)
                  const blob = new Blob([csvContent], { type: 'text/csv' })
                  const url = URL.createObjectURL(blob)
                  const a = document.createElement('a')
                  a.href = url
                  a.download = this.item.display_name
                  a.click()
                  a.remove()

                  message = rowsDownloaded === null ? this.$t('query.preview.download.csv.all') : this.$t('query.preview.download.csv.limit', [rowsDownloaded, maxRows])
                }

                this.$fpuiMessageBlock.success(message)
              } catch (err) {
                this.$fpuiMessageBlock.error(err)
                console.error(err)
              }
            }
            resolve(true)
          }, 100)
        })
      } else if (format === 'bucket-csv') {
        const user = await this.$api.KING.users.me()
        try {
          await this.$api.QUERY_ADMIN.exportQueryCsv(this.item?._id, user.email)
          this.$fpuiMessageBlock.success(this.$t('query.preview.download.bucket_csv.success'))
        } catch (err) {
          this.$fpuiMessageBlock.error(this.$t('query.preview.download.bucket_csv.error'))
        }
      }

      this.downloadLoading = false
    },
    setActive (tab) {
      this.tabActive = tab
    },
    getQueryInfosFromTrino: _debounce(async function (configuration) {
      if (!this.hasTrinoOrMutualized) return

      this.errorQueryConfiguration = false
      this.errorMessage = ''

      // If query empty do not analyze
      if ((!this.isSql && !Object.keys(this.item?.configuration?.data?.fields).length) || (this.isSql && !this.item?.configuration?.sql)) {
        this.queryScannedData = null
        return
      }

      this.scanLoading = true
      let sql = null
      try {
        const getSql = this.isSql ? configuration : await this.$api.QB.getSQLFromConfiguration(configuration)
        sql = getSql?.sql
        if (sql.length && sql[sql.length - 1] === ';') sql = sql.slice(0, -1)
      } catch (err) {
        this.scanLoading = false
        this.errorQueryConfiguration = true
        this.errorMessage = err.message
        return
      }
      if (!sql) {
        this.scanLoading = false
        return
      }

      try {
        let source = this.item?._id ? 'Query' : 'Analytics Manager'
        if (this.fromExplorer) source = 'Explorer'
        const request = `EXPLAIN (TYPE IO, FORMAT JSON) ${sql}`
        const result = await this.$api.QB.getResultSql(request, this.item?._id, source)

        this.infos = JSON.parse(result.results.results[0]) // To use when we will get result in json

        const dataInBytes = Bytes(this.infos?.estimate?.outputSizeInBytes)
        this.queryScannedData = dataInBytes === '-' ? null : dataInBytes
      } catch (err) {
        this.scanLoading = false
        this.errorQueryConfiguration = true
        this.errorMessage = err.message
      }

      this.scanLoading = false
    }, 2000)
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";

.query-preview {
  background: #FFF;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 25px 14px 25px;
    height: 45px;
    border-bottom: 1px solid #D6DEE5;
    .left-buttons-container {
      display: flex;
      align-items: center;
      .button-settings {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        height: 25px;
        width: 37px;
        border: 1px solid rgba(151,167,183,0.21);
        border-radius: 5px;
        color: #97A7B7;
        cursor: pointer;
        &.active, &:hover:not(.disabled) {
          color: white;
          background-color: @grey;
          box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
        }
        &.disabled {
          cursor: not-allowed;
          box-shadow: none;
          border: 1px solid rgba(151,167,183,0.21);
          color: #E4E7EC;
        }
      }
      .preview-switch-mode {
        display: flex;
        align-items: center;
        border: 1px solid rgba(151,167,183,0.21);
        border-radius: 5px;
        margin-left: 10px;
        height: 25px;
        align-content: center;
        a {
          font-size: 20px;
          display: inline-block;
          color: #97A7B7;
          height: 23px;
          width: 37px;
          padding: 0 6px 0 4px;
          cursor: pointer;
          border-right: 0.5px solid rgba(151,167,183,0.21);
          border-left: 0.5px solid rgba(151,167,183,0.21);
          display: flex;
          justify-content: center;
          align-items: center;
          &:first-child {
            border-radius: 4px 0 0 4px;
            border-left: none;
          }
          &:last-child {
            border-radius: 0 4px 4px 0;
            border-right: none;
          }
          &:only-child {
            border-radius: 4px;
            border: none;
          }
          &.active, &:hover {
            color: white;
            background-color: @grey;
            box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
            border: none;
            width: 37px !important;
          }
          i {
            font-size: 20px;
          }
        }
      }
      .fpui-tabs.explorer {
        margin-bottom: -32px;
        .fpui-tabs-heads {
          padding-left: 12px;
          .fpui-tabs-head {
            margin-right: 40px;
          }
        }
      }
    }
    .right-buttons-container {
      display: flex;
      align-items: center;

      .estimator-container {
        margin-right: 20px;
        display: flex;
        align-items: center;
        color: #97A7B7;

        i {
          font-size: 16px;
          margin-right: 5px;
        }
        span {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        &.error {
          color: @red;
        }
      }

      .download-loading, .scan-loading {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .fp-loading {
          margin-right: 3px;
          width: 20px !important;
          height: 20px !important;
        }
        span {
          color: #97a7b7;
        }
      }
      .limit {
        margin-right: 10px;
        .fpui-input-select {
          width: 195px;
        }
      }
      .run {
        .fpui-button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 34px;
          .btn-content {
            font-size: 13px;
          }
        }
      }
      .actions-container {
        display: flex;
        align-items: center;
        border: 1px solid rgba(151,167,183,0.21);
        border-radius: 5px;
        margin-left: 10px;
        height: 34px;
        a {
          font-size: 20px;
          display: inline-block;
          color: #97A7B7;
          height: 32px;
          width: 34px;
          padding: 4px 6px;
          cursor: pointer;
          border-right: 0.5px solid rgba(151,167,183,0.21);
          border-left: 0.5px solid rgba(151,167,183,0.21);
          &:first-child {
            border-radius: 4px 0 0 4px;
            border-left: none;
          }
          &:last-child {
            border-radius: 0 4px 4px 0;
            border-right: none;
          }
          &.disabled {
            cursor: not-allowed;
            box-shadow: none;
            color: #E4E7EC;
          }
          &:hover:not(.disabled) {
            color: white;
            background-color: @grey;
            box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
            border: none;
          }
        }
        > .download {
          .fpui-input-select-container, .fpui-input-select-categories-container {
            position: relative;
            border-radius: 4px 0 0 4px;
            .fpui-input-label-container {
              display: flex;
            }
            &.disabled {
              > .fpui-input-label {
                i {
                  color: #E4E7EC;
                }
              }
            }
            &:hover:not(.disabled) {
            background-color: @grey;
            box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
              > .fpui-input-label {
                color: @white;
                i {
                  color: @white;
                }
                .fpui-input-label-container {
                  span {
                    color: @white;
                  }
                }
              }
            }
            > .fpui-input-label {
              position: absolute;
              left: 7px;
              top: 4px;
              i {
                font-size: 20px;
                color: #97a7b7;
              }
              span {
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;
                margin-left: 6px;
                color: #97a7b7;
              }
            }
            > .content {
              > .default {
                > .fpui-input-select {
                  border: none;
                  background-color: initial;
                  display: initial;
                  padding: 8px 17px;
                }
                .select-label {
                  display: none;
                }
              }
              > .dropdown {
                left: unset;
                right: 0;
                top: 36px;
              }
              li[disabled] {
                color: @grey;
                user-select: none;
                cursor: initial;
                &:hover {
                  background-color: initial;
                }
              }
              i.fp4-angle-down {
                display: none;
              }
            }
          }
        }

        &.from-explorer {
          border: none;
          > .download {
            .fpui-input-select-container, .fpui-input-select-categories-container {
              > .content {
                > .default {
                  > .fpui-input-select {
                    border: none;
                    background-color: initial;
                    display: block;
                    padding: 0;
                    width: 110px;
                  }
                }
              }
              > .fpui-input-label {
                position: absolute;
                left: 7px;
                top: 6px;
              }

              &:hover {
                background: none;
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
  .preview-data-container {
    display: flex;
    overflow-y: auto;
    width: 100%;
    flex-grow: 1;
    .results {
      display: flex;
      width: 100%;
      flex-grow: 1;
      .settings-container {
        width: 328px;
        height: 100%;
        border-right: 1px solid rgba(151,167,183,0.21);
      }
      .result-container {
        height: 100%;
        width: 100%;
      }

      .result-error {
        margin: auto;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .status.center {
          margin-bottom: 3px;
          .fp4-circle-exclamation {
            color: @red;
            font-size: 46px;
          }
        }
        .text {
          color: #000;
          font-size: 22px;
          line-height: 28px;
          margin-bottom: 25px;
        }
        .panel-error {
          width: 70%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 10px;
          background: #F6F9FC;
          border-radius: 10px;
          .summary {
            display: flex;
            align-items: center;
            margin-bottom: 9px;
            .circle {
              margin-top: 3px;
              margin-right: 6px;
              border-radius: 100%;
              width: 8px;
              height: 8px;
              background-color: @red;
            }
            .summary-text {
              font-size: 14px;
              color: #000;
            }
          }
          .error-message {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 20px;
            background: #3E4550;
            border: 1px solid rgba(151, 167, 183, 0.2);
            box-sizing: border-box;
            border-radius: 4px;
            font-family: Courier;
            font-size: 12px;
            line-height: 14px;
            color: #FFF;
            width: 100%;
            word-break: break-word;
          }
        }
      }

      .no-result {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          height: calc(~"100% - 80px");
          max-height: 180px;
          margin-bottom: 30px;
        }
        .message {
          font-size: 14px;
          line-height: 18px;
          color: @grey-chart;
        }
      }
    }

    .explorer-history {
      width: 100%;

      .no-execution {
        width: 50%;
        margin: auto;
      }

      .queries-history-content {
        .fpui-table {
          height: 100%!important;
          .fpui-table_body {
            height: calc(~"100% - 80px")!important;
            overflow: overlay;
            overflow: auto;
          }
          .fpui-table_row {
            height: 90px;
            overflow: hidden;
            .fpui-table_row-actions {
              padding-right: 35px;
              background: transparent;
              box-shadow: none;
              -webkit-box-shadow: none;
            }
          }
        }
      }
    }
  }
}
</style>
