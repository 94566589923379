<template lang="pug">
  .fp-splash-error
    .background
      img(:src="background")
    .content
      img.logo(:src="logo")
      h1
        i.fp4.fp4-circle-xmark
        span {{ error.message }}
      .buttons-container
        fpui-button(color="white",@click="back") Return
        fpui-button(color="blue-flash",@click="reload") Retry
      pre.stack(v-if="error.stack") {{error.stack}}
</template>

<script>
export default {
  props: {
    error: {
      type: Error,
      default: ''
    }
  },
  computed: {
    background () {
      return require('./background.png')
    },
    logo () {
      return require('@/shared/assets/img/ovhcloud_logo_blue.png')
    }
  },
  methods: {
    back () {
      document.location.href = document.location.origin
    },
    reload () {
      if (this.error.redirect_to) {
        document.location.href = this.error.redirect_to
        return
      }
      document.location.reload()
    }
  }
}
</script>

<style lang="less">
  .fp-splash-error {
    position:fixed;
    display: flex;
    top:0;
    left:0;
    right:0;
    bottom:0;
    .buttons-container {
      display: flex;
      margin-top: 60px;
      .button-container {
        margin-right: 10px;
      }
    }
    .content {
      background:white;
      overflow: auto;
      overflow: overlay;
      position: absolute;
      left: 35%;
      top: 0;
      bottom: 0;
      right: 0;
      padding:40px 70px;
      h1 {
        margin: 35px 0;
        font-size: 40px;
        line-height:60px;
        font-weight: 700;
        color: #F62172;
        > i, > span {
          vertical-align: middle;
          display: inline-block;
        }
      }
      h2 {
        margin-top: 20px;
        line-height: 28px;
        color: #3e4550;
        max-width: 450px;
      }
      pre {
        overflow: auto;
        overflow: overlay;
        background: rgba(0,0,0,0.04);
        border-radius: 8px;
        padding: 20px;
      }
    }
    .background {
      height:100%;
      width:35%;
      position:absolute;
      left:0;
      overflow: hidden;
      img {
        height:100%;
        width:100%;
        object-fit:cover;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: -1px;
        bottom: 0;
        width: 190px;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTM5cHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAxMzkgODAwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1NS4xICg3ODEzNikgLSBodHRwczovL3NrZXRjaGFwcC5jb20gLS0+CiAgICA8dGl0bGU+bG9naW5fc2hhcGU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iREFTSEJPQVJEX0NMSUVOVCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IjA0XzBfZGFzaGJvYXJkX1NLX2xvZ2luLUNvcHkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02MzIuMDAwMDAwLCAwLjAwMDAwMCkiIGZpbGw9IiNGRkZGRkYiPgogICAgICAgICAgICA8ZyBpZD0ibG9naW5fc2hhcGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYzMi4wMDAwMDAsIDAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iUmVjdGFuZ2xlLTkiIG9wYWNpdHk9IjAuOTQxNTQ1NzU5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2OS41MDAwMDAsIDQwMC4wMDAwMDApIHNjYWxlKC0xLCAxKSB0cmFuc2xhdGUoLTY5LjUwMDAwMCwgLTQwMC4wMDAwMDApICIgcG9pbnRzPSIyLjU4NDg3Njc2ZS0xMyAwIDEzOSA4MDAgMCA4MDAiPjwvcG9seWdvbj4KICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJSZWN0YW5nbGUtOSIgb3BhY2l0eT0iMC40MzEwMzYwODYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDY5LjUwMDAwMCwgNDAwLjAwMDAwMCkgc2NhbGUoLTEsIC0xKSB0cmFuc2xhdGUoLTY5LjUwMDAwMCwgLTQwMC4wMDAwMDApICIgcG9pbnRzPSI1LjIxNzk3ODgzZS0xMyAwIDEzOSA4MDAgMCA4MDAiPjwvcG9seWdvbj4KICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoLUNvcHkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEwNC4yNTAwMDAsIDQwMC4wMDAwMDApIHNjYWxlKC0xLCAxKSB0cmFuc2xhdGUoLTEwNC4yNTAwMDAsIC00MDAuMDAwMDAwKSAiIHBvaW50cz0iMTM5IDQwMCA2OS41IDgwMCA2OS41IDAiPjwvcG9seWdvbj4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+');
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: right;
      }
    }
    .logo {
      max-height: 70px;
    }
  }
  @media (max-width: 1279px) {
    .fp-splash-error {
      .background {
        width:35%;
      }
      .content {
        left:35%;
      }
    }
  }
  @media (max-width: 600px) {
    .fp-splash-error {
      .background {
        display:none;
      }
      .content {
        left:0%;
      }
    }
  }
</style>
