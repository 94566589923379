<template lang="pug">
.template-description-modal.fpui-modal
  fpui-modal-header(
    @close="$emit('close')"
    closeTopButton
  )
    .service-icons-container
      .icon-container(
        v-for="service in services"
        v-tooltip="{ content: service.service, delay: 0 }"
      )
        img(:src='service.img')
  fpui-modal-body.no-padding
    section.main-focus
      .title {{ $t(`project.${item.id}.prompt`) }}
      .description(
        v-html="$sanitize($t(`project.${item.id}.long_description`))"
      )
    section.included
      .title {{ $t('home.create_template.whats_included') }}
      .included-boxes
        .service-box(v-for='includedService in includedServices')
          .icon-container
            img.icon(:src='includedService.icon')
          .service-description
            .title {{ includedService.title }}
            .subtitle {{ includedService.subtitle }}
  fpui-modal-footer
    fpui-button(
      color="blue-flash"
      :disabled="disabled"
      v-tooltip="{ content: disabled ? disabledMessage : '', delay: 0 }"
      @click="onConfirmation"
    ) {{ $t('home.create_template.try_it_now') }}
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
    services: { type: Array, required: true },
    onConfirm: { type: Function, required: true },
    disabled: { type: Boolean, default: false }
  },
  computed: {
    includedServices () {
      const alwaysServices = {
        icon: require('@/core/components/Dataplant/assets/included_iam_cc.svg'),
        title: this.$t('home.create_template.included.iam_cc.title'),
        subtitle: this.$t('home.create_template.included.iam_cc.subtitle')
      }
      const moreServices = []
      this.services.forEach(service => {
        if (!['iam', 'control-center'].includes(service.id)) {
          let icon = ''
          try {
            icon = require(`@/core/components/Dataplant/assets/included_${service.id}.svg`)
          } catch (err) {
            icon = null
          }

          const addService = {
            icon,
            title: this.$t(`home.create_template.included.${service.id}.title`),
            subtitle: this.$t(`home.create_template.included.${service.id}.subtitle`)
          }
          moreServices.push(addService)
        }
      })
      return [alwaysServices, ...moreServices]
    },
    disabledMessage () {
      return this.$t('core.onboarding.no_permission_to_create')
    }
  },
  methods: {
    onConfirmation () {
      this.onConfirm()
      this.$emit('close')
    }
  }
}
</script>

<style lang="less">
.template-description-modal {
  &.fpui-modal {
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 55%, rgba(225,251,255,1) 100%);
    &:before {
      position: absolute;
      width: 100%;
      height: 100%;
      content: '';
      background: url('~@/shared/assets/project/array-blocks.svg') no-repeat;
    }
    > .modal-header {
      border-bottom: none !important;
      height: 85px;
      .actions {
        right: 46px;
        top: 26px;
        i {
          font-size: 20px;
        }
      }
      .service-icons-container {
        display: flex;
        justify-content: flex-start;
        padding: 53px 20px 0 20px;
        .icon-container {
          display: flex;
          padding: 4px;
          width: 32px;
          height: 32px;
          border: 1px solid #E4E7EC;
          background-color: @white;
          border-radius: 5px;
          position: relative;
          cursor: pointer;
          align-items: center;
          justify-content: center;
          &:not(:last-child) {
            margin-right: 6px;
          }
          img {
            width: 100%;
            height: auto;
          }
          span {
            font-size: 14px;
            line-height: 18px;
            color: @blue;
          }
        }
      }
    }
    > .modal-body {
      padding: 9px 20px 19px 20px;
      section {
        &.main-focus {
          > .title {
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            color: @text-color;
          }
          > .description {
            padding-top: 13px;
            font-weight: 400;
            font-size: 13px;
            line-height: 15px;
            color: @text-color;
            width: 435px;
          }
        }
        &.included {
          padding-top: 19px;
          > .title {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: @green;
          }
          > .included-boxes {
            display: flex;
            flex-wrap: wrap;
            padding-top: 24px;
            justify-content: flex-start;
            .service-box {
              width: 135px;
              height: 139px;
              border: 1px solid #E4E7EC;
              border-radius: 5px;
              background-color: @white;
              margin-right: 16px;
              margin-bottom: 8px;
              > .icon-container {
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                height: 54px;
                background: linear-gradient(180deg, rgba(118, 230, 255, 0.49) 0%, rgba(118, 230, 255, 0) 100%);
              }
              > .service-description {
                > .title {
                  padding: 6px;
                  font-size: 14px;
                  line-height: 18px;
                  display: flex;
                  align-items: center;
                  letter-spacing: -0.01em;
                  color: @text-color;
                }
                > .subtitle {
                  padding: 10px 6px 0 6px;
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 15px;
                  color: @grey;
                }
              }
            }
          }
        }
      }
    }
    > .modal-footer-container {
        border-top: none;
      > .modal-footer {
        padding: 0 20px 20px 20px;
        display: block;
        button {
          width: 100%;
        }
      }
    }
  }
}
</style>
