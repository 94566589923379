import moment from 'moment'
import 'moment-timezone'

import FpDimension from './FpDimension'

const FpDimensions = function (dimensions) {
  const inst = new Array() // eslint-disable-line no-array-constructor
  inst.__proto__ = FpDimensions.prototype // eslint-disable-line no-proto
  if (!Array.isArray(dimensions)) dimensions = []
  Object.assign(inst, dimensions.map((dimension) => {
    return new FpDimension(dimension)
  }))
  return inst
}

FpDimensions.prototype = Object.create(Array.prototype)
FpDimensions.prototype.getDate = function (scales) {
  let date = moment(0)
  this.forEach((dimension) => {
    date = dimension.setToDate(date, scales[dimension])
  })
  return new Date(date.valueOf())
}
FpDimensions.prototype.isTemporal = function () {
  return !this.filter((dimension) => !dimension.isTemporal()).length
}

FpDimensions.prototype.format = function (result) {
  if (this.isTemporal()) {
    return this.getDate(result.scales)
  }
  let label = ''
  for (const key in result.scales) {
    const value = result.scales[key]
    if (label) label += ' '
    label += key + '-' + value
  }
  return label
}

FpDimensions.prototype.formatFromTimestamp = function (value) {
  const ret = []
  const tmp = this.map(elem => elem.formatFromTimestamp(value))
  tmp.forEach(elem => {
    if (ret.indexOf(elem) === -1) {
      ret.push(elem)
    }
  })
  return ret.join(' ')
}

export default FpDimensions
