<template lang='pug'>
  .fpui-step(v-if='isActive')
    slot
</template>

<script>

export default {
  props: {
    disabled: { type: [Function, Boolean], default: false }, // A bindable boolean/function to disable a step
    validate: { type: [Function, Boolean], default: true }, // A bindable boolean/function to validate a step to go to next
    header: { type: String, default: null }, // A string field, not optional if :headers is set to true in parent fpui-steps
    // Optional events
    onNext: { type: Function, default: null },
    onPrevious: { type: Function, default: null },
    onLeave: { type: Function, default: null },
    tooltipNext: { type: String, default: null }
  },
  computed: {
    isActive () {
      return this.$parent.isActive(this.$vnode)
    }
  },
  mounted () {
    this.$parent.updateSlots()
  },
  destroyed () {
    this.$parent.updateSlots()
  }
}
</script>
