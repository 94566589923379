import Vue from 'vue'
import FpuiModal from './Modal'
import FpuiModalConfirm from './ModalConfirm'
import FpuiModalBody from './ModalBody'
import FpuiModalHeader from './ModalHeader'
import FpuiModalFooter from './ModalFooter'
import FpuiModalMessage from './ModalMessage'
import Confirm from './Confirm'
import FpuiModalSearch from './ModalSearch'

export { FpuiModal, FpuiModalBody, FpuiModalHeader, FpuiModalFooter, FpuiModalConfirm, Confirm, FpuiModalMessage, FpuiModalSearch }
export default { FpuiModal, FpuiModalBody, FpuiModalHeader, FpuiModalConfirm, Confirm, FpuiModalMessage, FpuiModalSearch }

Vue.component('FpuiModal', FpuiModal)
Vue.component('FpuiModalSearch', FpuiModalSearch)
Vue.component('FpuiModalHeader', FpuiModalHeader)
Vue.component('FpuiModalBody', FpuiModalBody)
Vue.component('FpuiModalFooter', FpuiModalFooter)
