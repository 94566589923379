<template lang="pug">
.fpui-date-picker(@click="$event.stopPropagation()")
  .closed(
    v-if="!noButton && !opened && !customButton"
    @click="open"
  )
    i.fp4.fp4-clock
    .date-container
      span.date {{ valueToDisplay }}
    i.fp4.fp4-chevron-down

  .closed(
    v-if="!noButton && !opened && customButton"
    @click="open"
  )
    slot

  fpui-button.refresh(
    color="white"
    v-if="!hideRefresh"
    :disabled="disableRefresh"
    v-tooltip="$t('logs_explorer.refresh')"
    icon-center
    noshadow
    @click="refresh"
  )
    i.fp4.fp4-arrows-rotate

  .opened(
    v-if="opened"
    :style="{ right: openedRight !== null ? openedRight : 0 }"
  )
    slot(
      name="header"
    )
    .date-picker-container
      .short-cuts(v-if="!noShortcuts")
        .short-cut(
          v-for="shortcut in shortcuts"
          v-if="!shortcut.hide"
          :class="{ active: shortcut.key === date.shortcut }"
          @click="selectShortcut(shortcut)"
        )
          span {{ shortcut.label }}
      .date-picker
        date-picker(
          v-model="date"
          format="YYYY-MM-DD"
          persistent
          no-header
          no-label
          inline
          range
          no-shortcuts
          no-keyboard
          :locale="lang"
          :max-date="maxDate"
        )
        .add-time-button(
          v-if="displaySwitchTime && !displayTimeVal"
        )
          fpui-button(
            color="grey"
            reverse
            icon-left
            noshadow
            unbordered
            auto-width
            icon="fp4 fp4-plus"
            @click="displayTimeFunc"
          ) {{ $t('datepicker.add_a_time') }}
        .add-time-button(
          v-if="displaySwitchTime && displayTimeVal"
        )
          fpui-button(
            color="grey"
            reverse
            icon-left
            noshadow
            unbordered
            auto-width
            icon="fp4 fp4-xmark"
            @click="hideTimeFunc"
          ) {{ $t('datepicker.remove_a_time') }}
      .time-selection(
        v-if="displayTime"
      )
        .date-selected
          span {{ valueToDisplay }}
        .from-utc-container
          .from-container(
            :style="{ width: hasUTC ? '110px' : '100%' }"
          )
            .label {{ $t('datepicker.from') }} {{ displayFrom }}
            fpui-input-text(
              :value="from"
              type="time"
              step="1"
              @input="updateFrom"
            )
          fpui-input-select.utc(
            v-if="hasUTC"
            v-model="utcSelection"
            :options="utcOptions"
            :clearable="false"
            custom-value-format="labelInfo"
          )
        .to-utc-container(
          v-if="!shortcutsByKey[date.shortcut].noRange"
        )
          .to-container(
            :style="{ width: hasUTC ? '110px' : '100%' }"
          )
            .label {{ $t('datepicker.to') }} {{ displayTo }}
            fpui-input-text(
              :value="to"
              type="time"
              step="1"
              @input="updateTo"
            )
          .utc-info(v-if="hasUTC") {{ utcSelectionLabel }}
        .error-from-to(v-if="errorFromTo")
          span {{ $t('datepicker.error.from_to') }}
    .footer
      .date-details(
        v-if="displayDateFooter"
      )
        .from(
          v-if="date.shortcut !== 'after'"
          v-html="$sanitize(`<span>${$t('datepicker.from')}</span><strong>${displayFrom}</strong><span>${hasTime ? from : ''}</span>`)"
        )
        .to(
          v-if="date.shortcut !== 'after'"
          v-html="$sanitize(`<span>${$t('datepicker.to')}</span><strong>${displayTo}</strong><span>${hasTime ? to : ''}</span>`)"
        )
        .after(
          v-if="date.shortcut === 'after'"
          v-html="$sanitize(`<span>${$t('datepicker.after')}</span><strong>${displayFrom}</strong><span>${hasTime ? from : ''}</span>`)"
        )
      .right
        fpui-button(
          color="white"
          auto-width
          @click="close(null)"
        ) {{ $t('button.cancel') }}
        fpui-button(
          color="blue-flash"
          auto-width
          :disabled="!date.start || errorFromTo"
          @click="setDateSelected"
        ) {{ $t('button.done') }}
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import _uniqBy from 'lodash/uniqBy'
import _keyBy from 'lodash/keyBy'
import moment from 'moment'
import { utcOptions, getUtcUser, shortcutsDefault } from './utils'

export default {
  props: {
    value: { type: Object, default: () => {} },
    noShortcuts: { type: Boolean, default: false },
    customShortcut: { type: Array, default: () => [] },
    hideRefresh: { type: Boolean, default: false },
    disableRefresh: { type: Boolean, default: false },
    refreshAction: { type: Function, default: null },
    customButton: { type: Boolean, default: false },
    noButton: { type: Boolean, default: false },
    hasTime: { type: Boolean, default: true },
    hasUTC: { type: Boolean, default: true },
    openedRight: { type: String, default: null },
    displayDateFooter: { type: Boolean, default: false },
    displaySwitchTime: { type: Boolean, default: false },
    timestamp: { type: Boolean, default: true },
    fromComponent: { type: String, default: 'cc' },
    onClose: { type: Function, default: null }
  },
  data () {
    return {
      date: {
        start: null,
        end: null,
        shortcut: null
      },
      from: '00:00:00',
      to: '23:59:59',
      utcSelection: 0,
      opened: false,
      utcOptions,
      displayTimeVal: false
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale()
    },
    maxDate () {
      return moment().format('YYYY-MM-DD HH:mm:ss')
    },
    shortcuts () {
      const shortcuts = [...this.customShortcut, ...shortcutsDefault]
        .map(sc => {
          sc.label = this.$t(`datepicker.${sc.key}`)
          return sc
        })

      return _uniqBy(shortcuts, 'key')
    },
    shortcutsByKey () {
      return _keyBy(this.shortcuts, 'key')
    },
    valueToDisplay () {
      if ((this.date?.shortcut && this.opened) || (this.date?.shortcut && !this.opened && this.date?.shortcut !== 'custom')) return this.$t(`datepicker.${this.date.shortcut}`)
      else if (this.date?.start) {
        const start = moment(this.date.start).format('Do MMMM YYYY')
        const end = moment(this.date.end).format('Do MMMM YYYY')
        if (!this.date.end || start === end) return start
        return `${start} - ${end}`
      } else return this.$t('datepicker.select_date')
    },
    displayTime () {
      if (this.fromComponent === 'cc') return true
      return this.hasTime && this.displayTimeVal
    },
    displayFrom () {
      if (this.date?.start) return moment(this.date.start).format('Do MMMM YYYY')
      return ''
    },
    displayTo () {
      if (this.date?.end) return moment(this.date.end).format('Do MMMM YYYY')
      if (this.date?.start) return moment(this.date.start).format('Do MMMM YYYY')
      return ''
    },
    utcSelectionLabel () {
      return utcOptions.find(utc => utc.value === this.utcSelection)?.labelInfo
    },
    errorFromTo () {
      if (this.date?.start && !this.date?.end && this.from > this.to) return true
      if (this.date?.start && this.date?.end && moment(this.date?.start).isSame(moment(this.date?.end), 'day') && this.from > this.to) return true
      return false
    }
  },
  watch: {
    date: function (val, oldVal) {
      // If noRange -> Set only start of date
      if (this.shortcutsByKey[val.shortcut]?.noRange) {
        if (!_isEqual(val, oldVal)) this.date = Object.assign({}, this.date, { start: val.end || val.start, end: null })
      } else {
        // If edit manually and shortcut do not tell us to not do it -> shortcut = custom
        const preventCustomOnEdit = !val.shortcut && oldVal.shortcut && this.shortcutsByKey[oldVal.shortcut]?.isCustom

        if (preventCustomOnEdit) this.date = Object.assign({}, this.date, { shortcut: oldVal.shortcut })
        if (val && !val.shortcut && !preventCustomOnEdit) this.date = Object.assign({}, this.date, { shortcut: 'custom' })
      }
    }
  },
  async mounted () {
    this.initDate()
    window.addEventListener('click', this.close)
  },
  destroyed () {
    window.removeEventListener('click', this.close)
  },
  methods: {
    isParent (target) {
      if (target === this.$el) return true
      if (target.parentNode) return this.isParent(target.parentNode)
      return false
    },
    initDate () {
      // Init this.date
      const shortcut = this.shortcuts.find(sc => sc.key === this.value?.shortcut)
      const isShortcutNotCustom = shortcut && !shortcut.isCustom
      if (isShortcutNotCustom) {
        this.selectShortcut(this.shortcuts.find(sc => sc.key === this.value.shortcut))
      } else {
        const date = _cloneDeep(this.value)

        if (date.start && ((this.timestamp && moment(parseInt(date.start)).isValid()) || (!this.timestamp && moment(date.start).isValid()))) {
          const start = this.timestamp ? moment(parseInt(date.start)) : moment(date.start)
          const end = this.timestamp ? moment(parseInt(date.end)) : moment(date.end)

          date.start = start.isValid() ? start.format('YYYY-MM-DD') : null
          date.end = end.isValid() ? end.format('YYYY-MM-DD') : null
          this.from = start.format('HH:mm:ss')
          this.to = end.format('HH:mm:ss')
        }

        this.date = date
      }

      // We use another variable for utc because the lib for datepicker will erase this value on click
      this.utcSelection = this.value.utc ?? getUtcUser()
    },
    close ($event, restore = true) {
      if ($event && this.isParent($event.target)) return
      if (restore) this.initDate()
      if (!$event && this.onClose) this.onClose()
      this.opened = false
    },
    open () {
      // If shortcut includes 'last' we set 'to' to now
      const shortcut = this.shortcuts.find(sc => sc.key === this.value?.shortcut)
      const isShortcutNotCustom = shortcut && !shortcut.isCustom
      if (isShortcutNotCustom) this.selectShortcut(shortcut)

      if (this.hasTime && (this.from !== '00:00:00' || this.to !== '23:59:59')) this.displayTimeVal = true // For cc it is always true -> see computed displayTime

      this.opened = true
    },
    setDateSelected () {
      const newDate = _cloneDeep(this.date)
      newDate.start = moment(`${moment(newDate.start).format('YYYY-MM-DD')} ${this.from}`)

      if (!newDate.end) newDate.end = moment(`${moment(newDate.start).format('YYYY-MM-DD')} ${this.to}`)
      else newDate.end = moment(`${moment(newDate.end).format('YYYY-MM-DD')} ${this.to}`)

      newDate.utc = this.utcSelection

      this.$emit('input', newDate)
      this.close(null, false)
    },
    selectShortcut (shortcut) {
      const start = shortcut.value.start()
      const end = shortcut.value.end()
      this.date = Object.assign({}, this.date, { start, end, shortcut: shortcut.key })

      if (this.date.start) {
        const from = moment(this.date.start).format('HH:mm:ss')
        this.updateFrom(from, false)
      }

      if (this.date.end) {
        const to = moment(this.date.end).format('HH:mm:ss')
        this.updateTo(to, false)
      }
    },
    displayTimeFunc () {
      this.displayTimeVal = true
    },
    hideTimeFunc () {
      this.displayTimeVal = false
      this.updateFrom('00:00:00', false)
      this.updateTo('23:59:59', false)
    },
    updateFrom (value, shortcut = true) {
      this.from = value
      if (shortcut) this.date = Object.assign({}, this.date, { shortcut: 'custom' })
    },
    updateTo (value, shortcut = true) {
      this.to = value
      if (shortcut) this.date = Object.assign({}, this.date, { shortcut: 'custom' })
    },
    refresh () {
      if (this.refreshAction) this.refreshAction()
      else {
        this.initDate()
        this.setDateSelected()
        this.$emit('refresh')
      }
    }
  }
}
</script>

<style lang='less'>
// Style from lib and in datepicker.less

.fpui-date-picker {
  position: relative;
  display: flex;
  align-items: center;
  .closed {
    cursor: pointer;
    border-radius: 5px;
    line-height: 34px;
    height: 26px;
    position: relative;
    max-width: 300px;
    display: flex;
    align-items: center;
    border: 1px solid #CBD3DB;
    padding-left: 7px;
    .fp4-clock {
      font-size: 17px;
      color: #748294;
      line-height: 34px;
      margin-right: 6px;
    }
    .date-container {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      .date {
        max-width: 180px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-weight: 600;
        font-size: 13px;
        color: #748294;
        text-transform: uppercase;
      }
    }
    .fp4-chevron-down {
      float: right;
      line-height: 34px;
      padding: 0 10px;
      font-weight: 600;
      font-size: 12px;
      color: #748294;
    }
    &:hover {
      background-color: darken(@white, 2.5%) !important;
    }
    &:active {
      background-color: darken(@white, 10%) !important;
    }
  }

  .refresh {
    .fpui-button {
      height: 26px;
      width: 26px;
      margin-left: 8px;
      padding: 2px 3px;
      align-items: flex-start;
      border: 1px solid @grey_helper !important;
      i {
        font-size: 20px !important;

        &.fp4-arrows-rotate {
          line-height: 20px;
        }
      }
    }
  }

  .opened {
    position: absolute;
    top: -12px;
    right: 0;
    border: 0px;
    z-index: 99;
    background: #FFFFFF;
    box-shadow: 0px 3px 13px rgba(151, 167, 183, 0.3);
    border-radius: 10px;

    .date-picker-container {
      height: 305px;
      display: flex;
      width: 100%;

      .short-cuts {
        width: 194px;
        height: 100%;
        overflow: auto;
        padding: 20px 35px 20px 20px;

        .short-cut {
          padding: 7px 10px;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #3E4550;
          cursor: pointer;
          margin-top: 1.5px;
          margin-bottom: 1.5px;

          &:hover {
            background: #F5F6F8;
            border-radius: 4px;
          }
          &.active {
            color: #FFFFFF;
            background: #00CCF9;
            border-radius: 4px;
          }
        }
      }

      .date-picker {
        width: 310px;
        height: 100%;
        padding: 20px 35px;
        position: relative;

        &:before {
          position: absolute;
          left: 0;
          top: 20px;
          content: "";
          height: 253px;
          width: 1px;
          background: #E9ECF0;
        }
        &:after {
          position: absolute;
          right: 0;
          top: 20px;
          content: "";
          height: 253px;
          width: 1px;
          background: #E9ECF0;
        }

        .date-time-picker {
          z-index: 1;
        }

        .add-time-button {
          position: absolute;
          right: 25px;
          bottom: 10px;
          z-index: 2;
        }
      }

      .time-selection {
        width: 291px;
        height: 100%;
        padding: 20px 20px 20px 35px;

        .date-selected {
          font-weight: 400;
          font-size: 18px;
          line-height: 23px;
          letter-spacing: -0.01em;
          color: #3E4550;
        }

        .from-utc-container, .to-utc-container {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-top: 25px;
          .from-container, .to-container {
            margin-right: 10px;
            width: 110px;
            .label {
              position: absolute;
            }
            .fpui-input-text {
              margin-top: 20px;
            }
          }
          .fpui-input-select-container {
            width: 110px;
            &.utc {
              width: 116px;
            }
            .select-label {
              color: #3E4550;
            }
            .dropdown {
              left: -125px;
            }
          }
          .utc-info {
            width: 118px;
            font-weight: 400;
            font-size: 14px;
            line-height: 34px;
            padding-left: 11px;
            color: #CBD3DB;
          }
        }

        .error-from-to {
          margin-top: 20px;
          color: #F62172;
          font-weight: 600;
          font-size: 12px;
        }
      }
    }

    .footer {
      display: flex;
      flex: 0 1 100%;
      align-self: auto;
      justify-content: space-between;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-content: space-between;
      align-items: center;
      height: 74px;
      border-top: 1px solid #E9ECF0;
      padding-right: 40px;

      .date-details {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #97A7B7;
        width: 100%;
        margin-left: 40px;

        strong {
          margin-left: 3px;
          margin-right: 3px;
        }
      }

      .right {
        display: flex;
        flex: 0 1 100%;
        align-self: auto;
        justify-content: flex-end;
        .button-container:not(:first-child) {
          margin-left: 13px;
        }
      }
    }
  }
}
</style>
