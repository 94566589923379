import Vue from 'vue'
import Sortable from 'sortablejs'
Vue.directive('sortable', {
  bind (el, { value }) {
    Sortable.create(el, value)
  },
  update (el, { value }) {
    Sortable.create(el, value)
  }
})
