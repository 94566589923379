import moment from 'moment'

export const shortcutsDatePicker = [
  {
    key: 'today',
    value: { start: () => moment().startOf('day'), end: () => moment().endOf('day') }
  },
  { key: 'yesterday', hide: true },
  { key: 'last1Hour', hide: true },
  { key: 'last6Hours', hide: true },
  { key: 'last24Hours', hide: true },
  {
    key: 'last7Days',
    value: { start: () => moment().subtract(7, 'days').startOf('day'), end: () => moment().endOf('day') }
  },
  {
    key: 'last30Days',
    value: { start: () => moment().subtract(30, 'days').startOf('day'), end: () => moment().endOf('day') }
  },
  {
    key: 'last3Months',
    value: { start: () => moment().subtract(3, 'months').startOf('day'), end: () => moment().endOf('day') }
  },
  {
    key: 'last6Months',
    value: { start: () => moment().subtract(6, 'months').startOf('day'), end: () => moment().endOf('day') }
  },
  {
    key: 'last12Months',
    value: { start: () => moment().subtract(12, 'months').startOf('day'), end: () => moment().endOf('day') }
  },
  // {
  //   key: 'custom_relative',
  //   value: { start: () => null, end: () => null }
  // },
  {
    key: 'after',
    value: { start: () => moment().startOf('day'), end: () => null },
    isCustom: true, // isCustom is used to not update date if click on value and to not reset value when open datepicker
    noRange: true
  },
  {
    key: 'weekToDate',
    value: { start: () => moment().startOf('isoWeek').startOf('day'), end: () => moment().endOf('day') }
  },
  {
    key: 'monthToDate',
    value: { start: () => moment().startOf('month').startOf('day'), end: () => moment().endOf('day') }
  },
  {
    key: 'quarterToDate',
    value: { start: () => moment().startOf('quarter').startOf('day'), end: () => moment().endOf('day') }
  },
  {
    key: 'yearToDate',
    value: { start: () => moment().startOf('year').startOf('day'), end: () => moment().endOf('day') }
  },
  {
    key: 'custom',
    value: { start: () => moment().startOf('day'), end: () => moment().endOf('day') },
    isCustom: true // isCustom is used to not update date if click on value and to not reset value when open datepicker
  }
]

export const getCustomRelative = (label) => {
  if (!label.includes('last')) return null
}

export default shortcutsDatePicker
