<template lang="pug">
  .fpui-title-editor
    .title
      .title-container
        .details
          .base-title-container
            .base-title
              span.ss(
                v-if="!disableEdit && context"
                ref="input"
                v-edit-context-content
                @update="(value) => $emit('input', value)"
              ) {{value}}
              span(
                v-if="!disableEdit && !context"
                ref="input"
                v-edit-content
                @update="(value) => $emit('input', value)"
              ) {{value}}
              span(
                v-if="disableEdit"
                @update="(value) => $emit('input', value)"
              ) {{value}}
              span.opacity0 .
      slot
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    disableEdit: {
      type: Boolean,
      default: false
    },
    context: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  mounted () {
    if (this.$refs.input && !this.disableEdit && this.id === this.$route.params.id && this.context) this.$refs.input.click()
  }
}
</script>

<style lang="less">
  .fpui-title-editor {
    height: 30px;
    .title {

      > .title-container {
        user-select: none;
        align-items: flex-start;
        flex-wrap: no-wrap;
        flex-basis: 100%;
        display:flex;
        flex-shrink: 1;
        span {
          font-size: 14px;
          white-space: nowrap;
        }
        .details {
          position: relative;
          overflow: hidden;
          .border-edit {
            position:absolute;
            left: 0;
            right: 0;
            top: 58px;
            height: 1px;
            background-color: #CBD3DB;
          }
          .base-title-container {
            flex-grow: 1;
            display: block;
            overflow: hidden;
            .base-title {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              display: flex;
              &:focus {
                text-overflow: initial;
                overflow: visible;
              }
              span:focus {
                outline:none;
              }
              .opacity0 {
                opacity: 0;
              }
            }
          }
        }
        i {
          margin-top: 33px;
        }
      }
    }
  }
</style>
