<template lang="pug">
  form.login
    img.logo(:src="logo")
    h1 {{ title }}
    p {{ message }}
    .inner-content.login
      .auth_modes(v-if="auth_modes.length > 1")
        .auth_mode(@click="openAuthMode(auth_mode)",v-for="auth_mode in auth_modes",:style="authModeStyle(auth_mode)")
          img.icon(:src="auth_mode.icon")
          .name {{auth_mode.name}}

</template>

<script>
export default {
  computed: {
    auth_modes () {
      const authModes = this.$store.getters.PREFERENCES.auth_mode.filter(authMode => {
        return authMode.visible && !authMode.hidden
      })
      return authModes
    },
    logo () {
      return this.$store.getters.PREFERENCES?.logo || ''
    },
    title () {
      return this.$store.getters.PREFERENCES.title || this.$store.getters.PREFERENCES.name
    },
    message () {
      return this.$store.getters.PREFERENCES.message || this.$store.getters.PREFERENCES.description
    }
  },
  async mounted () {
    await this.$store.dispatch('REFRESH_PREFERENCES')
    if (this.auth_modes.length === 1) return this.openAuthMode(this.auth_modes[0])
    if (this.$route.query.authentication_provider) {
      const authMode = this.auth_modes.find(a => {
        return a.type === this.$route.query.authentication_provider
      })
      if (authMode) return this.openAuthMode(authMode)
      if (this.$route.query.authentication_provider === 'login_password') {
        return this.openAuthMode({
          type: 'login_password'
        })
      }
    }
  },
  methods: {
    authModeStyle (authMode) {
      return {
        background: authMode.background,
        color: authMode.color
      }
    },
    openAuthMode (authMode) {
      if (authMode.type === 'login_password') return this.$emit('select', authMode)
      return this.$api.IAM.loginRedirect(authMode, this.$route.query.force_auth)
    }
  }
}
</script>

<style lang="less">
  .auth_modes .auth_mode {
    margin:15px 0;
    width:250px;
    padding: 10px 0;
    display:flex;
    align-items:center;
    cursor:pointer;
    border-radius:4px;
    .icon {
      margin-right:15px;
      max-width:40px;
      max-height:40px;
    }
  }
</style>
