import { API } from '@/shared/plugins/Api/API'

class Quota extends API {
  constructor (options) {
    super('ML')
  }
}

class Quotas extends API {
  list () {
    return this.request({
      method: 'get',
      url: 'v2/configurations/quotas'
    })
      .then(quotas => {
        return quotas
      })
  }
}

export default Quotas

export {
  Quotas,
  Quota
}
