/* istanbul ignore next */
const later = (ms, fn) => {
  return new Promise((resolve, reject) => {
    setTimeout(async _ => {
      try {
        await fn()
      } catch (err) {
        reject(err)
      }
    }, ms)
  })
}

module.exports = later
