/*
Exemple format : {
  {
    ca: "desc",
    qte: "asc"
  }
}

Exemple format : {
  [
    ["ca","desc"],
    "qte"
  ]
}
*/

class Order {
  constructor (value) {
    this.orders = value.orders || {}
    this.data = value.data || {}
    this._ = this.parse(this.orders)
  }

  parse (orders) {
    if (Array.isArray(orders)) {
      return orders.map(this.parseValue)
    }
    const res = []
    for (const key in orders) {
      res.push([key, orders[key]])
    }
    return res
  }

  parseValue (order) {
    return Array.isArray(order) ? order : [order, 'asc']
  }

  toJSON () {
    return this._
  }

  get fields () {
    return this._.map(e => e[0])
  }

  formatOrderData (o) {
    // If attribute is in data, we need to add compute in ORDER
    const orderAttribute = o[0]
    const orderValue = o[1].toUpperCase()
    if (!Object.keys(this.data.fields).includes(orderAttribute)) return `${orderAttribute} ${orderValue}`
    return `${
      this.data.fields[orderAttribute].map(operator => {
        if (operator === 'select') {
          return `${orderAttribute} ${orderValue}`
        }
        if (operator === 'select_distinct') {
          return `${orderAttribute} ${orderValue}`
        }
        if (operator === 'sum') {
          return `SUM(${orderAttribute}) ${orderValue}`
        }
        if (operator === 'avg') {
          return `AVG(${orderAttribute}) ${orderValue}`
        }
        if (operator === 'min') {
          return `MIN(${orderAttribute}) ${orderValue}`
        }
        if (operator === 'max') {
          return `MAX(${orderAttribute}) ${orderValue}`
        }
        if (operator === 'count') {
          return `COUNT(${orderAttribute}) ${orderValue}`
        }
        if (operator === 'count_distinct') {
          return `COUNT(DISTINCT ${orderAttribute}) ${orderValue}`
        }
        throw new Error(`${operator} is not a valid operator`)
      }).join(',\n  ')
    }`
  }

  toSQL () {
    if (!this._.length) return null
    return `ORDER BY\n  ${this._.map(o => `${this.formatOrderData(o)}`).join(',\n  ')}`
  }
}

module.exports = Order
