import Vue from 'vue'
import _get from 'lodash/get'

export default {
  state: {
    users: [],
    serviceAccounts: []
  },
  mutations: {
    SET_IAM_USERS (state, users) { // Not WS
      state.users = users
    },
    SET_IAM_SERVICE_ACCOUNTS (state, serviceAccounts) { // Not WS
      state.serviceAccounts = serviceAccounts
    }
  },
  actions: {
    async REFRESH_IAM_USERS ({ commit }) {
      const users = await Vue.api.IAM.users.names()
      // @LEGACY_CAM commit(..., users)
      commit('SET_IAM_USERS', Array.isArray(users) ? users : users.users)
    },
    async REFRESH_SERVICE_ACCOUNTS ({ commit }) {
      const serviceAccounts = await Vue.api.IAM.service_accounts.impersonate()
      commit('SET_IAM_SERVICE_ACCOUNTS', serviceAccounts)
    }
  },
  getters: {
    IAM_USER_BY_UID (state) {
      return (uid, field = '', fallback) => {
        const user = state.users.find(user => user._id === uid)
        if (!user) return fallback ? uid : null
        if (!field) return user
        return _get(user, field)
      }
    },
    IAM_USERS (state) {
      return state.users
    },
    IAM_SERVICE_ACCOUNTS (state) {
      return state.serviceAccounts
    },
    IAM_HAS_ACCESS_TO_SERVICE_ACCOUNT (state) {
      return (serviceAccountName) => {
        return !!state.serviceAccounts.find(s => s.name === serviceAccountName)
      }
    }
  }
}
