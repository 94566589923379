import { API } from '@/shared/plugins/Api/API'
import Organizations from './Organizations'

class ORGANIZATION extends API {
  constructor (id) {
    super(id, true, true)
    this.organizations = new Organizations(id)
  }
}

export default ORGANIZATION
