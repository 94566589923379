<template lang="pug">
  .home
</template>

<script>
import Config from '@/shared/Config'
export default {
  async mounted () {
    const config = await Config()
    if (config.PROJECT_ID) this.$router.push(`/${config.PROJECT_ID}/`)
    else document.location.href = config.FPUI
  }
}
</script>

<style lang="less">

</style>
