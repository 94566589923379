import Vue from 'vue'
import moment from 'moment'

const durationLetter = function (durationInSecond, format, displayInDays) {
  if (isNaN(durationInSecond) || !durationInSecond) return '0'
  const seconds = (durationInSecond % 60)
  let minutes = Math.floor(durationInSecond / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  minutes %= 60
  if (format) {
    if (hours > 9) return `<span>${String(hours).padStart(2, '0')}h</span>`
    if (hours) return `<span class="duration-letter-hours-hm">${String(hours).padStart(2, '0')}h</span> <span class="duration-letter-minutes-hm">${String(minutes).padStart(2, '0')}m</span>`
    if (minutes) return `<span class="duration-letter-minutes-ms">${String(minutes).padStart(2, '0')}m</span> <span class="duration-letter-seconds-ms">${String(seconds.toFixed(0)).padStart(2, '0')}s</span>`
    return `<span>${String(seconds.toFixed(2)).padStart(2, '0')}s</span>`
  }
  if (displayInDays && days) return moment().subtract(durationInSecond, 'seconds').fromNow(true)
  if (hours > 9) return `${String(hours).padStart(2, '0')}h`
  if (hours) return `${String(hours).padStart(2, '0')}h ${String(minutes).padStart(2, '0')}m`
  if (minutes) return `${String(minutes).padStart(2, '0')}m ${String(seconds.toFixed(0)).padStart(2, '0')}s`
  return `${String(seconds.toFixed(2)).padStart(2, '0')}s`
}

export default durationLetter
Vue.filter('durationLetter', durationLetter)
