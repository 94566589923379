import Vue from 'vue'

const Bytes = function (bytes, precision, override) {
  const units = [
    Vue.i18n.translate('units.data.b'),
    Vue.i18n.translate('units.data.kb'),
    Vue.i18n.translate('units.data.mb'),
    Vue.i18n.translate('units.data.gb'),
    Vue.i18n.translate('units.data.tb'),
    Vue.i18n.translate('units.data.pb')
  ]
  if (!bytes) {
    return `0 ${units[0]}`
  }
  if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-'
  if (typeof precision === 'undefined') precision = 1
  const number = Math.floor(Math.log(bytes) / Math.log(1024))
  const value = (bytes / Math.pow(1024, Math.floor(number)))
  override = (typeof override !== 'undefined' && (override - 1)) || 0
  const max = Math.pow(10, override)
  precision = precision || 0
  const digitNbr = precision ? value.toFixed(precision).length - 1 : value.toFixed(precision).length

  if (override && precision && (digitNbr > max.toString().length)) {
    precision = precision - (digitNbr - max.toString().length)
  }
  const unit = units[number]
  return `${value.toFixed(precision)} ${unit}`
}

export default Bytes
Vue.filter('Bytes', Bytes)
