<template lang='pug'>
.fpui-input-text(:class='themeClass', @keyup='onKeyUp')
  fpui-input-label(
    :helper="helper"
    :helper-title="helperTitle"
    :required="required"
    v-if="label || $scopedSlots.label"
  )
    span(v-if="!$scopedSlots.label") {{ $t(label) }}
    slot(
      v-if="$scopedSlots.label"
      name="label"
    )
  .fpui-input-text-content
    .fpui-input-text-error(v-if="!value && requiredError") {{ requiredError | translate }}
    .fpui-input-text-error(v-show="error",v-tooltip="errorTooltip") {{ $t(errorMessage) }}

    .fpui-input-text-with-prefix-suffix(v-if="prefix || suffix")
      input(
        ref='input'
        :min="min"
        :max="max"
        autocomplete="off"
        oninput="validity.valid||(value='')"
        :name='name',
        :placeholder="placeholder | translate",
        :type="type",
        :pattern="pattern"
        :step="step"
        :id="id",
        v-model='model',
        :class="className",
        :disabled="disabled",
        @focus='onFocus',
        @focusout='onFocusOut',
        @keyup="$emit('keyup')"
        @click="$emit('click', $event)"
        @blur="$emit('blur', $event)"
      )
      .fpui-input-text-content-prefix(v-if="prefix") {{ prefix }}
      .fpui-input-text-content-suffix(v-if="suffix") {{ suffix }}
    input(
      v-else
      ref='input'
      :min="min"
      :max="max"
      autocomplete="off"
      oninput="validity.valid||(value='')"
      :name='name',
      :placeholder="placeholder | translate",
      :type="type",
      :pattern="pattern"
      :step="step"
      :id="id",
      v-model='model',
      :class="className",
      :disabled="disabled",
      @focus='onFocus',
      @focusout='onFocusOut',
      @keyup="$emit('keyup')"
      @click="$emit('click', $event)"
      @blur="$emit('blur', $event)"
    )
    span.optional(v-if="optional")
      i {{ $t('fpui-input.optional')}}
    span.copy(v-if="copy",@click="copyAction")
      i.fp4.fp4-copy
    ul.autocomplete(
      v-if='autocomplete && inAutocomplete.length > 0'
      :class='{ "autocomplete-top": autocompletePosition === "top", "hide-autocomplete": hideAutocomplete }'
    )
      li(
        v-for='(auto, index) in inAutocomplete'
        :class='{selected: auto.active}',
        @click="onAutocomplete(auto.value)"
      ) {{ auto.label || auto.value }}
    i.clear-input.fpui.fpui-remove(
      @click="model = ''"
      v-if='clearable && autocomplete && value && value.length > 0 && !disabled')
</template>

<script>

export default {
  props: {
    id: {
      type: String,
      required: false,
      default () { return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10) }
    },
    name: { type: String, default: '' },
    value: { type: [String, Number, Array], default: '' },
    color: { type: String, default: 'blue' },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' },
    errorTooltip: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    autocomplete: { type: Array, default: () => [] },
    autocompletePosition: { type: String, default: '' },
    pattern: { type: RegExp, default: null },
    type: { type: String, default: 'text' },
    step: { type: String, default: 'text' },
    autofocus: { type: Boolean, default: false },
    selectAllOnFocus: { type: Boolean, default: false },
    label: { type: String, default: '' },
    requiredError: { type: String, default: '' },
    required: { type: Boolean, default: false },
    helper: { type: String, default: '' },
    helperTitle: { type: String, default: '' },
    copy: { type: Boolean },
    clearable: { type: Boolean, default: true },
    theme: { type: String, default: 'v4' },
    optional: { type: Boolean, default: false },
    min: { type: Number, default: null },
    max: { type: Number, default: null },
    prefix: { type: String, default: null },
    suffix: { type: String, default: null }
  },
  data () {
    return {
      inAutocomplete: [],
      focused: false,
      hideAutocomplete: true
    }
  },
  computed: {
    model: {
      get () { return this.value },
      set (val) {
        if (this.autocomplete) {
          this.updateAutocomplete(val)
        }
        this.$emit('input', val)
      }
    },
    className () {
      const className = [`fpui-input-color-${this.color}`]
      if (this.disabled) className.push('disabled')
      if (this.error) className.push('error')
      if (this.requiredError && !this.value) className.push('error')
      if (this.copy) className.push('with-copy')
      if (this.prefix) className.push('with-prefix')
      if (this.suffix) className.push('with-suffix')
      return className.join(' ')
    },
    themeClass () {
      const className = [this.theme]
      if (this.focused) className.push('focused')
      if (this.error) className.push('error')
      return className.join(' ')
    }
  },
  watch: {
    autocomplete () {
      this.updateAutocomplete(this.model)
    }
  },
  mounted () {
    this.onKeyUp = this.onKeyUp.bind(this)
    if (this.autofocus) this.focus()
    if (!this.value) this.$emit('input', '')
  },
  methods: {
    async copyAction () {
      await navigator.clipboard.writeText(this.value)
      this.$fpuiMessageBlock.success('input.text.copy.success')
    },
    onFocusOut () {
      this.focused = false
      this.$emit('focusout')
    },
    onFocus (e) {
      this.focused = true
      if (this.selectAllOnFocus) {
        e.target.select()
      }
    },
    onAutocomplete (val) {
      this.model = val
      this.hideAutocomplete = true
    },
    onKeyUp (e) {
      if (this.inAutocomplete.length > 0) {
        let index = 0
        for (let i = 0; i < this.inAutocomplete.length; i++) {
          const auto = this.inAutocomplete[i]
          if (auto.active) { index = i }
        }
        // Enter key
        if (e.keyCode === 13) {
          this.onAutocomplete(this.inAutocomplete[index].value)
          return
        }
        // Down key
        if (e.keyCode === 40) {
          this.inAutocomplete[index].active = false
          index = index === (this.inAutocomplete.length - 1) ? 0 : index + 1
          this.inAutocomplete[index].active = true
        }
        // Up key
        if (e.keyCode === 38) {
          this.inAutocomplete[index].active = false
          index = index === 0 ? this.inAutocomplete.length - 1 : index - 1
          this.inAutocomplete[index].active = true
        }
      }
      if (e.keyCode === 13) this.$emit('enter', e)
      this.$emit('keyup', e)
    },
    focus () { this.$refs.input && this.$refs.input.focus() },
    updateAutocomplete (val) {
      if (val && val.length > 0) {
        const tmp = this.autocomplete.filter(auto => {
          try {
            return new RegExp(val, 'i').test(auto)
          } catch (err) {
            return false
          }
        })
        this.inAutocomplete = tmp.map((auto, index) => {
          if (index === 0) { return { value: auto.value, label: auto.label, active: true } }
          return { value: auto.value, label: auto.label, active: false }
        })
        this.hideAutocomplete = false
      }
    }
  }
}
</script>

<style lang='less'>
@import "~@/shared/styles/_variables.less";

.fpui-input-text.v4 {
  position:relative;
  .fpui-input-text-content input::placeholder {
    color: rgba(62,69,80,0.35);
  }
  .fpui-input-text-content:not(.with-padding) {
    padding: 0;
  }
  .fpui-input-text-error {
    color:#F62172;
    position: absolute;
    line-height: 15px;
    font-size: 12px;
    right:5px;
    top: 5px;
  }
  .copy {
    width: 40px;
    border-radius: 0 3px 3px 0;
    background-color: rgba(151,167,183,0.12);
    text-align: center;
    height: 34px;
    line-height: 34px;
    vertical-align: middle;
    display: inline-block;
    border: 1px solid @grey_dark;
    cursor: pointer;
    font-size: 16px;
    &:hover {
      background-color: rgba(151,167,183,0.18);
    }
    &:active {
      background-color: rgba(151,167,183,0.24);
    }
  }
  input {
    vertical-align: middle;
    width: 100%;
    border-radius: 3px;
    &.with-copy {
      width: calc(~"100% - 40px");
      border-radius: 3px 0 0 3px;
    }
    height: 34px;
    background-color: rgba(151,167,183,0.06);
    padding: 8px;
    padding-left: 11px;
    border: 1px solid @grey_dark;
    font-size: 14px;
    font-weight: 300;
    outline: none;
    font-weight: 400;
    color: rgb(62, 69, 80);
    font-family: @global-font-family;
    font-weight: 400;
    letter-spacing: normal;
    &:disabled {
      border: unset;
      background-color: rgba(151,167,183,0.06);
      cursor: not-allowed;
      color: rgba(62,69,80,0.65);
    }
    .input-transition();
    &.fpui-input-color-blue:not(.error):focus {
      border-color: @blue;
    }
    &.fpui-input-color-green:not(.error):focus {
      border-color: @green;
    }
    &.fpui-input-color-red:focus, &.error {
      border-color: @red;
    }
    &.fpui-input-color-yellow:not(.error):focus {
      border-color: @yellow;
    }
  }
  .fpui-input-text-with-prefix-suffix {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .fpui-input-text-content-prefix {
      order: 1;
      padding-left: 10px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-top: 1px solid @grey_dark;
      border-left: 1px solid @grey_dark;
      border-bottom: 1px solid @grey_dark;
      height: 34px;
      line-height: 34px;
      background-color: rgba(151,167,183,0.06);
      .input-transition();
    }
    .fpui-input-text-content-suffix {
      order: 3;
      padding-right: 10px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-top: 1px solid @grey_dark;
      border-right: 1px solid @grey_dark;
      border-bottom: 1px solid @grey_dark;
      height: 34px;
      line-height: 34px;
      background-color: rgba(151,167,183,0.06);
      .input-transition();
    }
    input {
      order: 2;
      flex: 0 1 100%;
      border-radius: 0;
      border-left: none;
      border-right: none;
      padding-left: 5px;
      &:focus {
        &~ div[class^="fpui-input-text-content-"]  {
          border-color: @blue;
        }
      }
    }
  }
  .optional {
    position: absolute;
    right: 15px;
    top: 50%;
    color: rgba(62,69,80,.25);
    font-size: 13px;
  }
  .autocomplete {
    padding: 0;
    margin: 0;
    list-style: none;
    position:absolute;
    left: 0;
    width: 100%;
    z-index: 1000;
    opacity: 1;
    background-color: white;
    display: block;
    li {
      padding: 7px;
      cursor: pointer;
      &:hover, &.selected {
        background-color: @grey_dark;
      }
    }
    &.autocomplete-top {
      bottom: 40px;
      overflow-y: scroll;
    }
    &.hide-autocomplete {
      display: none;
    }
  }
  .clear-input {
    position:absolute;
    right: 5px;
    top: 0px;
    color: @blue;
    cursor:pointer;
    font-size: 15px;
  }
}
</style>
