import { API, APIObject } from '@/shared/plugins/Api/API'
import _set from 'lodash/set'

class Alert extends APIObject {
  constructor (options) {
    // Init
    super('CC', options)

    // Default variables
    this.display_name = this.display_name || ''
    this.name = this.name || ''
    this.description = this.description || ''
    this.tags = this.tags || {}
    this.tags.path = this.tags?.path || ''
    this.tags.tags = this.tags?.tags || []
    this.uid = this.uid || null
    this.type = this.type || 'health'
    this.target = this.target || {
      type: null,
      dataplant_id: null,
      cluster: null,
      resource_id: '.*',
      only_cron: false
    }
    this.notify = this.notify || {
      type: 'once',
      wait_for: '0m',
      on_resolution: false
    }
    this.triggers = this.triggers || []
    this.subscribers = this.subscribers || {
      users: [],
      iam_groups: [],
      external_consumers: []
    }
  }
}

class Alerts extends API {
  async list () {
    const alerts = await this.request({
      method: 'GET',
      url: '/v1/alerts'
    })

    return alerts
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Alert(item)
  }
}

export default Alerts
export {
  Alert,
  Alerts
}
