import Vue from 'vue'

export default {
  state: {
    buckets: null,
    bucketLoading: false
  },
  mutations: {
    SET_DS_BUCKETS (state, buckets) { // Not WS
      state.buckets = buckets
    },
    SET_DS_BUCKET_LOADING (state, value) { // Not WS
      state.bucketLoading = value
    },
    ADD_DS_BUCKET (state, bucket) {
      state.buckets.push(bucket)
    }
  },
  actions: {
    async REFRESH_DS_BUCKETS ({ commit }) {
      commit('SET_DS_BUCKET_LOADING', true)
      const buckets = await Vue.api.DS.buckets.list()
      commit('SET_DS_BUCKETS', buckets)
      commit('SET_DS_BUCKET_LOADING', false)
    },
    async CREATE_DS_BUCKET ({ commit }, name) {
      const displayName = name
      const bucket = Vue.api.DS.buckets.new({ Name: [`${name}`], displayName })
      await bucket.save()
      commit('ADD_DS_BUCKET', bucket)
    }
  },
  getters: {
    DS_BUCKET_LOADING (state) {
      return state.bucketLoading
    },
    DS_BUCKETS (state) {
      return state.buckets || []
    },
    DS_BUCKET (state) {
      return (bucketName) => {
        return state.buckets.find(bucket => bucket.name === bucketName)
      }
    }
  }
}
