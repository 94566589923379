<template lang="pug">
.add-edit-story-modal.fpui-modal
  fpui-modal-header(
    :title="$t('dashbaord.add_story.title')"
    @close="$emit('close')"
    closeTopButton
  )
  fpui-modal-body
    .add-edit-story-modal-body
      .chart-container(
        v-if="displayChart"
      )
        chart-display(
          :result="result"
          :chart-mode="chartMode"
          :result-to-display="resultToDisplay"
          :type="type"
          :full-height="true"
          :querydoesnt-exist="queryDoesntExist"
        )
      fpui-input-textarea(
        v-model="text"
        :placeholder="$t('dashboard.add_story.placeholder_story')"
        :class="{ 'from-dashboard': !displayChart }"
        autofocus
      )

  fpui-modal-footer(:buttons="buttons")
    story-author(
      v-if="story.updated_by"
      :story="story"
    )
</template>

<script>
import ChartDisplay from '@/shared/components/dashboarding/ChartDisplay.vue'
import StoryAuthor from '@/shared/components/dashboarding/StoryAuthor.vue'

export default {
  components: {
    ChartDisplay,
    StoryAuthor
  },
  props: {
    story: { type: Object, required: true },
    handleChange: { type: Function, required: true },
    result: { type: Object, default: () => {} },
    chartMode: { type: String, default: 'table' },
    error: { type: String, default: '' },
    resultToDisplay: { type: Object, default: () => {} },
    type: { type: String, default: 'forepaas' },
    queryDoesntExist: { type: Boolean, default: false }
  },
  data () {
    return {
      text: ''
    }
  },
  computed: {
    displayChart () {
      return this.result && !this.error
    },
    buttons () {
      return [{
        label: this.$t('cancel'),
        type: 'close',
        handler: () => {
          this.$emit('close')
        }
      }, {
        label: this.$t('save'),
        color: 'blue-flash',
        handler: () => {
          this.handleChange(this.text)
          this.$emit('close')
        }
      }]
    }
  },
  mounted () {
    this.text = this.story.text
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";

.add-edit-story-modal {
  .modal-body {
    height: 340px;
    padding-bottom: 0;

    .add-edit-story-modal-body {
      height: 100%;

      .chart-container {
        height: 170px;
        margin-bottom: 15px;
        position: relative;
      }

      .fpui-textarea {
        textarea {
          height: 120px !important;
        }

        &.from-dashboard {
          textarea {
            height: 305px !important;
          }
        }
      }
    }
  }
}
</style>
