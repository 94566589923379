<template lang="pug">
  .sample
    fp-title(
      parent="Sample"
      :border="true"
    )
    fpui-tabs
      fpui-tab(header="Components")
        components
      fpui-tab(header="Filters")
        filters
</template>

<script>
import Components from './Components'
import Filters from './Filters'
export default {
  components: {
    Components, Filters
  }
}
</script>

<style lang="less">

</style>
