import _get from 'lodash/get'
import _set from 'lodash/set'
import { join } from 'path'

export const toTree = (objects, options = {}, tmp = {}) => {
  options.children = options.children || 'children'
  tmp.opens = tmp.opens || []
  tmp.selected = tmp.selected || []
  tmp.folders = tmp.folders || []
  const items = []

  const setFolderAt = (array, key, parentKey = '') => {
    const keys = key.split('/')
    const name = keys.shift()
    let folder = array.find(item => _get(item, options.name) === name)
    if (!folder) {
      folder = {
        type: 'folder'
      }
      _set(folder, options.children, [])
      _set(folder, options.path, parentKey)
      _set(folder, options.name, name)
      _set(folder, options.id, join(parentKey, name))
      array.push(folder)
    }
    if (keys.length) return setFolderAt(_get(folder, options.children), keys.join('/'), join(parentKey, name))
  }
  // This method is used on each item and creates a folder when
  // needed. Otherwise, it will just create the item
  const setAsPath = (items, key, object, parentKey = '') => {
    // When the item is at the latest level, key is empty
    // then should push the item to the items array
    if (!key || typeof (key) !== 'string') {
      return items.push(object)
    }

    // Otherwise it will start to parse the path
    const keys = key.split('/') // if nested folder, split them before
    const nextKey = keys.shift() // this will be used as the next folder name to work with

    // If the folder already exists, it will be found right here
    let folder = items.find(item => {
      return (_get(item, options.name) === nextKey) && (item.type === 'folder')
    })

    // No folder was found with the above parameters, let's create it
    if (!folder) {
      folder = {
        type: 'folder'
      }
      _set(folder, options.children, [])
      _set(folder, options.path, parentKey)
      _set(folder, options.name, nextKey)
      _set(folder, options.id, join(parentKey, nextKey))
      items.push(folder)
    }
    // Trigger that method again if ever there's more folder to be created
    // Otherwise it will simply add the item to the items array as expected
    setAsPath(_get(folder, options.children), keys.join('/'), object, join(parentKey, nextKey))
  }
  // Loop through all given objects to create possible folders
  objects.forEach(object => {
    object.children = []
    setAsPath(items, _get(object, options.path), object)
  })
  tmp.folders.forEach(folder => {
    setFolderAt(items, folder)
  })
  return items
}

export const getSortValue = (row, column) => {
  if (!column.sortable) return
  let func = column.target
  if (column.sortable !== true) {
    func = column.sortable
  }
  switch (typeof (func)) {
    case 'string':
      return _get(row, func) || '-'
    case 'function':
      return func(row)
  }
  return '-'
}

export const sortFunc = ({ sortOn, sortReverse }) => {
  return (a, b) => {
    // Folder always first, and sort by display name
    if (a.type === 'folder' && b.type === 'folder') {
      const aName = a.display_name || a.name
      const bName = b.display_name || b.name
      if (aName > bName) return sortReverse ? -1 : 1
      else if (aName < bName) return sortReverse ? 1 : -1
      return 0
    }
    if (a.type !== 'folder' && b.type === 'folder') return 1
    if (a.type === 'folder' && b.type !== 'folder') return -1

    // Handle real sortable
    if (sortOn) {
      const aValue = getSortValue(a, sortOn, sortReverse)
      const bValue = getSortValue(b, sortOn, sortReverse)
      if (aValue < bValue) return sortReverse ? 1 : -1
      if (aValue > bValue) return sortReverse ? -1 : 1
    }

    return 0
  }
}

export const toFlat = (items, options = {}, tmp = {}, level = 0) => {
  tmp = tmp || {}
  tmp.opens = tmp.opens || []
  tmp.selected = tmp.selected || []
  tmp.folders = tmp.folders || []
  let rows = []
  items.sort(sortFunc(tmp))
  items.forEach(item => {
    rows.push({
      object: item,
      level
    })

    options.children = options.children || 'children'

    if (_get(item, options.children)) {
      if (!options.openAll && tmp.opens.indexOf(_get(item, options.id)) === -1) return
      rows = rows.concat(toFlat(_get(item, options.children), options, tmp, level + 1))
    }
  })
  return rows
}

export const toFlatLight = (items) => {
  const rows = []
  items.forEach(item => {
    rows.push({
      object: item,
      level: 0
    })
  })
  return rows
}
