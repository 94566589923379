import { API, APIObject } from '@/shared/plugins/Api/API'
import _omit from 'lodash/omit'
import _set from 'lodash/set'

class Deployment extends APIObject {
  constructor (options) {
    // Init
    super('DPE', options)
  }

  _filter (object) {
    return _omit(super._filter(object), [
      '_id',
      '__socketId',
      '_type',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
      'name'
    ])
  }
}

class Deployments extends API {
  list () {
    return this.request({
      method: 'get',
      url: 'v3/deployments',
      params: {
        latest: true,
        active: true
      }
    }).then(deployments => {
      return deployments.map(deployment => {
        try {
          return new Deployment(deployment)
        } catch (err) {
          console.error(err.stack)
          return null
        }
      }).filter(i => i)
    })
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Deployment(item)
  }
}

export default Deployments

export {
  Deployment,
  Deployments
}
