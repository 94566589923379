import API from '@/shared/plugins/Api/API'
import Alerts from './Alerts'
import Metrics from './Metrics'

class CC extends API {
  constructor (id) {
    super(id)
    this.alerts = new Alerts(id)
    this.metrics = new Metrics(id)
  }
}
export default CC
