<template lang="pug">
  .td.fp-tree-cell.cell-checkbox
    fpui-input-checkbox(
      :value="reverse ? !model : model"
      v-if="!undisplayCheckbox"
      @input="update"
      :disabled="disabledCheckbox"
    )
    fpui-helper(
      v-if="!undisplayCheckbox && disabledCheckbox && helperContent.text"
      :title="helperContent.title"
      :text="helperContent.text"
    )
</template>

<script>
import _get from 'lodash/get'

export default {
  props: {
    item: { type: Object, default: () => ({}) },
    itemKey: { type: String, default: '' },
    value: { type: Boolean, default: () => undefined },
    reverse: { type: Boolean, default: false },
    disabledCheckbox: { type: Boolean, default: () => false },
    undisplayCheckbox: { type: Boolean, default: () => false },
    helperContent: { type: Object, default: () => { return {} } },
    itemUpdate: { type: Function, required: true }
  },
  computed: {
    model () {
      return this.value !== undefined ? this.value : _get(this.item, this.itemKey)
    }
  },
  methods: {
    update (event) {
      this.itemUpdate(event)
    }
  }
}
</script>

<style lang="less">
.cell-checkbox {
  .fpui-input-checkbox {
    position: absolute;
    top: 6px
  }
  .fpui-helper {
    position: absolute;
    left: 5px;
    top: 3px
  }
}

</style>
