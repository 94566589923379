import Vue from 'vue'
import { KnowledgeBase } from '@/shared/plugins/Api/GAB/KnowledgeBases'

export default {
  state: {
    knowledgeBases: []
  },
  mutations: {
    FOREPAAS_GAB_KB_INIT (state, knowledgeBases) { // Not WS
      state.knowledgeBases = knowledgeBases
    },
    FOREPAAS_GAB_KB_CREATE (state, event) {
      const exists = state.knowledgeBases.find(a => a.id === event.id)
      if (!exists) state.knowledgeBases.push(new KnowledgeBase(event))
    },
    FOREPAAS_GAB_KB_REMOVE (state, event) {
      state.knowledgeBases = state.knowledgeBases.filter(a => event.id !== a.id)
    },
    FOREPAAS_GAB_KB_BULK_REMOVE (state, event) {
      if (!event.removed || !Array.isArray(event.removed)) return
      state.knowledgeBases = state.knowledgeBases.filter(a => !event.removed.includes(a.id))
    }
  },
  actions: {
    /*
      Assistants actions
    */
    // List assistants
    async REFRESH_GAB_KB ({ getters, commit }) {
      const assistants = await Vue.api.GAB.knowledgeBases.list()
      commit('FOREPAAS_GAB_KB_INIT', assistants)
    },
    async CREATE_KB ({ commit }, event) {
      // TODO
    }
  },
  getters: {
    GAB_KB (state) {
      return state.knowledgeBases
    },
    GAB_KB_BY_ID (state) {
      return (id) => state.knowledgeBases.find(a => a.id === id)
    }
  }
}
