import API from '@/shared/plugins/Api/API'
import Applications from './Applications'

class APPSERVICE extends API {
  constructor (id) {
    super(id)
    this.applications = new Applications(id)
  }

  async sshPublic () {
    const data = await this.request({
      url: 'ssh/public'
    })
    return data.public
  }

  async fetch (repository) {
    const data = await this.request({
      url: 'repo/git',
      params: repository
    })
    return data.branches
  }

  async configurationList () {
    return await this.request({
      url: 'configuration/list'
    }).then(data => data.results)
  }
}
export default APPSERVICE
