<template lang='pug'>
span.fp-tree-edit(
  :style="{ height: `${rowHeight}px`, 'line-height': `${rowHeight}px` }"
  @click="$event.stopPropagation()"
  @drag="$event.stopPropagation()"
)
  input(
    v-model="model"
    @keyup.esc="onCancel"
    @keyup.enter="onApply"
    name="tree-cell"
    ref="input"
  )
</template>

<script>

export default {
  props: {
    value: { type: String, default: '' },
    rowHeight: { type: Number, default: 45 }
  },
  data () {
    return {
      model: this.value
    }
  },
  mounted () {
    window.addEventListener('click', this.onApply)
    this.$refs.input.focus()
    this.$refs.input.select()
  },
  destroyed () {
    window.removeEventListener('click', this.onCancel)
  },
  methods: {
    onCancel ($event) {
      this.$emit('input', this.value)
      $event.stopPropagation()
    },
    onApply ($event) {
      this.$emit('input', this.model)
      $event.stopPropagation()
    }
  }
}
</script>
