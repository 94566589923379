<template lang="pug">
  .home
</template>

<script>
import Config from '@/shared/Config'
export default {
  async mounted () {
    const config = await Config()

    if (config.ORGANIZATION_ID && config.PROJECT_ID) this.$router.push(`/${config.ORGANIZATION_ID}/${config.PROJECT_ID}/`)
    else if (config.ORGANIZATION_ID) this.$router.push(`/${config.ORGANIZATION_ID}/`)
    else document.location.href = config.FPUI
  }
}
</script>

<style lang="less">

</style>
