<template lang="pug">
  .mfa-mail
    .text-center
      button.mfa-button(
        :style="buttonStyle"
        @click="send"
        type="button"
      ) {{ alreadySent ? $t('authentication.mfa.email.resend') : $t('authentication.mfa.email.send') }}
    .text-center(v-if="alreadySent")
      label {{$t('authentication.mfa.email.code_label')}}
      input(
        type="text"
        :value="value"
        :placeholder="$t('authentication.mfa.code')"
        @input="val=>$emit('input',val.target.value)"
      )
    .clearfix
    br
</template>

<script>
export default {
  icon: 'fp4 fp4-paper-plane',
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      alreadySent: false,
      canBeResend: false
    }
  },
  computed: {
    color () { return this.$store.getters.PREFERENCES.color || '#00ccf9' },
    buttonStyle () {
      if (!this.alreadySent) {
        return {
          color: 'white',
          backgroundColor: this.color,
          cursor: 'pointer',
          opacity: 1
        }
      }
      if (!this.canBeResend) {
        return {
          color: '#888',
          backgroundColor: 'white',
          cursor: 'not-allowed',
          opacity: 0.5
        }
      }
      return {
        color: '#888',
        backgroundColor: 'white',
        cursor: 'pointer',
        opacity: 1
      }
    }
  },
  methods: {
    async send () {
      if (this.alreadySent && !this.canBeResend) return
      try {
        this.alreadySent = true
        this.canBeResend = false
        await this.$store.dispatch('SEND_MFA_CODE', {
          type: 'email'
        })
        this.$fpuiMessageBlock.pop('success', this.$t('authentication.mfa.email.send.success'))
        setTimeout(() => {
          this.canBeResend = true
          this.alreadySent = true
        }, 5000)
      } catch (err) {
        this.alreadySent = false
        console.error(err)
        this.$fpuiMessageBlock.error(err)
      }
    }
  }
}
</script>

<style lang="less">
.mfa-mail {
  .text-center {
    text-align: center;
    .button-container {
      display: inline-block;
    }
    label {
      text-transform: none;
      color: #3e4550;
      font-size: 14px;
      font-weight: normal;
      width: 300px;
      margin: 20px auto 0;
    }
    input {
      width: 200px;
      margin-top: 10px;
      text-align: center;
    }
  }
}
</style>
