<template lang="pug">
  .force-table(ref="force-table")
    .force-table-container
      i.fp4.fp4-table(
        @click="setOpen()"
      )
      .from-table-container(v-if="configuration.table_name")
        .from-table {{ $t('query.fields.data.force_table.from', [configuration.table_name]) }}
        i.fp4.fp4-xmark(@click="updateForcedTable(null)")
    .force-table-dropdown(v-if="open")
      .no-fields(v-if="!fieldsSelected.length")
        span.title {{ $t('query.fields.data.force_table.no_fields') }}
      .fields(v-else)
        span.title {{ $t('query.fields.data.force_table.with_fields') }}
        .tables
          .table
            fpui-input-radio(
              :value="forcedTable"
              @input="v => updateForcedTable(v)"
              :options="[{ value: '', label: $t('query.fields.data.force_table.automatic_selection') }]"
            )
          .table(v-for="table in diamonds")
            .table-content
              fpui-input-radio(
                :value="forcedTable"
                @input="v => updateForcedTable(v)"
                :options="[{ value: table.name, label: table.name }]"
              )
              .last-executed(
                v-if="latestUsedTable === table.name"
              ) {{ $t('query.fields.data.force_table.last_executed') }}
</template>

<script>
export default {
  props: {
    configuration: { type: Object, default: () => ({}) },
    diamonds: { type: Array, default: () => [] },
    readOnly: { type: Boolean, default: false },
    tablePreset: { type: Object, default: () => { } }
  },
  data () {
    return {
      open: false
    }
  },
  computed: {
    forcedTable () {
      return this.configuration.table_name || ''
    },
    fieldsSelected () {
      let dataScaleFilterOrder = []
      Object.keys(this.configuration).forEach(val => {
        if (val === 'filter' && this.configuration[val]) {
          dataScaleFilterOrder = [...dataScaleFilterOrder, ...Object.keys(this.configuration[val])]
        }
        if (val === 'scale' && this.configuration[val]?.fields && this.configuration[val]?.fields.length > 0) {
          dataScaleFilterOrder = [...dataScaleFilterOrder, ...this.configuration[val].fields]
        }
        if (val === 'data' && this.configuration[val]?.fields) {
          dataScaleFilterOrder = [...dataScaleFilterOrder, ...Object.keys(this.configuration[val].fields)]
        }
        if (val === 'order' && this.configuration[val]) {
          dataScaleFilterOrder = [...dataScaleFilterOrder, ...Object.keys(this.configuration[val])]
        }
      })

      return dataScaleFilterOrder
    },
    latestUsedTable () {
      const lastTableUsed = this.$store.getters.QB_QUERY_HISTORY_LATEST_BY_QUERY_ID(this.$route.params.id)?.queried_tables[0]
      if (lastTableUsed) {
        const lastTableUsedDetails = lastTableUsed.split('.')
        return lastTableUsedDetails.length ? lastTableUsedDetails[lastTableUsedDetails.length - 1] : lastTableUsedDetails
      }
      return null
    }
  },
  mounted () {
    document.addEventListener('click', this.onClickOutside)
    if (this.tablePreset) this.updateForcedTable(this.tablePreset.name)
  },
  destroyed () {
    document.removeEventListener('click', this.onClickOutside)
  },
  methods: {
    setOpen () {
      this.open = !this.open
    },
    onClickOutside (event) {
      if (!this.$refs['force-table'].contains(event.target)) {
        this.open = false
      }
    },
    updateForcedTable (table) {
      if (this.readOnly) return
      const newValue = table || null
      this.$emit('update', { key: 'table_name', value: newValue })
      this.open = false
    }
  }
}
</script>

<style lang="less">
.force-table {
  position: relative;
  .force-table-container {
    display: flex;
    align-items: center;
    .fp4-table {
      color: #CBD3DB;
      font-size: 16px;
      cursor: pointer;
    }
    .from-table-container {
      margin-left: 5px;
      color: #CBD3DB;
      display: flex;
      align-items: center;
      .force-table {
        font-size: 13px;
        line-height: 16px;
      }
      .fp4-xmark {
        margin-left: 5px;
        font-size: 13px;
        cursor: pointer;
      }
    }
  }
  .force-table-dropdown {
    position: absolute;
    top: 28px;
    left: -33px;
    background: #2e2e2e;
    padding: 12px 10px 10px 10px;
    border-radius: 8px;
    z-index: 9;

    .title {
      font-size: 13px;
      line-height: 20px;
      font-style: italic;
      color: #B2BECA;
    }
    .no-fields {
      width: 219px;
    }
    .fields {
      min-width: 271px;
      max-height: 160px;
      overflow: auto;

      .tables {
        height: calc(~"100% - 44px");
        overflow: auto;
        margin-top: 5px;

        .table {
          .fpui-input-radios {
            margin: 7px 0;
          }
          span {
            font-size: 13px;
            font-style: normal;
            color: #FFF;
            margin-left: 3px;
          }

          .table-content {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .last-executed {
              border: 1px solid #00CCF9;
              padding: 2px 5px;
              border-radius: 12px;
              color: #00CCF9;
              font-size: 10px;
            }
          }
        }
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      left: 32px;
      top: -8px;
      background: #2e2e2e;
      transform: rotate(45deg);
      border: 0.5px solid rgba(151,167,183,0.21);
      border-bottom: none;
      border-right: none;
    }
  }
}
</style>
