<template lang='pug'>
.project-template-selector
  .template-container
    .template-container-title {{ $t('dataplant.creation.template.description') }}
    data-project-templates(
      :organization-id="organizationId"
      :active-template="options.template.id"
      from-funnel
      @chosenTemplate="chosenTemplate"
      @next="$emit('next')"
      @click="browseTemplates"
    )
    .box-title-link(@click="browseTemplates")
      span {{ $t('core.data_project_creation.templates.browse_all') }}
      i.fp4.fp4-angle-right
  dataplant-creation-step-footer(
    :steps="[0, 1, 2]"
    :step-active="0"
    :disabled-next="disabledNext"
    @previous="$emit('previous')"
    @next="$emit('next')"
  )
</template>

<script>
import _get from 'lodash/get'
import DataProjectTemplates from '@/shared/components/project/DataProjectTemplates'
import DataplantCreationStepFooter from '@/shared/components/project/DataplantCreationFullscreen/DataplantCreationStepFooter.vue'
import Store from '@/shared/components/store'

export default {
  components: {
    DataProjectTemplates,
    DataplantCreationStepFooter
  },
  props: {
    options: { type: Object, required: true },
    organizationId: { type: String, default: () => '' },
    disabledNext: {
      type: Boolean,
      default: false
    },
    nextColor: {
      type: String,
      default: 'blue'
    }
  },
  methods: {
    browseTemplates () {
      this.$modal.show(Store, {
        name: this.$t('core.onboarding.store_template'),
        type: 'project.templates',
        icon: 'right',
        onSelect: (template, modal) => {
          this.templateById(template)
          modal.$emit('close')
        }
      }, {
        width: 980,
        height: 640,
        styles: { 'box-shadow': '0 3px 13px 0 #000 !important' }
      })
    },
    async templateById (selectedTemplate) {
      const storeTemplates = this.$store.getters.STORE_PACKAGES.project?.templates
      const modules = this.$store.getters.STORE_PACKAGES.project?.modules
      const template = storeTemplates[selectedTemplate.id]
      const meta = await this.$api.STORE.metas('project', 'templates', selectedTemplate.id, 'latest')
      const services = Object.keys(meta.options?.services).map(service => {
        let icon = ''
        try {
          icon = require(`@/core/components/Dataplant/assets/${service}.svg`)
        } catch (err) {
          icon = require('@/shared/assets/img/ovhcloud_logo_simplified_blue.png')
        }
        return {
          img: icon,
          service: _get(modules, [service, 'description'])
        }
      })
      this.chosenTemplate({
        id: selectedTemplate.id,
        services: services,
        title: template.description,
        description: template.long_description,
        image: template.image
      })
    },
    chosenTemplate (template) {
      this.$emit('update', { template })
    }
  }
}
</script>

<style lang='less'>
.project-template-selector{
  position: absolute;
  bottom: 104px;
  left: 0;
  right: 0;
  width: 776px;
  margin: 0 auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  background: #FFFFFF;
  border-radius: 5px;

  .template-container {
    width: 776px;
    padding: 20px 20px 0 20px;

    .template-container-title {
      flex-basis: 100%;
      margin-bottom: 16px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #3E4550;
    }

    > .data-project-templates {
      margin-bottom: 6px;
      justify-content: start;

      .project-template-box {
        margin-right: 10px;

        &:last-child{
          margin: 0;
        }
      }
    }

    > .box-title-link {
      display: flex;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #0089C0;
      cursor: pointer;
      align-items: center;

      &:hover {
        span {
          text-decoration: underline;
        }
      }

      > .fp4-angle-right{
        margin-left: 4px;
        font-size: 19px;
      }
    }
  }

  .dataplant-creation-step-footer {
    width: 100%;
  }
}
</style>
