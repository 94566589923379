import axios from 'axios'
import _merge from 'lodash/merge'
import urljoin from 'url-join'
import Vue from 'vue'
import later from './later'

let config = {
  APP_ID: 'forepaas'
}

const setEnv = (env = 'production') => {
  const defaultValues = {
    STORE: 'https://store.forepaas.io',
    FPUI: 'https://hq.forepaas.io',
    DOCS: 'https://docs.forepaas.io',
    STATUS: 'https://status.forepaas.io',
    KING: 'https://hq-api.forepaas.io/iam',
    ORGANIZATION: 'https://hq-api.forepaas.io/organizations',
    BILLING: 'https://hq-api.forepaas.io/billing',
    FPAPI: 'https://hq-api.forepaas.io',
    SUPPORT: 'https://hq.forepaas.io/#/support',
    SUPPORT_API: 'https://hq-support.forepaas.io',
    STORAGE: 'https://storage-engine.forepaas.io',
    SIGNUP: false,
    CREATE_ORGANIZATION: true,
    DATA_PLATFORM_ACCESS: false
  }
  const PLATFORM = {
    local: {
      STORE: 'https://store-dev.forepaas.com',
      FPUI: 'https://hq-dev.forepaas.com',
      DOCS: 'https://hq-dev-docs.forepaas.com',
      STATUS: 'https://status.forepaas.io',
      KING: 'https://hq-dev-api.forepaas.com/iam',
      ORGANIZATION: 'https://hq-dev-api.forepaas.com/organizations',
      BILLING: 'https://hq-dev-api.forepaas.com/billing',
      FPAPI: 'https://hq-dev-api.forepaas.com',
      SUPPORT: 'https://hq-dev.forepaas.com/#/support',
      SUPPORT_API: 'https://hq-dev-support.forepaas.com',
      STORAGE: 'https://storage-engine-dev.forepaas.com',
      SIGNUP: true,
      CREATE_ORGANIZATION: true,
      DATA_PLATFORM_ACCESS: true
    }
  }
  config = _merge(config, defaultValues)
  if (PLATFORM[env]) _merge(config, PLATFORM[env])


  config.DATAPLANT_MODE = false
  config.STORE = process.env.VUE_APP_STORE || config.STORE
  config.FPUI = process.env.VUE_APP_FPUI || config.FPUI
  config.DOCS = process.env.VUE_APP_DOCS || config.DOCS
  config.STATUS = process.env.VUE_APP_STATUS || config.STATUS
  config.KING = process.env.VUE_APP_KING || config.KING
  config.FPAPI = process.env.VUE_APP_FPAPI || config.FPAPI
  config.BILLING = process.env.VUE_APP_BILLING || config.BILLING
  config.ORGANIZATION = process.env.VUE_APP_ORGANIZATION || config.ORGANIZATION
  config.SUPPORT = process.env.VUE_APP_SUPPORT || config.SUPPORT
  config.SUPPORT_API = process.env.VUE_APP_SUPPORT_API || config.SUPPORT_API
  config.STORAGE = process.env.VUE_APP_STORAGE || config.STORAGE
  config.SIGNUP = process.env.VUE_APP_SIGNUP === 'true' || config.SIGNUP
  config.CREATE_ORGANIZATION = process.env.VUE_APP_CREATE_ORGANIZATION === 'false' ? false : config.CREATE_ORGANIZATION
  // DATA_PLATFORM_ACCESS to true if set to true on ENV var, else default to default value
  config.DATA_PLATFORM_ACCESS = process.env.VUE_APP_DATA_PLATFORM_ACCESS === 'true' ? true : config.DATA_PLATFORM_ACCESS
}

setEnv(process.env.VUE_APP_ENV || 'production')

let cache = null

const setConfig = (appConfig = {}) => {
  config = _merge(config, appConfig)
  config._init = true
}

const setProjectBaseUrl = (project) => {
  config.ORGANIZATION_ID = project.organization_id
  config.BASE_URL = `https://${project.url}`
  config.PROJECT_ID = project.id
  config.FPRN = project.fprn
  config.PROJECT_NAME = project.display_name
  config.OFFER = project.offer || 'mutualized'
  config.CLUSTER = project.cluster || project.cluster_name
  config.SERVICES = project.services || []

  config.IAM = process.env.VUE_APP_IAM || urljoin(config.BASE_URL, 'iam')
  config.DPE = process.env.VUE_APP_DPE || urljoin(config.BASE_URL, 'dpe')
  config.DWH = process.env.VUE_APP_DWH || urljoin(config.BASE_URL, 'dwh')
  config.DM = process.env.VUE_APP_DM || urljoin(config.BASE_URL, 'dwh')
  config.QB = process.env.VUE_APP_QB || urljoin(config.BASE_URL, 'qb')
  config.CC = process.env.VUE_APP_CC || urljoin(config.BASE_URL, 'control-center')
  config.QUERY_ADMIN = process.env.VUE_APP_QUERY_ADMIN || urljoin(config.BASE_URL, 'query-admin')
  config.ML = process.env.VUE_APP_ML || urljoin(config.BASE_URL, 'ml')
  config.DTL = process.env.VUE_APP_DTL || urljoin(config.BASE_URL, 'datalab')
  config.DS = process.env.VUE_APP_DS || urljoin(config.BASE_URL, 'datastore')
  config.APPSERVICE = process.env.VUE_APP_APPERVICE || urljoin(config.BASE_URL, 'appservice')
  config.GAB = process.env.VUE_APP_GAB || urljoin(config.BASE_URL, 'gab-control-plane')

  config.DPE_WS = `${config.DPE.replace('https://', 'wss://').replace('http://', 'ws://')}/ws`
  config.IAM_WS = `${config.IAM.replace('https://', 'wss://').replace('http://', 'ws://')}/ws`
  config.ML_WS = `${config.ML.replace('https://', 'wss://').replace('http://', 'ws://')}/ws`
  config.DWH_WS = `${config.DM.replace('https://', 'wss://').replace('http://', 'ws://')}/ws`
  config.QB_WS = `${config.QB.replace('https://', 'wss://').replace('http://', 'ws://')}/ws`

  const wsUrl = (httpUrl) => {
    return httpUrl.replace('https://', 'wss://').replace('http://', 'ws://')
  }

  config.EVENT_MANAGER_WS = `${wsUrl(config.BASE_URL)}/event-manager/ws`
}

const cacheProjectInfo = {}
const cacheOrganizationInfo = {}

/* istanbul ignore next */
const getConfig = async () => {
  if (!config._init) return later(10, getConfig)
  config.setEnv = setEnv

  if (!config.CMP) {
    const projectId = (window.location.hash.split('#/')[1] || '').split('/')[0].split('?')[0]
    // Use that variable to force project url when fpapi is down (dev only)
    if (process.env.VUE_APP_DATAPLANT) {
      setProjectBaseUrl({
        url: process.env.VUE_APP_DATAPLANT,
        organization_id: 'fpdevenv',
        id: 'fpdevenv',
        fprn: 'fpdevenv-lg3yw4m7',
        display_name: 'Local mode'
      })
      return config
    }
    if (projectId === 'king') {
      setProjectBaseUrl({
        url: new URL(config.KING).host,
        organization_id: 'iam',
        id: 'king',
        fprn: 'iam-king',
        name: 'King'
      })
      return config
    }
    cacheProjectInfo[projectId] = cacheProjectInfo[projectId] || axios.get(urljoin(config.FPAPI, `v1/projects/${projectId}/info`), {
      withCredentials: true
    })
    try {
      const projectMetadata = await cacheProjectInfo[projectId]
      setProjectBaseUrl(projectMetadata.data)
    } catch (err) {
      throw new Error('PROJECT_NOT_FOUND')
    }
  }

  if (config.ORGANIZATION_ID) {
    cacheOrganizationInfo[config.ORGANIZATION_ID] = cacheOrganizationInfo[config.ORGANIZATION_ID] || axios.get(urljoin(config.ORGANIZATION, `v1/organizations/${config.ORGANIZATION_ID}/info`), {
      withCredentials: true
    })
    try {
      const organizationMetadata = await cacheOrganizationInfo[config.ORGANIZATION_ID]
      config.ORGANIZATION_NAME = organizationMetadata.data.name
      config.SUB_STATUS = organizationMetadata.data.subscription_status
      config.SUB_SCHEDULED_STATUS = organizationMetadata.data.subscription_scheduled_status
    } catch (err) {
      throw new Error('ORGANIZATION_NOT_FOUND')
    }
  }

  return config
}

/* istanbul ignore next */
export default async () => {
  if (!cache) cache = await getConfig()
  const routeParams = Vue.$router.currentRoute.params
  if (routeParams.dataplantId) cache.PROJECT_ID = routeParams.dataplantId
  if (routeParams.projectId) cache.PROJECT_ID = routeParams.projectId
  if (routeParams.organizationId) cache.ORGANIZATION_ID = routeParams.organizationId
  return cache
}

export {
  setConfig,
  setProjectBaseUrl
}
