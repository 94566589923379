<template lang='pug'>
.repository-configuration-modal
  fpui-modal
    fpui-modal-header(
      :title="$t('queries.repository.configuration.modal.title')"
      @close='$emit("close")'
    )
    fp-loader(v-if="loading")
    fpui-modal-body
      .general-repository-informations.modal-form
        .fp-row
          .fp-column
            fpui-input-text(
              autofocus
              :label="$t('queries.repository.configuration.modal.general.name')"
              :value="repository.display_name"
              @input="(value) => repository.update('display_name', value, false)"
              :placeholder="'queries.repository.configuration.modal.general.name.placeholder' | translate"
            )
        .fp-row
          .fp-column
            fpui-input-text(
              :label="$t('queries.repository.configuration.modal.general.description')"
              :value="repository.description"
              @input="(value) => repository.update('description', value, false)"
              :placeholder="'queries.repository.configuration.modal.general.description.placeholder' | translate"
            )
      //- external-repository-connector(:repository="repository")
    fpui-modal-footer(:buttons="buttons")
      fpui-button.delete-repository(
        icon-left
        noshadow
        reverse
        auto-width
        color="pink"
        @click="removeRepository()"
        icon='fp4 fp4-trash-can'
        v-if="repository._id"
        :disabled='isDisabled'
        v-tooltip="isDisabled ? tooltipDisabled : ''"
      )
        span(v-tooltip='(repoLength < 2) ? $t("queries.repository.configuration.modal.delete_repository.last.tooltip") : ""') {{ $t('queries.repository.configuration.modal.delete_repository') }}
</template>

<script>
import Store from '@/shared/store'
import ConfirmModal from '@/shared/components/modals/Confirm'
// import ExternalRepositoryConnector from './ExternalRepositoryConnector'

export default {
  // components: {
  //   ExternalRepositoryConnector
  // },
  props: {
    repositoryId: { type: String, default: '' }
  },
  data () {
    return {
      repository: this.repositoryId ? this.$store.getters.QB_REPOSITORY_BY_ID(this.repositoryId) : this.$api.QUERY_ADMIN.repositories.new(),
      canCreate: true,
      loading: false,
      tooltipDisabled: this.$t('queries.repository.configuration.modal.delete_repository.last.tooltip_disabled')
    }
  },
  computed: {
    acl () {
      return this.canAmRepositoryDelete
    },
    buttons () {
      return [
        {
          label: this.$t('queries.repository.configuration.modal.cancel'),
          type: 'close',
          handler: () => {
            this.$emit('close')
          }
        }, {
          label: this.repositoryId ? this.$t('queries.repository.configuration.modal.save') : this.$t('queries.repository.configuration.modal.create'),
          color: 'blue-flash',
          tooltip: !this.repository.display_name ? this.$t('queries.repository.configuration.modal.repository_name_required') : '',
          disabled: !this.canCreate || !this.repository.display_name,
          handler: this.saveRepository
        }
      ]
    },
    repoLength () {
      return this.$store.getters.QB_REPOSITORIES_RAW?.length
    },
    canAmRepositoryDelete () {
      return this.$store.getters.ACL('canAmRepositoryDelete')
    },
    // Get the length of the list of actions in repository even those in folders
    repoNotEmpty () {
      return this.$store.getters.QB_QUERIES_FILTERED_BY_REPOSITORY_ID(this.repositoryId).length + this.$store.getters.QB_DASHBOARDS_FILTERED_BY_REPOSITORY_ID(this.repositoryId).length
    },
    // First one is for having at least one repository the rest is to check the number of actions in current repository
    // Had to put it here because the repoLength wasn empty if used in the data section
    isDisabled () {
      return (this.repoLength < 2 || !this.acl || (this.repoNotEmpty && this.repository.type !== 'git'))
    }
  },
  // Uncomment when git is ready
  // watch: {
  //   repository: {
  //     deep: true,
  //     handler () {
  //       if (!this.repository._git_link) {
  //         this.repository.update('params', { branch: '' }, false)
  //         if (this.repository?._id) {
  //           this.repository.update('type', 'forepaas', false)
  //           this.repository.update('version', undefined, false)
  //         }
  //         this.canCreate = true
  //       } else {
  //         this.repository.update('params.ssh_key', true, false)
  //         if (this.repository?._id) {
  //           this.repository.update('type', 'git', false)
  //           const editingVersion = this.repository.editingVersion || this.repository
  //           this.repository.update('version', { display_name: `${editingVersion?.name}-git`, from: editingVersion?._id }, false)
  //         }
  //         this.canCreate = (this.repository.params?.url !== '' && this.repository.params?.branch !== '')
  //       }
  //     }
  //   }
  // },
  async mounted () {
    this.repository.backup()
    // this.repository.update('_git_link', !!this.repository?.params?.url, false)
  },
  destroyed () {
    this.repository.restore()
  },
  methods: {
    async saveRepository () {
      try {
        Store.commit('SET_INTERNAL_LOADING', true)
        this.loading = true
        if (this.repository._id) {
          await this.repository.save()
          await this.repository.backup()
          this.$analytics.track('Edit repository', {
            repository_id: this.repository._id,
            linked_account: this.repository._git_link ? this.repository._git_link : 'None',
            nb_of_versions: this.repository.versions.length
          })
          this.$fpuiMessageBlock.success('queries.repository.modified.success')
          this.$store.commit('FOREPAAS_QB_REPOSITORY_UPDATE', this.repository)
          this.$store.commit('FOREPAAS_QB_REPOSITORY_SET_ACTIVE', this.repository._id)
        } else {
          this.repository.update('name', this.repository.display_name, false)
          this.repository.update('active_version', 'v1', false)
          this.repository.update('created_by', 'test', false)
          this.repository.update('updated_by', 'test', false)
          this.repository.update('params.url', 'none', false)

          // Uncomment when git mode is ready
          // if (!this.repository.params.url) await this.repository.create()
          // else await this.repository.create('git', this.repository.params)
          await this.repository.create()
          this.$analytics.track('Create repository', {
            repository_id: this.repository._id,
            linked_account: this.repository._git_link ? this.repository._git_link : 'None',
            nb_of_versions: this.repository.versions.length
          })
          await this.$store.dispatch('REFRESH_QB_REPOSITORIES')
          this.$store.commit('FOREPAAS_QB_REPOSITORY_SET_ACTIVE', this.repository._id)
          this.$fpuiMessageBlock.success('queries.repository.added.success')
        }
        await this.$store.dispatch('REFRESH_QB_QUERIES')
        await this.$store.dispatch('REFRESH_QB_REPOSITORIES')
        this.$emit('close')
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      } finally {
        this.loading = false
        Store.commit('SET_INTERNAL_LOADING', false)
      }
    },
    async removeRepository () {
      const title = this.$t('repository.remove.modal.title')
      let content = []
      const contentHtml = true

      content.push(`<span style="font-size:14px">${this.$t('treeview.remove.modal.content_head')} <span style="font-weight:600"> ${this.repository.display_name}</span>${this.$t('treeview.remove.modal.question_mark')}<br />${this.$t('treeview.remove.modal.content_tail')} </span>`)
      content = ['<div>', ...content, '</div>']
      content = content.join('')

      const confirmResult = await ConfirmModal(this, {
        content,
        title,
        contentHtml,
        yes: {
          text: this.$t('fpui-modal-confirm.remove'),
          color: 'red'
        }
      })
      if (!confirmResult) return
      this.loading = true
      try {
        this.$analytics.track('Delete repository', { repository_id: this.repository._id })
        await this.repository.remove()
        await this.$store.commit('FOREPAAS_QB_REPOSITORY_REMOVE', this.repository)
        this.$fpuiMessageBlock.success(this.$t('repository.remove.success', [this.repository.display_name]))
        this.$emit('close')
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      }
      this.loading = false
    }
  }
}
</script>

<style lang='less'>
  @import "~@/shared/styles/_variables.less";

  .repository-configuration-modal {
    position: relative;
    height: 100%;
    .fp-row, .modal-form {
      margin-bottom: 5px !important;
    }
    .header {
      height: 56px;
      border-bottom: 1px solid rgba(151,167,183,0.21);
      i {
        position: absolute;
        right: 29px;
        top: 16px;
        cursor: pointer;
        color: #97A7B7;
        font-size: 20px;
        transition: all 100ms ease-in-out;
      }
      h3 {
        width: 100%;
        font-weight: 400!important;
        margin: 0;
        padding: 14px 0 0 29px;
        position: relative;
        color: #3E4550;
        line-height: 25px;
        font-size: 20px !important;
      }
    }
    .modal-footer {
      > .left-footer {
        .fpui-button.delete-repository {
          > button {
            padding: 0 !important;
            color: @red !important;
            &.disabled {
              color: #CBD3DB !important;
            }
            font-size: 12px;
            i {
              font-size: 13px;
            }
            &:hover:not(.disabled), &:active:not(.disabled), &:focus:not(.disabled) {
              background-color: transparent !important;
              box-shadow: none !important;
            }
            &:hover:not(.disabled) {
              color: @red !important;
            }
          }
        }
      }
    }
  }
</style>
