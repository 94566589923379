import Queries from './Queries'
import Dashboards from './Dashboards'
import Repositories from './Repositories'
import Resources from './Resources'
import QueriesHistory from './QueriesHistory'
import API from '@/shared/plugins/Api/API'

class QUERY_ADMIN extends API {
  constructor (id) {
    super(id)
    this.queries = new Queries(id)
    this.dashboards = new Dashboards(id)
    this.repositories = new Repositories(id)
    this.queriesHistory = new QueriesHistory(id)
    this.resources = new Resources(id)
  }

  fetchRepositoryBranches (url) {
    return this.request({
      method: 'GET',
      url: '/v3/git/branches/list',
      params: {
        url: url
      }
    })
  }

  fetchPublicKey () {
    return this.request({
      method: 'GET',
      url: '/v3/git/public_key'
    })
  }

  async exportQueryCsv (sql, email, bucket, path) {
    return this.request({
      method: 'POST',
      url: '/v3/query/export',
      data: {
        sql: sql,
        mail: email,
        bucket: bucket,
        path: path
      }
    })
  }

  async jobCheckQueryCsv (jobId) {
    return this.request({
      method: 'GET',
      url: `/v3/query/export/${jobId}`
    })
  }

  async import (item) {
    return this.request({
      method: 'POST',
      url: 'v4/backup/import',
      data: item,
      timeout: 60 * 10 * 1000
    })
  }
}

export default QUERY_ADMIN
