import ChartEcharts from './Charts'
import ChartJson from './Json'
import ChartTable from './Table'
import ChartBigNumber from './BigNumber'
import ChartGauge from './Gauge'

export {
  ChartEcharts,
  ChartJson,
  ChartTable,
  ChartBigNumber,
  ChartGauge
}

export default {
  ChartEcharts,
  ChartJson,
  ChartTable,
  ChartBigNumber,
  ChartGauge
}
