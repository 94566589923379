import Config from '@/shared/Config'
import { API, APIObject } from '@/shared/plugins/Api/API'
import fileReaderStream from 'filereader-stream'
import sha256 from 'js-sha256'
import _omit from 'lodash/omit'
import mime from 'mime'
import moment from 'moment'
import { join } from 'path'
import Queue from 'promise-queue'
import toBuffer from 'typedarray-to-buffer'


class Bucket extends APIObject {
  constructor (options) {
    // Init
    super('DS', {})
    this.name = options.Name[0]
    this.displayName = options.displayName
    this.creation_date = options.CreationDate?.[0]
  }

  _filter (object) {
    return _omit(object, [
      'xhrID',
      '__v',
      '__saveQueue',
      '__saving',
      '__backup',
      '__saveDebounced',
      '__error',
      'displayName'
    ])
  }

  async save () {
    return this.request({
      method: 'PUT',
      url: '/' + this.name,
      headers: {
        'Content-Type': 'text/xml'
      }
    })
  }

  async listAllWithLimit (prefix = '', limit = 20) {
    const data = await this.request({
      url: this.name,
      responseType: 'xml',
      params: {
        prefix,
        'max-keys': limit,
        'list-type': 2
      }
    })
    return data.ListBucketResult.Contents
  }

  async search (search = '', continuationToken = null, limit = 500) {
    const data = await this.request({
      url: this.name,
      responseType: 'xml',
      params: {
        'max-keys': limit,
        'list-type': 2,
        'continuation-token': continuationToken && encodeURI(continuationToken)
      }
    })
    return {
      files: data.ListBucketResult.Contents.map(object => {
        return {
          type: 'file',
          name: object.Key?.[0],
          key: object.Key?.[0],
          owner: object.Owner?.[0].DisplayName?.[0],
          last_modified: object.LastModified?.[0],
          size: object.Size?.[0]
        }
      }).filter(object => {
        return object.key.toLowerCase().indexOf(search.toLowerCase()) !== -1
      }),
      token: data.ListBucketResult.NextContinuationToken && data.ListBucketResult.NextContinuationToken[0]
    }
  }

  /*
    pattern : will be use as a base for the research, if you ask for pattern == '/myfolder/', it will list the subfolder of that one
    recursive : will list or not recursively subfolder
  */
  listObjects (prefix = '') {
    return this.listObjectsRecursive(prefix)
      .then((data) => {
        const { files, folders } = data
        if (!files.length && !folders.length) return []
        return files.map(object => {
          return {
            type: 'file',
            name: object.Key?.[0].split('/').reverse()[0],
            key: object.Key?.[0],
            owner: object.Owner?.[0].DisplayName?.[0],
            last_modified: object.LastModified?.[0],
            size: object.Size?.[0]
          }
        }).concat(folders.map(folder => {
          return {
            type: 'folder',
            name: folder.Prefix[0].split('/').reverse()[1],
            key: folder.Prefix[0]
          }
        }))
      })
  }

  listObjectsRecursive (prefix = '', token, folders = [], files = []) {
    return this.request({
      url: this.name,
      responseType: 'xml',
      params: {
        delimiter: '/',
        prefix,
        'max-keys': 250,
        'list-type': 2,
        'continuation-token': token && encodeURI(token)
      }
    })
      .then(data => {
        folders = folders.concat(data.ListBucketResult.CommonPrefixes || [])
        files = files.concat(data.ListBucketResult.Contents || [])
        if (data.ListBucketResult.NextContinuationToken && data.ListBucketResult.NextContinuationToken[0] && files.length <= 1000) {
          return this.listObjectsRecursive(prefix, data.ListBucketResult.NextContinuationToken[0], folders, files)
        }
        return {
          folders,
          files
        }
      })
  }

  async moveObject (source, dest) {
    if (source === dest) return
    await this.copyObject(source, dest)
    await this.removeObject({ key: source })
  }

  async copyObject (source, dest) {
    await this.request({
      url: join(this.name, encodeURIComponent(dest)),
      method: 'PUT',
      headers: {
        'x-amz-copy-source': join(this.name, encodeURIComponent(source))
      }
    })
    return dest
  }

  async uploadObject (file, path, onProgress) {
    const url = `${this.name}/${encodeURIComponent(path)}`
    const config = await Config()
    if (file.size === 0) {
      await this.request({
        method: 'PUT',
        url: url,
        responseType: 'xml',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: ''
      })
    } else if ([].includes(config.CLUSTER)) {
      console.warn(`This cluster ${config.CLUSTER} is not using the multipart upload, it may broke with some big files`)
      await this.binaryUpload(file, url, onProgress)
    } else {
      await this.multiPartUpload(file, url, onProgress)
    }

    return {
      type: 'object',
      percent: 1,
      name: file.name,
      mimeType: mime.getType(file.name),
      extension: mime.getExtension(mime.getType(file.name)),
      key: this.name,
      updatedAt: moment().format('YYYY-MM-DDTHH:mm:ss'),
      size: parseInt(file.size)
    }
  }

  async binaryUpload (file, url, onProgress) {
    const reader = new FileReader()

    await new Promise((resolve, reject) => {
      reader.onloadend = async (event) => {
        let data = event.target.result
        if (data instanceof ArrayBuffer) data = toBuffer(new Uint8Array(event.target.result))

        try {
          const hasher = sha256.create()
          hasher.update(data)
          const hash = hasher.hex()
          await this.request({
            method: 'PUT',
            url: url,
            timeout: 10 * 60 * 1000, // 10 min
            responseType: 'xml',
            headers: {
              'X-Amz-Content-SHA256': hash
            },
            onUploadProgress: (progressEvent) => {
              onProgress({
                type: 'object',
                percent: progressEvent.loaded / file.size,
                name: file.name,
                mimeType: mime.getType(file.name),
                extension: mime.getExtension(mime.getType(file.name)),
                key: this.name,
                updatedAt: moment().format('YYYY-MM-DDTHH:mm:ss'),
                size: parseInt(file.size)
              })
            },
            data: data
          })
          resolve()
        } catch (err) {
          reject(err)
        }
      }
      reader.readAsArrayBuffer(file)
    })
  }

  async multiPartUpload (file, url, onProgress) {
    const chunkSize = 50 * 1024 * 1024
    const stream = fileReaderStream(file, {
      chunkSize: chunkSize // 50 Mo
    })
    // Initiate upload
    const data = await this.request({
      method: 'POST',
      url: url,
      responseType: 'xml',
      params: {
        uploads: ''
      }
    })
    const uploadId = data.InitiateMultipartUploadResult.UploadId[0]
    const partsEtags = []

    let index = 0

    await new Promise((resolve, reject) => {
      const queue = new Queue(1, Infinity)
      let error = false
      stream.on('data', (chunk) => {
        queue.add(() => {
          const hasher = sha256.create()
          hasher.update(chunk)
          const hash = hasher.hex()
          index++
          return (async (index) => {
            if (error) return
            try {
              const response = await this.request({
                originalResponse: true,
                method: 'PUT',
                responseType: 'xml',
                timeout: 10 * 60 * 1000, // 10 min
                url: url,
                params: {
                  partNumber: index,
                  uploadId: uploadId
                },
                onUploadProgress: (progressEvent) => {
                  onProgress({
                    type: 'object',
                    percent: (progressEvent.loaded + (chunkSize * index)) / file.size,
                    name: file.name,
                    mimeType: mime.getType(file.name),
                    extension: mime.getExtension(mime.getType(file.name)),
                    key: this.name,
                    updatedAt: moment().format('YYYY-MM-DDTHH:mm:ss'),
                    size: parseInt(file.size)
                  })
                },
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'X-Amz-Content-SHA256': hash
                },
                data: chunk
              })
              if (!response.headers?.etag) throw new Error('ExposeHeaderNotSet:Etag')
              partsEtags[index] = JSON.parse(response.headers.etag)
            } catch (err) {
              error = true
              throw err
            }
          })(index)
        })
      })
      stream.on('end', () => {
        if (error) return
        queue.add(async () => {
          // End of upload
          const xml = `<CompleteMultipartUpload>${partsEtags.map((etag, index) => {
            return `<Part><PartNumber>${index}</PartNumber><ETag>${etag}</ETag></Part>`
          }).join('')}</CompleteMultipartUpload>`

          const hasher = sha256.create()
          hasher.update(xml)
          const hash = hasher.hex()
          try {
            await this.request({
              method: 'POST',
              responseType: 'xml',
              url: url,
              headers: {
                'Content-Type': 'text/xml',
                'X-Amz-Content-SHA256': hash
              },
              params: {
                uploadId: uploadId
              },
              data: xml
            })
            resolve()
          } catch (err) {
            reject(err)
          }
        })
      })
    })
  }

  async getObjectDownloadUrl (path) {
    const config = await Config()
    const pathClean = encodeURIComponent(path.startsWith('/') ? path.substring(1) : path)
    return `${config.DS}/${this.name}/${pathClean}`
  }

  remove () {
    return this.request({
      method: 'delete',
      url: `${this.name}`
    })
  }

  removeObject (object) {
    return this.request({
      method: 'delete',
      url: `${this.name}/${encodeURIComponent(object.key)}`
    })
  }
}
class Buckets extends API {
  async list () {
    const data = await this.request({
      url: '/',
      method: 'GET',
      responseType: 'xml'
    })
    if (!data.ListAllMyBucketsResult.Buckets[0].Bucket) return []
    return data.ListAllMyBucketsResult.Buckets[0].Bucket.map(bucket => {
      if (bucket.Name.length) bucket.displayName = bucket.Name[0]
      return new Bucket(bucket)
    })
  }

  new (item) {
    return new Bucket(item)
  }
}

export default Buckets

export {
  Bucket,
  Buckets
}
