<template lang="pug">
  .fpui-progress-bar
    .top-progression
      span.text {{ text }}
      span.percent(
        v-if='!textOnly'
        :style="{color}"
      ) {{ percent }}%
    .progression(:class='{smaller: small}' v-if='!textOnly')
      .inner-progression(:style='progressionStyle')
</template>

<script>
export default {
  props: {
    text: { type: String, default: '' },
    percent: { type: Number, default: 0 },
    color: { type: String, default: '#00CCF9' },
    small: { type: Boolean, default: false },
    textOnly: { type: Boolean, default: false }
  },
  computed: {
    progressionStyle () {
      return {
        backgroundColor: this.color,
        width: `${this.percent}%`
      }
    }
  }
}
</script>

<style lang="less">
  .fpui-progress-bar {
    width: 100%;
    display: block;
    > .top-progression {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      font-size: 14px;
    }
    > .progression {
      background-color: #E9ECF0;
      height: 4px;
      border-radius: 2px;
      &.smaller {
        height: 2px;
      }
      > .inner-progression {
        height: 100%;
        transition: width .15s ease;
        border-radius: 2px;
      }
    }
  }
</style>
