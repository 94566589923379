<template lang="pug">
  .dataplant-creation__provider-selection__map(
    :ref="componentId"
    :class='{ "read-only": readOnly }'
  )
    canvas.land-globe(:ref="canvasLandId")
    canvas.regions-globe(:ref="canvasRegionsId")
    .selection-tooltip__map(:ref="tooltipId")

</template>

<script>
import _find from 'lodash/find'

import {
  select as d3select,
  geoPath,
  geoCircle,
  quadtree,
  pointer as d3pointer
} from 'd3'
import { geoGinzburg8 } from 'd3-geo-projection'
import { feature as topojsonFeature } from 'topojson-client'

import worldMap from '@/shared/components/project/DataplantCreationFullscreen/ProviderSelection/world-110m-topology.json'

export default {
  props: {
    options: { type: Object, required: true },
    readOnly: { type: Boolean, default: false }
  },
  data () {
    return {
      landCanvas: null,
      regionsCanvas: null,
      landContext: null,
      regionsContext: null,
      projection: null, // same projection object is used on landContext and regionsContext
      landPath: null,
      regionsPath: null, // holds the canvas shapes of the region (circles from regionCircleGenerator)
      regionCircleGenerator: null, // holds a `d3.geoCircle()` method for long/lat positioning on the map
      land: null, // will contain `topojson.feature()` method: https://github.com/topojson/topojson-client#feature
      canvasWidth: null,
      canvasHeight: null,
      quadtree: null, // will hold informations about x,y points positioning: https://github.com/d3/d3-quadtree#api-reference
      hoveredRegion: null, // contains the region the user is currently hovering
      selectedProvider: null,
      selectedRegion: null,
      hdpiScaleFactor: 2.0 // used for better look on Retina displays
    }
  },
  computed: {
    componentId () {
      return `component-map-${this._uid}`
    },
    canvasLandId () {
      return `land-map-${this._uid}`
    },
    canvasRegionsId () {
      return `regions-map-${this._uid}`
    },
    tooltipId () {
      return `region-tooltip-${this._uid}`
    },
    regions () {
      const regions = this.options.providers
        .filter((p) => {
          if (this.options.configuration.dataplant.provider) {
            return p.name === this.options.configuration.dataplant.provider
          }
          return true
        }).map((p) => {
          return p.regions.map((r) => {
            return {
              id: r.name,
              provider: r.provider,
              providerName: p.display_options?.display_name,
              regionName: r.display_options?.display_name,
              lng: r.display_options?.longitude,
              lat: r.display_options?.latitude
            }
          })
        })
      return regions.reduce((a, b) => a.concat(b), [])
    }
  },
  watch: {
    'options.configuration.dataplant.provider' (provider) {
      if (provider !== this.selectedProvider) {
        this.selectedProvider = provider
        this.renderRegions()
        this.selectRegion(null)
      }
    },
    'options.configuration.dataplant.region' (regionObject) {
      this.renderRegions()
      if (regionObject === null || regionObject?.id !== this.selectedRegion) {
        this.selectRegion(regionObject?.id || null)
      }
    }
  },
  async mounted () {
    this.setupCanvas()
    this.clearCanvas()
    this.render()
    this.setupEvents()
  },
  methods: {
    // Setup basic and general informations for both canvas
    setupCanvas () {
      // Removing 230px corresponding the header height of the page
      this.canvasWidth = document.documentElement.clientWidth
      this.canvasHeight = document.documentElement.clientHeight - 230

      this.landCanvas = d3select(this.$refs[this.canvasLandId])
      this.landContext = this.landCanvas.node().getContext('2d')
      this.projection = geoGinzburg8()
      this.landPath = geoPath(this.projection).context(this.landContext)
      this.land = topojsonFeature(worldMap, worldMap.objects.land)

      this.regionsCanvas = d3select(this.$refs[this.canvasRegionsId])
      this.regionsContext = this.regionsCanvas.node().getContext('2d')
      this.regionsPath = geoPath(this.projection).context(this.regionsContext)
      this.regionCircleGenerator = geoCircle().radius(1)
    },
    setupEvents () {
      // On window resize
      window.addEventListener('resize', () => {
        this.canvasWidth = document.documentElement.clientWidth
        this.canvasHeight = document.documentElement.clientHeight - 230
        this.clearCanvas()
        this.render()
      }, false)

      if (!this.readOnly) {
        // On mousemove
        this.regionsCanvas.on('mousemove', this.onMouseMove)
        // On click
        this.regionsCanvas.on('click', this.selectRegion)
      }
    },
    render () {
      this.renderLand()
      this.renderRegions()
    },
    renderLand () {
      this.landCanvas.attr('width', this.canvasWidth * this.hdpiScaleFactor).attr('height', this.canvasHeight * this.hdpiScaleFactor)
      this.landContext.scale(this.hdpiScaleFactor, this.hdpiScaleFactor)
      this.projection.center([0, 70]).scale(225).translate([this.canvasWidth / 2, 100])

      this.landContext.beginPath()
      this.landPath(this.land)
      this.landContext.fillStyle = 'rgba(19, 83, 104, 0.85)'
      this.landContext.shadowBlur = 22
      this.landContext.shadowColor = 'rgba(0, 0, 0, 0.2)'
      this.landContext.shadowOffsetX = -4
      this.landContext.shadowOffsetY = 32
      this.landContext.fill()
      this.landContext.save()
    },
    renderRegions () {
      this.regionsCanvas.attr('width', this.canvasWidth * this.hdpiScaleFactor).attr('height', this.canvasHeight * this.hdpiScaleFactor)
      this.regionsContext.scale(this.hdpiScaleFactor, this.hdpiScaleFactor)
      this.projection.center([0, 70]).scale(225).translate([this.canvasWidth / 2, 100])

      this.regions.forEach((r, i) => {
        if ((this.hoveredRegion === r.id || this.selectedRegion === r.id || (this.options.configuration?.dataplant?.region?.id === r.id))) {
          return this.createRegionPoint(r.lng, r.lat, true)
        }
        const [x, y] = this.createRegionPoint(r.lng, r.lat)
        this.regions[i].x = x
        this.regions[i].y = y
      })
      // Generate a quadtree from the regions: https://en.wikipedia.org/wiki/Quadtree
      this.generateQuadTree()
    },
    clearCanvas () {
      // Clear the regions each time we want to make a change on them
      return this.regionsContext?.clearRect(0, 0, this.canvasWidth * this.hdpiScaleFactor, this.canvasHeight * this.hdpiScaleFactor)
    },
    onMouseMove (evnt) {
      const [mouseX, mouseY] = d3pointer(evnt)
      // Find the closest point in quadtree, if any (with 20px radius)
      const closest = this.quadtree.find(mouseX, mouseY, 20)

      if (closest) {
        // When the closest point from the mouse has been found (r=20),
        // change the cursor to `pointer` and change the hovered region to
        // `hovered` state
        // Also display the tooltip with hovered region informations
        this.canvasCursor(true)
        this.hoveredRegion = closest.id
        if (this.selectedRegion !== closest.id) {
          this.clearCanvas()
          this.renderRegions()
        }

        const regionTooltip = d3select(this.$refs[this.tooltipId])
        regionTooltip.style('opacity', 1)
          .style('top', `${evnt.pageY - 135}px`)
          .style('left', `${evnt.pageX + 10}px`)

        if (!regionTooltip.attr('id') || regionTooltip.attr('id') !== closest.id) {
          regionTooltip.html(`
              <img src="${this.providerImage(closest.provider)}">
              <div class="region-informations">
                <div class="provider-name">${closest.providerName}</div>\n
                <div class="provider-region-name">${closest.regionName}</div>
                <div class="provider-region-id">${closest.id}</div>
              </div>
            `).attr('id', closest.id)
        }
      } else if (!closest && this.hoveredRegion) {
        // When nothing has been found close to the mouse, render every
        // region points back to their original state
        // Also hides the tooltip
        this.hoveredRegion = null
        d3select(this.$refs[this.tooltipId]).style('opacity', 0).attr('id', null)
        this.renderRegions()
        this.canvasCursor()
      }
    },
    selectRegion (regionOrEvent) {
      // Select a region to its `active` state
      // Note this method is accessed from `regionsCanvas.on('click')` event
      // and when the provider and region are changed outside the Map component scope
      // That ends up getting two `regionOrEvent` types: region (id) or JS mouse event
      const regionToSelect = regionOrEvent?.type === 'click' ? this.hoveredRegion : regionOrEvent
      if (typeof regionToSelect === 'undefined') return

      if (regionToSelect !== this.selectedRegion) {
        this.selectedRegion = regionToSelect
      } else {
        this.selectedRegion = null
      }
      this.clearCanvas()
      this.renderRegions()
      if (regionOrEvent?.type === 'click') {
        const foundRegion = _find(this.regions, { id: this.selectedRegion })
        if (foundRegion) {
          this.selectedProvider = foundRegion?.provider
          this.$emit('update', { 'configuration.dataplant.provider': foundRegion?.provider })
          // Force setting the region keys to update so that
          // ProviderSelectionPersonalize can understand the object
          this.$emit('update',
            {
              'configuration.dataplant.region': {
                id: foundRegion.id,
                provider: foundRegion.provider,
                providerName: foundRegion.providerName,
                regionName: foundRegion.regionName
              }
            }
          )
        }
      }
    },
    generateQuadTree () {
      this.quadtree = quadtree()
        .extent([[0, 0], [this.canvasWidth, this.canvasHeight]])
        .x((d) => d.x)
        .y((d) => d.y)
        .addAll(this.regions)
    },
    createRegionPoint (lng, lat, active) {
      this.regionsContext.restore()
      const region = this.regionsPath.centroid({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lng, lat]
        }
      })

      // active or hovered
      if (active) {
        this.regionsContext.beginPath()
        this.regionsContext.shadowColor = 'transparent'
        // White stroke
        this.regionsContext.arc(region[0], region[1], 8, 0, Math.PI * 2)
        this.regionsContext.lineWidth = 1.5
        this.regionsContext.strokeStyle = '#fff'
        this.regionsContext.stroke()
        this.regionsContext.closePath()
        // Blue Stroke
        this.regionsContext.beginPath()
        this.regionsContext.arc(region[0], region[1], 10.8, 0, Math.PI * 2)
        this.regionsContext.lineWidth = 4
        this.regionsContext.strokeStyle = '#00CFFE'
        this.regionsContext.stroke()
        this.regionsContext.closePath()
        // Bigger blue stroke with transparency
        this.regionsContext.beginPath()
        this.regionsContext.arc(region[0], region[1], 17.5, 0, Math.PI * 2)
        this.regionsContext.lineWidth = 9
        this.regionsContext.strokeStyle = 'rgba(0, 204, 249, 0.5)'
        this.regionsContext.stroke()
        this.regionsContext.closePath()
      } else {
        this.regionsContext.beginPath()
        this.regionsContext.arc(region[0], region[1], 8, 0, Math.PI * 2)
        this.regionsContext.fillStyle = 'rgba(255, 255, 255, 0.5)'
        this.regionsContext.strokeStyle = '#fff'
        this.regionsContext.lineWidth = 1
        this.regionsContext.shadowBlur = 13
        this.regionsContext.shadowColor = 'rgba(0, 0, 0, 0.95)'
        this.regionsContext.shadowOffsetX = -1
        this.regionsContext.shadowOffsetY = 1
        this.regionsContext.stroke()
        this.regionsContext.fill()
        this.regionsContext.closePath()
      }
      this.regionsContext.save()

      return region
    },
    canvasCursor (pointer) {
      this.$refs[this.componentId].style.cursor = pointer ? 'pointer' : 'auto'
    },
    providerImage (provider) {
      try {
        return require(`@/core/assets/img/providers/${provider}@2x.png`)
      } catch (err) {
        return require('@/core/assets/img/providers/standard.png')
      }
    }
  }
}
</script>

<style lang="less">
  .dataplant-creation__provider-selection__map {
    position: relative;
    > canvas.land-globe {
      position: absolute;
      top: -15px;
      mask: linear-gradient(to bottom, black 60%, transparent 100%);
      /* hDPI scaling */
      height: calc(~"100vh - 230px");
      width: 100vw;
      z-index: 0;
    }
    > canvas.regions-globe {
      position: absolute;
      top: -15px;
      /* hDPI scaling */
      height: calc(~"100vh - 230px");
      width: 100vw;
      z-index: 1;
    }
    > .selection-tooltip__map {
      position: absolute;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: center;
      align-items: center;
      padding: 10px;
      color: #3E4550;
      background-color: #fff;
      pointer-events: none;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 1;
      border-radius: 5px;
      box-shadow: 0 12px 14px 0 rgba(0, 0, 0, 0.17);
      font-size: 14px;
      transition: opacity 250ms;
      img {
        height: 28px;
      }
      .region-informations {
        margin-left: 8px;
        .provider-name {
          font-weight: 600;
        }
        .provider-region-id {
          font-size: 13px;
          font-style: italic;
          color: #B2BECA;
        }
      }
    }
    &.read-only {
      border: none;
    }
  }
</style>
