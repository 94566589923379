import { API, APIObject } from '@/shared/plugins/Api/API'

class Database extends APIObject {
  constructor (options) {
    // Init
    super('STORAGE', options, true, true)
  }
}

class Databases extends API {
  constructor (ID) {
    super(ID, true, true)
  }

  // Remove Database when remove dataplant
  async remove (dataplantId) {
    return this.request({
      method: 'DELETE',
      url: `databases/${dataplantId}/detach`
    })
  }
}

export default Databases
export {
  Database,
  Databases
}
