/* eslint-disable camelcase */
const moment = require('moment')

function dwhTodb (dwhdb, drivers) {
  let value = dwhdb
  drivers.forEach(driver => {
    value = value.replace(new RegExp(driver + '\\.', 'g'), '')
  })
  return value
}

class DictionaryUtils {
  constructor (metas) {
    this.metas = metas

    this.asDateTime = moment().format('YYYY-MM-DD HH:mm:ss')
    this.supportedStorage = ['mysql', 'postgresql', 'snowflake', 'trino']
    this.supportedLevel = ['data_prim', 'data_mart', 'data_ml']
  }

  weekdays (mode = 0) {
    return mode === 0 ? Array.from({ length: 7 }, (_, i) => i) : Array.from({ length: 7 }, (_, i) => i + 1)
  }

  days () {
    return Array.from({ length: 31 }, (_, i) => i + 1)
  }

  weeks (mode = 0) {
    return mode === 0 ? Array.from({ length: 53 }, (_, i) => i + 1) : Array.from({ length: 54 }, (_, i) => i)
  }

  hours () {
    return Array.from({ length: 24 }, (_, i) => i)
  }

  seconds () {
    return Array.from({ length: 60 }, (_, i) => i)
  }

  minutes () {
    return this.seconds()
  }

  months () {
    return Array.from({ length: 12 }, (_, i) => i + 1)
  }

  quarters () {
    return Array.from({ length: 4 }, (_, i) => i + 1)
  }

  semesters () {
    return [1, 2]
  }

  years (from = 1970) {
    const currentYear = moment().year()
    return Array.from({ length: currentYear - from + 1 }, (_, i) => from + i)
  }

  getRelation () {
    const datawarehouse = this.metas
    const relations = {}

    datawarehouse.relations
      .filter((relation) => relation.relation_type === 'hierarchical')
      .forEach((relation) => {
        const child = relation.attributes_source_names
        const parent = relation.attributes_parent_names

        const childMap = relations[child] || null
        const parentMap = relations[parent] || null

        if (childMap === null) {
          relations[child] = { parents: [parent] }
        } else {
          const list = childMap.parents || []
          list.unshift(parent)
          childMap.parents = list
          relations[child] = childMap
        }

        if (parentMap === null) {
          relations[parent] = { children: [child] }
        } else {
          const list = parentMap.children || []
          list.unshift(child)
          parentMap.children = list
          relations[parent] = parentMap
        }
      })

    return relations
  }

  getDataAvailable () {
    const datawarehouse = this.metas
    const diamondsDate = {}
    let maximum = []
    let minimum = []

    const databases = Object.keys(datawarehouse.databases).filter(
      (dbName) =>
        this.supportedStorage.includes(datawarehouse.databases[dbName].dbms) &&
                this.supportedLevel.includes(datawarehouse.databases[dbName].level_name)
    )

    const tables = Object.entries(datawarehouse.tables)
      .filter(([_, table]) => databases.includes(dwhTodb(table.database, this.supportedStorage)) && table.nb_rows > 0)
      .sort((a, b) => a[1].nb_rows - b[1].nb_rows)

    const diamonds = datawarehouse.diamonds || []

    tables.forEach(([_, table]) => {
      const min = table.min_date || null
      const max = table.max_date || null

      if (min !== null) minimum.push(min)
      if (max !== null) maximum.push(max)
    })

    diamonds.forEach((diamond) => {
      let min = null
      let max = null

      const minimum = diamond.dateMin || null
      const maximum = diamond.dateMax || null

      if (minimum !== null) {
        try {
          min = moment(minimum).format(this.asDateTime)
        } catch (error) {
          min = minimum
        }
      }

      if (maximum !== null) {
        try {
          max = moment(maximum).endOf('day').format(this.asDateTime)
        } catch (error) {
          max = maximum
        }
      }

      diamondsDate[diamond.name] = { min, max }
    })

    minimum = [...new Set(minimum)].sort()
    maximum = [...new Set(maximum)].sort()

    let min = null
    if (minimum.length > 0) {
      try {
        min = moment(minimum[0]).format(this.asDateTime)
      } catch (error) {
        min = minimum[0]
      }
    }

    let max = null
    if (maximum.length > 0) {
      try {
        max = moment(maximum[maximum.length - 1]).endOf('day').format(this.asDateTime)
      } catch (error) {
        max = maximum[maximum.length - 1]
      }
    }

    return { min: min || null, max: max || null, diamondsDate: diamondsDate }
  }

  getDictionary () {
    const datawarehouse = this.metas
    const dictionary = {}
    const entries = datawarehouse.referentials || []
    const aliases = datawarehouse.aliases || {}
    const virtual_attributes = datawarehouse.virtual_attributes || {}

    for (const [, virtual_attribute] of Object.entries(virtual_attributes)) {
      const name = virtual_attribute.name
      if (virtual_attribute.is_dimension) {
        dictionary[name] = { id: name, value: name }
      }
    }

    for (const entry of entries) {
      const id = entry[0]?.toString()
      const value = entry[1]?.toString()

      for (const [alias, aliasValues] of Object.entries(aliases)) {
        if (aliasValues.includes(id)) {
          dictionary[alias] = { id, value }
        }
      }

      dictionary[id] = { id, value }
    }

    return dictionary
  }
}

module.exports = DictionaryUtils
