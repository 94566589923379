import Vue from 'vue'

const Octets = function (octets, precision, override) {
  if (octets < 0) {
    return '0 o'
  }
  if (!octets) { return '0 o' }
  if (isNaN(parseFloat(octets)) || !isFinite(octets)) {
    return '-'
  }
  if (typeof precision === 'undefined') {
    precision = 1
  }
  const units = [
    'o',
    'Ko',
    'Mo',
    'Go',
    'To',
    'Po'
  ]
  const number = Math.floor(Math.log(octets) / Math.log(1024))
  const value = (octets / Math.pow(1024, Math.floor(number)))
  override = (override - 1) || 0
  const max = Math.pow(10, override)
  if (precision < 0) {
    precision = 0
  }
  if (precision > 20) {
    precision = 20
  }
  const digitNbr = precision ? value.toFixed(precision).length - 1 : value.toFixed(precision).length
  if (override && precision && (digitNbr > max.toString().length)) {
    precision = precision - (digitNbr - max.toString().length)
  }
  return `${value.toFixed(precision)} ${units[number]}`
}
Vue.filter('Octets', Octets)
export default Octets
