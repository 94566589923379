/* eslint-disable camelcase */
const _intersection = require('lodash/intersection')
const _minBy = require('lodash/minBy')
const _flatten = require('lodash/flatten')
const DictionaryUtils = require('./DictionaryUtils')
const bucketMetaSchema = require('./DatasetHelpers')

let cacheDatabases = null
let cacheTables = null
let cacheAttributes = null
let cacheVirtualAttributes = null

// for metas endpoint
let cacheMetas = null

/**
 * Metas
 *
 * MetaData loader from DWH component
 *
 */
class Metas {
  /**
   * constructor
   * The only parameter here will be internaly call
   * @param   Object  context   Context will be an instance of QueryBuilder object, giving access to dataplant api calls
   */
  constructor (context) {
    this.context = context
  }

  /**
   * findBestTableByAttributes
   * Get the best table from a list of attributes
   * It will select the table where there is the lower number of line, not empty, and where all attributes from the list are existing
   *
   * @param   Array<String>   attrs   List of attributes which need to exist in the table
   * @return  Object                  Return the table found as an object (definition of object related to DWH API)
   */
  findBestTableByAttributes (attrs = []) {
    let tables = _flatten(attrs
      .map(attr => {
        const vattr = this.virtual_attributes.find(vattr => vattr.name === attr)
        if (vattr) return vattr.required_attributes_names || []
        return attr
      }))
      .map(attr => {
        return this.attributes.filter(a => a.name === attr).map(a => a.table_id)
      })
    tables = _intersection(...tables)
    tables = this.tables.filter(table => {
      return tables.includes(table._id) && table.nb_rows > 0 && !table.hidden
    })

    if (!tables.length) throw new Error('No table found for this query')

    return _minBy(tables, 'nb_rows')
  }

  /**
   * load
   * Load all the databases, tables and attributes from dwh if not already loaded
   */
  async load () {
    if (!this.databases) {
      cacheDatabases = cacheDatabases || this.context.requestAPI({
        url: 'dwh/v4/databases'
      })

      const data = await cacheDatabases
      this.databases = data.filter(db => {
        return ['mart', 'prim', 'ml'].includes(db.level)
      })
    }

    if (!this.tables) {
      cacheTables = cacheTables || this.context.requestAPI({
        url: 'dwh/v4/tables',
        params: {
          filter: JSON.stringify({
            database_id: {
              $in: this.databases.map(db => db._id)
            }
          })
        }
      })

      const data = await cacheTables
      this.tables = data.filter(t => {
        return this.databases.map(db => db._id).includes(t.database_id)
      })
    }

    if (!this.attributes) {
      cacheAttributes = cacheAttributes || this.context.requestAPI({
        url: 'dwh/v4/attributes',
        params: {
          filter: JSON.stringify({
            database_id: {
              $in: this.databases.map(db => db._id)
            }
          })
        }
      })

      const data = await cacheAttributes
      this.attributes = data.filter(t => {
        return this.databases.map(db => db._id).includes(t.database_id)
      })
    }

    if (!this.virtual_attributes) {
      cacheVirtualAttributes = cacheVirtualAttributes || this.context.requestAPI({
        url: 'dwh/v4/virtual/attributes',
        params: {
          filter: JSON.stringify({
            database_id: {
              $in: this.databases.map(db => db._id)
            }
          })
        }
      })

      const data = await cacheVirtualAttributes
      this.virtual_attributes = data
    }
  }

  /**
   * dataset
   * Get dataset with data, attributes, virtual_attributes, tables, databases, diamonds
   */
  async dataset () {
    if (!this.metas) {
      cacheMetas = cacheMetas || this.context.requestAPI({
        url: 'dwh/metas',
        params: {
          mode: 'complete',
          module: 'QB'
        }
      })

      const data = await cacheMetas
      const metas = data?.metas
      const bucket = bucketMetaSchema(metas)
      const dict = new DictionaryUtils(metas)
      const dictionary = dict.getDictionary()
      const data_available = dict.getDataAvailable()
      const relations = dict.getRelation()

      return {
        data: bucket.bucketData,
        acl_attributes: metas.acl_attributes || null,
        compute_mods: Object.keys(bucket.bucketComputes),
        orders: Object.keys(bucket.bucketOrders),
        filters: Object.keys(bucket.bucketFilter),
        scales: Object.keys(bucket.bucketScales),
        evols: bucket.bucketEvols,
        data_available,
        diamonds: bucket.bucketDiamonds || null,
        relations,
        dictionnary: dictionary // There is a misspelling in the Front App
      }
    }
  }
}

module.exports = Metas
