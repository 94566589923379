<template lang="pug">
.td.fp-tree-cell.cell-checkbox
  fpui-input-checkbox(:value="value" @input="update" :disabled="disabledCheckbox")
  fpui-helper(
    v-if="disabledCheckbox && helperContent.text"
    :title="helperContent.title"
    :text="helperContent.text"
  )
</template>

<script>
export default {
  props: {
    item: { type: Object, default: () => ({}) },
    value: { type: Boolean, default: false },
    disabledCheckbox: { type: Boolean, default: () => false },
    helperContent: { type: Object, default: () => { return {} } },
    itemUpdate: { type: Function, required: true }
  },
  methods: {
    update () {
      this.itemUpdate(this.item)
    }
  }
}
</script>

<style lang="less">
.cell-checkbox {
  .fpui-input-checkbox {
    position: absolute;
    top: 6px
  }
  .fpui-helper {
    position: absolute;
    left: 5px;
    top: 3px
  }
}

</style>
