import { render, staticRenderFns } from "./FpTreeFooter.vue?vue&type=template&id=2ac9db82&lang=pug&"
import script from "./FpTreeFooter.vue?vue&type=script&lang=js&"
export * from "./FpTreeFooter.vue?vue&type=script&lang=js&"
import style0 from "./FpTreeFooter.vue?vue&type=style&index=0&id=2ac9db82&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports