<template lang="pug">
.add-tile-modal.fpui-modal
  fp-loader(v-if="loading")
  fpui-modal-header(
    :title="$t('dashbaord.add_tile_modal.title_add')"
    @close="$emit('close')"
    closeTopButton
  )
  fpui-modal-body
    .add-tile-modal-body
      fpui-input-search(
        v-if="value.length"
        v-model="search"
        :placeholder="$t('dashboard.search_query_placeholder')"
      )
      fpui-tree-view.fp-no-margin(
        :columns='columns'
        :value="value"
        :placeholder="{ button: 'queries.new_query', text: 'treeview.placeholder.queries' }"
        :no-header="true"
        :no-multi-select="true"
        :class="{ 'no-value': !value.length }"
        icon="fp4 fp4-queries"
        type="query"
        @add="createQuery"
        @select="v => querySelected = v"
        @unselect="v => querySelected = null"
      )
  fpui-modal-footer(:buttons="buttons")
    fpui-button(
      color="blue-flash"
      reverse
      noshadow
      icon="fp4 fp4-plus"
      icon-left
      auto-width
      @click="createQuery"
    ) {{ $t('dashboard.create_new_query') }}
</template>

<script>
import _get from 'lodash/get'

import CellTags from '@/shared/components/dashboarding/CellTags'

export default {
  props: {
    layout: { type: Object, required: true },
    item: { type: Object, required: true },
    tile: { type: Object, default: () => ({}) },
    noChange: { type: Function, default: () => {} },
    handleChange: { type: Function, required: true }
  },
  data () {
    return {
      querySelected: null,
      search: '',
      loading: false
    }
  },
  computed: {
    disabled () {
      return !this.querySelected
    },
    buttons () {
      return [{
        label: this.$t('cancel'),
        type: 'close',
        handler: () => {
          this.noChange()
          this.$emit('close')
        }
      }, {
        label: this.$t('dashboard.add_query'),
        color: 'blue-flash',
        disabled: this.disabled,
        autoWidth: true,
        handler: async () => {
          await this.handleChange({ query: this.querySelected?.object, newQuery: false })
        }
      }]
    },
    value () {
      return this.$store.getters.QB_QUERIES_FILTERED
        .filter(query => {
          if (!this.search) return true
          if (!query.display_name) return false
          if (query.display_name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) return true
          const path = (_get(query, 'tags.path') || '')
          if (path.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) return true
          return false
        })
        .sort((a, b) => {
          if (a.display_name < b.display_name) return -1
          if (a.display_name > b.display_name) return 1
          return 0
        })
    },
    columns () {
      return [
        {
          label: this.$t('treeview.query_name'),
          target: 'display_name',
          icon: row => {
            switch (row.chart_active) {
              case 'table':
                return 'fp4 fp4-table'
              case 'line':
                return 'fp4 fp4-chart-line'
              case 'area':
                return 'fp4 fp4-chart-area'
              case 'bar':
                return 'fp4 fp4-chart-bar'
              case 'scatter':
                return 'fp4 fp4-chart-scatter'
              case 'pie':
                return 'fp4 fp4-chart-pie'
              case 'big-number':
                return 'fp4 fp4-chart-number'
              case 'gauge':
                return 'fp4 fp4-chart-gauge'
              // case 'combo-chart':
              //   return 'fp4 fp4-chart-multi'
            }
          },
          // Put grow: 5 when uncomment the last 2 columns
          grow: 3,
          rename: {
            target: 'display_name'
          },
          sortable: true
        },
        {
          label: this.$t('treeview.query_tags'),
          target: CellTags,
          grow: 2,
          props: {
            type: 'tags.tags',
            showTooltips: true
          }
        },
        {
          label: this.$t('treeview.query_author'),
          target: (row) => {
            return this.$store.getters.IAM_USER_BY_UID(row.created_by, 'displayname', true)
          },
          grow: 2,
          sortable: true
        },
        {
          label: this.$t('treeview.query_updated_at'),
          grow: 2,
          target: (row) => {
            return row.updated_at ? row.updated_at.format(this.$t('date.formatter')) : '-'
          },
          sortable: true
        }
      ]
    }
  },
  methods: {
    async createQuery () {
      // TODO Check acls query

      this.loading = true
      try {
        // Create new query
        const queryName = this.generateNewName()
        let newQuery = this.$api.QUERY_ADMIN.queries.new({
          tags: {
            path: `Automatically saved from dashboards/${this.item.name}`
          },
          repository: this.$store.getters.QB_REPOSITORY_ACTIVE?._id,
          repository_version: this.$store.getters.QB_REPOSITORY_ACTIVE?.editingVersion?._id,
          name: queryName,
          display_name: queryName
        })
        newQuery = await newQuery.create()
        this.$store.commit('FOREPAAS_QB_QUERY_CREATE', newQuery)

        this.handleChange({ query: newQuery, newQuery: true })
        this.$emit('close')
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
        console.error(err)
      }
      this.loading = false
    },
    generateNewName () {
      const existingQueryNames = this.$store.getters.QB_QUERIES.map(ac => ac.display_name)
      let idx = 0
      let name = this.$t('query.new_query_name')
      while (existingQueryNames.includes(name)) {
        idx++
        name = `${this.$t('query.new_query_name')} (${idx})`
      }

      return name
    }
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";

.add-tile-modal {
  .modal-body {
    height: 491px;
    padding-bottom: 0;

    .add-tile-modal-body {
      height: 100%;
      .fpui-input-search {
        margin-bottom: 15px;
      }
      .fp-no-margin {
        margin: 0 -29px;
        height: calc(~"100% - 48px");

        .actions {
          display: none;
        }

        &.no-value {
          .fp-tree-root.no-header {
            top: 0;
          }
        }
      }
    }
  }
}
</style>
