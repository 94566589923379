import interact from 'interactjs'
import Vue from 'vue'

Vue.directive('resizable', {
  bind (el, { value }) {
    const element = interact(el)
    element.draggable({
      inertia: true,
      autoScroll: true,
      onstart: (event) => {
      },
      onmove: (event) => {
        // keep the dragged position in the data-x/data-y attributes
        const x = (parseFloat(event.target.getAttribute('data-x')) || 0) + event.dx
        // translate the element
        event.target.style.webkitTransform = `translateX(${x}px)`
        event.target.style.transform = event.target.style.webkitTransform
        // update the posiion attributes
        event.target.setAttribute('data-x', x)
      },
      onend: (event) => {
        const x = event.target.getAttribute('data-x')
        event.target.setAttribute('data-x', 0)
        event.target.style.webkitTransform = `translateX(${0}px)`
        event.target.style.transform = event.target.style.webkitTransform
        value(x)
      }
    })
  }
})
