<template lang="pug">
  .story-author
    span.label {{ $t('tile.story.last_updated_by') }}
    .author-container
      fpui-canvas-icon(
        v-if="storyAuthor.displayname"
        :name="storyAuthor.displayname"
        :logo="storyAuthor.logo"
        fontSize="10px"
        :size="32"
        type="circle"
        :letterNumber="2"
        color="#ffffff"
      )
      .name-update-container
        .name {{ storyAuthor.displayname }}
        .updated-at {{ updatedAt }}
</template>

<script>
import moment from 'moment'

export default {
  props: {
    story: { type: Object, default: () => ({}) }
  },
  computed: {
    storyAuthor () {
      return this.$store.getters.IAM_USER_BY_UID(this.story?.updated_by) || {}
    },
    updatedAt () {
      if (!this.story?.updated_at) return '-'
      return moment(this.story?.updated_at).format('DD/MM/YYYY HH:mm')
    }
  }
}
</script>

<style lang="less">
  .story-author {
    min-width: 280px;
    height: 50px;
    display: flex;
    align-items: center;

    .label {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.01em;
      color: #97A7B7;
      max-width: 82px;
    }

    .author-container {
      display: flex;
      align-items: center;
      margin-left: 10px;

      .name-update-container {
        margin-left: 10px;
        .name {
          font-weight: 600;
          font-size: 14px;
          line-height: 15px;
          letter-spacing: -0.01em;
          color: #3E4550;
          margin-bottom: 2px;
        }
        .updated-at {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: -0.01em;
          color: #97A7B7;
        }
      }
    }
  }
</style>
