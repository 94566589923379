<template lang="pug">
  .dataplant-creation__provider-selection__choices
    .provider__choices
      .provider__choice(
        v-for='provider in options.providers'
        @click='selectProvider(provider)'
        :class='{ active: provider.name === options.configuration.dataplant.provider }'
      )
        .provider__choice__img
          img(:src="providerImage(provider)")
        .provider__choice__informations
          .provider__choice__informations__title
            span {{ provider.display_options.display_name }}
          .provider__choice__informations__free-trial(
            v-if="canBeFreeTrialOrStandardPlan && provider.name === 'automatic'"
            :class='{ blue: (!isFreeTrial && isStandardPlan) }'
          )
            i.fp4.fp4-circle-check
            span {{ subText }}

</template>

<script>

export default {
  props: {
    options: { type: Object, required: true },
    organizationId: { type: String, required: true }
  },
  computed: {
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    subscription () {
      return this.organization.subscription
    },
    isStandardPlan () {
      return this.subscription?.plan === 'standard'
    },
    isFreeTrial () {
      return this.subscription?.status === 'in_trial' && this.subscription?.scheduled_status === 'cancelled'
    },
    canBeFreeTrialOrStandardPlan () {
      return (this.isFreeTrial) || (this.subscription?.plan === 'standard')
    },
    subText () {
      if (this.isFreeTrial) return this.$t('dataplant.creation.free_trial')
      else if (this.isStandardPlan) return this.$t('dataplant.creation.standard_plan')
      return ''
    },
    regions () {
      const regions = this.options.providers
        .filter((p) => {
          if (this.options.configuration.dataplant.provider) {
            return p.name === this.options.configuration.dataplant.provider
          }
          return true
        }).map((p) => {
          return p.regions.map((r) => {
            return {
              id: r.name,
              provider: r.provider,
              providerName: p.display_options?.display_name,
              regionName: r.display_options?.display_name,
              lng: r.display_options?.longitude,
              lat: r.display_options?.latitude
            }
          })
        })
      return regions.reduce((a, b) => a.concat(b), [])
    }
  },
  methods: {
    selectProvider (provider) {
      this.$emit('update', { 'configuration.dataplant.provider': provider?.name })
      if (this.options.configuration.dataplant.provider && this.regions.length) {
        const firstRegion = this.regions[0]
        this.$emit('update',
          {
            'configuration.dataplant.region': {
              id: firstRegion.id,
              provider: firstRegion.provider,
              providerName: firstRegion.providerName,
              regionName: firstRegion.regionName
            }
          }
        )
      } else {
        this.$emit('update', { 'configuration.dataplant.region': null })
      }
    },
    providerImage (provider) {
      try {
        return require(`@/core/assets/img/providers/${provider?.name}@2x.png`)
      } catch (err) {
        return require('@/core/assets/img/providers/standard.png')
      }
    }
  }
}
</script>

<style lang="less">
  .dataplant-creation__provider-selection__choices {
    position: relative;
    z-index: 2;
    padding: 0 30px;
    > .provider__choices {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-wrap: wrap;
      > .provider__choice {
        display: flex;
        align-items: center;
        height: 50px;
        padding: 6px 13px;
        background-color: rgba(255, 255, 255, 0.75);
        border: 3px solid transparent;
        filter: drop-shadow(0 12px 8px rgba(0, 0, 0, 0.2));
        border-radius: 7px;
        cursor: pointer;
        transition: filter 800ms, border-color 250ms, background-color 500ms;
        margin-bottom: 8px;
        user-select: none;
        &:not(:last-child) {
          margin-right: 8px;
        }
        &.active, &:hover {
          filter: drop-shadow(0 12px 8px rgba(0, 0, 0, 0.2)) drop-shadow(0 0px 8px rgba(255, 255, 255, 0.5));
          border-color: #00CFFE;
          background-color: #FFF;
        }
        > .provider__choice__img {
          height: 28px;
          width: 28px;
          border-radius: 5.5px;
          background-color: #fff;
          margin-right: 9px;
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
          img {
            height: 26px;
          }
        }
        > .provider__choice__informations {
          > .provider__choice__informations__title {
            color: #3E4550;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 18px;
            span {
              text-transform: capitalize;
            }
          }
          > .provider__choice__informations__free-trial {
            display: inline-flex;
            align-items: center;
            background-color: #9EDF10;
            border-radius: 8px;
            padding: 2px 6px 2px 2px;
            &.blue {
              background-color: #00CCF9;
            }
            > span {
              font-size: 9px;
              text-transform: uppercase;
              color: #fff;
              font-weight: bold;
              margin-left: 2px;
              line-height: 0;
            }
            > i {
              font-size: 10px;
              color: #fff;
              vertical-align: 10%;
            }
          }
        }
      }
    }
  }
</style>
