import { APIObject } from '@/shared/plugins/Api/API'

class Configuration extends APIObject {
  constructor (options) {
    // Init
    super('QUERY_ADMIN', options)

    // Default variables
    this.active = this.active || false
    this.query_engine = this.query_engine || null
  }

  async list () {
    return this.request({
      method: 'GET',
      url: 'v3/configuration'
    })
  }

  async getMetrics () {
    return super.request({
      method: 'GET',
      url: 'v3/metrics'
    })
  }

  async save () {
    return super.save({
      method: 'POST',
      url: 'v3/configuration',
      params: {
        active: this.active,
        query_engine: this.query_engine
      }
    })
  }
}

export default Configuration
