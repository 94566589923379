<template lang="pug">
</template>

<script>
export default {
  mounted () {
    window.opener.postMessage({
      id: 'auth',
      op: 'ADD_LINKED_ACCOUNT'
    }, '*')

    window.close()
  }
}
</script>

<style lang="less">
</style>
