<template lang="pug">
.subscription-new-step-committed
  .header
    h4 {{ 'committeds.new' | translate }}
    p(v-html="$t('committeds.new-modal-subtitle')")
    //- If var is put in v-html -> use $sanitize()

  committeds(:value="committeds",@input="v=>$emit('committeds',v)",:standalone="false")
</template>
<script>

import Committeds from '@/core/components/Subscription/Committeds.vue'
export default {
  components: {
    Committeds
  },
  props: {
    committeds: {
      type: Array,
      required: true
    }
  }
}
</script>
<style lang="less">

</style>
