<template lang="pug">
.chart-line
  fpui-echarts(:options="options")
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import EchartsFormatter from './EchartsFormatter'

export default {
  props: {
    value: { type: Object, default: () => {} }
  },
  computed: {
    options () {
      const value = _cloneDeep(this.value)
      const options = new EchartsFormatter(value)
      return options
    }
  }
}
</script>

<style lang="less">
.chart-line {
  position: relative;
  height: 100%;
}
</style>
