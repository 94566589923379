import { API } from '@/shared/plugins/Api/API'

class Acls extends API {
  list () {
    return this.request({
      method: 'get',
      url: 'v4/services_acls'
    })
  }
}

export default Acls

export {
  Acls
}
