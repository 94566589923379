<template lang='pug'>
.modal-header(
  :style="{ 'border-bottom': unbordered ? 'none' : '1px solid rgba(151,167,183,0.21)' }"
)
  .actions
    fpui-button(
      v-if="headerButton"
      :color="headerButton.color || 'white'"
      :icon="headerButton.icon"
      icon-left
      auto-width
      @click.stop="clickHeaderButton()"
    ) {{ headerButton.text }}
    i.fp4.fp4-xmark(v-if="closeTopButton" @click.stop='close()' type="button")
    i.fp4.fp4-circle-check(v-if="checkTopButton" @click.stop="$emit('check')" type="button")
  h3(v-if="icon || image || title" v-tooltip="title")
    i(
      v-if="icon"
      :class="icon"
      :style="{ 'font-size': iconFontSize, 'color': iconColor }"
    )
    img(v-if="image" :src="image")
    .modal-title(
      v-if='title'
      id="modalHeaderTitle"
      v-html="$sanitize(title) || ' '"
    )
  slot
</template>

<script>

export default {
  props: {
    image: { type: String, default: '' },
    icon: { type: String, default: '' },
    iconFontSize: { type: String, default: '25px ' },
    iconColor: { type: String, default: '' },
    title: { type: String, default: '' },
    hideClose: { type: Boolean, default: false },
    closeTopButton: { type: Boolean, default: false },
    checkTopButton: { type: Boolean, default: false },
    unbordered: { type: Boolean, default: false },
    headerButton: { type: Object, default: () => {} }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    clickHeaderButton () {
      if (this.headerButton && this.headerButton.click) {
        return typeof this.headerButton.click === 'function' ? this.headerButton.click() : this.headerButton.click
      }
    }
  }
}
</script>

<style lang='less'>
  @import "~@/shared/styles/_variables.less";

  .modal-header {
    position: relative;
    z-index: 1;
    height: 56px;
    width: 100%;
    h3 {
      font-weight: 400!important;
      padding: 14px 29px 0 29px;
      margin: 0;
      color: #3E4550;
      line-height: 20px;
      font-size: 20px !important;
      line-height: 1.42857143;
      align-items: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      text-overflow: ellipsis;
      white-space: nowrap;
      img {
        width: 36px;
        margin-right: 5px;
      }
      i {
        margin-right: 9px;
      }
    }
    .actions {
      height: 20px;
      top: 17px;
      right: 21px;
      position: absolute;
      display: flex;
      align-items: center;
      i {
        cursor: pointer;
        border: none;
        background: transparent;
        color: #97A7B7;
        font-size: 20px;
        margin-left: 15px;
        transition: all 100ms ease-in-out;
        &.disabled {
          color: lighten(@blue, 20%);
          cursor: not-allowed;
        }
        &:hover {
          color: #3E4550;
        }
      }
    }
  }
</style>
