import { API, APIObject } from '@/shared/plugins/Api/API'
import _set from 'lodash/set'

class Instance extends APIObject {
  constructor (options) {
    // Init
    super('STORAGE', options, true, true)

    this.fpu_type = 'fpu-s'
    this.organization_id = this.organization_id || ''
  }

  get sizeCalculated () {
    let sizeCalculated = 0
    if (this.engine !== 'polar-data') {
      const instanceCount = this.desired_state?.instance_count || this.instance_count
      sizeCalculated = !instanceCount ? 0 : instanceCount * (this.desired_state?.fpu || this.fpu)
    }
    return sizeCalculated
  }

  // Deploys an instance
  async create (configuration) {
    return super.create({
      method: 'POST',
      url: 'instances',
      data: {
        instance: this,
        configuration
      }
    })
  }

  // Update an instance by instance_id
  async save (configuration) {
    return super.save({
      method: 'PUT',
      url: `instances/${this.id}`,
      data: {
        instance: this,
        configuration
      }
    }, true)
    // If websocket, remove the true here
  }

  // Delete an instance by instance_id
  async remove () {
    return this.request({
      method: 'DELETE',
      url: `instances/${this.id}`
    })
  }

  async attach (dataplantId, instanceId) {
    const response = await this.request({
      method: 'POST',
      url: `databases/${dataplantId}/attach`,
      data: {
        configuration: {
          data_prim: instanceId,
          data_mart: instanceId,
          data_ml: instanceId
        }
      }
    })
    return response
  }

  async detach (dataplantId) {
    const response = await this.request({
      method: 'DELETE',
      url: `databases/${dataplantId}/detach/${this.id}`
    })
    return response
  }
}

class Instances extends API {
  constructor (ID) {
    super(ID, true, true)
  }

  // List instances by organization_id
  async listByOrganization (organizationId) {
    const instances = await this.request({
      method: 'get',
      url: '/instances',
      params: {
        organization_id: organizationId
      }
    })

    return {
      instances: instances
        .filter(i => i.status !== 'Deleted')
        .map(i => {
          try {
            return new Instance(i)
          } catch (err) {
            console.error(err.stack)
            return null
          }
        }).filter(i => i),
      organizationId: organizationId
    }
  }

  // List sandbox instances - Use in DM settings
  async listSandboxes (organizationId, clusterId) {
    const sandboxes = await this.request({
      method: 'get',
      url: '/instances/sandboxes',
      params: {
        organization_id: organizationId,
        cluster: clusterId
      }
    })

    return sandboxes
      .filter(i => i.status !== 'Deleted')
      .map(i => {
        i.engine_original = i.engine
        i.engine = 'sandbox'
        i.display_name = 'Sandbox'
        i.description = 'Data Platform Engine'
        i.provider = ''
        i.region = ''
        i.display_region = ''
        i.fpu = ''
        i.fpu_type = ''
        return new Instance(i)
      }).filter(i => i)
  }

  // Status of attaching instance in Dataplant
  async listStatusAttachingInstances (dataplantId) {
    return this.request({
      method: 'GET',
      url: `instances/attached/${dataplantId}`
    })
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Instance(item)
  }
}

export default Instances
export {
  Instance,
  Instances
}
