import { API } from '@/shared/plugins/Api/API'
import Accounts from './Accounts'

class BILLING extends API {
  constructor (id) {
    super(id, true)
    this.accounts = new Accounts(id)
  }

  async loadSubscription (id) {
    return await this.request({
      method: 'GET',
      url: `v1/organizations/${id}/subscription`
    })
  }

  async loadCommitteds (id) {
    return await this.request({
      method: 'GET',
      url: `v1/organizations/${id}/subscriptions/committed`
    })
  }

  async createSubscription (id, data) {
    return await this.request({
      method: 'POST',
      url: `v1/organizations/${id}/subscription`,
      data
    })
  }

  async cancelSubscription (id) {
    return await this.request({
      method: 'DELETE',
      url: `v1/organizations/${id}/subscription/cancel`
    })
  }

  async reactivateSubscription (id) {
    return await this.request({
      method: 'PUT',
      url: `v1/organizations/${id}/subscription/reactivate`
    })
  }

  async createCommitteds (id, data) {
    return await this.request({
      method: 'POST',
      url: `v1/organizations/${id}/subscriptions/committed`,
      data: data
    })
  }

  async loadConsumptions (id) {
    return await this.request({
      method: 'GET',
      url: `v1/organizations/${id}/consumptions`
    })
  }

  async getInvoices (id) {
    return await this.request({
      method: 'GET',
      url: `v1/organizations/${id}/invoices`
    })
  }
}

export default BILLING
