import { API } from '@/shared/plugins/Api/API'
import Authentications from './Authentications'
import Users from './Users'

class KING extends API {
  constructor (id) {
    super(id, true)
    this.users = new Users(id)
    this.authentications = new Authentications(id)
  }

  async resetMFA () {
    return this.request({
      method: 'post',
      url: 'v4/mfa/register/authenticator',
      retries: 0
    })
  }

  async logout () {
    return this.request({
      method: 'delete',
      url: 'v4/logout',
      retries: 0
    })
      .catch(err => {
        if (err.response.status === 400) return null
        if (err.response.status === 401) return null
        throw err
      })
  }

  async getToken (authenticationId) {
    const { authentication_providers: ap } = await this.request({
      method: 'GET',
      url: 'v4/checksession',
      retries: 0
    })
    return ap.find(a => a.id === authenticationId)?.refresh_token
  }
}

export default KING
