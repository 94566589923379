import { API, APIObject } from '@/shared/plugins/Api/API'
import _omit from 'lodash/omit'
import _set from 'lodash/set'


class Attribute extends APIObject {
  constructor (options) {
    // Init
    super('DM', options)

    this.tags.selected = typeof (this.tags.selected) === 'boolean' ? this.tags.selected : true
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by'
    ])
    return obj
  }

  async createRule (rule) {
    const res = await this.request({
      method: 'POST',
      url: 'v4/rules',
      data: rule
    })
    return res
  }
}

class Attributes extends API {
  list (params = {}) {
    return this.request({
      method: 'get',
      url: 'v4/attributes',
      params
    })
      .then(attributes => {
        return attributes.map(attribute => {
          try {
            return new Attribute(attribute)
          } catch (err) {
            console.error(err.stack)
            return null
          }
        }).filter(i => i)
      })
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Attribute(item)
  }
}

export default Attributes
export {
  Attribute,
  Attributes
}
