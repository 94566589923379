import { API, APIObject } from '@/shared/plugins/Api/API'
import _omit from 'lodash/omit'
import _set from 'lodash/set'
import Vue from 'vue'

class KnowledgeBase extends APIObject {
  constructor (options) {
    // Init
    super('GAB', options)

    // Default variables
    this.display_name = this.display_name || ''
    this.description = this.description || ''
    this.tags = this.tags || {}
    this.tags.path = this.tags.path || ''
    this.tags.tags = this.tags.tags || []
    this.documents = this.documents || []
    this.data_catalog_connections = this.data_catalog_connections || []
    this.object_store_buckets = this.object_store_buckets || []
    this.data_manager_tables = this.data_manager_tables || []
    this.git_repositories = this.git_repositories || []
    this.websites = this.websites || []
    this.speech_to_text = this.speech_to_text || []
    // Possible statuses:
    // SUBMITTED, QUEUED, BUILDING, PROVISIONING, PROCESSING
    // STOPPED, SUCCESS, FAILED, TIMEOUT, UNKNOWN
    this.status = this.status || null

    this.__ingestingLoading = false
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      'id',
      'createdAt',
      'updatedAt',
      'createdBy',
      'updatedBy',
      '__ingestingLoading',
      'name',
      '__editedName'
    ])
    return obj
  }

  /**
  * Creates a new knowledge base
  */
  async create (data) {
    return super.create({
      method: 'POST',
      url: 'v1/knowledge_bases',
      data
    })
  }

  /**
  * Save a knowledge base
  */
  async save () {
    return super.save({
      method: 'PUT',
      url: `v1/knowledge_bases/${this.id}`
    })
  }

  /**
  * Removes a knowledge base
  */
  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v1/knowledge_bases/${this.id}`
    })
  }

  /**
  * Starts ingesting data
  */
  async start () {
    this.ingestingLoading = true
    try {
      const kb = await this.request({
        method: 'POST',
        url: `v1/knowledge_bases/${this.id}/start`
      })
      this.assign(kb)
    } catch (error) {
      Vue.fpuiMessageBlock.error(error)
    } finally {
      this.ingestingLoading = false
    }
  }

  /**
  * Stops ingesting data
  */
  async stop () {
    this.ingestingLoading = true
    try {
      const kb = await this.request({
        method: 'DELETE',
        url: `v1/knowledge_bases/${this.id}/stop`
      })
      this.assign(kb)
    } catch (error) {
      Vue.fpuiMessageBlock.error(error)
    } finally {
      this.ingestingLoading = false
    }
  }

  async refresh () {
    const kb = await this.request({
      method: 'GET',
      url: `v1/knowledge_bases/${this.id}`
    })
    this.assign(kb)
  }

  get isIngesting () {
    return ['SUBMITTED', 'QUEUED', 'BUILDING', 'PROVISIONING', 'PROCESSING'].includes(this.status)
  }

  get isIngestSuccess () {
    return ['SUCCESS'].includes(this.status)
  }

  get isIngestFailed () {
    return ['FAILED', 'TIMEOUT', 'UNKNOWN'].includes(this.status)
  }

  get isIngestStopped () {
    return ['STOPPED'].includes(this.status)
  }

  get ingestingLoading () {
    return this.__ingestingLoading
  }

  set ingestingLoading (value) {
    this.__ingestingLoading = value
  }

  get sources () {
    return [
      ...this.data_manager_tables,
      ...this.object_store_buckets,
      ...this.data_catalog_connections,
      ...this.speech_to_text,
      ...this.git_repositories,
      ...this.documents,
      ...this.websites
    ]
  }
}

class KnowledgeBases extends API {
  async list () {
    const data = await this.request({
      method: 'GET',
      url: 'v1/knowledge_bases'
    })
    return data.map(kb => new KnowledgeBase(_omit(kb, [])))
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new KnowledgeBase(item)
  }
}

export default KnowledgeBases
export {
  KnowledgeBase,
  KnowledgeBases
}
