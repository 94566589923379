<template lang="pug">
  .table-details
    .closed(v-if="!isShow")
      a.button-toggle(@click="toggle()")
        i.fp4.fp4-angle-right
      .title {{ $t('query.fields.table_details.title') }}
    .opened(v-if="isShow")
      .title-container
        .title {{ $t('query.fields.table_details.title.hide') }}
        a.button-toggle(@click="toggle()")
          i.fp4.fp4-angle-left
      fpui-input-select-categories(
        is-filter
        v-model='tableId',
        :options='tablesOptions',
        :placeholder="$t('query.fields.table_details.select.placeholder')"
        :disabled='!tableId'
        :clearable="false"
        :categories='tableCategories'
      )
      fpui-input-search.search-attributes(
        v-model='search'
        :placeholder="$t('query.fields.table_details.search.placeholder')"
      )
      .attribute-container(v-if="Object.keys(attributesList).length")
        .attribute(v-for="attribute in attributesList")
          .name {{ attribute.name }}
          .type {{ attribute.type.main }}

</template>

<script>
import _orderBy from 'lodash/orderBy'

export default {
  props: {
    latestUsedTable: { type: String, default: null }
  },
  data () {
    return {
      isShow: false,
      tableId: null,
      search: '',
      tableCategories: [
        {
          // To handle if there are more than one table used in the future
          label: this.$t('query.fields.table_details.select.used_table'),
          id: 'used_tables',
          highlight: true
        }, {
          label: this.$t('query.fields.table_details.select.all_tables'),
          id: 'all_tables',
          all: true
        }
      ]
    }
  },
  computed: {
    tables () {
      return this.$store.getters.DWH_METAS_TABLES
    },
    tableSelected () {
      return this.tables.find(table => table._id === this.tableId)
    },
    tablesOptions () {
      return this.tables
        .filter(table => !table.database_name.includes('data_ml'))
        .map(table => {
          return {
            label: table.display_name,
            value: table._id,
            category: this.latestUsedTable && this.latestUsedTable === table.display_name ? 'used_tables' : null
          }
        })
    },
    usedTables () {
      const usedAttributes = Object.keys(this.fields)
      const tables = this.tables.filter(table => {
        return usedAttributes.some(attribute => {
          const attributes = this.$store.getters.DWH_ATTRIBUTES_BY_TABLE_ID_BY_ID(table?._id).map(att => att.name)
          return attributes.includes(attribute)
        })
      })
      return Array.from(tables, t => t.table_name)
    },
    attributesList () {
      if (!this.tableSelected) return []
      const attributes = this.$store.getters.DWH_ATTRIBUTES_BY_TABLE_ID_BY_ID(this.tableSelected._id)
      if (!this.search) return _orderBy(attributes, ['name'], ['asc'])
      const attributesFiltered = _orderBy(attributes, ['name'], ['asc'])
        .filter(attr => attr.name.toLowerCase().includes(this.search.toLowerCase()))
      return attributesFiltered
    }
  },
  watch: {
    latestUsedTable: {
      handler (value) {
        if (value) {
          const v = value.split('.').pop()
          this.tableId = this.getTableIdFromTableName(v)?._id
        }
      }
    }
  },
  mounted () {
    if (this.tablesOptions.length > 0) {
      if (this.latestUsedTable) this.tableId = this.getTableIdFromTableName(this.latestUsedTable)?._id
      else this.tableId = this.tablesOptions[0].value
    }
  },
  methods: {
    toggle () {
      this.isShow = !this.isShow
      this.$emit('toggle', this.isShow)
    },
    getTableIdFromTableName (name) {
      return this.tables.find(table => table.display_name === name)
    }
  }
}
</script>
<style lang="less">
.table-details {
  height: 100%;
  .button-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    height: 20px;
    width: 20px;
    border: 1px solid rgba(151,167,183,0.21);
    border-radius: 5px;
    color: @grey;
    cursor: pointer;
  }
  .title {
    height: 14px;
    display: flex;
    color: #97A7B7;
    letter-spacing: -0.01em;
    text-align: right;
    font-size: 12px;
  }
  .closed {
    width: 60px;
    .button-toggle {
      margin: 10px 10px 0px 30px;
    }
    .title {
      margin: 20px 10px 20px 0px;
    }
  }
  .opened {
    width: 254px;
    height: 100%;
    padding: 10px 10px 10px 20px;
    .title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }
    .search-attributes {
      margin-top: 8px;
      margin-bottom: 12px;
      i.fp4 {
        z-index: 0;
      }
    }
    .attribute-container {
      height: calc(~"100% - 100px");
      overflow-y: auto;
      margin-right: -10px;
      padding-right: 10px;
      .attribute {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.01em;
        margin-bottom: 15px;
        .name {

        }
        .type {
          text-transform: uppercase;
          color: #97A7B7;
        }
      }
    }
  }
  .fpui-input-select-categories-container .content .dropdown {
    width: 130%;
    ul {
      max-height: 300px;
    }
  }
}
</style>
