<template lang='pug'>
.fpui-table_cell-component
  span.fpui-table_cell-content(
    v-html='$sanitize(cellContent)'
    v-tooltip="{ content: tooltip, classes: ['fit-content'], placement: tooltipPlacement, delay: tooltipDelay }"
    :style="column.style"
    @click="onClick"
  )
</template>

<script>
import _get from 'lodash/get'

export default {
  name: 'FpuiTableCell',
  props: {
    row: {
      type: Object,
      required: true
    },
    column: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number,
      required: true
    }
  },
  computed: {
    tooltip () {
      if (this.column.tooltip === false) return null

      let content = ''
      switch (typeof (this.column.tooltip)) {
        case 'string':
          content = this.column.tooltip
          break
        case 'function':
          content = this.column.tooltip(this.row)
          break
        default:
          content = this.cellContent
      }
      if ((content + '').length < 10) return null
      return content
    },
    tooltipPlacement () {
      if (this.column.tooltipPlacement) return this.column.tooltipPlacement
      return 'top'
    },
    tooltipDelay () {
      if (this.column.tooltipDelay || this.column.tooltipDelay === 0) return this.column.tooltipDelay
      return '500'
    },
    cellContent () {
      switch (typeof (this.column.target)) {
        case 'string':
          return _get(this.row, this.column.target, '-')
        case 'function':
          return this.column.target(this.row, this.rowIndex)
      }
      return '-'
    }
  },
  methods: {
    onClick () {
      if (this.column.click) {
        this.column.click(this.row)
      }
    }
  }
}
</script>
