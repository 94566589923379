<template lang="pug">
.overview-container.no-am-placeholder.fp-page
  fp-loader(v-if="loading")
  .fp-container
    i.icon.fp4(
      :class="icon"
    )
    .title {{ $t('overview.no_am.title') }}
    .text {{ $t('overview.no_am.text') }}
    a.info-container(
      href="https://docs.forepaas.io/#/en/product/am/resources"
      target="_blank"
    )
      .img-container
        .img
          img(:src="require('@/core/components/Dataplant/assets/am.svg')")
      .text-container
        .title-info {{ $t('overview.no_am.info.title') }}
        .text-info {{ $t('overview.no_am.info.text') }}
    .button
      fpui-button(
        :auto-width="true"
        color='blue-flash'
        @click='activateAm'
      ) {{ $t('overview.button.activate_am') }}
</template>

<script>
import Config from '@/shared/Config'

export default {
  props: {
    icon: { type: String, default: '' }
  },
  data () {
    return {
      loading: false,
      service: 'am'
    }
  },
  computed: {
    projectId () {
      return this.$route.params.projectId || this.$route.params.dataplantId
    },
    project () {
      return this.$store.getters.DATAPLANT_BY_ID(this.projectId)
    }
  },
  async mounted () {
    this.config = await Config()
  },
  methods: {
    async activateAm () {
      this.loading = true
      try {
        const packageUrl = `${this.config.STORE}/v1/packages/project/modules/am/latest`
        await this.project.addService(this.projectId, packageUrl)
        this.$fpuiMessageBlock.success(this.$t('services.add_services.creating_am'))
        window.location.reload()
      } catch (error) {
        this.$fpuiMessageBlock.error(this.$t('services.add_services.creating_am.error'))
        throw new Error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less">
.no-am-placeholder{
  margin-top: 40px;
  background: rgba(246, 249, 252, 0.3);
  .fp-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 561px;
    text-align: center;
    .icon {
      font-size: 72px;
      color: #97a7b7;
    }
    .title {
      margin-top: 15px;
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      color: #3e4550;
    }
    .text {
      margin-top: 20px;
      font-size: 14px;
      line-height: 25px;
      color: #3e4550;
    }
    .info-container {
      margin-top: 20px;
      padding: 10px;
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #E9ECF0;
      box-shadow: 0px 2px 4px rgba(52, 65, 78, 0.05);
      border-radius: 8px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .img-container {
        width: 180px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        background-color: #F6F9FC;
        border: 1px solid #E9ECF0;
        border-radius: 6px;
          .img {
            img {
              height: 48px;
            }
          }
      }
      .text-container {
        text-align: left;
        .title-info {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #3e4550;
        }
        .text-info {
          margin-top: 10px;
          font-size: 14px;
          line-height: 25px;
          color: #3e4550;
        }
      }
    }
    .button {
      margin-top: 20px;
    }
  }
}
</style>
