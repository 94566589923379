<template lang="pug">
.add-tile-container(
  :class="{ disabled }"
  v-tooltip.top-end="disabled ? { content: $t('dashboard.cannot_add_more_on_row'), delay: 0 } : ''"
  @click="addChart"
)
  span +
</template>

<script>
export default {
  props: {
    disabled: { type: Boolean, default: false }
  },
  methods: {
    addChart () {
      this.$emit('add-chart')
    }
  }
}
</script>

<style lang="less">
.add-tile-container {
  height: 100%;
  background: #CBD3DB;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  font-size: 36px;
  cursor: pointer;
  transform: matrix(-1, 0, 0, 1, 0, 0);

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:not(.disabled) {
    background: #97A7B7;
    .input-transition();
  }
}
</style>
