<template lang="pug">
.echarts-container(v-if="!theOptions.noData")
  .legend-custom(
    v-if="theOptions.legend.custom"
    :style="theOptions.legend.style"
  )
    .legend-item(
      v-for="(serie, id) in theOptions.series"
      v-if="!serie.noLegend"
      :style="theOptions.legend.itemStyle"
    )
      i.fa.fa-circle(
        :style="{ color: serie.color || serie.itemStyle.color || theOptions.color[id] || '#000' }"
        v-if="serie.type !== 'line'"
      )
      span.line-legend(
        :style="{ background: serie.color || serie.itemStyle.color || theOptions.color[id] || '#000' }"
        v-if="serie.type === 'line'"
      )
      .label {{ serie.name }}
  echarts.echarts(
    :options="theOptions"
    :autoresize="true"
    :class="{ 'no-cursor': !displayCursor }"
    @mouseover="mouseover"
    @mouseout="mouseout"
    @click="onClickFunction"
  )
  .max-gauge(
    v-if="theOptions.series[0].type === 'gauge' && options.displayMax"
  ) {{ options.maxGauge }}
no-metrics(v-else :daysInfo="noDataDays")
</template>

<script>
import _merge from 'lodash/merge'

import Duration from '@/shared/filters/Duration'
import DurationLetter from '@/shared/filters/DurationLetter'
import Bytes from '@/shared/filters/Bytes'

import NoMetrics from './NoMetrics/NoMetrics.vue'

export default {
  components: { NoMetrics },
  props: {
    options: { type: Object, required: true },
    mouseover: { type: Function, required: false, default: () => {} },
    mouseout: { type: Function, required: false, default: () => {} },
    onClick: { type: Function, required: false, default: null },
    noDataDays: { type: String, required: false, default: '' }
  },
  data () {
    return {
      axisColor: '#97A7B7',
      gridLineColor: '#F3F5F6'
    }
  },
  computed: {
    theOptions () {
      const yAxisRight = this.options.series.filter(serie => serie.yAxisIndex)
      const options = _merge({}, {
        grid: {
          left: 32,
          top: 25,
          bottom: 40,
          right: yAxisRight.length ? 25 : 0
        },
        yAxis: [{
          splitNumber: 2,
          type: 'value',
          axisLine: {
            show: false
          },
          axisLabel: {
            inside: true,
            fontFamily: 'Source Sans Pro',
            fontSize: 11,
            padding: [0, 0, 4, 0],
            verticalAlign: 'bottom',
            color: [this.axisColor]
          },
          splitLine: {
            lineStyle: {
              shadowColor: this.gridLineColor,
              shadowOffsetX: -30,
              color: [this.gridLineColor]
            }
          },
          offset: 40,
          axisTick: {
            show: false
          }
        },
        {
          splitNumber: 2,
          type: 'value',
          axisLine: {
            show: false
          },
          axisLabel: {
            inside: true,
            fontFamily: 'Source Sans Pro',
            fontSize: 11,
            padding: [0, 0, 4, 0],
            verticalAlign: 'bottom',
            color: [this.axisColor]
          },
          splitLine: {
            lineStyle: {
              shadowColor: this.gridLineColor,
              shadowOffsetX: 25,
              color: [this.gridLineColor]
            }
          },
          offset: 30,
          axisTick: {
            show: false
          }
        }],
        xAxis: {
          type: 'category',
          axisLine: {
            show: false
          },
          axisLabel: {
            color: [this.axisColor],
            fontSize: 11,
            padding: [5, 0, 0, 0]
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        tooltip: {
          trigger: 'axis',
          show: true,
          axisPointer: {
            type: 'none'
          },
          backgroundColor: 'white',
          textStyle: {
            color: '#3E4550',
            fontFamily: 'Source Sans Pro',
            fontSize: '11px',
            textShadowOffsetX: 10
          },
          padding: [15, 20, 10, 20],
          extraCssText: 'box-shadow: 0 10px 30px 0 rgba(151,167,183,0.3); width: auto; border-radius: 10px;',
          formatter: (params) => {
            if (options.series[0].type !== 'heatmap') {
              let label = Array.isArray(params) ? params[0].axisValue : params.name

              // Label formatter
              const labelFormatter = options.series.find(serie => serie.tooltipLabelFormatter)
              if (typeof labelFormatter?.tooltipLabelFormatter === 'function') label = labelFormatter?.tooltipLabelFormatter(label || params.data[0])
              if (labelFormatter?.tooltipLabelFormatter === 'duration') label = Duration(label, labelFormatter.tooltipLabelFormatterDurationDecimal || 0)
              if (labelFormatter?.tooltipLabelFormatter === 'bytes') label = Bytes(label)
              if (labelFormatter?.tooltipLabelFormatter === 'durationLetter') label = DurationLetter(label)

              const colorSpan = color => `
                <span style="display:inline-block; margin-right:10px; border-radius:100%; width:7px; height:7px; background-color: ${color}"></span>
              `
              let html = `
                <div style="margin-bottom: 10px; font-size: 13px;">
                  <strong>${label}</strong>
                </div>
              `

              // If params is array (trigger: axis)
              if (Array.isArray(params)) {
                let hide = true
                params.forEach((item, i) => {
                  const { tooltipFormatter, tootlipDecimal, noTooltip, valueFormatter, doNotHideTooltipIfNoValue } = this.options.series[i]
                  if (!noTooltip) {
                    let value = item.data

                    // Get value by type of data
                    if (Array.isArray(value)) value = value.length ? value[1] : value
                    else if (value && typeof item.data === 'object') value = item.data.value

                    // Format value in tooltip
                    if (value || doNotHideTooltipIfNoValue) {
                      hide = false
                      if (tooltipFormatter === 'duration') value = Duration(value)
                      if (tooltipFormatter === 'bytes') value = Bytes(value)
                      if (tooltipFormatter === 'durationLetter') value = DurationLetter(value)
                      if (tooltipFormatter === 'valueAxis') value = value.length ? value[1] : value
                      if (value && typeof value === 'number') value = value.toFixed(tootlipDecimal || 0)
                      if (valueFormatter) value = valueFormatter(value)
                      if (!value && value !== 0) value = '-'
                      const line = `
                        <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px; margin-top; 5px; font-size: 11px;">
                          <span style="margin-right: 8px;">${colorSpan(item.color)} ${item.seriesName}</span>
                          <span style="font-size: 13px;"><strong>${value}</strong></span>
                        </div>
                      `
                      html += line
                    }
                  }
                })
                if (hide) {
                  html = ''
                }
              } else {
                // If params is object (trigger: item)
                const { tooltipFormatter, tootlipDecimal, valueFormatter, noTooltip } = this.options.series[0]
                if (!noTooltip) {
                  let value = params.data

                  // Get value by type of data
                  if (Array.isArray(value)) value = value.length ? value[1] : value
                  else if (value && typeof params.data === 'object') value = params.data.value

                  // Format value in tooltip
                  if (tooltipFormatter === 'duration') value = Duration(value)
                  if (tooltipFormatter === 'bytes') value = Bytes(value)
                  if (tooltipFormatter === 'DurationLetter') value = DurationLetter(value)
                  if (tooltipFormatter === 'valueAxis') value = value.length ? value[1] : value
                  if (value && typeof value === 'number') value = value.toFixed(tootlipDecimal || 0)
                  if (valueFormatter) value = valueFormatter(value)
                  if (!value && value !== 0) value = '-'
                  const line = `
                    <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px; margin-top; 5px; font-size: 11px;">
                      <span>${colorSpan(params.color)} ${params.seriesName}</span>
                      <span style="font-size: 13px;"><strong>${value}</strong></span>
                    </div>
                  `
                  html += line
                }
              }

              return html
            }
          }
        },
        legend: {
          show: false
        },
        series: [{
          type: 'bar'
        }]
      }, this.options)

      // Config for heat map
      if (options.series.length === 1 && options.series[0].type === 'heatmap') {
        options.yAxis = {
          type: 'category',
          axisLabel: {
            fontFamily: 'Source Sans Pro',
            fontSize: 11,
            color: [this.axisColor]
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          ...this.options.yAxis
        }
        options.xAxis = {
          position: 'top',
          ...options.xAxis
        }
        options.grid = {
          top: 25,
          bottom: 10,
          left: 18,
          right: 0,
          ...this.options.grid
        }
        options.visualMap = {
          type: 'piecewise',
          min: 0,
          max: 10,
          show: false,
          pieces: [
            { value: 0, color: '#F9FAFB' },
            { max: 20, min: 0, color: '#F62A72' },
            { max: 40, min: 20, color: '#DFDF0F' },
            { max: 60, min: 40, color: '#DFCB11' },
            { max: 80, min: 60, color: '#DEA80F' },
            { min: 80, color: '#9EDF12' }
          ],
          ...options.visualMap
        }
        options.tooltip = {
          ...options.tooltip,
          extraCssText: 'box-shadow: 0 10px 30px 0 rgba(151,167,183,0.3); width: 180px; border-radius: 10px;',
          formatter: (params) => {
            const colorSpan = color => `
              <span style="display:inline-block; margin-right:10px; border-radius:100%; width:7px; height:7px; background-color: ${color}"></span>
            `
            let html = `
              <div style="margin-bottom: 10px; font-size: 13px;">
                <strong>${params[0].axisValue}</strong>
              </div>
            `
            params.reverse().forEach(item => {
              const { tooltipValues } = this.options.series[0]
              const day = tooltipValues[item.data[1]]
              let value = item.data[2].toFixed(2) + '%'
              if (value === '-1.00%') value = '-'
              const line = `
                <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px; margin-top; 5px; font-size: 11px;">
                  <span style="text-transform: capitalize;">${colorSpan(item.color)} ${day}</span>
                  <span style="font-size: 13px;"><strong>${value}</strong></span>
                </div>
              `
              html += line
            })
            return html
          },
          ...this.options.tooltip
        }
      }

      // Config for Gauge
      if (options.series[0].type === 'gauge') {
        const temp = options.series[0]
        const initGauge = {
          type: 'gauge',
          anchor: {
            show: true,
            showAbove: true,
            size: 0
          },
          pointer: {
            show: false
          },
          axisLine: {
            roundCap: true,
            lineStyle: {
              width: 2.5,
              color: [[1, '#E9ECF0']]
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          },
          progress: {
            show: true,
            roundCap: true,
            clip: true,
            width: 4
          },
          data: [],
          title: {
            offsetCenter: ['0%', '110%'],
            fontSize: 12,
            fontWeight: 600,
            fontFamily: 'Source Sans Pro'
          },
          detail: {
            offsetCenter: ['0%', '0%'],
            width: 45,
            height: 15,
            fontSize: 22,
            fontWeight: '600',
            color: '#3E4550',
            formatter: temp.formatPercent ? '{value}%' : '{value}',
            fontFamily: 'Source Sans Pro'
          }
        }

        options.series[0] = _merge({}, initGauge, temp)

        // Use the 2 next series because the progress key is available in v5 of echarts and it is not available with vue-echarts for now
        options.series[1] = _merge({}, initGauge, {
          title: { show: false },
          detail: { show: false },
          axisLine: {
            lineStyle: {
              width: 1.5,
              color: [[1, 'white']]
            }
          }
        })
        options.series[2] = _merge({}, initGauge, {
          title: { show: false },
          detail: { show: false },
          axisLine: {
            roundCap: true,
            lineStyle: {
              width: 4,
              color: [[1, temp?.data?.itemStyle?.color || '#00CCF9']]
            }
          }
        })
        const val = temp?.data[0]?.valueGauge || temp?.data[0]?.value
        options.series[2].endAngle = this.getEndAngleGauge(val)
      }

      return options
    },
    displayCursor () {
      return this.onClick
    },
    onClickFunction () {
      // Use to disable cursor if no onClick set
      return this.onClick || function () {}
    }
  },
  methods: {
    getEndAngleGauge (val) {
      if (val <= 0) return 225
      if (val >= 100) return -45
      return 225 - (val * 2.7)
    }
  }
}
</script>

<style lang='less'>
.echarts-container {
  height: 100%;
  width: 100%;
  position: relative;
  .legend-custom {
    position: absolute;
    top: -30px;
    right: 0;
    color: #B2BECA;
    display: flex;
    .legend-item {
      display: flex;
      align-items: center;
      margin-left: 10px;
      i {
        font-size: 7px;
        margin-right: 5px;
      }
      .line-legend {
        height: 1px;
        width: 20px;
        margin-right: 5px;
      }
      .label {
        font-size: 11px;
        padding: 0;
      }
    }
  }
  .echarts {
    height: 100%;
    width: 100%;

    &.no-cursor {
      canvas {
        cursor: default;
      }
    }
  }
  .max-gauge {
    position: absolute;
    right: 0;
    bottom: 17px;
    color: #B2BECA;
    font-size: 10px;
    font-weight: 600;
  }
  .no-data {
    position: absolute;
    top: 0;
  }
}
</style>
