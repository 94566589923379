/* eslint-disable no-case-declarations */
import moment from 'moment'
import 'moment-timezone'

const FpDimension = function (dimension) {
  const inst = new String(dimension) // eslint-disable-line no-new-wrappers
  inst.__proto__ = FpDimension.prototype // eslint-disable-line no-proto
  inst.info = {}
  return inst
}

FpDimension.prototype = Object.create(String.prototype)
FpDimension.prototype.isTemporal = function () {
  try {
    return this.info.type === 'temporal'
  } catch (e) {}
  return false
}
FpDimension.prototype.setToDate = function (date, value) {
  if (!this.isTemporal) {
    throw new Error(this + ' is not a temporal scale.')
  }
  if (!value) return 0
  switch (this.info.subtype) {
    case 'year':
      date.year(value)
      break
    case 'month':
      date.month(value - 1)
      break
    case 'quarter':
      date.month((value - 1) * 3)
      break
    case 'semester':
      date.month((value - 1) * 6)
      break
    case 'date':
      value = value || ''
      const v = value.split(' ')[0].split('-')
      date.year(v[0]).month(parseInt(v[1]) - 1).date(v[2]).hour(0)
      break
    case 'datetime':
      value = value || ''
      const days = value.split(' ')[0].split('-')
      const hours = value.split(' ')[1].split(':')
      date.year(days[0]).month(parseInt(days[1]) - 1).date(days[2]).hour(hours[0]).minute(hours[1]).second(hours[2])
      break
    case 'month-year':
      value = value || ''
      const v2 = value.split('-')
      date.year(v2[0]).month(parseInt(v2[1]) - 1).date(1).hour(0)
      break
    case 'week-year':
      value = value || ''
      const v3 = value.split('-')
      date.year(v3[0]).isoWeek(v3[1])
      break
    default:
      date = moment(value, 'YYYY-MM-DD')
  }
  return date
}
FpDimension.prototype.getTimeFormat = function () {
  const defaults = {
    year: 'YYYY',
    month: 'MMM',
    quarter: '[Q]Q'
  }
  return this.info.display_format || this.info.format || defaults[this.info.subtype]
}
FpDimension.prototype.formatFromScale = function (scaleValue) {
  if (this.isTemporal() && scaleValue) {
    const date = this.setToDate(moment(0), scaleValue)
    return date.format(this.getTimeFormat())
  }
  return scaleValue
}
FpDimension.prototype.formatFromTimestamp = function (value) {
  return moment(value).format(this.getTimeFormat())
}

export default FpDimension
