import { API, APIObject } from '@/shared/plugins/Api/API'
import _omit from 'lodash/omit'
import _set from 'lodash/set'

class Role extends APIObject {
  constructor (options) {
    super('IAM', options)
    this._id = this._id || ''
    this.display_name = this.display_name || ''
    this.users = this.users || []
    this.groups = this.groups || []
    this.service_accounts = this.service_accounts || []
    this.name = this.name || ''
    this.description = this.description || ''
    this.admin = this.admin || false
    this.global = this.global || false
    this.permissions = this.permissions || []
    this.tags = this.tags || []
    this.configuration = this.configuration || {}
    this.configuration.logo = this.configuration.logo || ''
    this.configuration.color = this.configuration.color || ''
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      'createdAt',
      'updatedAt',
      'createdBy',
      'updatedBy',
      'name'
    ])
    return obj
  }

  async create () {
    return super.create({
      method: 'POST',
      url: 'v4/roles'
    })
  }

  async save () {
    return super.save({
      method: 'PUT',
      url: `v4/roles/${this._id}`
    })
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v4/roles/${this._id}`
    })
  }

  async load () {
    const role = await this.request({
      method: 'get',
      url: `v4/roles/${this._id}`
    })
    this.assign(role)
  }
}

class Roles extends API {
  list () {
    return this.paginateListing({
      method: 'get',
      url: 'v4/roles'
    })
      .then(data => {
        return data.map(role => new Role(role))
      })
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Role(item)
  }
}

export default Roles
export {
  Role,
  Roles
}
