<template lang="pug">
div(
  ref="item"
  class="vue-grid-layout"
  :style="mergedStyle"
)
  slot
  grid-item(
    v-show="isDragging"
    class="vue-grid-placeholder"
    :x="placeholder.x"
    :y="placeholder.y"
    :w="placeholder.w"
    :h="placeholder.h"
    :i="placeholder.i"
  )
</template>

<script>
import Vue from 'vue'

// import { getBreakpointFromWidth, getColsFromBreakpoint, findOrGenerateResponsiveLayout } from './helpers/responsiveUtils'
import GridItem from './GridItem.vue'
import { addWindowEventListener, removeWindowEventListener } from './helpers/DOM'
const elementResizeDetectorMaker = require('element-resize-detector')

export default {
  name: 'GridLayout',
  components: {
    GridItem
  },
  provide () {
    return {
      eventBus: null,
      layout: this
    }
  },
  props: {
    colNum: { type: Number, default: 12 },
    maxRows: { type: Number, default: Infinity },
    margin: { type: Array, default: () => [10, 10] },
    isDraggable: { type: Boolean, default: true },
    isResizable: { type: Boolean, default: true },
    isMirrored: { type: Boolean, default: false },
    isBounded: { type: Boolean, default: false },
    useCssTransforms: { type: Boolean, default: true },
    horizontalCompact: { type: Boolean, default: true },
    restoreOnDrag: { type: Boolean, default: false },
    layout: { type: Object, required: true },
    responsive: { type: Boolean, default: false },
    responsiveLayouts: { type: Object, default: () => ({}) },
    transformScale: { type: Number, default: 1 },
    breakpoints: { type: Object, default: function () { return { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 } } },
    cols: { type: Object, default: function () { return { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 } } },
    preventCollision: { type: Boolean, default: false },
    useStyleCursor: { type: Boolean, default: true },
    minW: { type: Number, required: false, default: 1 },
    minH: { type: Number, required: false, default: 1 },
    editing: { type: Boolean, default: false },
    lite: { type: Boolean, default: false }
  },
  data: function () {
    return {
      width: null,
      lastLayoutLength: 0,
      isDragging: false,
      placeholder: {
        x: 0,
        y: 0,
        w: 0,
        h: 0,
        i: -1
      },
      lastBreakpoint: null, // store last active breakpoint
      previousRow: null // store previous row when drag element, to restore previous row's element width
    }
  },
  computed: {
    mergedStyle () {
      if (this.lite) {
        return {
          height: `${this.layout.layoutHeight}px`,
          width: '100%'
        }
      }

      return {
        height: `${this.editing ? this.layout.layoutHeight + 60 : this.layout.layoutHeight}px`,
        width: this.editing ? 'calc(100% - 40px)' : '100%'
      }
    }
  },
  watch: {
    width: function (newval, oldval) {
      const self = this
      this.$nextTick(function () {
        // this.$broadcast("updateWidth", this.width);
        this.eventBus.$emit('updateWidth', this.width)
        if (oldval === null) {
          /*
            If oldval == null is when the width has never been
            set before. That only occurs when mouting is
            finished, and onWindowResize has been called and
            this.width has been changed the first time after it
            got set to null in the constructor. It is now time
            to issue layout-ready events as the GridItems have
            their sizes configured properly.

            The reason for emitting the layout-ready events on
            the next tick is to allow for the newly-emitted
            updateWidth event (above) to have reached the
            children GridItem-s and had their effect, so we're
            sure that they have the final size before we emit
            layout-ready (for this GridLayout) and
            item-layout-ready (for the GridItem-s).

            This way any client event handlers can reliably
            invistigate stable sizes of GridItem-s.
          */
          this.$nextTick(() => {
            this.$emit('layout-ready', self.layout.tiles)
          })
        }
      })
    },
    colNum: function (val) {
      this.eventBus.$emit('setColNum', val)
    },
    isDraggable: function () {
      this.eventBus.$emit('setDraggable', this.isDraggable)
    },
    isResizable: function () {
      this.eventBus.$emit('setResizable', this.isResizable)
    },
    isBounded: function () {
      this.eventBus.$emit('setBounded', this.isBounded)
    },
    transformScale: function () {
      this.eventBus.$emit('setTransformScale', this.transformScale)
    },
    responsive () {
      if (!this.responsive) {
        this.$emit('update:layout', this.originalTiles)
        this.eventBus.$emit('setColNum', this.colNum)
      }
      this.onWindowResize()
    },
    maxRows: function () {
      this.eventBus.$emit('setMaxRows', this.maxRows)
    }
  },
  created () {
    const self = this

    // Accessible refernces of functions for removing in beforeDestroy
    self.resizeEventHandler = function (eventType, i, x, y, h, w) {
      self.resizeEvent(eventType, i, x, y, h, w)
    }

    self.dragEventHandler = function (eventType, i, x, y, h, w) {
      self.dragEvent(eventType, i, x, y, h, w)
    }

    self._provided.eventBus = new Vue()
    self.eventBus = self._provided.eventBus
    self.eventBus.$on('resizeEvent', self.resizeEventHandler)
    self.eventBus.$on('dragEvent', self.dragEventHandler)
    self.$emit('layout-created', self.layout.tiles)
  },
  beforeDestroy: function () {
    // Remove listeners
    this.eventBus.$off('resizeEvent', this.resizeEventHandler)
    this.eventBus.$off('dragEvent', this.dragEventHandler)
    this.eventBus.$destroy()
    removeWindowEventListener('resize', this.onWindowResize)
    if (this.erd) {
      this.erd.uninstall(this.$refs.item)
    }
  },
  beforeMount: function () {
    this.$emit('layout-before-mount', this.layout.tiles)
  },
  mounted: function () {
    this.$emit('layout-mounted', this.layout.tiles)
    this.$nextTick(function () {
      const self = this
      this.$nextTick(function () {
        // self.initResponsiveFeatures()
        self.onWindowResize()

        addWindowEventListener('resize', self.onWindowResize)

        self.$nextTick(function () {
          this.erd = elementResizeDetectorMaker({
            strategy: 'scroll', // <- For ultra performance.
            // See https://github.com/wnr/element-resize-detector/issues/110 about callOnAdd.
            callOnAdd: false
          })
          this.erd.listenTo(self.$refs.item, function () {
            self.onWindowResize()
          })
        })
      })
    })
  },
  methods: {
    onWindowResize: function () {
      let emitEvent = false
      if (this.$refs !== null && this.$refs.item !== null && this.$refs.item !== undefined) {
        if (this.width !== this.$refs.item.offsetWidth) {
          emitEvent = true
          this.width = this.$refs.item.offsetWidth
        }
      }
      if (emitEvent) this.eventBus.$emit('resizeEvent')
    },
    dragEvent: function (eventName, id, x, y, h, w) {
      let tile = this.layout.getLayoutTile(id)
      // getLayoutTile sometimes returns null object
      if (tile === undefined || tile === null) {
        tile = { x: 0, y: 0 }
      }

      if (eventName === 'dragstart') {
        // Si besoin
      }

      const heightDestinationOfRow = this.layout.originalTiles.find(t => t.y === y && !t.edit && t.i !== tile.i)?.height || this.minH
      if (eventName === 'dragmove' || eventName === 'dragstart') {
        this.placeholder.i = id
        this.placeholder.x = tile.x
        this.placeholder.y = tile.y
        this.placeholder.w = w
        this.placeholder.h = heightDestinationOfRow
        tile.moving = true
        this.$nextTick(function () {
          this.isDragging = true
        })
        this.eventBus.$emit('updateWidth', this.width)
      } else {
        this.$nextTick(function () {
          this.isDragging = false
        })
      }

      if (this.previousRow === null) this.previousRow = this.layout.originalTilesObject[id].y

      // const yToUse = this.layout.getYUsedByTiles.includes(y) ? y :

      const tileChangeRow = this.previousRow !== y && this.layout.getYUsedByTiles.includes(y)
      const tilesInRow = this.layout.getRowTiles(y)

      if (tileChangeRow && tilesInRow.length >= this.layout.maxTilesInRow && this.layout.originalTilesObject[id].y !== y) {
        // Do nothing because there is not enought place
      } else {
        // Move the element to the dragged location.
        this.layout.moveElement(tile, x, y, true)

        // If we change line, we need to update width of items
        if (tileChangeRow) {
          // Add Item in new -> resize element in row
          this.layout.addTileInRow(tile)

          // Resize element from originalTiles in previousRow
          if (this.previousRow !== this.layout.originalTilesObject[id].y) this.layout.resetTilesFromOriginalTiles(this.previousRow)

          // Set previousRow to new row
          if (this.layout.getYUsedByTiles.includes(y)) this.previousRow = y
        }
      }

      if (this.restoreOnDrag) {
        // Do not compact items more than in layout before drag
        // Set moved item as static to avoid to compact it
        tile.static = true
        this.layout.compact(this.layout.originalTilesObject)
        tile.static = false
      } else {
        this.layout.compact()
      }

      // needed because vue can't detect changes on array element properties
      this.eventBus.$emit('compact')
      if (eventName === 'dragend') {
        this.previousRow = null
        this.layout.updateTile(tile.i, 'height', heightDestinationOfRow)
        delete tile.moving
        if (this.layout.hasChanges) {
          this.layout.updateOriginalTiles()
          this.$emit('layout-updated')
        }
      }
    },
    resizeEvent: function (eventName, id, x, y, h, w) {
      let tile = this.layout.getLayoutTile(id)
      // getLayoutTile sometimes return null object
      if (tile === undefined || tile === null) {
        tile = { height: 0, width: 0 }
      }

      let hasCollisions
      if (this.layout.preventCollision) {
        const collisions = this.layout.getAllCollisions(this.layout.tiles, { ...tile, width: w, height: h }).filter(t => t.i !== tile.i)
        hasCollisions = collisions.length > 0

        // If we're colliding, we need adjust the placeholder.
        if (hasCollisions) {
          // adjust w && h to maximum allowed space
          let leastX = Infinity
          let leastY = Infinity
          collisions.forEach(layoutItem => {
            if (layoutItem.x > tile.x) leastX = Math.min(leastX, layoutItem.x)
            if (layoutItem.y > tile.y) leastY = Math.min(leastY, layoutItem.y)
          })

          if (Number.isFinite(leastX)) tile.width = leastX - tile.x
          if (Number.isFinite(leastY)) tile.height = leastY - tile.y
        }
      }

      if (eventName === 'resizestart' || eventName === 'resizemove') {
        this.eventBus.$emit('updateWidth', this.width)
        tile.resizing = true

        // TODO Udpate condition here
        // const getItemFromoriginalTiles = this.originalTiles.find(l => l.i === id)
        // if (w === getItemFromoriginalTiles?.w) this.resetTilesFromOriginalTiles(y)
      }

      if (tile.type === 'vertical-resize') {
        this.layout.resizeHeightTilesInRow(tile, y, h)
      } else {
        // Resize elements in same line if width improve.
        this.layout.resizeElement(tile, x, y, w, h, true)
      }

      // if (this.responsive) this.responsiveGridLayout()
      this.layout.compact()
      this.eventBus.$emit('compact')

      if (eventName === 'resizeend') {
        delete tile.resizing
        if (this.layout.hasChanges) {
          this.layout.updateOriginalTiles()
          this.$emit('layout-updated')
        }
      }
    }

    // finds or generates new layouts for set breakpoints
    // responsiveGridLayout () {
    //   const newBreakpoint = getBreakpointFromWidth(this.breakpoints, this.width)
    //   const newCols = getColsFromBreakpoint(newBreakpoint, this.cols)

    //   // save actual layout in layouts
    //   if (this.lastBreakpoint != null && !this.layouts[this.lastBreakpoint]) { this.layouts[this.lastBreakpoint] = this.layout.cloneLayout() }

    //   // Find or generate a new layout.
    //   const layout = findOrGenerateResponsiveLayout(
    //     this.originalTiles,
    //     this.layouts,
    //     this.breakpoints,
    //     newBreakpoint,
    //     this.lastBreakpoint,
    //     newCols,
    //     this.horizontalCompact
    //   )

    //   // Store the new layout.
    //   this.layouts[newBreakpoint] = layout

    //   if (this.lastBreakpoint !== newBreakpoint) {
    //     this.$emit('breakpoint-changed', newBreakpoint, layout)
    //   }

    //   // new prop sync
    //   this.$emit('update:layout', layout)

    //   this.lastBreakpoint = newBreakpoint
    //   this.eventBus.$emit('setColNum', getColsFromBreakpoint(newBreakpoint, this.cols))
    // },

    // // clear all responsive layouts
    // initResponsiveFeatures () {
    //   // clear layouts
    //   this.layouts = Object.assign({}, this.responsiveLayouts)
    // }
  }
}
</script>

<style lang="less">
  .vue-grid-layout {
    position: relative;
    transition: height 200ms ease;
  }
</style>
