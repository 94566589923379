/* istanbul ignore file */
import Toaster from './Toaster'
import './style.less'
import Vue from 'vue'

Vue.use({
  install (vue) {
    window.fpuiMessageBlockRemove = Toaster.remove
    vue.fpuiMessageBlock = vue.prototype.$fpuiMessageBlock = Toaster
  }
})
