<template lang='pug'>
  .tr
    .tr-content(v-show="!open" @dblclick="open = true")
      .td.key
        span {{ model.key }}
      .td.type {{translateType(model.type)}}
      .td.value {{model.value}}
      .actions
        i.fp4.fp4-pencil(@click="open = true")
        i.fp4.fp4-trash-can(@click="removeRow()")
    .tr-content(v-if="open && model")
      .td.key(@keyup.enter="onClose()")
        fpui-input-text(
          v-model="model.key"
          :disabled="disabled"
        )
      .td.type
        fpui-input-select(
          v-model='type',
          :disabled='disabled'
          :options='typesOptions'
          :clearable='false'
        )
      .td.value(@keyup.enter="onClose()")
        fpui-input-text(
          v-if="model.type === 'string'"
          v-model="model.value"
          :disabled="disabled"
        )
        fpui-input-number(
          v-else-if="model.type === 'number'"
          v-model="model.value"
          :disabled="disabled"
        )
        fpui-input-toggle(
          v-model="model.value"
          v-else-if="model.type === 'boolean'"
          :disabled="disabled"
        )
      .td.edit-actions
        fpui-button.cancel(
          auto-width
          color="red"
          @click="onClose(true)"
          icon='fp4 fp4-rotate-left'
          icon-only
        )
        fpui-button.done(
          auto-width
          :disabled="disabled"
          color="green"
          @click="onClose()"
        ) {{ 'button.done' | translate }}

</template>

<script>
import FpuiInputEnvironmentVariablesRow from './FpuiInputEnvironmentVariablesRow'
import ConfirmModal from '@/shared/components/modals/Confirm'

export default {
  components: {
    FpuiInputEnvironmentVariablesRow
  },
  props: {
    autoOpen: Boolean,
    disabled: Boolean,
    value: { type: Object, default: () => {} }
  },
  data () {
    return {
      edit: false,
      model: null,
      restoredModel: { ...this.value }
    }
  },
  computed: {
    typesOptions () {
      return [{
        label: this.translateType('boolean'),
        value: 'boolean'
      }, {
        label: this.translateType('string'),
        value: 'string'
      }, {
        label: this.translateType('number'),
        value: 'number'
      }]
    },
    open: {
      get () {
        return this.edit
      },
      set (v) {
        if (this.disabled) return
        this.edit = v
        this.$emit('editing', this.edit)
      }
    },
    type: {
      get () {
        return this.model.type
      },
      set (v) {
        this.model.value = undefined
        this.model.type = v
      }
    }
  },
  created () {
    this.model = { ...this.value }
  },
  mounted () {
    if (this.autoOpen) this.open = true
  },
  methods: {
    translateType (type) { return this.$t(`fpui-input-environment-variables.type.${type}`) },
    onRestore () {
      this.model.value = this.restoredModel.value
      this.model.type = this.restoredModel.type
      this.model.key = this.restoredModel.key
      this.open = false
    },
    onClose (restore = false) {
      if (!this.open) return
      if (restore) return this.onRestore()
      switch (this.model.type) {
        case 'string':
          this.model.value = this.model.value || ''
          this.model.value = this.model.value + ''
          break
        case 'boolean':
          this.model.value = !!this.model.value
          break
        case 'number':
          this.model.value = parseFloat(this.model.value)
      }
      this.$emit('input', {
        value: this.model,
        callback: (value) => {
          if (!value) {
            this.$fpuiMessageBlock.error(new Error('fpui-input-environment-variables.key.duplicate'))
            return
          }
          this.restoredModel = { ...this.model }
          this.open = false
        }
      })
    },
    isParent (target) {
      if (target === this.$el) return true
      if (target.parentNode) return this.isParent(target.parentNode)
      return false
    },
    async removeRow () {
      const title = this.$t('fpui-input-environment-variables.remove.row')
      let content = []
      const contentHtml = true

      content.push(this.$t('fpui-input-environment-variables.remove.row.content', [this.model.key, this.model.value]))
      content = ['<div>', ...content, '</div>']
      content = content.join('')

      const confirmResult = await ConfirmModal(this, {
        content,
        title,
        contentHtml,
        yes: {
          text: this.$t('fpui-modal-confirm.remove'),
          color: 'red'
        }
      })
      if (!confirmResult) return
      this.$emit('remove')
    }
  }
}
</script>
