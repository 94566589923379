import Dataplant from '@/JsonExtension'
import Config from '@/shared/Config'
import API from '@/shared/plugins/Api/API'

const JSONQuery = Dataplant.AM.JSONQuery
const SQLQuery = Dataplant.AM.SQLQuery
const DashboardJSONQuery = Dataplant.AM.DashboardJSONQuery
const DashboardSQLQuery = Dataplant.AM.DashboardSQLQuery

const cache = {}

class QB extends API {
  async loadSdk () {
    if (this.dataplant) return
    const config = await Config()
    this.dataplant = new Dataplant({
      dataplant_url: config.BASE_URL
    })
  }

  /* istanbul ignore next */
  async getDirectResult (query, limit, opts = { }) {
    const key = `${JSON.stringify(query)}-${limit}`
    if (opts.cache && cache[key]) return await cache[key]

    await this.loadSdk()
    try {
      let q = null

      if (opts.fromDashboard) {
        q = query.type === 'sql'
          ? new DashboardSQLQuery(
            query.configuration.sql,
            {
              source: opts.source,
              query_id: query.shared_id,
              limit: limit !== 'all' ? limit : undefined,
              dashboard_id: opts.dashboardId,
              url: opts.url
            }
          )
          : new DashboardJSONQuery({
            query_id: query.shared_id,
            source: opts.source,
            limit: limit !== 'all' ? limit : undefined,
            ...query.configuration,
            dashboard_id: opts.dashboardId,
            filters: opts.filters,
            url: opts.url
          })
      } else {
        q = query.type === 'sql'
          ? new SQLQuery(
            query.configuration.sql,
            {
              source: opts.source,
              query_id: query._id,
              dashboard_id: opts.dashboardId,
              limit: limit !== 'all' ? limit : undefined
            }
          )
          : new JSONQuery({
            query_id: query._id,
            dashboard_id: opts.dashboardId,
            source: opts.source,
            limit: limit !== 'all' ? limit : undefined,
            ...query.configuration
          })
      }

      cache[key] = this.dataplant.am.run(q)
      return await cache[key]
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  /**
   * To get result of query
   */
  /* istanbul ignore next */
  async getResult (query, limit = 'all', opts = {}) {
    if (opts.legacy === false) return this.getDirectResult(query, limit, opts)
    const options = {
      method: 'GET',
      url: 'query',
      params: {
        repository: query.repository,
        repository_version: query.repository_version
      }
    }

    // Add limit to display in preview
    if (limit !== 'all') options.params.limit = limit
    if (query._id) { // If query exist, we get the result from _id
      options.params = {
        ...options.params,
        type: 'id',
        query: query.shared_id
      }
    } else {
      // The query does'nt exist
      options.method = 'POST'
      options.data = query.configuration
    }

    return this.request(options)
  }

  /* istanbul ignore next */
  async getResultSql (sql, queryId, source) {
    await this.loadSdk()
    const q = new SQLQuery(
      sql,
      {
        source,
        query_id: queryId,
        limit: undefined
      }
    )

    return await this.dataplant.am.run(q)
  }

  flush () {
    return this.request({
      method: 'GET',
      url: 'flush'
    })
  }


  // Old QB
  /* istanbul ignore next */
  async getDataset () {
    const dataset = await this.request({
      method: 'GET',
      url: 'dataset'
    })
    return dataset
  }

  /* istanbul ignore next */
  async query (query, params) {
    return this.request({
      url: 'query',
      method: 'POST',
      params: params,
      data: query
    })
  }

  /* istanbul ignore next */
  async getSQLFromConfiguration (configuration) {
    await this.loadSdk()
    const q = new JSONQuery(configuration)
    await q.metas(this.dataplant)
    return q
  }

  async configurationList () {
    return await this.request({
      url: 'configuration/list'
    }).then(data => data.results)
  }
}

export default QB
