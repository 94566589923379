<template lang='pug'>
  li.aside-item(
    :class="itemClass",
  )
    .coming-soon-overlay(v-if="val.soon")
      img(:src="comingSoonImage")
      span(:class="{ fr: ($i18n.locale() === 'fr' )}") {{ $t('aside.soon') }}
    div(v-show="val.url")
      a.disable(v-if='isLoading || val.loading')
        i.fa.fa-cog.fa-spin
        span {{ menuName }}
      router-link(
        v-if="hasAccess && !isLoading",
        :to="`/${dataplantId}${val.url}`"
        :class="disableClass"
      )
        i(:class='val.icon')
        span {{ menuName }}
        img(v-if="val.isNewFeature" :src="newFeatureIcon")
        img(
          v-if="val.isAlphaFeature"
          :src="alphaFeatureIcon"
          :style="{ height: '47px' }"
        )
      a.disable(
        v-if="!hasAccess && !isLoading"
        :class="disableClass"
        v-tooltip="{ content: tooltip, classes: ['fp-aside'], placement:'right', delay: 0 }"
        @click='addAside'
      )
        i(:class='val.icon')
        span {{ menuName }}
    div(v-show="val.click")
      a(
        v-if="val.click"
        v-tooltip='val.tooltip'
        data-placement='right'
        @click="val.click()"
      )
        i.fpui.fpui-plus
        span {{ menuName }}
</template>

<script>

export default {
  props: {
    menutype: { type: String, default: '' },
    dataplantId: { type: String, default: '' },
    id: {
      type: String,
      required: true
    },
    val: {
      type: Object,
      required: true
    },
    sectionIsOpenList: {
      type: Object,
      default: () => {}
    },
    callback: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      hasAccess: true,
      newFeatureIcon: require('./NEW_STATIC_CORNER.svg'),
      alphaFeatureIcon: require('./NEW_STATIC_CORNER_ALPHA.svg'),
      comingSoonImage: require('./aside-coming-soon.png')
    }
  },
  computed: {
    itemClass () {
      let ret = ''
      if (this.isActive) { ret += ' active' }
      if (this.val.hidden) { ret += ' hidden' }
      return ret
    },
    menuName () {
      const lckey = this.id.toLowerCase()
      if (this.menutype) {
        return this.$t(`aside.${this.menutype}.${lckey}`, { default: this.id })
      } else {
        return this.$t(`aside.${lckey}`, { default: this.id })
      }
    },
    disableClass () {
      if (this.val.disable || this.val.soon) return 'disable events-pointer'
      return ''
    },
    isLoading () {
      if (typeof this.val.loading === 'function') return this.val.loading.call(this)
      if (this.val.loading) { return true }
      return false
    },
    tooltip () {
      if (typeof this.val.addTooltip === 'function') return this.$t(this.val.addTooltip.call(this))
      return this.$t(this.val.addTooltip)
    },
    addAside () {
      if (typeof this.val.add !== 'function') return () => null
      return this.val.add.call(this)
    },
    isActive () {
      let isActive = false

      if (!this.val.url) { isActive = false }
      const path = this.$route.fullPath.split('?')[0]
      if (this.val.url && (`/${this.dataplantId}${this.val.url}` === path || `/${this.dataplantId}${this.val.url}` === path + '/')) isActive = true
      if (this.val.regexp && path.match(this.val.regexp)) isActive = true

      const isSectionHidden = this.sectionIsOpenList && Object.keys(this.sectionIsOpenList).find(key => this.sectionIsOpenList[key] === false)
      if (isSectionHidden) {
        if (this.callback && !this.callback(this.val)) {
          isActive = this.callback(this.val)
        }
      }
      return isActive
    }
  },
  watch: {
    '$store.getters.READY_ORGANIZATION' () {
      if (!this.hasAccess) this.checkAccess()
    }
  },
  async mounted () {
    await this.checkAccess()
  },
  methods: {
    async checkAccess () {
      this.hasAccess = !this.val.access || await this.val.access()
    }
  }
}
</script>
