/*
Exemple format : {
  fields: [pays,region]
}

Exemple format : {
  fields: region
}

Exemple format : [pays,region]

Exemple format : pays
*/

class Scale {
  constructor (scales = []) {
    this._ = {}
    this.fields = scales
  }

  get fields () {
    return this._.fields
  }

  set fields (values = []) {
    values = values || []
    let v = values.fields ? values.fields : values
    v = Array.isArray(v) ? v : [v]
    this._.fields = v || []
  }

  toJSON () {
    return this._
  }

  toSQL () {
    if (!this._.fields.length) return null
    return `GROUP BY ${this._.fields.join(', ')}`
  }
}

module.exports = Scale
