import { API, APIObject } from '@/shared/plugins/Api/API'
import _omit from 'lodash/omit'
import _set from 'lodash/set'

class Group extends APIObject {
  constructor (options) {
    super('IAM', options)

    this._id = this._id || ''
    this.display_name = this.display_name || ''
    this.name = this.name || ''
    this.picture = this.picture || ''
    this.description = this.description || ''
    this.tags = this.tags || []
    this.roles = this.roles || []
    this.users = this.users || []
    this.service_accounts = this.service_accounts || []
    this.configuration = this.configuration || {}
    this.configuration.logo = this.configuration.logo || ''
    this.configuration.color = this.configuration.color || ''
  }

  get displayname () {
    return this.display_name
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      'createdAt',
      'updatedAt',
      'createdBy',
      'updatedBy',
      'name'
    ])
    return obj
  }

  async create () {
    return super.create({
      method: 'POST',
      url: 'v4/groups'
    })
  }

  async save () {
    return super.save({
      method: 'PUT',
      url: `v4/groups/${this._id}`
    })
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v4/groups/${this._id}`
    })
  }

  async load () {
    const group = await this.request({
      method: 'get',
      url: `v4/groups/${this._id}`
    })
    this.assign(group)
  }

  share (data = {}) {
    return this.request({
      method: 'POST',
      url: 'applications/share',
      data
    })
  }
}

class Groups extends API {
  async list (options) {
    const groups = await this.paginateListing({
      method: 'get',
      url: 'v4/groups',
      onProgress (groups) {
        if (options.onProgress) options.onProgress(groups.map(group => new Group(group)))
      }
    })
    return groups.map(group => new Group(group))
  }

  async names () {
    const data = await this.request({
      method: 'get',
      url: 'v4/groups/names'
    })
    return data.groups
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Group(item)
  }
}

export default Groups
export {
  Group,
  Groups
}
