<template lang='pug'>
  monaco-editor(v-model="model",:read-only='readOnly')
</template>

<script>
import MonacoEditor from './MonacoEditor'

export default {
  components: {
    MonacoEditor
  },
  props: {
    readOnly: {
      default: false,
      type: Boolean
    },
    value: {
      type: [Array, Object, Number, String],
      default () {
        return {}
      }
    }
  },
  computed: {
    model: {
      get () {
        return JSON.stringify(this.value, null, 2)
      },
      set (value) {
        /* eslint-disable no-empty */
        try {
          const val = JSON.parse(value)
          this.$emit('input', val)
          this.$emit('getJsonError', false)
        } catch (_e) {
          this.$emit('getJsonError', _e)
        }
      }
    }
  }
}
</script>

<style lang='less'>
</style>
