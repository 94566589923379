import AutoFormHidden from '@/shared/components/form/FpuiInputHidden'
import AutoFormJson from '@/shared/components/form/FpuiInputJson'
import AutoFormNumber from '@/shared/components/form/FpuiInputNumber'
import AutoFormPassword from '@/shared/components/form/FpuiInputPassword'
import AutoFormSelect from '@/shared/components/form/FpuiInputSelect'
import AutoFormText from '@/shared/components/form/FpuiInputText'
import AutoFormTextarea from '@/shared/components/form/FpuiInputTextarea'
import AutoFormToggle from '@/shared/components/form/FpuiInputToggle'
import AutoFormTags from '@/shared/components/form/FpuiInputTags'
import AutoFormArray from './Array'
import AutoFormDisplay from './Display'
import AutoFormError from './Error'
import AutoFormObject from './Object'
export default {
  AutoFormDisplay,
  AutoFormHidden,
  AutoFormError,
  AutoFormArray,
  AutoFormJson,
  AutoFormTextarea,
  AutoFormText,
  AutoFormPassword,
  AutoFormNumber,
  AutoFormToggle,
  AutoFormObject,
  AutoFormSelect,
  AutoFormTags
}
