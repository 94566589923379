import { Account } from '@/shared/plugins/Api/BILLING/Accounts'
import { Organization } from '@/shared/plugins/Api/ORGANIZATION/Organizations'
import _flatten from 'lodash/flatten'
import _uniq from 'lodash/uniq'
import Vue from 'vue'
export default {
  state: {
    organizations: [],
    activeOrganization: null,
    accounts: null,
    allSubscriptions: [],
    allOrganizationsSubscriptions: [],
    availableOrganizations: [],
    requestedOrganizations: [],
    invitingOrganizations: []
  },
  mutations: {
    SET_ORGANIZATIONS (state, organizations) { // Not WS
      const stateOrgaIds = state.organizations.map(orga => orga._id)
      organizations.forEach(orga => {
        if (!stateOrgaIds.includes(orga._id)) state.organizations.push(orga)
      })
    },
    UPDATE_ORGANIZATIONS (state, organizations) { // Not WS
      state.organizations = organizations
    },
    SET_ACTIVE_ORGANIZATION (state, organizationId) { // Not WS
      state.activeOrganization = organizationId
      localStorage.setItem('ACTIVE_ORGANIZATION', organizationId)
    },
    CREATE_ORGANIZATION (state, data) { // Not WS
      state.organizations = state.organizations.filter(o => data._id !== o._id)
      state.organizations.push(new Organization(data))
    },
    UPDATE_ORGANIZATION (state, data) { // Not WS
      const updatedOrgaIndex = state.organizations.findIndex(o => data._id === o._id)
      if (updatedOrgaIndex !== -1) {
        Vue.set(state.organizations, updatedOrgaIndex, new Organization(data))
      } else {
        state.organizations.push(new Organization(data))
      }
    },
    REMOVE_ORGANIZATION (state, organizationId) { // Not WS
      state.organizations = state.organizations.filter(o => organizationId !== o._id)
    },
    SET_ACCOUNTS (state, accounts) { // Not WS
      state.accounts = accounts
    },
    ADD_ACCOUNT (state, account) { // Not WS
      state.accounts.push(account)
    },
    REMOVE_ACCOUNT (state, accountId) { // Not WS
      state.accounts = state.accounts.filter(a => accountId !== a._id)
    },
    SET_ALL_SUBSCRIPTIONS (state, subscriptions) { // Not WS
      state.allSubscriptions = subscriptions
    },
    SET_ALL_ORGANIZATIONS_SUBSCRIPTIONS (state, subscriptions) { // Not WS
      state.allOrganizationsSubscriptions = subscriptions
    },
    SET_AVAILABLE_ORGANIZATIONS (state, organizations) { // Not WS
      state.availableOrganizations = organizations
    },
    SET_REQUESTED_ORGANIZATIONS (state, organizations) { // Not WS
      state.requestedOrganizations = organizations
    },
    SET_INVITING_ORGANIZATIONS (state, organizations) { // Not WS
      state.invitingOrganizations = organizations
    },
    REMOVE_INVITING_ORGANIZATION (state, organizationId) { // Not WS
      state.invitingOrganizations = state.invitingOrganizations.filter(o => organizationId !== o._id)
    }
  },
  actions: {
    async LOAD_ORGANIZATIONS ({ commit }, requested = false) {
      if (requested) {
        const [orga, requested] = await Promise.all([
          await Vue.api.ORGANIZATION.organizations.list(),
          await Vue.api.ORGANIZATION.organizations.listRequested()
        ])
        commit('UPDATE_ORGANIZATIONS', [...orga, ...requested])
        commit('SET_REQUESTED_ORGANIZATIONS', requested)
      } else {
        const organizations = await Vue.api.ORGANIZATION.organizations.list()
        commit('SET_ORGANIZATIONS', organizations)
      }
    },
    async LOAD_ACCOUNTS ({ commit }) {
      commit('SET_ACCOUNTS', await Vue.api.BILLING.accounts.list())
    },
    async LOAD_ALL_SUBSCRIPTIONS ({ commit }) { // Not used anymore but i keep if needed
      commit('SET_ALL_SUBSCRIPTIONS', await Vue.api.BILLING.accounts.allSubscriptions())
    },
    async CREATE_ACCOUNT ({ commit, getters }) {
      const session = getters.SESSION
      const account = new Account({
        billing_address: {
          last_name: session?.lastname,
          first_name: session?.firstname,
          phone: session?.phone,
          email: session.email,
          company: session?.preferences?.company_name,
          line1: session?.preferences?.line1,
          line2: session?.preferences?.line2,
          line3: session?.preferences?.line3,
          city: session?.preferences?.city,
          country: session?.preferences?.country,
          state: session?.preferences?.state,
          zip: session?.preferences?.zip
        },
        last_name: session?.lastname,
        first_name: session?.firstname,
        phone: session?.phone,
        email: session.email,
        company: session?.preferences?.company_name,
        company_size: session?.preferences?.company_size,
        company_industry: session?.preferences?.company_industry,
        line1: session?.preferences?.line1,
        line2: session?.preferences?.line2,
        line3: session?.preferences?.line3,
        city: session?.preferences?.city,
        country: session?.preferences?.country,
        state: session?.preferences?.state,
        zip: session?.preferences?.zip
      })
      await account.create()
      commit('ADD_ACCOUNT', account)
      return account
    },
    async LOAD_AVAILABLE_ORGANIZATIONS ({ commit }) {
      commit('SET_AVAILABLE_ORGANIZATIONS', await Vue.api.ORGANIZATION.organizations.available())
    },
    async LOAD_REQUESTED_ORGANIZATIONS ({ commit }) {
      commit('SET_REQUESTED_ORGANIZATIONS', await Vue.api.ORGANIZATION.organizations.listRequested())
    },
    async LOAD_INVITING_ORGANIZATIONS ({ commit }) {
      commit('SET_INVITING_ORGANIZATIONS', await Vue.api.ORGANIZATION.organizations.invites())
    }
  },
  getters: {
    ACTIVE_ORGANIZATION (state, getters) {
      return (routeParams = {}) => {
        let organization = null
        if (routeParams?.organizationId) {
          // from routeParams
          organization = getters.ORGANIZATION_BY_ID(routeParams?.organizationId)
        }
        if (!organization) {
          // from dataplantRouteParams
          const dataplant = (routeParams?.dataplantId || routeParams?.projectId) ? getters.DATAPLANT_BY_ID(routeParams?.dataplantId || routeParams?.projectId) : null
          if (dataplant) organization = getters.ORGANIZATION_BY_ID(dataplant.organization_id)
        }
        if (!organization && state?.activeOrganization) {
          // From last use orga
          organization = getters.ORGANIZATIONS.find(org => org._id === state.activeOrganization)
        }

        if (!organization && localStorage.getItem('ACTIVE_ORGANIZATION')) {
          organization = getters.ORGANIZATIONS.find(org => org._id === localStorage.getItem('ACTIVE_ORGANIZATION'))
        }
        if (!organization) {
          // First of list
          if (getters.ORGANIZATIONS.length) organization = getters.ORGANIZATIONS[0]
        }
        return organization
      }
    },
    ORGANIZATIONS (state) {
      return state.organizations
    },
    ORGANIZATION_BY_ID (state) {
      return (id) => {
        return state.organizations.find(organization => organization._id === id || organization.id === id)
      }
    },
    ORGANIZATIONS_EMAILS (state) {
      const emails = _uniq(_flatten(state.organizations.map(organization => {
        const users = organization.accounts || organization.users || []
        return users.map(user => user.email)
      })))
      return emails || []
    },
    AVAILABLE_ORGANIZATIONS (state) {
      return state.availableOrganizations
    },
    REQUESTED_ORGANIZATIONS (state) {
      return state.requestedOrganizations
    },
    INVITING_ORGANIZATIONS (state) {
      return state.invitingOrganizations
    },
    // -------- Accounts --------
    ACCOUNT_BY_ID (state) {
      return (id) => {
        return state.accounts.find(account => account._id === id)
      }
    },
    ACCOUNTS (state) {
      return state.accounts
    },
    ALL_SUBSCRIPTIONS (state) {
      return state.allSubscriptions
    },
    ALL_ORGANIZATIONS_SUBSCRIPTIONS (state) {
      return state.allOrganizationsSubscriptions
    }
  }
}
