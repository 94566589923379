import { Configuration } from '@/shared/plugins/Api/STORAGE/Configurations'
import Vue from 'vue'

export default {
  state: {
    defaultConfiguration: {},
    configurations: []
  },
  mutations: {
    STORAGE_CONFIGURATION_DEFAULT_INIT (state, configuration) { // Not WS
      state.defaultConfiguration = configuration
    },
    STORAGE_CONFIGURATIONS_INIT (state, data) { // Not WS
      state.configurations = state.configurations.filter(c => data?.organization_id !== c?.organization_id)
      state.configurations.push(data)
    },
    STORAGE_CONFIGURATIONS_UPDATE (state, data) { // Not WS (I think)
      const updatedActionIndex = state.configurations.findIndex(a => data.id === a.id)
      if (updatedActionIndex !== -1) {
        Vue.set(state.configurations, updatedActionIndex, new Configuration(data))
      } else {
        state.configurations.push(new Configuration(data))
      }
    }
  },
  actions: {
    // List configurations
    async REFRESH_STORAGE_CONFIGURATIONS_BY_ORGANIZATION ({ commit }, organizationId) {
      // test sans organizationID
      const data = await Vue.api.STORAGE.configurations.getByOrganization(organizationId)
      commit('STORAGE_CONFIGURATIONS_INIT', data)
    }
  },
  getters: {
    // Return all configurations
    STORAGE_CONFIGURATIONS (state) {
      return state.configurations
    }
  }
}
