/* istanbul ignore file */
import Vue from 'vue'

import FpAnalytics from './FpAnalytics'

export default {
  async init (router, category = 'FPUI') {
    const analytics = new FpAnalytics(router, category)
    Vue.use(analytics)
    const options = await analytics.consent()
    analytics.load(options)
  }
}
