<template lang='pug'>
  input(type="hidden",v-model="model")
</template>

<script>

export default {
  props: {
    value: { type: [String, Number, Array, Object], default: '' },
    default: { type: [String, Number, Array, Object], default: null }
  },
  computed: {
    model: {
      get () { return this.value },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  mounted () {
    if (this.default && !this.value) {
      process.nextTick(() => {
        this.$emit('input', this.default)
      })
    }
  }
}
</script>
