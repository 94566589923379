<template lang="pug">
  form(@submit="validateOTP($event)").mfas.login
    img.logo(:src="logo")
    h1 {{ title }}
    .description
      div {{ $t('authentication.mfa.description') }}
      div {{ $t('authentication.mfa.description2') }}
    .mfa-select
      .mfa(
        v-for="mfa in modes"
        :class="{active:current === mfa}"
        :key="mfa"
        v-tooltip="tooltip(mfa)"
        :disabled="isSmsNoPhone(mfa)"
        :style="{'cursor':isSmsNoPhone(mfa)?'not-allowed':'pointer','opacity':isSmsNoPhone(mfa)?0.5:1}"
        @click="select(mfa)"
      )
        i(:class="icons(mfa)")
        span {{ $t('authentication.mfa.'+mfa) }}
    component(
      :is="current"
      v-if="current",
      v-model="otp"
    )
    .clearfix
    .actions
      .submit
        button.login-button.btn-login.btn.btn-primary(
          type='submit'
          :style="buttonStyle"
        ) {{ $t('authentication.signin') }}
        fp-loading(v-if='loading')
      .links
        a.cancel-link(
          @click="logout"
        ) {{ $t('authentication.cancel') }}

</template>

<script>
import pascalcase from 'pascalcase'
import mfas from './mfas'
export default {
  components: mfas,
  data () {
    return {
      current: null,
      otp: '',
      loading: false
    }
  },
  computed: {
    color () { return this.$store.getters.PREFERENCES.color || '#00ccf9' },
    mfa () { return this.$store.getters.PREFERENCES.mfa },
    modes () {
      const mfa = []
      if (this.mfa.email) mfa.push('email')
      if (this.mfa.sms) mfa.push('sms')
      if (this.mfa.authenticator) mfa.push('authenticator')
      return mfa
    },
    buttonStyle () {
      if (!this.otp || this.loading) {
        return {
          color: 'white',
          backgroundColor: this.color,
          cursor: 'not-allowed',
          opacity: 0.5
        }
      }
      return {
        color: 'white',
        backgroundColor: this.color,
        cursor: 'pointer',
        opacity: 1
      }
    },
    logo () {
      return this.$store.getters.PREFERENCES?.logo || ''
    },
    title () {
      return this.$store.getters.PREFERENCES.title || this.$store.getters.PREFERENCES.name
    },
    message () {
      return this.$store.getters.PREFERENCES.message || this.$store.getters.PREFERENCES.description
    }
  },
  methods: {
    async validateOTP ($event) {
      $event.preventDefault()
      if (this.loading) return
      try {
        this.loading = true
        await this.$store.dispatch('VALIDATE_MFA_CODE', {
          type: this.current,
          otp: this.otp
        })
      } catch (err) {
        this.$fpuiMessageBlock.pop('error', err.message)
      } finally {
        this.loading = false
      }
    },
    logout () {
      this.$store.dispatch('LOGOUT')
    },
    isSmsNoPhone (mfa) {
      if (mfa === 'sms' && !this.$store.getters.SESSION.phone) return true
      return false
    },
    select (mfa) {
      if (this.isSmsNoPhone(mfa)) return
      this.current = mfa
    },
    tooltip (mfa) {
      if (this.isSmsNoPhone(mfa)) {
        const ret = {
          content: this.$t('authentication.mfa.no-phone'),
          delay: {
            show: 0
          }
        }
        return ret
      }
      return ''
    },
    icons (mfa) {
      return mfas[pascalcase(mfa)].icon
    },
    updateOTP (otp) {
      this.$emit('input', {
        otp,
        type: this.current
      })
    }
  }
}
</script>

<style lang="less">
.mfas{
  .description {
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 1.5;
  }
  .mfa-button {
    height: 34px;
    width: 170px;
    line-height: 32px;
    border-radius: 5px;
    text-align: center;
    font-size: 13px;
    text-transform: uppercase;
    box-shadow: inset 0 -1px 0 0 rgba(0,0,0,.07), 0 5px 8px 0 rgba(0,0,0,.14);
    border: none;
    cursor: pointer;
  }
  margin-bottom: 40px;
  .mfa-select {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .mfa {
      cursor: pointer;
      border-radius: 5px;
      width: 146px;
      height: 83px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      font-size:14px;
      border: 0.5px solid rgba(151, 167, 183, 0.21);
      box-shadow: 0 2px 4px 0 rgba(52, 65, 78, 0.05);
      i {
        font-size: 40px;
      }
      &.active {
        border: 2px solid #0089c0;
        color: #0089c0;
      }
    }
  }
}
</style>
