import Vue from 'vue'
import FpuiTable from './FpuiTable'
import FpuiTableRow from './FpuiTableRow'
import FpuiTableHeader from './FpuiTableHeader'

import './FpuiTable.less'

export { FpuiTable, FpuiTableRow }
export default { FpuiTable, FpuiTableRow }

Vue.component('FpuiTable', FpuiTable)
Vue.component('FpuiTableRow', FpuiTableRow)
Vue.component('FpuiTableHeader', FpuiTableHeader)
