<template lang="pug">
.service-selector(
  @mouseover="mouseOver"
  @mouseleave="mouseLeave"
)
  .logo.logo-dataplant
    a.service-link(
      :href="getLink(service)"
      @click.stop="trackHeaderBreadcrumb"
    )
      img.service-logo(
        :src="require(`@/shared/assets/img/services/${service}.svg`)"
      )

  .closed(
    :class="{ shown: displayClosed }"
    @click="open"
  )
    .name-container
      .organization-dataplant-selector-container
        .organization-name {{ organizationName }}
        span.separator(v-if="dataplantName") |
        .dataplant-name(
          v-if="dataplantName"
        ) {{ `${dataplantName} ${dataplant ? `(${dataplant.region_name})` : ''}` }}
      span.name(v-if="item") {{ $t(item.displayName) }}
    i.fp4.fp4-chevron-down

  .opened(
    v-if="opened"
  )
    header-list(
      :key='headerListKey'
      :light-mode="lightMode"
    )
</template>

<script>
import Services from '@/core/helpers/services'
import Config from '@/shared/Config'
import HeaderList from './HeaderList.vue'

export default {
  components: {
    HeaderList
  },
  props: {
    service: { type: String, default: null },
    type: { type: String, default: '' },
    hoverFromParent: { type: String, default: '' },
    // `light-mode`: Checks if the header should be in a light mode or not
    // If the user is logged in as `login_password` (not King)
    // we only display the logo and current service
    lightMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      opened: false,
      config: null,
      hover: false,
      hide: false // Used to hide closed if another selector is active
    }
  },
  computed: {
    projectId () {
      return this.$route.params.dataplantId || this.$route.params.projectId || this.config?.PROJECT_ID
    },
    servicesList () {
      let servicesList = []

      Services.forEach(service => {
        servicesList = servicesList.concat(service.services)
      })

      return servicesList
    },
    item () {
      if (!this.service) return null
      return this.servicesList.find(item => {
        if (this.service === 'ml') return item.id === 'mlm'
        if (this.service === 'cc') return item.id === 'control-center'
        if (this.service === 'gab') return item.id === 'aab'
        return item.id === this.service
      })
    },
    dataplant () {
      if (this.projectId) return this.$store.getters.DATAPLANT_BY_ID(this.projectId)
      return null
    },
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.config?.ORGANIZATION_ID || this.dataplant?.organization_id)
    },
    organizationName () {
      return this.organization?.name || this.config?.ORGANIZATION_NAME
    },
    dataplantName () {
      return this.dataplant?.display_name || this.config?.PROJECT_NAME
    },
    links () {
      return {
        docs: this.config?.DOCS,
        status: this.config?.STATUS,
        support: this.config?.SUPPORT,
        profile: this.config?.FPUI ? `${this.config.FPUI}/#/user` : null,
        account: this.config?.FPUI ? `${this.config.FPUI}/#/accounts` : null,
        organization: this.config?.FPUI ? `${this.config.FPUI}/#/home/${this.config?.ORGANIZATION_ID}` : null,
        features: this.config?.FPUI ? `${this.config.FPUI}/#/features` : null,
        project: this.projectUrl
      }
    },
    projectUrl () {
      if (!this.config?.PROJECT_ID) return null
      return `${window.location.origin}/#/project/${this.config?.PROJECT_ID}`
    },
    displayClosed () {
      if (this.type === 'service' && !this.hide) return true
      return false
    },
    headerListKey () {
      return `header-list-${this.dataplant?.services?.length}`
    }
  },
  watch: {
    hoverFromParent: {
      handler (val) {
        if (!val || val === this.type) this.hide = false
        else if (val !== this.type) {
          this.hide = true
          this.opened = false
        }
      }
    },
    '$store.getters.CLICK_FROM_IFRAME': {
      handler () {
        this.close()
      }
    },
    async projectId (newId, oldId) {
      this.config = await Config()
    }
  },
  async mounted () {
    window.addEventListener('click', this.close)
    this.config = await Config()
  },
  async destroyed () {
    window.removeEventListener('click', this.close)
  },
  methods: {
    mouseOver () {
      if (this.type === 'organization' && !this.fromHome) this.hover = true
      if (this.type === 'dataplant' && !this.fromDataplant) this.hover = true
      this.$emit('hover', this.type)
    },
    mouseLeave () {
      if (!this.opened) {
        this.hover = false
        this.$emit('hover', null)
      }
    },
    getLink (item) {
      let link = `${window.location.origin}/${item}/#/${this.projectId}`
      if (['infra'].includes(item)) {
        link = `${window.location.origin}/#/${item}/${this.projectId}`
      }
      if (['api', 'app'].includes(item)) {
        link = `${window.location.origin}/#/project/${this.projectId}`
      }
      if (['cc', 'control-center'].includes(item)) {
        if (this.projectId) link = `${window.location.origin}/cc/#/${this.config?.ORGANIZATION_ID}/${this.projectId}`
        else link = `${window.location.origin}/cc/#/${this.config?.ORGANIZATION_ID}`
      }
      if (item.includes('ml')) link = `${window.location.origin}/ml/#/${this.projectId}`

      return link
    },
    trackHeaderBreadcrumb () {
      window.localStorage.setItem('tracking-from', 'header breadcrumb')
    },
    open () {
      // the setTimeout function is removed to not have latency when this component is clicked because it looks like working without setTimeout.
      // FRE-3939
      this.opened = !this.opened
    },
    isParent (target) {
      if (target === this.$el) return true
      if (target.parentNode) return this.isParent(target.parentNode)
      return false
    },
    close ($event) {
      if (($event && this.isParent($event.target))) return
      this.opened = false
    }
  }
}
</script>

<style lang="less">
// Style in src/shared/styles/header.less

.service-selector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(52, 65, 78, 0.05);
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  color: #3E4550;
}
</style>
