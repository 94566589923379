class AclManager {
  constructor (acls) {
    this.acls = acls || {}
  }

  // We loop on all set of acls here
  can (service, resource, action, attributes) {
    // For admin
    if (this.acls === '*') return true
    // If no acl define on that service
    if (!this.acls[service]) return false
    // If one acl is ok
    return !!this.acls[service].find(acl => this.canACL(acl, resource, action, attributes))
  }

  /*
  * Say if a rule allow or not the current set of parameter
  */
  canACL (acl, resource, action, attributes = {}) {
    if (acl.Resource !== '*' && acl.Resource !== resource) return false
    if (acl.Action !== '*' && acl.Action !== action) return false
    for (const key in acl.Attributes) {
      if (this.canAttribute(acl.Attributes[key], attributes[key])) continue
      return false
    }
    return true
  }

  /*
  * Say if an attribute is compatible with the current attribute
  */
  canAttribute (aclValue, value) {
    // If set to * on the acl, it's ok
    if (aclValue === '*') return true

    // If the aclValue is an array and includes the current value, it's ok
    if (Array.isArray(aclValue) && aclValue.includes(value)) return true

    if (typeof (aclValue) === 'object') {
      return this.canAttributeObject(aclValue, value)
    }

    // If the aclValue equal to the current value, it's ok
    if (aclValue === value) return true

    return false
  }

  /*
    New format, it can allow multiple filter
    {
      $lte:Value,
      $gte:Value,
      $lt:Value,
      $gt:Value,
      $eq:Value,
      $regex:RegexValue,
      $in:ArrayOfValue,
      $notin:ArrayOfValue
      $exist:Boolean
    }
  */
  canAttributeObject (aclValue, value) {
    // Not available for now
    return false
  }

  //
  hasAccessTo (service, resource = null, action = null) {
    // For admin
    if (this.acls === '*') return true
    // If no acl define on that service
    if (!this.acls[service]) return false
    // If at least one acl on the service -> use in dashboard of dp
    if (resource === null && this.acls[service]) return true
    // If one acl is ok
    return !!this.acls[service].find(acl => this.hasAccessToACL(acl, resource, action))
  }

  /*
  * Say if a rule allow or not the current set of parameter
  */
  hasAccessToACL (acl, resource, action) {
    if (acl.Resource !== '*' && acl.Resource !== resource) return false
    if (action && acl.Action !== '*' && acl.Action !== action) return false
    return true
  }
}

module.exports = AclManager
