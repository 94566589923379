import cronstrue from 'cronstrue/i18n'
import Vue from 'vue'

// Impacts CC, DPE and MLM
const Cron = function (value) {
  let formatValue = value
  try {
    // See cronstrue on npmjs.com, you have a lot of options like local time (const lang = Vue.i18n.locale() || 'en') or 24h format
    formatValue = cronstrue.toString(value, { use24HourTimeFormat: true }) + ' UTC±00:00'
    return formatValue
  } catch (err) {
    /* istanbul ignore next */
    return ''
  }
}

export default Cron

Vue.filter('Cron', Cron)
