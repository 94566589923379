<template lang="pug">
  .query-fields
    .table-container(v-if="!noTableDetails")
      table-details(
        v-if="$store.getters.DWH_METAS_LOADED && !$scopedSlots.content"
        :latest-used-table="latestUsedTable"
        @toggle="val => openTableDetails(val)"
      )
      slot(
        v-if="$scopedSlots.content"
        name="content"
      )

    .fields-container(
      ref="fields-container"
    )
      .visual-container(
        v-if="visualMode"
        :class="{ explorer: fromExplorer }"
      )
        fp-loader(
          v-if="!$store.getters.DWH_METAS_LOADED"
        )
        .visual-container-content(
          v-if="$store.getters.DWH_METAS_LOADED"
        )
          .data-scale-container
            .fp-box.data
              .force-table-container
                .box-title {{ $t('query.fields.data') }}
                force-table(
                  :configuration="currentConfiguration"
                  :diamonds="diamonds"
                  :read-only="readOnly"
                  :table-preset="fromTable"
                  @update="update($event)"
                )
              .box-line
                query-data(
                  :fields="currentConfiguration.data ? currentConfiguration.data.fields : {}"
                  :scales="currentConfiguration.scale ? currentConfiguration.scale.fields : []"
                  :order="currentConfiguration.order || {}"
                  :attributes="uniqCompatiblesAttributes"
                  :configuration="currentConfiguration"
                  :read-only="readOnly"
                  :from-table="fromTable"
                  @update="update($event)"
                )
            .fp-box.scale
              .box-title {{ $t('query.fields.scale') }}
              .box-line
                query-scale(
                  :fields="currentConfiguration.scale ? currentConfiguration.scale.fields : []"
                  :data="currentConfiguration.data ? Object.keys(currentConfiguration.data.fields) : []"
                  :order="currentConfiguration.order || {}"
                  :attributes="uniqCompatiblesAttributes"
                  :read-only="readOnly"
                  @update="update($event)"
                )
          .filter-order-limit-container(
            :class="{ block: displayBlock }"
          )
            .filter-container
              .fp-box.filter
                .box-title {{ $t('query.fields.filter') }}
                .box-line
                  query-filter(
                    :filter="currentConfiguration.filter || {}"
                    :attributes="uniqCompatiblesAttributes"
                    :configuration="currentConfiguration"
                    :read-only="readOnly"
                    @update="update($event)"
                  )
            .order-limit-container
              .fp-box.order
                .box-title {{ $t('query.fields.order') }}
                .box-line
                  query-order(
                    :order="currentConfiguration.order || {}"
                    :data="currentConfiguration.data ? Object.keys(currentConfiguration.data.fields) : []"
                    :scales="currentConfiguration.scale ? currentConfiguration.scale.fields : []"
                    :attributes="uniqCompatiblesAttributes"
                    :read-only="readOnly"
                    @update="update($event)"
                  )
              .fp-box.limit
                .box-title {{ $t('query.fields.limit') }}
                .box-line
                  .query-limit
                    .text {{ $t('query.limit.to') }}
                    fpui-input-text(
                      type="number"
                      :value="limit"
                      :disabled="readOnly"
                      :min="1"
                      placeholder="5000"
                      @input="v => updateLimit(v)"
                  )

      .sql-container(v-if="!visualMode")
        fpui-sql-editor(
          :value="currentConfiguration.sql || ''"
          :read-only="readOnly"
          :key="item._id"
          @input="(value) => update({key:'sql', value: value})"
          @customRun="$emit('customRun')"
        )

</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _uniq from 'lodash/uniq'
import _intersection from 'lodash/intersection'

import FpuiSqlEditor from '@/shared/components/editors/FpuiSqlEditor'

import TableDetails from './TableDetails'
import QueryData from './QueryData'
import QueryScale from './QueryScale'
import QueryOrder from './QueryOrder'
import QueryFilter from './QueryFilter'
import ForceTable from './ForceTable'

export default {
  components: {
    TableDetails,
    QueryData,
    QueryScale,
    QueryFilter,
    QueryOrder,
    ForceTable,
    FpuiSqlEditor
  },
  props: {
    item: { type: Object, default: () => {} },
    readOnly: { type: Boolean, default: false },
    latestUsedTable: { type: String, default: null },
    fromExplorer: { type: Boolean, default: false },
    fromTable: { type: Object, default: () => { } },
    noTableDetails: { type: Boolean, default: false }
  },
  data () {
    return {
      sqlValue: '',
      compatiblesAttributes: [],
      diamonds: [],
      tableDetailsOpen: false,
      displayBlock: false
    }
  },
  computed: {
    currentConfiguration () {
      return this.item?.configuration ?? {}
    },
    visualMode () { return this.item?.type !== 'sql' },
    attributes () {
      return [...this.$store.getters.DWH_ALL_ATTRIBUTES].sort()
    },
    metasTables () {
      return this.$store.getters.DWH_METAS_TABLES
    },
    diamondsWithVirtualAttributes () {
      return this.$store.getters.DWH_DIAMONDS_WITH_VA
    },
    limit () {
      return this.currentConfiguration?.data?.limit
    },
    uniqCompatiblesAttributes () {
      return _uniq(this.compatiblesAttributes)
    }
  },
  watch: {
    '$store.getters.DWH_METAS_LOADED': {
      handler (value) {
        if (value) this.getCompatiblesAttributes()
      }
    },
    'item.configuration': {
      handler (val) {
        // updateWithoutFilters = false is used in filter component to prevent first filter to update list of compatible attributes
        // Ask Thomas Crochet for more informations
        const updateWithoutFilters = val.filter && Object.keys(val.filter).length === 1
        this.getCompatiblesAttributes(updateWithoutFilters)
      }
    }
  },
  created () {
    this.getCompatiblesAttributes()
  },
  methods: {
    fieldsContainerWidth () {
      if (!this.$refs) return false
      return this.$refs['fields-container']?.clientWidth < 1260
    },
    openTableDetails (val) {
      this.tableDetailsOpen = val
      this.displayBlock = val ? this.fieldsContainerWidth() : false
    },
    update ({ key, value }) {
      const tmpConfiguration = _cloneDeep(this.currentConfiguration)
      tmpConfiguration[key] = value
      this.item.update('configuration', tmpConfiguration)
    },
    updateLimit (value) {
      if ((value && value < 1) || (!value && !this.currentConfiguration?.data?.limit)) return
      const tmpConfiguration = _cloneDeep(this.currentConfiguration)
      tmpConfiguration.data.limit = value ? parseInt(value) : undefined

      this.item.update('configuration', tmpConfiguration)
    },
    getCompatiblesAttributes (updateWithoutFilters = false) { // Also used in AddFilter.vue, AdvancedFiltersModal.vue, Tile.vue
      /* Hide incompatible fields vs selected fields */

      // If table is forces, get only attributes from this table
      if (this.currentConfiguration.table_name) {
        const tableForced = this.metasTables.find(table => table.name === this.currentConfiguration.table_name)
        const attributesTableForced = this.$store.getters.DWH_ATTRIBUTES_BY_TABLE_ID_BY_ID(tableForced?._id).map(att => att.name)

        const diamonds = this.diamondsWithVirtualAttributes.find(diamond => diamond.name === this.currentConfiguration.table_name)

        this.compatiblesAttributes = diamonds?.attributes || attributesTableForced
        this.diamonds = [diamonds]
      } else {
        // Get Data, Scales and Filters fields selected
        let dataScaleFilterOrder = []
        Object.keys(this.currentConfiguration).forEach(val => {
          if (!updateWithoutFilters && val === 'filter' && this.currentConfiguration[val]) {
            dataScaleFilterOrder = [...dataScaleFilterOrder, ...Object.keys(this.currentConfiguration[val])]
          }
          if (val === 'scale' && this.currentConfiguration[val]?.fields && this.currentConfiguration[val]?.fields.length > 0) {
            dataScaleFilterOrder = [...dataScaleFilterOrder, ...this.currentConfiguration[val].fields]
          }
          if (val === 'data' && this.currentConfiguration[val]?.fields) {
            dataScaleFilterOrder = [...dataScaleFilterOrder, ...Object.keys(this.currentConfiguration[val].fields)]
          }
          if (val === 'order' && this.currentConfiguration[val]) {
            dataScaleFilterOrder = [...dataScaleFilterOrder, ...Object.keys(this.currentConfiguration[val])]
          }
        })

        if (dataScaleFilterOrder.length > 0 && this.diamondsWithVirtualAttributes.length) {
          // If field(s) selected ...
          if (dataScaleFilterOrder.length === 1) {
            this.diamonds = this.diamondsWithVirtualAttributes.filter(diamond => diamond.attributes.includes(dataScaleFilterOrder[0]))
          } else {
            const diamondsNameList = []
            dataScaleFilterOrder.forEach(dsf => {
              // For each fields selected, get a list of diamond's name they are into
              const diamond = this.diamondsWithVirtualAttributes.filter(diamond => diamond.attributes.includes(dsf))
              const diamondsNames = []
              diamond.forEach(d => {
                diamondsNames.push(d.name)
              })
              diamondsNameList.push(diamondsNames)
            })
            let diamondNameCommon = []
            diamondsNameList.forEach((t, idx) => {
              // Get the diamond's name in common between the list of names we get previously
              if (idx === 0) {
                diamondNameCommon = t
              } else {
                diamondNameCommon = _intersection(diamondNameCommon, t)
              }
            })
            // Get the diamonds with compatible fields from from the names
            this.diamonds = this.diamondsWithVirtualAttributes.filter(diamond => diamondNameCommon.includes(diamond.name))
          }

          if (this.diamonds.length > 0) {
            let allFields = []
            this.diamonds.forEach(diamond => {
              allFields = [...allFields, ...diamond.attributes]
            })

            this.compatiblesAttributes = this.attributes.filter(field => allFields.includes(field))
          } else {
            this.compatiblesAttributes = Object.keys(this.currentConfiguration.data.fields) || []
          }
        } else {
          this.compatiblesAttributes = this.attributes
          this.diamonds = []
        }
      }
    }
  }
}
</script>
<style lang="less">
  .query-fields {
    width: 100%;
    height: 100%;
    display: flex;
    .table-container {
      height: 100%;
      border-right: 1px solid rgba(151,167,183,0.21);
      background: white;
    }
    .fields-container {
      overflow: auto;
      height: 100%;
      flex: 1;
      .visual-container {
        padding: 5px 72px 5px 25px;
        width: 100%;
        .error-configuration {
          margin-top: 8px;
          margin-bottom: 8px;
          height: 50px;
          width: 100%;
          border: 1px solid #F62172;
          border-radius: 9px;
          background-color: rgba(246,33,114,0.15);
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 10px 16px;
          color: #F62172;
          font-size: 12px;

          i {
            font-size: 20px;
            margin-right: 11px;
          }
        }
        .fp-box {
          padding-bottom: 11px;
          margin-top: 5px;
          margin-bottom: 5px;
          height: 10%;
        }
        .btn.fpui-button.v4 {
          padding-left: 0px;
        }
        .data-scale-container {
          display: flex;
          justify-content: space-between;
          > .data {
            width: 100%;
            margin-right: 8px;

            .force-table-container {
              display: flex;
              align-items: flex-start;

              .force-table {
                margin-left: 5px;
              }
            }
          }
          > .scale {
            width: 100%;
          }
        }

        .filter-order-limit-container {
          display: flex;
          justify-content: space-between;
          .filter-container {
            width: 100%;
            margin-right: 8px;
            > .filter {
              height: fit-content;
              width: 100%;
              margin-right: 8px;
            }
          }
          .order-limit-container {
            display: flex;
            justify-content: space-between;
            width: 100%;

            > .order {
              height: fit-content;
              width: calc(~"100% - 200px");
              min-width: 250px;
              margin-right: 8px;
            }
            > .limit {
              height: fit-content;
              width: 200px;
              padding-bottom: 9px;
              .query-limit {
                display: flex;
                align-items: center;
                .text {
                  width: 25px;
                  margin-right: 10px;
                  color: @grey-chart;
                  font-size: 14px;
                }
                .fpui-input-number {
                  width: 100%;
                }
              }
            }
          }

          &.block {
            display: block;
            .filter-order-container {
              width: 100%;
              .order {
                margin-right: 0;
              }
            }
            .limit {
              width: 200px;
              float: right;
            }
          }
        }
      }
      .sql-container {
        width: 100%;
        height: 100%;
        .fpui-sql-editor {
          width: 100%;
          height: 100%;
          overflow-y: hidden;
          iframe {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 1350px) {
    .query-fields {
      .data-scale-container {
        display: block!important;
        > .data {
          margin-bottom: 10px!important;
        }
        > .scale {
          margin-top: 10px!important;
          margin-bottom: 10px!important;
        }
      }
      .filter-order-limit-container {
        display: block !important;
        .filter-container {
          width: 100% !important;
        }
      }
    }
  }
  .layout-v {
    .query-fields {
      .data-scale-container {
        display: block!important;
        > .data {
          margin-bottom: 10px!important;
        }
        > .scale {
          margin-top: 10px!important;
          margin-bottom: 10px!important;
        }
      }
      .filter-order-limit-container {
        display: block !important;
        .filter-container {
          width: 100% !important;
        }
      }
    }
  }
</style>
