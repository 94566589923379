<template lang="pug">
  .type-selector(:class="{ disabled: readOnly }")
    .current-value(@click="open")
      span {{ value }}
      i.fp4.fp4-angle-down
    ul.values(v-if="state")
      li.value1(
        v-if="typesWithLabel.length"
        v-for="type in typesWithLabel"
        @click="select(type.value, type.disabled)"
        :disabled="type.disabled"
        :class="{ active: isActive(type), disabled: type.disabled }"
      )
        span {{ type.label }}
      li.value2(
        v-if="types.length"
        v-for="type in types"
        @click="select(type)"
      )
        span {{ type }}

</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    needOldValue: {
      type: Boolean,
      default: false
    },
    typesWithLabel: {
      type: Array,
      default: () => []
    },
    types: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      state: false
    }
  },
  mounted () {
    window.addEventListener('click', this.close)
  },
  destroyed () {
    window.removeEventListener('click', this.close)
  },
  methods: {
    isParent (target) {
      if (target === this.$el) return true
      if (target.parentNode) return this.isParent(target.parentNode)
      return false
    },
    isEqual (val1, val2) {
      return val1 === val2
    },
    isActive (option) {
      return this.isEqual(option.value, this.value)
    },
    close ($event) {
      if ($event && this.isParent($event.target)) return
      this.state = false
    },
    async open () {
      if (this.readOnly) return

      this.state = !this.state
      await this.$nextTick()
    },
    select (type, disabled = false) {
      if (disabled) return
      if (this.needOldValue) {
        this.$emit('input', {
          newVal: type,
          oldVal: this.value
        })
      } else {
        this.$emit('input', type)
      }
      this.close()
    }
  }
}
</script>

<style lang="less">
  .type-selector {
    margin-left: 10px;
    float     : right;
    position  : relative;

    .values {
      width        : 100%;
      position     : absolute;
      background   : white;
      border-radius: 9px;
      box-shadow   : 0 2px 10px 0 rgba(62, 69, 80, 0.25);
      padding      : 5px 4px;
      z-index      : 2;
      margin       : 0;

      li {
        color      : #3E4550;
        line-height: 18px;
        margin     : 5px 0;
        cursor     : pointer;

        &.disabled {
          cursor: not-allowed !important;
          color: rgba(62,69,80,0.65);
        }

        &:hover,
        &.active {
          color           : #0089C0;
          background-color: rgba(0, 204, 249, 0.08);
        }

        font-size    : 14px;
        list-style   : none;
        padding      : 0 8px;
        border-radius: 6px;
      }
    }

    .current-value {
      background-color: rgba(0, 137, 192, 0.08);
      border-radius   : 9px;
      min-height      : 19px;
      color           : #0089C0;
      font-size       : 14px;
      cursor          : pointer;
      line-height     : 14px;
      padding         : 0 7px;
      display: flex;
      justify-content: space-between;
      span,
      i {
        display       : inline-block;
        vertical-align: bottom;
      }
      span {
        line-height: 19px;
      }
      i {
        margin-top: 2px;
      }
    }
  }
</style>
