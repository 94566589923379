<template lang="pug">
  .cannot-use-because-of-trino
    fpui-modal
      fp-loader(v-if="loading")
      fpui-modal-body
        .content
          i.fp4.fp4-qc-resources
          .title {{ $t('qb.trino_paywall.title') }}
          .description {{ $t('qb.trino_paywall.description') }}
          .trino-radio
            .fp-row
              .fp-line
                fpui-input-radio(
                  :value="value"
                  @input="(v) => value = v"
                  :options="[{ value: false, label: 'qb.trino_paywall.not-activate.title' }]"
                )
              .fp-line.text-container
                span.text {{ $t('qb.trino_paywall.not-activate.description') }}
                a.learn-more(href="https://docs.forepaas.io/#/en/product/am/resources?id=no-query-engine" target="_blank") {{ $t('qb.trino_paywall.not-activate.description_learn_more') }}
            .fp-row
              .fp-line
                fpui-input-radio(
                  :value="value"
                  @input="(v) => value = v"
                  :options="[{ value: true, label: 'qb.trino_paywall.activate.title' }]"
                )
              .fp-line.text-container
                .text {{ $t('qb.trino_paywall.activate.description') }}

        .trino-selected(v-if="value")
          .img-container
            img(:src="require('@/qb/assets/img/trino-logo.png')")
          .text-container
            .text(v-html="$t('qb.trino_paywall.activated.text')")
            //- If var is put in v-html -> use $sanitize()

            a.learn-more(href="https://docs.forepaas.io/#/en/product/am/resources " target="_blank") {{ $t('qb.trino_paywall.activated.learn_more') }}
      fpui-modal-footer(:buttons="buttons")
</template>

<script>
export default {
  props: {
    redirect: { type: String, default: '' },
    noActivate: { type: Function, default: null },
    activate: { type: Function, default: null }
  },
  data () {
    return {
      value: false,
      loading: false
    }
  },
  computed: {
    buttons () {
      return [{
        label: this.$t('cancel'),
        type: 'close',
        handler: () => {
          if (this.noActivate) this.noActivate()
          this.$emit('close')
        }
      }, {
        label: this.$t('save'),
        color: 'blue-flash',
        disabled: !this.value,
        async handler () {
          this.loading = true
          try {
            await this.$api.FPAPI.dataplants.activateTrino(this.$route.params.dataplantId)
            this.$store.commit('FOREPAAS_QB_RESOURCES_UPDATE', { active: true })
            this.$fpuiMessageBlock.success(this.$t('resources.activate_trino'))
          } catch (err) {
            this.$store.commit('FOREPAAS_QB_RESOURCES_UPDATE', { active: false })
            this.$fpuiMessageBlock.error(err)
          }
          this.loading = false
          this.$emit('close')
          if (this.activate) this.activate()
          if (this.redirect) this.$router.push(this.redirect)
        }
      }]
    }
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";
  .cannot-use-because-of-trino {
    position: relative;

    .modal-body {
      padding: 0;
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 47px 30px 47px;

        .fp4-qc-resources {
          color: @blue;
          font-size: 94px;
        }
        .title {
          font-weight: 600;
          font-size: 20px;
          line-height: 20px;
          text-align: center;
          color: @text-color;
          margin-bottom: 20px;
        }
        .description {
          color: @grey;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          margin-bottom: 16px;
        }
        .trino-radio {
          padding-left: 70px;
          .text-container {
            padding-left: 17px;
            margin-top: -12px;
            .text {
              font-size: 12px;
              line-height: 16px;
              color: @grey;
            }
            .learn-more {
              margin-left: 3px;
              font-size: 12px;
              line-height: 16px;
              color: @grey;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
      .trino-selected {
        border-top: 1px solid rgba(151, 167, 183, 0.21);
        padding: 15px 50px;
        display: flex;
        align-items: center;
        .img-container {
          border: 1px solid #E9ECF0;
          box-sizing: border-box;
          border-radius: 7px;
          width: 56px;
          height: 56px;
          margin-right: 10px;
          display: flex;
          justify-content: center;

          img {
            height: 47px;
          }
        }
        .text-container {
          width: calc(~"100% - 66px");
          .text {
            font-size: 12px;
            line-height: 18px;
            color: @text-color;
          }
          .learn-more {
            font-size: 12px;
            line-height: 18px;
            text-decoration: underline;
            cursor: pointer;
            color: @text-color;
          }
        }
      }
    }
  }
</style>
