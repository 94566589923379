import moment from 'moment'
import Vue from 'vue'

const Period = function (secondsBeforeNow) {
  if (isNaN(secondsBeforeNow) || !secondsBeforeNow) return ''

  return moment().subtract(secondsBeforeNow, 'seconds').fromNow()
}

export default Period
Vue.filter('Period', Period)
