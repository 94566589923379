import Vue from 'vue'
import { Assistant } from '@/shared/plugins/Api/GAB/Assistants'

export default {
  state: {
    assistants: []
  },
  mutations: {
    FOREPAAS_GAB_ASSISTANT_INIT (state, assistants) { // Not WS
      state.assistants = assistants
    },
    FOREPAAS_GAB_ASSISTANT_CREATE (state, event) {
      const exists = state.assistants.find(a => a.id === event.id)
      if (!exists) state.assistants.push(new Assistant(event))
    },
    FOREPAAS_GAB_ASSISTANT_REMOVE (state, event) {
      state.assistants = state.assistants.filter(a => event.id !== a.id)
    },
    FOREPAAS_GAB_ASSISTANT_BULK_REMOVE (state, event) {
      if (!event.removed || !Array.isArray(event.removed)) return
      state.assistants = state.assistants.filter(a => !event.removed.includes(a.id))
    }
  },
  actions: {
    /*
      Assistants actions
    */
    // List assistants
    async REFRESH_GAB_ASSISTANTS ({ getters, commit }) {
      const assistants = await Vue.api.GAB.assistants.list()
      commit('FOREPAAS_GAB_ASSISTANT_INIT', assistants)
    },
    // Find one assistant (for aab-embed)
    async FIND_ONE_GAB_ASSISTANT ({ getters, commit }, assistantId) {
      const assistant = await Vue.api.GAB.assistants.findOne(assistantId)
      return assistant
    }
  },
  getters: {
    GAB_ASSISTANTS (state) {
      return state.assistants
    },
    GAB_ASSISTANT_BY_ID (state) {
      return (id) => state.assistants.find(a => a.id === id)
    },
    GAB_ASSISTANTS_BY_KB_ID (state) {
      return (kbId) => state.assistants.filter(a => a.knowledge_base === kbId)
    }
  }
}
