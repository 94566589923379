import _get from 'lodash/get'
import Vue from 'vue'
import template from './template'

const types = ['success', 'error', 'warning', 'info']
const saveMessages = {}
let eventAdded = false
types.forEach(type => { saveMessages[type] = [] })

const findById = (type, id) => {
  let found = -1
  saveMessages[type].forEach(function (message, index) {
    if (message.id === id) {
      found = index
    }
  })
  return found
}

export default {
  success (...args) { this.pop('success', ...args) },
  error (...args) { this.pop('error', ...args) },
  warning (...args) { this.pop('warning', ...args) },
  info (...args) { this.pop('info', ...args) },
  pop (type, message, elem, id, compile, callback, duration = 10000) {
    if (message && message.silent) return
    if (type === 'error') {
      const originalError = message
      const code = _get(message, 'code')
      if (code && Vue.prototype.$i18n.keyExists(code)) {
        message = code
      } else {
        let error = _get(message, 'response.data.message')
        error = error || _get(message, 'response.data.error')
        error = error || _get(message, 'response.statusText')
        message = error || message.message || message
        if (originalError.status) message = `${originalError.status}:${message}`
        if (originalError.service) message = `${originalError.service}::${message}`
      }
    }
    message = Vue.prototype.$t(message)
    if (types.indexOf(type) === -1) {
      console.debug(`Message Block ${type} isn't a type`)
      return
    }
    if (id && (findById(type, id) !== -1)) {
      console.debug(`Message Block ${id} already exist`)
      return
    }

    const obj = {
      id,
      message,
      compile: compile || false
    }
    const findIndex = saveMessages[type].findIndex(block => block.message === message)
    if (findIndex === -1) {
      saveMessages[type].push(obj)
    }
    template.update(saveMessages, elem)
    const messageBlockElem = document.getElementById(template.id)
    if (messageBlockElem && !eventAdded) {
      messageBlockElem.addEventListener('mouseover', this.cancel.bind(this))
      messageBlockElem.addEventListener('mouseleave', this.enableTimer.bind(this, type, id, elem, callback, duration))
      eventAdded = true
    }
    this.enableTimer(type, id, elem, callback, duration)
  },
  enableTimer (type, id, elem, callback, duration) {
    this.cancel()
    if (type !== 'alert' && type !== 'warning') {
      this.timer = setTimeout(() => {
        this.remove(type, id, elem)
        if (callback) { return callback(id) }
      }, duration)
    }
  },
  remove (type, id, elem) {
    // If `id` is not defined, delete all messages
    if (!id) saveMessages[type] = []
    const element = findById(type, id)
    if (element !== -1) {
      saveMessages[type].splice(element, 1)
    }
    template.update(saveMessages, elem)
  },
  cancel () { clearTimeout(this.timer) }
}
