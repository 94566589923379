<template lang="pug">
.subscription-new-step-account
  .header
    h4 {{ 'subscription.new.account' | translate }}
    p {{ 'subscription.new.account.details' | translate }}


  .subscription-new-select-account
    .account(
      @click="createAccount()"
      :class="{ active: 'create' === customerAccountId }"
    )
      .account-container
        img(:src="require('../assets/account_new.svg')")
        .text {{ 'subscription.new.create_account' | translate }}
        .desc-new {{ 'subscription.new.create_account.desc' | translate }}
    .account(
      v-for="(ca,index) in customerAccounts"
      :key="index"
      :class="{ active: customerAccount ? ca._id === customerAccount._id:false }"
      @click="selectAccount(ca)"
    )
      .account-container
        img(:src="require('../assets/account.svg')")
        .text {{ ca.billing_address.company || ca.company }}
        .desc {{ ca._id }}
        .desc-new
          div {{ ca.billing_address.zip }} {{ ca.billing_address.city }}
          div {{ ca.billing_address.country }}


</template>
<script>

export default {
  props: {
    customerAccountId: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      create: false
    }
  },
  computed: {
    customerAccounts () {
      return this.$store.getters.ACCOUNTS
    },
    customerAccount () {
      if (this.customerAccountId === null) return null
      return this.$store.getters.ACCOUNT_BY_ID(this.customerAccountId)
    }
  },
  methods: {
    selectAccount (account) {
      this.$emit('customer-account-id', account._id)
    },
    createAccount () {
      this.$emit('customer-account-id', 'create')
    }
  }
}
</script>
<style lang="less">
.subscription-new-step-account {
  .subscription-new-select-account {
    overflow: auto;
    min-width  : 100%;
    text-align : center;
    white-space: nowrap;
    margin     : 50px 0 0;
    position: relative;

    .account {
      height        : 300px;
      width         : 220px;
      display       : inline-block;
      vertical-align: middle;
      white-space   : normal;
      margin        : 10px;

      .account-container {
        position        : absolute;
        top             : 30px;
        text-align      : left;
        height          : 220px;
        width           : 220px;
        border          : 0.5px solid rgba(151, 167, 183, 0.21);
        border-radius   : 5px;
        background-color: rgba(255, 255, 255, 0.9);
        cursor          : pointer;
        padding         : 21px;
        transition      : top .2s, height .2s, border .2s, box-shadow .4s;

        .text {
          margin-top : 18px;
          font-size  : 22px;
          font-weight: bold;
          line-height: 28px;
        }

        .desc {
          color      : #B2BECA;
          font-size  : 12px;
          font-weight: bold;
          line-height: 15px;
          margin-top : 7px;
        }

        .desc-new {
          color      : #B2BECA;
          margin-top : 7px;
          font-size  : 12px;
          line-height: 16px;
        }
      }

      &.active,
      &:hover:not(.disabled) {
        .account-container {
          height    : 270px;
          top       : 0;
          box-shadow: 0 18px 17px 0 rgba(0, 0, 0, 0.21), 0 2px 4px 0 rgba(52, 65, 78, 0.05);
        }
      }

      &.active {
        .account-container {
          border: 0.5px solid #00CCF9;
        }
      }

      &.disabled {
        background-color: #ccd4dc;
        color           : grey;

        cursor: not-allowed;
      }
    }
  }
}

</style>
