<template lang='pug'>
.td.fp-tree-cell(
  :class="{ first: index === 0 }"
  @contextmenu.prevent="($event) => $event.preventDefault()"
  :style="{ 'z-index': columnsNumber - index, height: `${rowHeight}px` }"
)
  .fp-tree-row-heads(
    v-if='index === 0'
    :style="{ height: `${rowHeight}px` }"
  )
    //- value.level is folder hierarchy level
    //- value.level === 0 is root folder
    .fp-tree-row-head(
      v-for='n, index in [].constructor(value.level + 1)',
      :class='{ first: index === 0 || index !== value.level , last: index === value.level }'
      :key='index'
      :style="{ 'left': getFirstLeft(index), height: `${rowHeight}px` }"
    )
  i.icon(
    v-if='index === 0 && !column.noIcon',
    :class='displayIcon'
    :style="{ 'margin-left': getIconMarginLeft(value.level), color: column.iconColor ? column.iconColor(value) : null, 'line-height': `${rowHeight}px`}"
    @click="$emit('toggle')"
  )
  span.content(
    v-context-menu="contextMenuFn(value)"
    :style="{ width: `calc(100% - ${getIconMarginLeft(value.level)})`, height: `${rowHeight}px`, 'line-height': `${rowHeight}px` }"
    @click="select"
  )
    span.value(
      v-html='$sanitize(display)'
      v-if="!renaming"
      :class="{renamable: column.rename}"
      v-tooltip="tooltipDisplay"
    )
    fp-tree-edit.value(
      v-else
      :value="display"
      :row-height="rowHeight"
      @input="update"
    )

</template>

<script>
import FpTreeEdit from './FpTreeEdit'
import _get from 'lodash/get'

export default {
  components: { FpTreeEdit },
  props: {
    autoRename: { type: Boolean, default: false },
    icon: { type: String, default: 'fp4 fp4-folder' },
    index: { type: Number, required: true },
    columnsNumber: { type: Number, required: true },
    value: { type: Object, required: true },
    column: { type: Object, required: true },
    contextMenuOptions: { type: Object, default: () => {} },
    rowHeight: { type: Number, default: 45 }
  },
  data () {
    return {
      renaming: false
    }
  },
  computed: {
    // disable everything that has a value of this.item.global === true
    globalDisabled () {
      return this?.value?.object.global
    },
    isRenamable () {
      if (!this.column?.rename) return false
      // if (typeof (_get(this.value.object, this.column.rename.target)) === 'undefined') return false
      if (this.column.rename.if) {
        if (!this.column.rename.if(this.value.object)) return false
      }
      return true
    },
    displayIcon () {
      if (this.value.object.loading) return 'fa fa-spinner fa-spin'
      if (this.value.object.type === 'folder' && !this.column.iconCustom) return 'fp4 fp4-folder'
      if (this.column.icon) {
        switch (typeof (this.column.icon)) {
          case 'string':
            return this.column.icon || ''
          case 'function':
            return this.column.icon(this.value.object)
        }
      }
      return this.icon
    },
    display () {
      switch (typeof (this.column.target)) {
        case 'string':
          return _get(this.value.object, this.column.target) || '-'
        case 'function':
          return this.column.target(this.value.object)
      }
      return '-'
    },
    tooltipDisplay () {
      switch (typeof (this.column.tooltip)) {
        case 'string':
          return _get(this.value.object, this.column.tooltip) || '-'
        case 'function':
          return this.column.tooltip(this.value.object)
      }

      if (!this.display) return ''
      return this.display.length > 10 ? this.display : ''
    }
  },
  mounted () {
    if (this.autoRename) {
      this.rename()
    }
  },
  methods: {
    async update (value) {
      if (!this.renaming) return
      this.$emit('input', value)
      this.renaming = false
    },
    rename () {
      if (!this.isRenamable) return null
      this.renaming = true
    },
    contextConditional (parameter, fallback = true) {
      if (parameter !== undefined) {
        if (typeof parameter === 'function') return parameter(this.value.object)
        return parameter
      } else if (parameter === undefined) return fallback
      return parameter
    },
    contextMenuFn () {
      return {
        onFocus () {
          this.$emit('focus')
        },
        options: [
          {
            label: this.contextMenuOptions?.rename?.label || this.$t('treeview.rename'),
            class: this.contextMenuOptions?.rename?.class || 'rename',
            icon: this.contextMenuOptions?.rename?.icon || 'fp4-pencil',
            if: this.column?.rename && this.contextConditional(this.contextMenuOptions?.rename?.if),
            disabled: this.globalDisabled || this.contextConditional(this.contextMenuOptions?.rename?.disabled, false),
            click () {
              this.rename()
            }
          }, {
            label: this.contextMenuOptions?.edit?.label || this.$t('treeview.edit'),
            class: this.contextMenuOptions?.edit?.class || 'edit',
            icon: this.contextMenuOptions?.edit?.icon || 'fp4-gear',
            if: this.contextConditional(this.contextMenuOptions?.edit?.if, null) || this.value?.object?.type !== 'folder',
            disabled: this.globalDisabled || this.contextConditional(this.contextMenuOptions?.edit?.disabled, false),
            click () {
              this.$parent.$emit('edit')
            }
          }, {
            label: this.contextMenuOptions?.remove?.label || this.$t('treeview.remove'),
            class: this.contextMenuOptions?.remove?.class || 'remove',
            icon: this.contextMenuOptions?.remove?.icon || 'fp4-trash-can',
            if: this.contextConditional(this.contextMenuOptions?.remove?.if),
            disabled: this.globalDisabled || this.contextConditional(this.contextMenuOptions?.remove?.disabled, false),
            click () {
              this.$parent.$parent.$parent.remove(this.value)
            }
          }
        ]
      }
    },
    getFirstLeft (index) {
      return index === 0 ? '0px' : `${(index - 1) * 30}px`
    },
    getIconMarginLeft (level) {
      return level === 0 ? '0px' : `${level * 30 - 20}px`
    },
    select ($event) {
      this.$emit('select', $event)
    }
  }
}
</script>
