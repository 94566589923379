import Vue from 'vue'
Vue.directive('dropzone', {
  bind (el, { value }) {
    if (!value.onDrop) throw new Error('onDrop should be defined as parameter of directive dropzone')

    el.ondrop = function ($event) {
      $event.preventDefault()
      el.classList.remove('dragover')

      const files = []
      if ($event.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (let i = 0; i < $event.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if ($event.dataTransfer.items[i].kind === 'file') {
            const file = $event.dataTransfer.items[i].getAsFile()
            files.push(file)
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        for (let i = 0; i < $event.dataTransfer.files.length; i++) {
          files.push($event.dataTransfer.files[i])
        }
      }
      value.onDrop(files)
    }
    el.ondragenter = function ($event) {
      el.classList.add('dragover')
    }
    el.ondragleave = function ($event) {
      el.classList.remove('dragover')
    }
    el.ondragover = function ($event) {
      $event.preventDefault()
    }
  }
})
