const SQLQuery = require('./SQLQuery')

/**
 * DashboardSQLQuery
 *
 * Standard class to be able to create a simple SQL Query for dashboard
 */
class DashboardSQLQuery extends SQLQuery {
  /**
   * constructor
   * @param String sql  A standard SQL Query (ex : SELECT * FROM my_table)
   */
  constructor (sql, options = {}) {
    super(sql, options)

    this.dashboard_id = options.dashboard_id
    this.filters = options.filters || {}
    this.url = options.url
  }

  async execute (context) {
    const options = {
      url: `query-admin/v3/dashboards/${this.dashboard_id}/deployed/${this.query_id}/query`,
      method: 'POST',
      data: this.filters,
      source: this.source,
      query_id: this.query_id,
      dashboard_id: this.dashboard_id
    }

    if (this.url) {
      options.url = this.url
      options.baseURL = null
    }

    let response = await context.requestAPI(options)

    try {
      response = await this.parse(context, response, true)
      this.parse(context, response)
        .then(() => {
          this._data.end()
        })
        .catch(err => {
          this._data.emit('error', err)
        })
    } catch (err) {
      this._data.emit('error', err)
      this._data.end()
    }
  }
}

module.exports = DashboardSQLQuery
