<template lang='pug'>
.fp-modal-confirm
  fpui-modal
    fpui-modal-header(
      :title="$t(title)"
      @close='$emit("close")'
    )
    fpui-modal-body(v-if="content")
      .modal-confirm-content(v-if="!contentHtml") {{ content | translate }}
      .modal-confirm-content(v-if="contentHtml", v-html="$sanitize(content) || ' '")
    fpui-modal-footer(:buttons="buttons" :class='{ withoutBody: !content }')
</template>

<script>
export default {
  props: {
    content: { type: String, default: '' },
    contentHtml: { type: Boolean },
    title: { type: String, default: '' },
    buttons: { type: Array, required: true }
  }
}
</script>

<style lang='less'>
@import "~@/shared/styles/_variables.less";

  .fp-modal-confirm {
    .modal-title {
      font-weight: 600 !important;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      i {
        font-size: 30px;
        vertical-align: bottom;
      }
    }

    .modal-confirm-content {
      font-size: 14px;
      .paragraph-info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .helper {
          position: relative;
          right: -10px;
          i {
            font-size: 14px;
            color: @grey;
            &:hover {
              color: @blue_flash;
              cursor: pointer;
            }
          }
          .helper-hover {
            background-color: @white;
            border-radius: 10px;
            bottom: 30px;
            box-shadow: 0 12px 35px 0 rgba(62,69,80,0.55);
            display: none;
            left: 50%;
            min-width: 180px;
            width: auto;
            padding: 10px;
            position: absolute;
            transform: translateX(-50%);
            z-index: 100;
            text-align: center;
            .item {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              margin: 0;
            }
            &:after {
              content: " ";
              border-color: @white;
              border-width: 10px;
              background: white;
              border: solid transparent;
              position: absolute;
              bottom: -5px;
              left: 50%;
              width: 15px;
              height: 15px;
              transform: translateX(-50%) rotate(45deg);
            }
          }
          &:hover {
            .helper-hover {
              display: block;
            }
          }
        }
      }
      p {
        margin: 0;
      }
      .last-p {
        margin-top: 15px;
        margin-bottom: 0;
      }
    }

    .button-wrapper {
      height: 40px;
      width: 100%;
      .button-container {
        position: absolute;
        right: 0;
        margin-top: 24px;
        display: flex;
        align-items: center;
        .fpui-button {
          display: flex;
          align-items: flex-start;
          text-align: center;
          margin-right: 15px;
          width: 69px;
          padding: 6px 10px 6px 3px;
          i {
            font-size: 16px;
          }
          .fpui {
            font-size: 25px;
            margin-top: -8px;
            margin-right: -8px;
          }
        }
      }
    }
    .modal-footer.withoutBody {
      grid-area: unset;
      border-top: unset;
    }
    .btn-content {
      line-height: 14px;
    }
  }
</style>
