import API from '@/shared/plugins/Api/API'
import Configurations from './Configurations'
import Databases from './Databases'
import Engines from './Engines'
import Instances from './Instances'

class STORAGE extends API {
  constructor (id) {
    super(id, true, true)
    this.instances = new Instances(id)
    this.configurations = new Configurations(id)
    this.databases = new Databases(id)
    this.engines = new Engines(id)
  }
}
export default STORAGE
