import Date from '@/shared/filters/Date'
import { API, APIObject } from '@/shared/plugins/Api/API'
import _cloneDeep from 'lodash/cloneDeep'
import _omit from 'lodash/omit'
import _set from 'lodash/set'

class Dataset extends APIObject {
  constructor (options) {
    // Init
    super('DM', options)
    this._original_id = this._id

    // Default variables
    this.type = this.type || ''
    this.description = this.description || ''
    this.tags = this.tags || {}
    this.tags.path = this.tags.path || ''
    this.tags.tags = this.tags.tags || []
    if (!Array.isArray(this.tags.tags)) this.tags.tags = []
    this.display_name = this.display_name || ''
    this.cluster = this.cluster || ''
    this.resource_details = this.resource_details || {}
    this.color = this.color || '#00CCF9'
    this.dataset_parameters = this.dataset_parameters || null
    this.policy_tags = this.policy_tags || {}
    this.default_expiration_date = this.default_expiration_date || ''
    this.default_time_to_live = this.default_time_to_live || 0
    this.default_retention = this.default_retention || 0
  }

  get hasChanges () {
    return !!Object.keys(this._filter(this.__saveQueue)).length
  }

  assign (object) {
    super.assign(object)
    this._original_id = this._id
    this.created_at = Date(this.created_at) || null
    this.updated_at = Date(this.updated_at) || null
  }

  clone () {
    return new Dataset(_cloneDeep(this._filter(this)))
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      '_type',
      '_original_id',
      '__socketId',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
      'name'
    ])
    return obj
  }

  async create () {
    await super.create({
      method: 'POST',
      url: 'v4/datasets'
    })

    return this
  }

  async save () {
    await super.save({
      method: 'PUT',
      url: `v4/datasets/${this._id}`
    })

    return this
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v4/datasets/${this._id}`
    })
  }
}
class Datasets extends API {
  /**
  * List all datasets
  *
  * @return {Array} Dataset array
  */
  async list () {
    const datasets = await this.request({
      method: 'GET',
      url: 'v4/datasets',
      params: {
        policy_tags: true
      }
    })

    return datasets.map(dataset => {
      try {
        return new Dataset(dataset)
      } catch (err) {
        console.error(err.stack)
        return null
      }
    }).filter(i => i)
  }

  /**
  * Add new dataset
  *
  * @return {Array} Dataset item
  */
  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Dataset(item)
  }
}

export default Datasets
export {
  Dataset,
  Datasets
}
