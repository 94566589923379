<template lang="pug">
  .fpui-canvas-icon(:style="{ height: `${size}px`, width: `${size}px`}")
    canvas.icon#canvasIcon(
      ref="canvasIcon"
      :width='size',
      :height='size'
    )
</template>

<script>
export default {
  props: {
    name: { type: String, default: '' },
    size: { type: Number, default: 16 },
    fontSize: { type: String, default: '' },
    color: { type: String, default: 'black' },
    backgroundColor: { type: String, default: 'lightblue' },
    letterNumber: { type: Number, default: 2 },
    logo: { type: String, default: '' },
    paddingTop: { type: Number, default: 5 },
    edit: { type: Boolean },
    imageError: Boolean
  },
  mounted () {
    if ((!this.logo && this.name) || (this.imageError && this.name)) this.makeCanvas(this.name)
  },
  methods: {
    makeCanvas () {
      const splitName = this.name.split(' ')
      const firstLetters = splitName.map(([v]) => {
        if (typeof v === 'string') return v.toUpperCase()
        return v
      }).filter(v => v)
      const c = this.$refs.canvasIcon
      const ctx = c.getContext('2d')
      let txt = ''
      if (this.letterNumber === 1 || firstLetters.length === 1) txt = firstLetters[0] || ''
      else txt = (firstLetters[0] || '') + (firstLetters[1] || '')
      const totalHeight = this.size
      const totalWidth = this.size
      const fontSize = this.fontSize || `${totalWidth / 2}px`
      ctx.font = `${fontSize} Source Sans Pro`
      ctx.font = `${fontSize} Source Sans Pro`
      const txtHeight = ctx.measureText(txt).actualBoundingBoxDescent - ctx.measureText(txt).actualBoundingBoxAscent
      const txtWidth = ctx.measureText(txt).actualBoundingBoxRight - ctx.measureText(txt).actualBoundingBoxLeft
      const centerY = (totalHeight - txtHeight) / 2
      const centerX = (totalWidth - txtWidth) / 2
      ctx.fillStyle = this.backgroundColor || 'lightgrey'
      ctx.fillRect(0, 0, totalWidth, totalHeight)
      ctx.fillStyle = this.color
      ctx.fillText(txt, centerX, centerY)
    }
  }
}
</script>
