import _groupBy from 'lodash/groupBy'
import _keyBy from 'lodash/keyBy'
import _uniqBy from 'lodash/uniqBy'
import Vue from 'vue'

const getVirtualAttributes = (virtualAttributes, attributesNames) => {
  if (!virtualAttributes) return []
  const va = virtualAttributes.filter(va => {
    const requiredAttributes = va.required_attributes_names

    // Return requiredAttributes is a sublist of attributesNames
    if (!attributesNames) return false
    return requiredAttributes.every(e => attributesNames.includes(e))
  })

  return va.map(va => va.name)
}

export default {
  state: {
    attributesFull: [],
    attributes: [],
    virtualAttributes: [],
    attributesByTableId: {},
    tables: [],
    tablesByAttributeName: {},
    databasesFull: [],
    databases: [],
    diamondsWithVirtualAttributes: [],
    metasLoaded: false
  },
  mutations: {
    SET_DWH_METAS_LOADED (state) { // Not WS
      state.metasLoaded = true
    },
    SET_DWH_ATTRIBUTES_FULL (state, attributes) { // Not WS
      state.attributesFull = attributes
    },
    SET_DWH_ATTRIBUTES (state, attributes) { // Not WS
      state.attributes = attributes
    },
    SET_DWH_VIRTUAL_ATTRIBUTES (state, virtualAttributes) { // Not WS
      state.virtualAttributes = virtualAttributes
    },
    SET_DWH_ATTRIBUTES_BY_TABLE_ID (state, attributesByTableId) { // Not WS
      state.attributesByTableId = attributesByTableId
    },
    SET_DWH_TABLES_BY_ATTRIBUTE_NAME (state, tablesByAttributeName) { // Not WS
      state.tablesByAttributeName = tablesByAttributeName
    },
    SET_DWH_TABLES (state, tables) { // Not WS
      state.tables = tables
    },
    SET_DWH_DATABASES_FULL (state, databases) { // Not WS
      state.databasesFull = databases
    },
    SET_DWH_DATABASES (state, databases) { // Not WS
      state.databases = databases
    },
    SET_DWH_DIAMONDS_WITH_VIRTUAL_ATTRIBUTES (state, diamonds) { // Not WS
      state.diamondsWithVirtualAttributes = diamonds
    }
  },
  actions: {
    async LOAD_QB_METAS ({ commit }) {
      const [attributes, virtualAttributes, databases, tables] = await Promise.all([Vue.api.DM.attributes.list(), Vue.api.DM.virtualAttributes.list(), Vue.api.DM.databases.list(), Vue.api.DM.databases.listTables()])

      const attributesByTableId = _groupBy(attributes, 'table_id')
      commit('SET_DWH_ATTRIBUTES_BY_TABLE_ID', attributesByTableId)

      const attributesNames = _uniqBy(attributes.map(a => a.name))
      commit('SET_DWH_ATTRIBUTES', attributesNames)

      const virtualAttributesNames = virtualAttributes.map(a => a.name)
      commit('SET_DWH_VIRTUAL_ATTRIBUTES', virtualAttributesNames)

      const attributesFull = _keyBy(attributes, 'name')
      const virtualAttributesFull = _keyBy(virtualAttributes, 'name')
      commit('SET_DWH_ATTRIBUTES_FULL', { ...attributesFull, ...virtualAttributesFull })
      const databasesFiltered = databases.filter(d => ['prim', 'mart', 'ml'].includes(d.level))
      // Add both all databases and those like before the /metas migration, with prim, mart and ml filtering
      commit('SET_DWH_DATABASES_FULL', databases)
      commit('SET_DWH_DATABASES', databasesFiltered)

      // Add database_name and table_name in tables
      const databasesByDatabaseId = _keyBy(databasesFiltered, '_id')
      const tablesCompleted = tables.map(t => {
        return {
          ...t,
          table_name: t.name,
          database_name: databasesByDatabaseId[t.database_id]?.name || ''
        }
      }).filter(t => t.database_name)
      commit('SET_DWH_TABLES', tablesCompleted)

      // Get object like { attribute_key: { attribute_name: attributeName, tables: [Array of table names where the attribute is] } }
      const tablesByTableId = _keyBy(tables, '_id')
      const tablesByAttributeName = {}
      attributes.forEach((att, i) => {
        tablesByAttributeName[att.name] = tablesByAttributeName[att.name] || { attribute_name: att.name, tables: [] }
        const tableName = tablesByTableId[att.table_id]?.name
        if (tableName && !tablesByAttributeName[att.name].tables.includes(tableName)) tablesByAttributeName[att.name].tables.push(tableName)
      })
      commit('SET_DWH_TABLES_BY_ATTRIBUTE_NAME', tablesByAttributeName)

      const diamondsWithVirtualAttributes = []
      tablesCompleted.forEach(table => {
        const tableAttributes = attributesByTableId[table._id] || []
        if (!table.database_name.includes('data_ml')) {
          const dimensions = []
          const measures = []
          tableAttributes.forEach(attr => {
            if (attr.is_measure) measures.push(attr.name)
            else dimensions.push(attr.name)
          })

          const diamond = {
            name: table.name,
            attributes: [
              ...dimensions,
              ...measures,
              ...getVirtualAttributes(virtualAttributes, attributesByTableId[table._id]?.map(att => att.name))
            ]
          }
          diamondsWithVirtualAttributes.push(diamond)
        }
      })
      commit('SET_DWH_DIAMONDS_WITH_VIRTUAL_ATTRIBUTES', diamondsWithVirtualAttributes)

      commit('SET_DWH_METAS_LOADED')
    }
  },
  getters: {
    DWH_METAS_LOADED (state) {
      return state.metasLoaded
    },
    DWH_METAS_TABLES (state) {
      return state.tables
    },
    DWH_METAS_DATABASES (state) {
      return state.databases
    },
    DWH_METAS_DATABASES_FULL (state) {
      return state.databasesFull
    },
    DWH_ATTRIBUTES_FULL (state) {
      return state.attributesFull
    },
    DWH_ALL_ATTRIBUTES (state) {
      return [...state.attributes, ...state.virtualAttributes]
    },
    DWH_METAS_ATTRIBUTES (state) {
      return state.attributes
    },
    DWH_METAS_VIRTUAL_ATTRIBUTES (state) {
      return state.virtualAttributes
    },
    DWH_DIAMONDS_WITH_VA (state) {
      return state.diamondsWithVirtualAttributes
    },
    DWH_ATTRIBUTES_BY_TABLE_ID (state) {
      return state.attributesByTableId
    },
    DWH_ATTRIBUTES_BY_TABLE_ID_BY_ID (state) {
      return id => state.attributesByTableId[id] || []
    },
    DWH_TABLES_BY_ATTRIBUTE_NAME (state) {
      return state.tablesByAttributeName
    },
    DWH_TABLES_BY_ATTRIBUTE_NAME_BY_NAME (state) {
      return name => state.tablesByAttributeName[name] || {}
    }
  }
}
