import Date from '@/shared/filters/Date'
import { API, APIObject } from '@/shared/plugins/Api/API'
import _cloneDeep from 'lodash/cloneDeep'
import _omit from 'lodash/omit'
import _set from 'lodash/set'

class PolicyTag extends APIObject {
  constructor (options) {
    // Init
    super('DM', options)

    // Default variables
    this.display_name = this.display_name || ''
    this.name = this.name || ''
    this.description = this.description || ''
    this.tags = this.tags || {}
    this.resource_details = this.resource_details || {}
    this.values = this.values || []
    this.mask = this.mask || ''
  }

  assign (object) {
    super.assign(object)
    this.created_at = Date(this.created_at) || null
    this.updated_at = Date(this.updated_at) || null
  }

  clone () {
    return new PolicyTags(_cloneDeep(this._filter(this)))
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      '__socketId',
      'created_at',
      'updated_at',
      'created_by',
      'updated_by'
    ])
    return obj
  }

  async create () {
    await super.create({
      method: 'POST',
      url: 'v4/policy_tag_keys'
    })
    return this
  }

  async save () {
    await super.save({
      method: 'PUT',
      url: `v4/policy_tag_keys/${this._id}`
    })
    return this
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v4/policy_tag_keys/${this._id}`
    })
  }
}
class PolicyTags extends API {
  /**
  * List all PolicyTags
  *
  * @return {Array} PolicyTags array
  */
  async list () {
    const policyTags = await this.request({
      method: 'GET',
      url: 'v4/policy_tag_keys'
    })
    return policyTags.map(policyTag => {
      try {
        return new PolicyTag(policyTag)
      } catch (err) {
        console.error(err.stack)
        return null
      }
    }).filter(i => i)
  }

  async listBindings () {
    const policyTagsBindings = await this.request({
      method: 'GET',
      url: 'v4/policy_tag_bindings'
    })
    return policyTagsBindings.filter(ptb => ptb.binding_status === 'active')
  }

  /**
  * Add new Policy  tag
  *
  * @return {Array} Policy Tag item
  */
  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new PolicyTag(item)
  }
}

export default PolicyTags
export {
  PolicyTag,
  PolicyTags
}
