import { API, APIObject } from '@/shared/plugins/Api/API'
import Config from '@/shared/Config'
import _omit from 'lodash/omit'
import _set from 'lodash/set'
import _cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

class AssistantMessage {
  constructor (options) {
    // Default variables
    this.index = options.index || 0
    this.source = options.source || null
    this.content = options.content || ''
    this.regenerated_index = options.regenerated_index || 0
    this.user_feedback = options.user_feedback || {
      positive_count: 0,
      negative_count: 0
    }
  }

  get formattedContent () {
    // Check if it does not cause any security issue because it's on a v-html
    return this.content?.replace('<|endoftext|>', '').replace(/\n/g, '<br />')
  }

  get positiveFeedback () {
    return this.user_feedback?.positive_count
  }

  get negativeFeedback () {
    return this.user_feedback?.negative_count
  }
}

class AssistantChat extends APIObject {
  constructor (options) {
    // Init
    super('GAB', options)

    // Default variables
    this.url = this.url || null
    this.name = this.name || ''
    this.display_name = this.display_name || ''
    this.description = this.description || ''
    this.tags = this.tags || {}
    this.tags.path = this.tags.path || ''
    this.messages = this.messages?.length ? this.messages?.map(message => new AssistantMessage(message)) : []
  }

  _filter (object) {
    return _omit(super._filter(object), [
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
      'assistant',
      'id',
      'name'
    ])
  }

  async create (data) {
    const config = await Config()
    return super.create({
      method: 'POST',
      baseURL: config.BASE_URL,
      url: `${this.assistant?.url}/v1/chats`,
      data: {
        ...data,
        display_name: this.display_name || this.assistant?.display_name
      }
    })
  }

  async retrieveMessages () {
    const config = await Config()
    const chat = await this.request({
      method: 'GET',
      baseURL: config.BASE_URL,
      url: `${this.assistant?.url}/v1/chats/${this.id}`
    })

    this.messages = chat?.messages?.length ? chat?.messages?.map(message => new AssistantMessage(message)) : []

    return this
  }

  async sendMessage (content, streaming = true, onWriting = null, onEnd = null) {
    const config = await Config()

    if (streaming) {
      const assistantMessageIndex = this.messages.length + 1 // `this.messages.length` will be the index of the user message
      // Initialize an empty assistant message
      const assistantMessage = new AssistantMessage(
        {
          index: assistantMessageIndex,
          source: 'assistant'
        }
      )

      // Initialize the user message
      const userMessage = new AssistantMessage({
        index: assistantMessage.index - 1,
        source: 'user',
        content: content
      })

      // Add the user and empty assistant message to the messages list
      const messages = _cloneDeep(this.messages)
      messages.push(userMessage, assistantMessage)

      // Send the message and wait for the streaming response
      await this.request({
        method: 'POST',
        baseURL: config.BASE_URL,
        url: `${this.assistant?.url}/v1/chats/${this.id}`,
        originalResponse: true,
        params: {
          streaming
        },
        data: {
          content
        },
        // responseType: stream is not available for the browser so we need to use the `onDownloadProgress` event
        onDownloadProgress: (evt) => {
          // If using the latest version of Axios (v1.5.1 at the time of writing),
          // use `evt.event.target` instead of `evt.target` as this is a breaking change

          // Take the tokens from the response and parse them
          const tokens = evt.target?.responseText?.split('\n').filter(line => line.length)
          const message = tokens.map(token => {
            return JSON.parse(token.replace('data:', ''))
          }).reduce((acc, curr) => {
            return acc + curr.token
          }, '')
          // Example output: ["Hi", " ", ", how ", "can", " I ", "help", "?"]

          // Replace the content of the assistant message with the current streaming response
          assistantMessage.content = message
          messages[assistantMessageIndex] = assistantMessage
          this.messages = messages

          // Call the `onWriting` callback
          onWriting(message)
        }
      })
      if (onEnd) onEnd()
    } else {
      const message = await this.request({
        method: 'POST',
        baseURL: config.BASE_URL,
        url: `${this.assistant?.url}/v1/chats/${this.id}`,
        params: {
          streaming
        },
        data: {
          content
        }
      })

      const assistantMessage = new AssistantMessage(message)
      const userMessage = new AssistantMessage({
        index: assistantMessage.index - 1,
        source: 'user',
        content: content
      })
      const messages = _cloneDeep(this.messages)

      messages.push(userMessage, assistantMessage)
      this.messages = messages

      return assistantMessage
    }
  }

  async remove () {
    const config = await Config()
    return this.request({
      method: 'DELETE',
      baseURL: config.BASE_URL,
      url: `${this.assistant?.url}/v1/chats/${this.id}`
    })
  }

  /**
  * Message feedback
  *
  * @param {Number} message_index - Message index to evaluate
  * @param {String} evaluation - Either `positive` or `negative`
  */
  async messageFeedback (message_index, evaluation) {
    const config = await Config()
    return await this.request({
      method: 'POST',
      baseURL: config.BASE_URL,
      url: `${this.assistant?.url}/v1/chats/${this.id}/eval_message`,
      params: {
        message_index,
        evaluation
      }
    })
  }
}

class AssistantApplication extends APIObject {
  constructor (options) {
    // Init
    super('GAB', options)

    // Default variables
    this.ai_assistant_id = this.ai_assistant_id || null
    this.name = this.name || ''
    this.display_name = this.display_name || ''
    this.description = this.description || ''
    this.tags = this.tags || {}
    this.status = this.status || null
    this.type = this.type || null
    this.ready = this.ready || null
  }

  _filter (object) {
    return _omit(super._filter(object), [
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
      'assistant',
      'id',
      'name',
      'status',
      'ready'
    ])
  }

  async create (data) {
    return super.create({
      method: 'POST',
      url: `v1/applications/${this.id}`,
      data: {
        ...data,
        display_name: this.display_name || this.assistant?.display_name
      }
    })
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v1/assistants/${this.ai_assistant_id}/applications/${this.id}`
    })
  }

  async save () {
    return await super.save({
      method: 'PUT',
      url: `v1/assistants/${this.ai_assistant_id}/applications/${this.id}`
    }, true)
  }

  get applicationName () {
    // TODO: handle other applications names when available (e.g. Slack, WhatsApp, etc.)
    return this.tags?.appservice_app_name || ''
  }

  async applicationUrl () {
    const config = await Config()
    return `${config.BASE_URL}/${this.tags?.appservice_app_name}/`
  }

  updateAppServiceStatus (status) {
    this.assign({
      ...this,
      ready: status
    })
    return this
  }
}

class Assistant extends APIObject {
  constructor (options) {
    // Init
    super('GAB', options)

    // Default variables
    this.name = this.name || ''
    this.display_name = this.display_name || ''
    this.description = this.description || ''
    this.tags = this.tags || {}
    this.tags.name = this.tags.path || ''
    this.type = this.type || ''
    this.model = this.model || ''
    this.status = this.status || null
    this.knowledge_base = this.knowledge_base || null
    this.deployed_at = this.deployed_at || null
    this.url = this.url || null
    this.chats = this.chats || []
    this.assistant_parameters = this.assistant_parameters || {
      starting_prompt: '',
      prompt_template: [],
      restrictions: {
        subjects: [],
        vocabulary: []
      },
      parameters: {}
    }
    this.assistant_preset_answers = this.assistant_preset_answers || []
    this.applications = this.applications || []
  }

  _filter (object) {
    return _omit(super._filter(object), [
      'created_at',
      'updated_at',
      'created_by',
      'updated_by',
      'id',
      'name',
      'chats',
      '_new_kb'
    ])
  }

  async create (data) {
    return super.create({
      method: 'POST',
      url: 'v1/assistants',
      data
    })
  }

  async save () {
    return await super.save({
      method: 'PUT',
      url: `v1/assistants/${this.id}`
    }, true) // `true` flag means returns and assign response to this object
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v1/assistants/${this.id}`
    })
  }

  async deploy () {
    const assistant = await this.request({
      method: 'POST',
      url: `v1/assistants/${this.id}/deploy`
    })

    this.assign(assistant)
    return this
  }

  async undeploy () {
    const assistant = await this.request({
      method: 'DELETE',
      url: `v1/assistants/${this.id}/deploy`
    })

    this.assign(assistant)
    return this
  }

  // Assistant API

  /**
  * Assistant API health
  */
  async health () {
    const config = await Config()
    return await this.request({
      method: 'GET',
      baseURL: config.BASE_URL,
      url: `${this.url}/v1/health`
    })
  }

  /**
  * Assistant API chats list
  */
  async chatsList () {
    if (!this.url) return []
    const config = await Config()
    const chats = await this.request({
      method: 'GET',
      baseURL: config.BASE_URL,
      url: `${this.url}/v1/chats`
    })
    this.chats = chats.map(chat => new AssistantChat({ ...chat, assistant: this }))
    return this.chats
  }

  /**
  * Create a new chat model
  */
  async newChat (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    const chat = new AssistantChat({ ...item, assistant: this })
    await chat.create()
    return chat
  }

  /**
  * Assistant API applications list
  */
  async listApplications () {
    const data = await this.request({
      method: 'GET',
      url: `v1/assistants/${this.id}/applications`
    })

    const applications = data.map(application => new AssistantApplication(_omit(application, [])))
    this.applications = applications

    return this.applications
  }

  async createApplication (model) {
    const data = await this.request({
      method: 'POST',
      url: `v1/assistants/${this.id}/applications`,
      data: model
    })

    return data
  }

  // Getters

  get isDeployed () {
    return this.status === 'DEPLOYED'
  }

  get isPublished () {
    return this.applications.length
  }

  get canDeploy () {
    return this.status === null || this.status === 'DEPLOYED' || this.status === 'UNDEPLOYED'
  }

  get canDeployChanges () {
    return this.isDeployed &&
      this.deployed_at !== null &&
      this.updated_at !== null &&
      moment(this.updated_at).diff(moment(this.deployed_at)) > 20
  }

  get canUndeploy () {
    return this.status === 'DEPLOYED'
  }
}

class Assistants extends API {
  async list () {
    const data = await this.request({
      method: 'GET',
      url: 'v1/assistants'
    })
    return data.map(assistant => new Assistant(_omit(assistant, [])))
  }

  async findOne (id) {
    const assistant = await this.request({
      method: 'GET',
      url: `v1/assistants/${id}`
    })

    if (assistant) return new Assistant(_omit(assistant, []))
    return null
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Assistant(item)
  }
}

export default Assistants
export {
  Assistant,
  Assistants,
  AssistantChat,
  AssistantMessage,
  AssistantApplication
}
