import moment from 'moment'

export const lastNDays = (n = 7) => {
  const daysAgo = []
  for (let i = 0; i <= n - 1; i++) {
    daysAgo.push(moment().subtract(i, 'days').format('YYYY-MM-DD'))
  }
  return daysAgo.reverse()
}

export const lastTwentyFourHours = () => {
  const hoursAgo = []
  for (let i = 0; i <= 23; i++) {
    hoursAgo.push(moment().subtract(i, 'hours').format('ha'))
  }
  return hoursAgo.reverse()
}

export const lastNType = (options = { type: 'days', max: 7, step: 1, format: 'YYYY-MM-DD', from: undefined, end: undefined }) => {
  const value = []

  if (options.from && options.end) {
    let i = 0
    let date = moment(options.end)
    while (date > moment(options.from)) {
      value.push(moment(options.end).subtract(i, options.type).format(options.format))

      i += options.step
      date = moment(options.end).subtract(i, options.type)
    }
  } else {
    for (let i = 0; i <= options.max - 1; i += options.step) {
      value.push(moment().subtract(i, options.type).format(options.format))
    }
  }

  return value.reverse()
}

export default {
  lastNDays,
  lastTwentyFourHours,
  lastNType
}
