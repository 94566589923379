import Vue from 'vue'

const Price = function (price, currencyCode = 'USD', round = 0) {
  if (!price && price !== 0) return '-'
  const symb = {
    USD: '$',
    EUR: '€'
  }
  return `${(price / 100).toFixed(round)} ${symb[currencyCode.toUpperCase()]}`
}

export default Price
Vue.filter('Price', Price)
