import { API } from '@/shared/plugins/Api/API'

class Addon {
  constructor (options) {
    Object.assign(this, options)
  }
}

class Coupon {
  constructor (options) {
    Object.assign(this, options)
  }
}

class Plan {
  constructor (options) {
    Object.assign(this, options)
  }
}

class Prices extends API {
  constructor (ID) {
    super(ID, true)
  }

  async list () {
    const prices = await this.request({
      method: 'GET',
      url: 'prices'
    })
    return {
      addons: prices.addons.map(a => new Addon(a)),
      coupons: prices.coupons.map(c => new Coupon(c)),
      plans: prices.plans.map(p => new Plan(p))
    }
  }
}

export default Prices
