<template lang="pug">
.chart(
  @mouseleave="hideTooltip"
)
  echarts.echarts(
    ref="chart"
    :options="valueFinal"
    autoresize
    @click="handleDrilldown"
  )
</template>

<script>
export default {
  props: {
    value: { type: Object, default: () => {} },
    activeDrilldown: { type: Boolean, default: false }
  },
  data () {
    return {
      valueWithTooltipUpdated: null,
      clickOnChart: false
    }
  },
  computed: {
    tooltipDisplayed () {
      return this.$store.getters.DASHBOARD_TOOLTIP_DRILLDOWN_DISPLAYED
    },
    tooltipId () {
      return this.value.tooltipId
    },
    valueFinal () {
      return Object.assign({}, this.value, { tooltip: this.valueWithTooltipUpdated?.tooltip || this.value?.tooltip })
    }
  },
  watch: {
    '$store.getters.DASHBOARD_TOOLTIP_DRILLDOWN_DISPLAYED': {
      handler (val) {
        if (val) {
          this.$nextTick(() => {
            this.valueWithTooltipUpdated = {
              ...this.valueWithTooltipUpdated,
              tooltip: {
                ...this.valueWithTooltipUpdated?.tooltip,
                triggerOn: 'none'
              }
            }
          })
        }
        if (!val) {
          this.hideTooltip()
          this.$nextTick(() => {
            this.valueWithTooltipUpdated = {
              ...this.valueWithTooltipUpdated,
              tooltip: {
                ...this.valueWithTooltipUpdated?.tooltip,
                triggerOn: 'mousemove|click'
              }
            }
          })
        }
      }
    }
  },
  mounted () {
    this.valueWithTooltipUpdated = this.value
    if (this.activeDrilldown) document.addEventListener('click', this.close)
  },
  destroyed () {
    document.removeEventListener('click', this.close)
  },
  methods: {
    close (e) {
      const tooltipEchartElem = document.getElementById(`tooltip-echarts-${this.tooltipId}`)
      const tooltipEchart = tooltipEchartElem && tooltipEchartElem.contains(e.target)

      const tooltipDrilldownElem = document.getElementById(`tooltip-drilldown-${this.tooltipId}`)
      const tooltipDrilldown = tooltipDrilldownElem && tooltipDrilldownElem.contains(e.target)

      if (tooltipEchart || tooltipDrilldown || this.$store.getters.DASHBOARD_TOOLTIP_DRILLDOWN_CLICK_ON_CHART) {
        setTimeout(() => { // Cannot find another way to do it
          this.$store.commit('FOREPAAS_QB_TOOLTIP_DRILLDOWN_CLICK_ON_CHART', null)
        }, 50)
        return
      }

      this.$store.commit('FOREPAAS_QB_TOOLTIP_DRILLDOWN_DISPLAYED', null)
      this.$emit('deactive-tile')
    },
    handleDrilldown (e) {
      if (!this.activeDrilldown) return

      this.$emit('click-chart', e)
      if (this.tooltipDisplayed) {
        this.$store.commit('FOREPAAS_QB_TOOLTIP_DRILLDOWN_DISPLAYED', null)
      } else {
        this.$store.commit('FOREPAAS_QB_TOOLTIP_DRILLDOWN_CLICK_ON_CHART', this.tooltipId)
        this.$store.commit('FOREPAAS_QB_TOOLTIP_DRILLDOWN_DISPLAYED', { elem: this.$refs.chart, id: this.tooltipId })
      }
    },
    hideTooltip () {
      if (this.$refs.chart && !this.tooltipDisplayed) {
        this.$refs.chart.chart._api.dispatchAction({
          type: 'hideTip'
        })
      }
    }
  }
}
</script>

<style lang="less">
.chart {
  position: relative;
  height: 100%;
  width: 100%;

  .echarts {
    height: 100%;
    width: 100%;
  }
}
</style>
