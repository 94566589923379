import DatePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

import Vue from 'vue'

import FpuiDatePicker from './FpuiDatePicker'

export { DatePicker }
export default { DatePicker }
Vue.component('FpuiDatePicker', FpuiDatePicker)
Vue.component('DatePicker', DatePicker)
