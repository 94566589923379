/* eslint-disable camelcase */
const stream = require('readable-stream')
const { PassThrough } = stream
const JSONQuery = require('./JSONQuery')

class DashboardJSONQuery extends JSONQuery {
  constructor (options = {}) {
    super(options)

    this.dashboard_id = options.dashboard_id
    this.filters = options.filters || {}
    this.url = options.url
  }

  async run (context, options = {}) {
    this._data = new PassThrough({ objectMode: true })
    await this.execute(context)

    if (!options.stream) {
      await this.toSync()
    }
    return this.display()
  }

  async execute (context) {
    const options = {
      url: `query-admin/v3/dashboards/${this.dashboard_id}/deployed/${this.query_id}/query`,
      method: 'POST',
      data: this.filters,
      source: this.source,
      query_id: this.query_id,
      dashboard_id: this.dashboard_id
    }

    if (this.url) {
      options.url = this.url
      options.baseURL = null
    }

    let response = await context.requestAPI(options)

    try {
      response = await this.parse(context, response, true)
      this.parse(context, response)
        .then(() => {
          this._data.end()
        })
        .catch(err => {
          this._data.emit('error', err)
        })
    } catch (err) {
      this._data.emit('error', err)
      this._data.end()
    }
  }
}
module.exports = DashboardJSONQuery
