/*
Exemple format : {
  fields: {
    ca: sum,
    qte: [sum,avg]
  },
  skip: 10
  limit: 10
}

Exemple format short : {
  ca: sum,
  qte: [sum,avg]
}
*/
const _uniq = require('lodash/uniq')

class Data {
  constructor (data = {}) {
    this._ = {}
    if (!data.fields || typeof (data.fields) !== 'object') {
      this.fields = data
    } else {
      this.fields = data.fields
      this.limit = data.limit
      this.skip = data.skip
    }
  }

  get fields () {
    return this._.fields
  }

  set fields (fields) {
    this._.fields = {}
    const fieldsOrder = {}
    for (const key in fields) {
      this._.fields[key] = Array.isArray(fields[key]) ? fields[key] : [fields[key]]
      if (this._.fields[key].includes('select_distinct')) {
        fieldsOrder[key] = null
        this._.fields[key] = this._.fields[key].filter(compute => compute !== 'select_distinct')
        this._.fields[key].unshift('select_distinct')
      }
    }

    this._.fields = Object.assign(fieldsOrder, this._.fields)
  }

  toJSON () {
    return {
      fields: this._.fields,
      limit: this.limit || null,
      skip: this.skip || null
    }
  }

  toSQLStart (scales) {
    if (!Object.keys(this._.fields).length) return 'SELECT *'
    let isSelectDistinct = false
    const start = `SELECT\n  ${
      _uniq(
        Object.keys(this._.fields).map(field => {
          return this._.fields[field].map(operator => {
            if (operator === 'select') {
              return `${field} as select_${field}`
            }
            if (operator === 'select_distinct') {
              if (isSelectDistinct) return `${field} as select_distinct_${field}`
              isSelectDistinct = true
              return `DISTINCT ${field} as select_distinct_${field}`
            }
            if (operator === 'sum') {
              return `SUM(${field}) as sum_${field}`
            }
            if (operator === 'avg') {
              return `AVG(${field}) as avg_${field}`
            }
            if (operator === 'min') {
              return `MIN(${field}) as min_${field}`
            }
            if (operator === 'max') {
              return `MAX(${field}) as max_${field}`
            }
            if (operator === 'count') {
              return `COUNT(${field}) as count_${field}`
            }
            if (operator === 'count_distinct') {
              return `COUNT(DISTINCT ${field}) as count_distinct_${field}`
            }
            throw new Error(`${operator} is not a valid operator`)
          }).join(',\n  ')
        }).concat(scales.fields.map(scale => {
          return `${scale} as v_${scale}`
        }))
      )
      .join(',\n  ')
    }`

    return start
  }

  toSQLEnd () {
    return [
      this.limit ? `LIMIT ${this.limit}` : null,
      this.skip ? `OFFSET ${this.skip}` : null
    ].filter(e => e).join(' ')
  }
}

module.exports = Data
