<template lang="pug">
.store
  .head
    h3.back(@click="$emit('back')")
      i.fa.fa-chevron-left
      span {{ 'store.back' | translate }}
    i.fp4.fp4-xmark.close(@click="$emit('close')")
  .details
    .left
      fpui-canvas-icon(
        :name="pckgName"
        backgroundColor="white"
        :logo="value.image"
        fontSize="68px"
       :width=158
       :height=158
      )
      .version
        .title {{ 'store.version' | translate }}
        .content {{lastVersion}}
      .author
        .title {{ 'store.author' | translate }}
        .content
          img(:src="fpIcon")
          span {{ owner }}
    .right
      .big-title {{pckgName}}
      .categories(v-if="pckgCategories.length")
        .category(v-for="category in pckgCategories") {{category}}
      .description
        .title {{ 'store.description' | translate }}
        .content
          span(v-html='$sanitize(pckgLongDescription||pckgDescription)')
  .foot
    img(:src="icForepaasStore")
    fpui-button(
      :disabled="value.disable"
      @click="confirm"
      v-if="onSelect"
      color="green"
    ).pull-right {{ 'store.select' | translate }}
</template>

<script>
import _get from 'lodash/get'
import gt from 'semver/functions/gt'
import coerce from 'semver/functions/coerce'

export default {
  props: {
    value: { type: Object, default: () => { } },
    onSelect: { type: Function, default: null }
  },
  computed: {
    pckgType () {
      return this.value?.type.split('.')[0]
    },
    locale () {
      return this.$i18n.locale()
    },
    icForepaasStore () { return require('./ovh_cloud_store@2x.png') },
    fpIcon () { return require('@/shared/assets/img/ovhcloud_logo_simplified_blue.svg') },
    pckgLongDescription () {
      const longDescriptionKey = `${this.pckgType}.${this.value?.id}.long_description`
      return this.$i18n.keyExists(longDescriptionKey) ? this.$t(longDescriptionKey) : (this.value?.type === '' ? (this.value?.long_description || this.pckgDescription) : this.pckgDescription)
    },
    pckgDescription () {
      const descriptionKey = `${this.pckgType}.${this.value?.id}.description`
      return this.$i18n.keyExists(descriptionKey) ? this.$t(descriptionKey) : (this.value?.type === '' ? (this.value?.description || this.$t('store.no_description')) : this.$t('store.no_description'))
    },
    pckgName () {
      const nameKey = `${this.pckgType}.${this.value?.id}.name`
      return this.$i18n.keyExists(nameKey) ? this.$t(nameKey) : this.value?.name
    },
    pckgCategories () {
      const categoryKey = `${this.pckgType}.${this.value?.id}.category`
      const category = this.$i18n.keyExists(categoryKey) ? this.$t(categoryKey) : this.value?.category
      if (!this.value.category) return []
      if (!Array.isArray(category)) return [category]
      return category
    },
    lastVersion () {
      const versions = _get(this.value, 'versions', [])
      return versions.sort((a, b) => {
        // coerce is used to format version to be use with gt from semver
        const v1 = coerce(a.split('/').pop())
        const v2 = coerce(b.split('/').pop())
        if (gt(v1, v2)) return -1
        if (gt(v2, v1)) return 1
        return 0
      })[0]
    },
    owner () {
      const organization = this.$store.getters.ORGANIZATION_BY_ID(this.value.owner)
      if (organization && this.value.owner !== 'forepaas') {
        return organization.name
      }
      return 'Data Platform'
    }
  },
  methods: {
    confirm () {
      return this.onSelect(this.value, this)
    }
  }
}
</script>
