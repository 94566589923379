<template lang="pug">
  .multi-type-selector
    .current-value(
      :class="{ disabled: readOnly }"
      @click.stop="open"
    )
      span {{ displayValue }}
      i.fp4.fp4-angle-down
    .values(v-if="state")
      fpui-input-checkbox(
        v-if="typesWithLabel.length"
        v-for="type in typesWithLabel"
        :key="type.label"
        :value="value.includes(type.value)"
        @input="() => select(type.value)"
      ) {{ type.label }}
      fpui-input-checkbox(
        v-else
        v-for="type in types"
        :key="type"
        :value="value.includes(type)"
        @input="() => select(type)"
      ) {{ type }}

</template>

<script>
export default {
  props: {
    value: { type: Array, default: () => [] },
    types: { type: Array, default: () => [] },
    typesWithLabel: { type: Array, default: () => [] },
    readOnly: { type: Boolean, default: false }
  },
  data () {
    return {
      state: false
    }
  },
  computed: {
    displayValue () {
      if (this.value.length > 3) {
        const smallValue = this.value.slice(0, 3)
        return `${smallValue.join(' | ')} | ${this.value.length - 3}+`
      }
      return this.value.join(' | ')
    }
  },
  mounted () {
    window.addEventListener('click', this.close)
  },
  destroyed () {
    window.removeEventListener('click', this.close)
  },
  methods: {
    isParent (target) {
      if (target === this.$el) return true
      if (target.parentNode) return this.isParent(target.parentNode)
      return false
    },
    close ($event) {
      if ($event && this.isParent($event.target)) return
      this.state = false
    },
    async open () {
      if (this.readOnly) return
      this.state = !this.state
      // Close selectbox of parent if open
      if (this.state) this.$emit('close')
      await this.$nextTick()
    },
    select (type) {
      const newValue = [...this.value]
      const index = newValue.indexOf(type)
      if (index === -1) newValue.push(type)
      else newValue.splice(index, 1)
      this.$emit('input', newValue)
    }
  }
}
</script>

<style lang="less">
  .multi-type-selector {
    margin-left: 10px;
    float     : right;
    position  : relative;

    .values {
      width        : 200px;
      position     : absolute;
      background   : white;
      border-radius: 9px;
      box-shadow   : 0 2px 10px 0 rgba(62, 69, 80, 0.25);
      padding      : 15px 20px;
      z-index      : 2;
      margin       : 0;
      display: flex;
      flex-direction: column;

      .fpui-input-checkbox {
        margin-top: 10px;
        cursor: pointer;

        &:first-of-type {
          margin-top: 0;
        }
      }
    }

    .current-value {
      background-color: rgba(0, 137, 192, 0.08);
      border-radius   : 9px;
      height          : 19px;
      color           : #0089C0;
      font-size       : 14px;
      cursor          : pointer;
      line-height     : 14px;
      padding         : 0 7px;
      display: flex;
      justify-content: space-between;
      min-width: 55px;
      span,
      i {
        display       : inline-block;
        vertical-align: bottom;
      }
      span {
        line-height: 19px;
      }
      i {
        margin-top: 2px;
        margin-left: 5px;
      }
      &.disabled {
        cursor: not-allowed;
      }
    }
  }
</style>
