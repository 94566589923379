import Config from '@/shared/Config'
import socket from '@/shared/store/socket'
import fpapi from '@/shared/store/fpapi'
import king from '@/shared/store/king'
import session from '@/shared/store/session'
import Vue from 'vue'
import Vuex from 'vuex'
import storage from './storage'

Vue.use(Vuex)
Config().catch(err => {
  Store.commit('SET_GLOBAL_ERROR', err)
})

const Store = new Vuex.Store({
  modules: { socket, king, fpapi, storage, session }
})

export default Store
