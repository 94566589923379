<template lang='pug'>
  .fpui-input-environment-variables
    .table
      .thead
        .tr
          .tr-content
            .th.key {{ 'fpui-input-environment-variables.key' | translate }}
            .th.type {{ 'fpui-input-environment-variables.type' | translate }}
            .th.value {{ 'fpui-input-environment-variables.value' | translate }}
      .tbody
        fpui-input-environment-variables-row(
          v-for="(row,index) in rows",
          :disabled="disabled"
          :key="index",
          :value="row",
          :auto-open="row.autoOpen"
          @remove="(event)=>removeRow(index)",
          @input="(event)=>updateRow(index, event)"
        )
        .placeholder(v-if="!rows.length") {{ 'fpui-input-environment-variables.placeholder' | translate }}
    .table-actions
      fpui-button.add(
        auto-width
        :disabled="disabled"
        color="white"
        icon="fp4 fp4-plus"
        icon-left
        @click="addVariable"
      ) {{ 'fpui-input-environment-variables.add' | translate }}
</template>

<script>
import FpuiInputEnvironmentVariablesRow from './FpuiInputEnvironmentVariablesRow'
export default {
  components: {
    FpuiInputEnvironmentVariablesRow
  },
  props: {
    value: { type: Object, default: () => {} },
    disabled: Boolean
  },
  data () {
    return {
      rows: [],
      editing: false
    }
  },
  mounted () {
    if (!this.value) this.$emit('input', {})
    for (const key in this.value) {
      this.rows.push({
        key: key,
        value: this.value[key],
        type: typeof (this.value[key])
      })
    }
  },
  methods: {
    updateParent () {
      const value = {}
      this.rows.forEach(row => {
        value[row.key] = row.value
      })
      this.$emit('input', value)
    },
    isUsedKey (key) {
      return this.rows.map(r => r.key).indexOf(key) !== -1
    },
    findAvailableKey () {
      const key = this.$t('fpui-input-environment-variables.key.new')
      if (!this.isUsedKey(key)) return key
      let index = 2
      while (this.isUsedKey(key + ' ' + index)) {
        index++
        if (index > 10) return
      }
      return key + ' ' + index
    },
    addVariable () {
      this.rows.push({
        key: this.findAvailableKey(),
        type: 'string',
        value: '',
        autoOpen: true
      })
      this.updateParent()
    },
    updateRow (index, event) {
      for (const i in this.rows) {
        if (index === parseInt(i)) continue
        if (event.value.key === this.rows[i].key) return event.callback(false)
      }
      this.rows[index] = { ...event.value }
      this.updateParent()
      event.callback(true)
    },
    removeRow (index, event) {
      this.rows.splice(index, 1)
      this.updateParent()
    }
  }
}
</script>

<style lang='less'>
@import "~@/shared/styles/_variables.less";
.fpui-input-environment-variables {
  margin-right: -20px;
  margin-left: -20px;
  .table-actions {
    display: flex;
    justify-content: space-between;
    padding: 5px 19px 0 19px;
  }
  .table {
    width:100%;
    display: block;
    .thead {
      display: block;
      .tr {
        background:#F6F9FC;
        height:31px;
        line-height: 31px;
        .th {
          color: #3E4550;
          font-size: 13px;
          font-weight: 600;
        }
      }
    }
    .tbody {
      display: block;
      .tr {
        height:45px;
        line-height:45px;
        white-space: nowrap;
        text-overflow: ellipsis;
        &:nth-child(even) {
          background:rgba(246,249,252,0.5);
        }
        .td {
          color: #3E4550;
          font-size: 14px;
        }
      }
    }
    .tr {
      position: relative;
      .tr-content {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .td,.th {
      display: block;
      padding: 0 20px;
      text-align:left;
      &.edit-actions {
        display: flex;
        > .done {
          margin-left: 5px;
        }
      }
    }
    .key {
      width:35%;
    }
    .type {
      width:30%;
    }
    .value {
      width:35%;
    }
    .actions {
      position: absolute;
      right: 30px;
      top: 0;
      font-size: 20px;
      color: #CBD3DB;
      display: none;
      i {
        text-align: center;
        line-height: 35px;
        height:45px;
        width:35px;
        cursor:pointer;
        vertical-align: middle;
        display: inline-block;
        &:hover {
          &.fp4-pencil {
            color: @blue;
          }
          &.fp4-trash-can {
            color: @red;
          }
        }
      }
    }
    .tr:hover {
      .actions {
        display: block;
      }
    }
    input {
      height:35px;
      padding:0 11px;
      line-height: 35px;
      // width:calc(~"100% + 24px");
      border: 1px solid rgba(151,167,183,0.21);
      border-radius: 3px;
      background-color: rgba(151,167,183,0.06);
    }
    // select {
    //   margin:5px -12px;
    //   padding:0 11px;
    //   -webkit-appearance: none;
    //   -moz-appearance: none;
    //   appearance: none;
    //   border-radius:3px;
    //   border: 1px solid rgba(151,167,183,0.21);
    //   background-color: rgba(151,167,183,0.06);
    //   width:calc(~"100% + 24px");
    //   height:35px;
    //   line-height: 35px;
    //   outline:none;
    //   cursor: pointer;
    // }
    .placeholder {
      text-align:center;
      line-height: 45px;
      font-size: 14px;
      color:#3E4550;
    }
    .fpui-input-toggle {
      width:37px;
      margin:11px auto;
    }
  }
}
</style>
