<template lang="pug">
.axis
  .fp-box
    .box-title {{ $t('query.settings.advanced.title') }}
    //- Table config
    .box-line
      fpui-input-toggle(
        :label="$t('query.settings.general.options.json')"
        :value="options.json"
        @input="val => $emit('update', 'json', val)"
      )
</template>

<script>
export default {
  props: {
    type: { type: String, default: '' },
    options: { type: Object, default: () => ({}) }
  }
}
</script>

<style lang="less">
.axis {
  margin-top: 10px;
  .fp-box {
    padding-bottom: 3px;
    .box-line {
      margin-bottom: 10px;

      &.inline {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .fpui-input-number:first-of-type {
        margin-right: 20px;
      }
    }
  }
}
</style>
