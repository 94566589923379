const Query = require('./Query')

/**
 * SQLQuery
 *
 * Standard class to be able to create a simple SQL Query
 */
class SQLQuery extends Query {
  /**
   * constructor
   * @param String sql  A standard SQL Query (ex : SELECT * FROM my_table)
   */
  constructor (sql, options = {}) {
    super()
    this.sql = sql
    this.limit = options.limit
    this.query_id = options.query_id
    this.dashboard_id = options.dashboard_id
    this.source = options.source
  }

  /**
   * Internal : get sql
   * A method describing to the core how to access to SQL parameter
   */
  get sql () {
    return this._sql
  }

  /**
   * Internal : set sql
   * A method describing to the core how to store the SQL parameter
   */
  set sql (sql) {
    this._sql = sql
  }

  /**
   * toJSON
   * A method describing to the core how to print the request as JSON
   */
  toJSON () {
    return this.sql
  }

  /**
   * toString
   * A method describing to the core how to print the request as String
   */
  toString () {
    return this.formatedSql
  }

  /**
   * display
   * A method describing to the core how to print the response as JSON
   */
  display () {
    let tables = []
    // DM-2452: Temporarily avoid raising error when user specifies CATALOG.SCHEMA.TABLE in his query
    try {
      tables = this.ast.from.map(from => from.table)
    } catch (error) {
      console.error(error.stack)
    }
    return {
      sql: this.toString(),
      tables,
      results: {
        columns: this.columns.map(c => c.name),
        results: this._data
      },
      query_params: {
        sql: this.toString()
      },
      table_name: tables[0],
      columns: this.columns
    }
  }

  /**
   * formatRow
   * A method describing to the core how to print the each row of the response as JSON
   */
  formatRow (data) {
    return data
  }
}

module.exports = SQLQuery
