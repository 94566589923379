import { API } from '@/shared/plugins/Api/API'

const metasCache = {}

class Store extends API {
  constructor (ID) {
    super(ID, true, true)
  }

  // DO NOT USE IT ANYMORE
  async list (consumer) {
    return await this.request({
      url: `/v1/packages/${consumer}`
    })
  }

  async get (consumer) {
    return await this.request({
      url: `/v1/packages/${consumer}`
    })
  }

  async metas (consumer, type, pckg, version = 'latest') {
    const cacheKey = `${consumer}_${type}_${pckg}_${version}`
    if (metasCache[cacheKey]) return metasCache[cacheKey]

    const response = await this.request({
      url: `/v1/packages/${consumer}/${type}/${pckg}/${encodeURIComponent(version)}/metas`
    })
    metasCache[cacheKey] = response

    return response
  }

  createPackage (consumer, type, id, pkg, camToken) {
    return this.request({
      method: 'PUT',
      url: `/v1/packages/${consumer}/${type}/${id}`,
      data: pkg
    })
  }

  fillPackage (type, id, services, dataplantUrl, dwhName, camToken, version = '1.0.0') {
    return this.request({
      method: 'PUT',
      url: `/v1/packages/dataplant/${type}/${id}/${version}`,
      params: {
        dataplant_url: dataplantUrl
      },
      data: {
        proxyActions: services,
        dwhName: dwhName,
        camToken: camToken
      }
    })
  }
}

export default Store
