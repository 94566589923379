import _sortBy from 'lodash/sortBy'
import moment from 'moment-timezone'

const names = moment.tz.names()

const zoneByOffset = {}

for (const name of names) {
  zoneByOffset[moment().tz(name).utcOffset()] = zoneByOffset[moment().tz(name).utcOffset()] || []
  zoneByOffset[moment().tz(name).utcOffset()].push(name)
}


const toHours = (minutes) => {
  const min = (Math.abs(minutes % 60) + '').padStart(2, '0')
  const hour = (Math.abs(Math.ceil(minutes / 60)) + '').padStart(2, '0')
  return `${hour}:${min}`
}

const ignoreOffset = []
const preferedNames = [
  'US/Samoa',
  'US/Hawaii',
  'Pacific/Honolulu',
  'US/Aleutian',
  'US/Alaska',
  'US/Mountain',
  'America/Chicago',
  'America/New_York',
  'America/Sao_Paulo',
  'America/St_John',
  'America/Miquelon',
  'Atlantic/Cape_Verde',
  'Greenwich',
  'Europe/London',
  'Europe/Paris',
  'Europe/Moscow',
  'Asia/Dubai',
  'Asia/Tehran',
  'Asia/Karachi',
  'Asia/Calcutta',
  'Asia/Katmandu',
  'Asia/Thimbu',
  'Asia/Yangon',
  'Asia/Bangkok',
  'Hongkong',
  'Japan',
  'Australia/Adelaide',
  'Australia/Melbourne',
  'Australia/Lord_Howe',
  'Pacific/Noumea',
  'Pacific/Fiji',
  'Pacific/Chatham',
  'Pacific/Apia',
  'Pacific/Kiritimati'
]

const bestZoneLabel = (labels) => {
  for (const label of labels) {
    if (preferedNames.includes(label)) return label
  }
  return labels[0]
}

let options = []
for (const offset in zoneByOffset) {
  const os = parseInt(offset)
  if (ignoreOffset.includes(os)) continue
  const labelInfo = `UTC${os >= 0 ? '+' : '-'}${toHours(os)}`
  options.push({
    value: -parseInt(os),
    labelInfo,
    label: `${labelInfo} (${bestZoneLabel(zoneByOffset[offset])})`,
    all: zoneByOffset[offset]
  })
}
options = _sortBy(options, 'value').reverse()

export const utcOptions = options

export const getUtcUser = () => {
  const offset = -moment().utcOffset()
  const utc = utcOptions.find(utc => utc.value === offset)

  return utc?.value || 0
}

export const shortcutsDefault = [
  {
    key: 'today',
    value: { start: () => moment().startOf('day'), end: () => moment().endOf('day') }
  },
  {
    key: 'yesterday',
    value: { start: () => moment().subtract(1, 'days').startOf('day'), end: () => moment().subtract(1, 'days').endOf('day') }
  },
  {
    key: 'last1Hour',
    value: { start: () => moment().subtract(1, 'hours'), end: () => moment() }
  },
  {
    key: 'last6Hours',
    value: { start: () => moment().subtract(6, 'hours'), end: () => moment() }
  },
  {
    key: 'last24Hours',
    value: { start: () => moment().subtract(24, 'hours'), end: () => moment() }
  },
  {
    key: 'last7Days',
    value: { start: () => moment().subtract(7, 'days'), end: () => moment() }
  },
  {
    key: 'last30Days',
    value: { start: () => moment().subtract(30, 'days'), end: () => moment() }
  },
  {
    key: 'custom',
    value: { start: () => moment().startOf('day'), end: () => moment().endOf('day') },
    isCustom: true // isCustom is used to not update date if click on value and to not reset value when open datepicker
  }
]

export default {
  utcOptions,
  getUtcUser,
  shortcutsDefault
}
