<template lang='pug'>
  .fpui-input-qty
    i.fp4.fp4-minus-circle-light(
      @click="less"
    )
    fpui-input-number.input(
      :value="value"
      @input="update"
    )
    i.fp4.fp4-circle-plus-light(
      @click="more"
    )

</template>

<script>
// import _cloneDeep from 'lodash/cloneDeep'

export default {
  props: {
    value: {
      type: Number,
      required: false,
      default () {
        return 0
      }
    }
  },
  computed: {
    total () { return this.value.instances * this.value.size }
  },
  methods: {
    update (value) {
      if (isNaN(value)) value = this.value
      value = parseInt(value)
      if (value < 0) value = 0
      this.$emit('input', value)
    },
    less () {
      this.update(this.value - 1)
    },
    more () {
      this.update(this.value + 1)
    }
  }
}
</script>

<style lang='less'>
.fpui-input-qty {
  display: flex;
  > i {
    cursor: pointer;
    font-size: 28px;
    color: #00CCF9;
  }
  > .input {
    flex: 1;
  }
}
</style>
