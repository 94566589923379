<template lang='pug'>
  .fpui-input-json
    fpui-input-label(v-if="label",:helper="helper")
      span {{ label | translate }}
      span.asterisk(v-if="required") {{ '*' }}
    fpui-json-editor(:value="value",@input="v=>$emit('input',v)")
</template>

<script>

import FpuiJsonEditor from '@/shared/components/editors/FpuiJsonEditor'

export default {
  components: {
    FpuiJsonEditor
  },
  props: {
    required: { type: Boolean },
    helper: { type: String, default: null },
    value: { type: [String, Number, Array, Object], default: () => {} },
    label: { type: String, default: '' }
  }
}
</script>
