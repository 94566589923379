<template lang="pug">
  .cell-tags.td.fp-tree-cell(
    v-tooltip="tagsTooltip"
    @click="select"
  )
    .tag-container(v-for="tag in tags")
      .tag {{ tag }}
    .empty(v-if="!tags.length") -
</template>

<script>

export default {
  props: {
    value: { type: Object, default: () => {} }
  },
  computed: {
    tags () {
      const tags = this.value?.object?.tags?.tags
      if (Array.isArray(tags)) {
        if (tags?.length <= 3) return tags
        else return tags.filter((v, i) => i < 2).concat(`+${tags.length - 2}`)
      }
      return []
    },
    tagsTooltip () {
      const tags = this.value?.object?.tags?.tags
      if (Array.isArray(tags)) {
        return tags.join(' / ')
      }
      return ''
    }
  },
  methods: {
    select ($event) {
      this.$emit('select', $event)
    }
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";
  .cell-tags {
    display: flex !important;
    align-items: center;
    overflow: hidden;
    .tag-container {
      max-width: 100%;
      .tag {
        background-color: #e4e7ec;
        color: rgba(62, 69, 80, 0.65);
        font-family: "Source Sans Pro";
        font-size: 11px;
        line-height: 14px;
        height: 17px;
        margin: 5px 6px;
        padding: 1px 8px 0 7px;
        border-radius: 8.5px;
        box-shadow: inset 0 -1px 0 0 rgba(62,69,80,0.1), inset 0 1px 0 0 rgba(255,255,255,0.3);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .tag-container:first-child .tag {
      margin: 0;
    }
  }
</style>
