<template lang='pug'>
  fpui-input-tags(
    :value="currentValue"
    :disabled="disabled"
    :label="label"
    :placeholder="$t('action.wizard.custom.requirements.placeholder')"
    @input="update"
    :helper="helper"
    :helper-title="helperTitle"
  )
</template>

<script>
import _get from 'lodash/get'
import valid from 'semver/functions/valid'
import coerce from 'semver/functions/coerce'

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    default: {
      type: String,
      default: null
    },
    value: { type: Object, default: () => ({}) },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    label: { type: String, default: '' },
    helper: { type: String, default: '' },
    helperTitle: { type: String, default: '' }
  },
  computed: {
    currentValue () {
      const values = []
      for (const key in this.value[this.type]) {
        if (!this.value[this.type][key]) {
          values.push(key)
        } else {
          values.push(key + ':' + this.value[this.type][key])
        }
      }
      return values
    }
  },
  created () {
    if (typeof (_get(this, `value.${this.type}`)) !== 'object') {
      let value
      if (typeof (this.value) !== 'object') value = {}
      else value = { ...value }
      value[this.type] = {}
      this.$emit('input', value)
    }
  },
  methods: {
    update (v) {
      const value = { ...this.value }
      value[this.type] = {}
      v.forEach(val => {
        const tmp = val.split(':')

        // To be able to add git+https://<access_token>@github.com/<myorg>/<myproject>.git in dependencies
        // See https://docs.forepaas.io/#/en/technical/sdk/dpe/3D.git
        let version = tmp.length > 1 ? tmp.pop() : 'null'
        if (version === 'null') version = null
        else if (valid(coerce(version))) version = valid(coerce(version))
        else {
          tmp.push(version)
          version = null
        }
        const key = tmp.join(':')

        value[this.type][key] = version || this.default
      })
      this.$emit('input', value)
    }
  }
}
</script>
