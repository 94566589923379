<script>
import { Multipane } from 'vue-multipane'
export default {
  extends: Multipane,
  watch: {
    isResizing (value) {
      this.$store.commit('SET_RESIZING', value)
      window.dispatchEvent(new Event('resize'))
    }
  }
}
</script>
