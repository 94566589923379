import Vue from 'vue'
import FpTab from './FpTab'
import FpTabIf from './FpTabIf'
import FpTabs from './FpTabs'


Vue.component('FpTabs', FpTabs)
Vue.component('FpTab', FpTab)
Vue.component('FpTabIf', FpTabIf)
Vue.component('FpuiTabs', FpTabs)
Vue.component('FpuiTab', FpTab)
Vue.component('FpuiTabIf', FpTabIf)
