<template lang="pug">
  .object(v-if="value && !loading")
    .fpui-input-label-container(
        v-if='label'
      )
        span {{ label | translate }}
        .required-container(v-if='required') *
        fpui-helper(v-if='helper', :text="helper | translate")
    .fpui-input-object-content
      .item(
        @mouseenter="hover=true",
        @mouseleave="hover=false",
        :style="CSSObject(options[key],'.item',{hover:hover})",
        :key="key",
        v-if="!displayOnly || options[key].type !== 'hidden' || !options[key].hidden"
        v-for="(v,key) in options"
      )
        component(
          :is="getComponent(v.type)"
          @input="(value)=>update(key,value)"
          :display-only="displayOnly"
          :value="getValue(key)"
          :disabled="disabled"
          :autoi18n='autoi18n'
          v-bind="options[key]"
          v-if="ifComponentWith(key)"
        )
</template>

<script>
import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'
export default {
  props: {
    displayOnly: { type: Boolean, default: false },
    value: { type: Object, default: () => {} },
    label: { type: String, default: '' },
    required: Boolean,
    helper: { type: String, default: '' },
    options: { type: Object, default: () => {} },
    autoi18n: Boolean, // Automatically translate tooltip & label
    disabled: Boolean
  },
  data () {
    return {
      hover: false,
      loading: true
    }
  },
  mounted () {
    if (!this.value) this.$emit('input', {})
    this.initDefaultValue()
    this.loading = false
  },
  methods: {
    getValue (key) {
      const field = this.options[key].field || key
      return _get(this.value, field)
    },
    initDefaultValue () {
      Object.keys(this.options).forEach(key => {
        const options = this.options[key]
        const field = options.field || key
        const value = _get(this.value, field)
        if (Object.keys(options).includes('default') && !value && value !== false) {
          this.update(key, options.default)
        }
      })
    },
    update (key, v) {
      const field = this.options[key].field || key
      const value = _cloneDeep(this.value.toJSON ? this.value.toJSON() : this.value)
      _set(value, field, v)
      this.$emit('input', value)
    },
    getComponent (type) {
      if (this.displayOnly && !['object', 'array'].includes(type)) {
        return 'auto-form-display'
      }
      return `auto-form-${type}`
    },
    ifComponentWith (key) {
      const opt = this.options[key]
      if (opt.if && typeof (opt.if) === 'function') {
        return opt.if(this.value)
      }
      if (opt.if && typeof (opt.if) === 'object') {
        // Get an array of matched result for every condition
        const conditionResult = Object.keys(opt.if).map((objectKey) => {
          const objectKeyValue = this.getValue(objectKey)
          return objectKeyValue === opt.if[objectKey]
        })
        // Only displayed if all conditions are met
        return !conditionResult.includes(false)
      }
      return true
    }
  }
}
</script>

<style lang="less">
  .item {
    margin-bottom:14px;

    .fpui-input-select-container .content .dropdown ul {
      max-height: 270px;
    }
  }
</style>
