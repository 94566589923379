<template lang='pug'>
  label.fpui-input-checkbox(
    :class='{checked:value, disabled:disabled}'
    @click="event=>$emit('click',event)"
  )
    input(
      type='checkbox'
      v-model='model'
      :disabled='disabled||readOnly'
    )
    span.fp-checkbox
      span.check(:class='color')
      span.content-checkbox
        slot
</template>

<script>

export default {
  props: {
    color: { type: String, default: 'blue' },
    value: { type: Boolean, required: true, default: false },
    disabled: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false }
  },
  computed: {
    model: {
      get () { return this.value },
      set (val) { this.$emit('input', val) }
    }
  }
}
</script>

<style lang='less'>
@import "~@/shared/styles/_variables.less";

.fpui-input-checkbox {
  box-sizing: border-box;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 0;
  transform: translateZ(0);
  // Hide native checkbox
  input[type=checkbox] {
    opacity: 0;
    position: absolute;
    margin: 0;
    z-index: -1;
    width: 0;
    height: 0;
    overflow: hidden;
    left: 0;
    pointer-events: none;
  }
  label {
    cursor: pointer;
    padding-left: 0;
  }
  .fp-checkbox {
    position: relative;
    height: 30px;
    display: block;
    top: 8px;
    span {
      display: inline-block;
      vertical-align: top;
      line-height: 18px;
      font-size: 14px;
      font-weight: 400;
      color: @text-color;
    }
    &:before {
      position: absolute;
      left: 8px;
      top: 2px;
      content: "";
      background-color: rgba(0,0,0,0.5);
      height: 10px;
      width: 4px;
      border-radius: 100%;
      z-index: 1;
      opacity: 0;
      margin: 0;
    }

    .check {
      cursor: pointer;
      position: relative;
      display: inline-block;
      width: 19px;
      height: 19px;
      border: 1px solid #E9ECF0;
      border-radius: 2px;
      overflow: hidden;
      z-index: 1;
      color: @blue_flash;
      box-shadow: 0 2px 3px 0 rgba(0,0,0,0.06);
      margin-right: 13px;
      &.green {
        color: @green;
        border-color: @green;
      }
      &.red {
        color: @red;
        border-color: @red;
      }
      &.yellow {
        color: @yellow;
        border-color: @yellow;
      }
    }
    .check:before {
      position: absolute;
      content: "";
      transform: rotate(45deg);
      display: block;
      margin-top: -3px;
      margin-left: 7px;
      width: 0;
      height: 0;
      // animation: checkbox-off 0.3s forwards ease-out;
    }
  }

  &.checked .blue {
    color: @blue_flash;
    border-color: @blue;
    box-shadow: unset;
  }

  input[type=checkbox]:focus + .fp-checkbox .check:after {
    opacity: 0.2;
  }
  input[type=checkbox]:checked + .fp-checkbox .check:before {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
    // animation: checkbox-on 0.3s forwards ease-out;
  }
  input[type=checkbox][disabled]:not(:checked) ~ .fp-checkbox .check:before, input[type=checkbox][disabled] + .circle {
    opacity: 0.5;
    // animation: checkbox-off 0.3s forwards ease-out;
  }
  input[type=checkbox][disabled] + .fp-checkbox .check:after {
    background-color: rgba(0,0,0,0.84);
    transform: rotate(-45deg);
  }
  &.disabled {
    .fp-checkbox {
      .check {
        cursor: not-allowed;
        color: grey;
      }
    }
  }
}

// Animations
@keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@keyframes checkbox-off {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }

  25% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  50% {
    transform: rotate(45deg);
    margin-top: -4px;
    margin-left: 6px;
    width: 0px;
    height: 0px;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
  }
  51% {
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0px 0px 0 10px inset;
  }
  100% {
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0px 0px 0 0px inset;
  }
}
</style>
