import { APIObject } from '@/shared/plugins/Api/API'
import _omit from 'lodash/omit'
import _set from 'lodash/set'

class Configuration extends APIObject {
  constructor (options) {
    super('IAM', options)

    this.certificate = this.certificate || {}
    this.password_policies = this.password_policies || {}
    this.session_lifetime = this.session_lifetime || ''
    this.cors = this.cors || {}
    this.cors.origins = this.cors.origins || []
    this.admin_contacts = this.admin_contacts || []
    this.apikey_lifetime = this.apikey_lifetime || ''
    this.anti_spam = this.anti_spam || {}
    this.anti_spam.suspend_on = this.anti_spam.suspend_on || {}
    this.anti_spam.suspend_on.failed_authentication = this.anti_spam.suspend_on.failed_authentication || false
    this.anti_spam.suspend_on.spam_password_renewal = this.anti_spam.suspend_on.spam_password_renewal || false
    this.anti_spam.attempts = this.anti_spam.attempts || {}
    this.anti_spam.attempts.period = this.anti_spam.attempts.period || '1h'
    this.anti_spam.attempts.number = this.anti_spam.attempts.number || 3
    this.anti_spam.notify_by_mail = this.anti_spam.notify_by_mail || false
    this.anti_spam.suspend_duration = this.anti_spam.suspend_duration || '5m'
  }

  async save () {
    return super.save({
      method: 'PUT',
      url: 'v4/configuration'
    })
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      '_id',
      'createdAt',
      'updatedAt',
      'createdBy',
      'updatedBy',
      'name'
    ])
    return obj
  }

  list () {
    return this.request({
      method: 'get',
      url: 'v4/configuration'
    })
      .then(data => {
        return new Configuration(data)
      })
  }

  async getCertificate () {
    return this.request({
      method: 'GET',
      url: 'v4/certificate'
    })
  }

  async updateCertificate () {
    return this.request({
      method: 'PUT',
      url: 'v4/certificate'
    })
  }

  async import (item) {
    return this.request({
      method: 'POST',
      url: 'v4/backup/import',
      data: item,
      timeout: 60 * 10 * 1000
    })
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Configuration(item)
  }
}

export default Configuration
export {
  Configuration
}
