import { API, APIObject } from '@/shared/plugins/Api/API'
import _cloneDeep from 'lodash/cloneDeep'
import _omit from 'lodash/omit'
import _set from 'lodash/set'
import _sortBy from 'lodash/sortBy'
import Certificate from '../FPAPI/Certificates'
import Vue from 'vue'
class Organization extends APIObject {
  constructor (options) {
    // Init
    super('ORGANIZATION', options, true)
    this.force_mfa = this.force_mfa || []
    this.subscription = null
    this.consumptions = null
    this.committeds = null
    this._id = options.id
  }

  get customerAccountId () {
    return this.subscription?.customer_account_id || null
  }

  async create (name) {
    return super.create({
      method: 'POST',
      url: 'v1/organizations',
      data: {
        name
      }
    })
  }

  async save () {
    return super.save({
      method: 'PUT',
      url: `v1/organizations/${this._id}`
    })
  }

  async remove () {
    return this.request({
      method: 'DELETE',
      url: `v1/organizations/${this._id}`
    })
  }

  async sendInvitation (email) {
    await this.request({
      method: 'POST',
      url: `v1/organizations/${this._id}/users/resend`,
      data: {
        email
      }
    })
  }

  async removeUser (email) {
    const response = await this.request({
      method: 'DELETE',
      url: `v1/organizations/${this._id}/users/${email}`
    })
    const users = this.accounts || this.users
    const idx = users.findIndex(usr => usr.email === email)
    if (idx === -1) return
    users.splice(idx, 1)
    return response
  }

  async loadSubscription (force = false) {
    if (!force && this.subscription) return this.subscription
    try {
      this.subscription = {}
      const subscription = await Vue.api.BILLING.loadSubscription(this._id)
      if (subscription.error) {
        throw new Error(subscription.error)
      }
      this.subscription = subscription
      return this.subscription
    } catch (err) {
      this.subscription = null
    }
  }

  async loadCommitteds (force = false) {
    if (!force && this.committeds) return this.committeds
    try {
      const committeds = await Vue.api.BILLING.loadCommitteds(this._id)
      if (committeds.error) {
        throw new Error(committeds.error)
      }
      this.committeds = committeds
      return this.committeds
    } catch (err) {
      this.committeds = null
      return this.committeds
    }
  }

  async createSubscription (data) {
    if (this.subscription) throw new Error('ALREADY_A_SUBSCRIPTION')
    this.subscription = await Vue.api.BILLING.createSubscription(this._id, data)
  }

  async updateSubscription (data) {
    this.subscription = await Vue.api.BILLING.updateSubscription(this._id, data)
  }

  async createTrialSubscription (data) {
    if (this.subscription) throw new Error('ALREADY_A_SUBSCRIPTION')
    this.subscription = await Vue.api.BILLING.createTrialSubscription(this._id, data)
  }

  async cancelSubscription () {
    await Vue.api.BILLING.cancelSubscription(this._id)
    await this.loadSubscription(true)
  }

  async reactivateSubscription () {
    await Vue.api.BILLING.reactivateSubscription(this._id)
    await this.loadSubscription(true)
  }

  async createCommitteds (data) {
    await Vue.api.BILLING.createCommitteds(this._id, data)
    await this.loadCommitteds(true)
  }

  async loadConsumptions () {
    if (this.consumptions) return
    try {
      this.consumptions = await Vue.api.BILLING.loadConsumptions(this._id)
    } catch (err) {
      this.consumptions = null
    }
  }

  async loadDomains () {
    try {
      this.authorized_domain = await this.request({
        method: 'GET',
        url: `v1/organizations/${this._id}/authorized_domain`
      })
    } catch (err) {
      this.authorized_domain = null
    }
  }

  async createDomain (domain) {
    await this.request({
      method: 'POST',
      url: `v1/organizations/${this._id}/authorized_domain`,
      data: {
        domain
      }
    })
    await this.loadDomains()
  }

  async removeDomain (domainId) {
    await this.request({
      method: 'DELETE',
      url: `v1/organizations/${this._id}/authorized_domain/${domainId}`
    })
    this.authorized_domain = this.authorized_domain.filter(d => d.domain !== domainId)
  }

  async loadCertificates () {
    try {
      const test = await this.request({
        method: 'GET',
        url: `v1/organizations/${this._id}/certificates`
      })
      this.certificates = test.map(t => {
        t.organizationId = this._id
        return new Certificate(t)
      })
    } catch (err) {
      this.certificates = null
    }
  }

  async editCertificate (certificate) {
    const res = await this.request({
      method: 'PUT',
      url: `v1/organizations/${this._id}/certificates/${certificate._id}`,
      data: certificate
    })
    return res
  }

  async toJSON () {
    _omit(this, ['subscription', 'consumptions'])
  }

  async sendDataplantRemovalEmail (dataplant) {
    const email = await this.request({
      method: 'POST',
      url: `v1/organizations/${this._id}/projects/send/remove`,
      data: {
        project: {
          id: dataplant.id,
          name: dataplant.name
        }
      }
    })
    return email
  }

  async getInvoices () {
    return await Vue.api.BILLING.getInvoices(this._id)
  }

  async editUser (user) {
    const res = await this.request({
      method: 'PUT',
      url: `v1/organizations/${this._id}/users/${user.email}`,
      data: user
    })
    const users = this.accounts || this.users
    const oldIdx = users.findIndex(u => u.email === user.email)
    if (oldIdx === -1) return res
    users.splice(oldIdx, 1, user)
    return res
  }

  async addUser (usersInfo) {
    const res = await this.request({
      method: 'POST',
      url: `v1/organizations/${this._id}/users`,
      data: usersInfo
    })
    usersInfo.emails.forEach(email => {
      const usr = _cloneDeep(usersInfo)
      usr.email = email
      delete usr.emails
      const users = this.accounts || this.users
      users.push(usr)
    })
    return res
  }

  async listRequests () {
    return await this.request({
      method: 'GET',
      url: `v1/organizations/${this._id}/users/requests`
    })
  }

  async addRequest () {
    return await this.request({
      method: 'PUT',
      url: `v1/organizations/${this._id}/users/request`
    })
  }

  async acceptJoin (user) {
    return await this.request({
      method: 'POST',
      url: `v1/organizations/${this._id}/users/request/${encodeURI(user.email)}`,
      data: user
    })
  }

  async deleteJoin (userId) {
    return await this.request({
      method: 'DELETE',
      url: `v1/organizations/${this._id}/users/request/${encodeURI(userId)}`
    })
  }
}

class Organizations extends API {
  constructor (ID) {
    super(ID, true)
  }

  async me () {
    return await this.request({
      method: 'GET',
      url: 'v1/organizations/me'
    })
  }

  async get (id) {
    const organization = await this.request({
      method: 'get',
      url: `v1/organizations/${id}`
    })
    return new Organization(organization)
  }

  async list () {
    const data = await this.request({
      method: 'GET',
      url: 'v1/organizations'
    })
    return _sortBy(data, 'name').map(organization => {
      return new Organization(_omit(organization, []))
    })
  }

  async listRequested () {
    const data = await this.request({
      method: 'GET',
      url: 'v1/organizations/requested'
    })
    return _sortBy(data, 'name').map(organization => {
      return new Organization(_omit(organization, []))
    })
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Organization(item)
  }

  async userJoins (organizationId, token) {
    return await this.request({
      method: 'GET',
      url: `v1/organizations/${organizationId}/users/join`,
      params: {
        tokenJoin: token
      }
    })
  }

  async userJoinsFromProfile (organizationId) {
    return await this.request({
      method: 'GET',
      url: `v1/organizations/${organizationId}/users/join_organization`
    })
  }

  async userRejectJoinFromProfile (organizationId) {
    return await this.request({
      method: 'GET',
      url: `v1/organizations/${organizationId}/users/reject_join_organization`
    })
  }

  async available () {
    const data = await this.request({
      method: 'GET',
      url: 'v1/organizations/me/available'
    })

    return _sortBy(data, 'name').map(organization => {
      return new Organization(_omit(organization, []))
    })
  }

  async invites () {
    const data = await this.request({
      method: 'GET',
      url: 'v1/organizations/me/invites'
    })

    return _sortBy(data, 'name').map(organization => {
      return new Organization(_omit(organization, []))
    })
  }
}

export default Organizations
export {
  Organization,
  Organizations
}
