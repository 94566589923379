<template lang="pug">
  .no-metrics-echarts
    .big(v-if="big")
      img(src="./no_metrics_to_display@2x.png")
      .text {{ $t(text || 'echarts.no-metrics-to-display') }}
      .info(v-if="info") {{ info }}
    .small(v-if="!big")
      img(src="./placeholder.png")
      .text(v-if="!daysInfo") {{ $t(text || 'echarts.no-activity') }}
      .info(
        v-if="daysInfo"
        v-html="$sanitize($t('echarts.no-activity-in-last-n-days', [daysInfo]))"
      )
</template>

<script>
export default {
  props: {
    text: { type: String, default: '' },
    info: { type: String, default: '' },
    big: { type: Boolean },
    daysInfo: { type: String, default: '' }
  }
}
</script>

<style lang="less">
  .no-metrics-echarts {
    width: 100%;
    height: 100%;
    text-align: center;

    .big {
      color: #3E4550;
      img {
        width: 360px;
      }
      .text {
        width: 100%;
        font-weight: bold;
        font-size: 32px;
      }
      .info {
        margin-top: -2px;
        font-size: 14px;
      }
    }

    .small {
      color: #97A7B7;
      font-weight: bold;
      font-size: 12px;
      img {
        width: 100px;
        margin-top: 25px;
      }
      .text {
        width: 100%;
        margin-top: 2px;
      }
      .info {
        margin-top: -2px;
      }
    }
  }
</style>
