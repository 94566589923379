import assistants from './assistants'
import knowledgeBases from './knowledgeBases'

export default {
  modules: { assistants, knowledgeBases },
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters: {
  }
}
