<template lang="pug">
.fp-container
  .fp-column
    .fp-box
      .box-title Box title
      fpui-input-label Empty
      fpui-input-text(
        v-model="emptyInput"
        placeholder='Text'
      )
      br
      fpui-input-label Filled
      fpui-input-text(
        v-model="filledInput"
        placeholder='Text'
      )
      br
      fpui-input-label Not edisable
      fpui-input-text(
        v-model="filledInput"
        placeholder='Text'
        disabled
      )
      br
      fpui-input-label Input select
      fpui-input-select(
        :options="options"
        v-model="inputSelect"
        placeholder='Text'
      )
      br
  .fp-column
    .fp-box
      .box-title Box title
      div
        fpui-button(color="green" :placeholder="'Text'") Button
      br
      div
        fpui-button(color="blue-flash" :placeholder="'Text'") Button
      br
      div
        fpui-button(color="white" :placeholder="'Text'") Button
      br
      div
        fpui-button.col-xs-3(color="white" autoWidth iconLeft icon="fpui fpui-plus" :placeholder="'Text'") Very long button label
      br
      fpui-input-checkbox(
        :value="true"
      ) Checkbox option
      fpui-input-checkbox(
        :value="false"
      ) Checkbox option
      br
      .icons
        i.fp4.fp4-trash-can(style="font-size: 24px;")
      br
      fpui-input-radio(:options="radioOptions" v-model="radio")
      br
</template>

<script>

export default {
  data () {
    return {
      emptyInput: '',
      filledInput: 'Filled',
      options: [{ label: 'Label', value: 'Value' }],
      inputSelect: '',
      checkbox: false,
      radio: false,
      radioOptions: [{ value: '', label: 'Radio border 3px looks very big' }, { value: '2', label: 'Short label' }]
    }
  },
  computed: {
  },
  mounted () {
  }
}
</script>

<style lang="less">

</style>
