<template lang='pug'>
  .dpe-code-editor
    .info(v-if="readOnly",v-tooltip="$t('action.readonly.helper.tooltip')") {{"action.readonly.helper" | translate}}
    iframe(:src="url" :style="style" ref="iframe")
</template>

<script>
import _uniqueId from 'lodash/uniqueId'
import _get from 'lodash/get'
import _debounce from 'lodash/debounce'
import qs from 'qs'

export default {
  props: {
    value: {
      required: true,
      type: [String, Object]
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    isJson: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      uniqueId: _uniqueId()
    }
  },
  computed: {
    style () {
      if (this.$store.getters.RESIZING) {
        return {
          'pointer-events': 'none'
        }
      }
      return {}
    },
    queryString () {
      return {
        uniqueId: this.uniqueId,
        lang: this.$i18n.locale(),
        language: this.isJson ? 'json' : 'python',
        readOnly: this.readOnly
      }
    },
    url () {
      return `vs/Iframe.html?${qs.stringify(this.queryString)}`
    }
  },
  watch: {
    value (val) {
      this.instantUpdate(val)
    },
    $route (val) {
      // Set a new uniqueId each time we change file to rerender the iframe (the uniqueId is used in the url of the iframe)
      // We do that because if only the content change, we have issue with the async between the update of the value here and the value of the iframe
      if (val.params.id) this.uniqueId = _uniqueId()
    }
  },
  created () {
    this.updateIframe = _debounce(this.updateIframe, 1000)
    window.addEventListener('message', (event) => {
      if (_get(event, 'data.type') !== 'MonacoIframe') return
      if (_get(event, 'data.id') !== this.uniqueId) return
      this[event.data.name](event.data)
    }, false)
  },
  methods: {
    focus () {
      this.$refs.iframe.contentWindow.focus()
      this.$refs.iframe.contentWindow.postMessage({ event: 'focus' }, window.location.origin)
    },
    updateIframe (val) {
      this.instantUpdate(val)
    },
    instantUpdate (val) {
      if (this.isJson) val = JSON.stringify(val, null, 2)
      if (this.$refs.iframe && this.$refs.iframe.contentWindow) {
        this.$refs.iframe.contentWindow.postMessage({ event: 'content', content: val }, window.location.origin)
      }
    },
    refresh () {
      let val = this.value
      if (this.isJson) val = JSON.stringify(val, null, 2)
      if (this.$refs.iframe && this.$refs.iframe.contentWindow) {
        this.$refs.iframe.contentWindow.postMessage({ event: 'content', content: val }, window.location.origin)
      }
    },
    ready () {
      this.instantUpdate(this.value)
    },
    input (event) {
      try {
        if (this.isJson) event.data = JSON.parse(event.data)
        this.$emit('input', event.data)
      } catch (error) {
        return null
      }
    }
  }
}
</script>

<style lang='less'>
@import "~@/shared/styles/_variables.less";
.dpe-code-editor {
  width: 100%;
  height: 100%;
  background-color: #3E4550;
  display:flex;
  flex-direction: column;
  .info {
    height: 30px;
    font-size: 13px;
    padding: 5px;
    text-align: center;
    background:@blue;
    color: white;
    width:100%;
  }
  iframe {
    width: 100%;
    border: 0;
    flex:1;
  }
}
</style>
