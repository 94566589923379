import Vue from 'vue'

Vue.directive('editContextContent', {
  bind (element, _binding, vnode) {
    element.contenteditable = false
    element.spellcheck = false
    element.addEventListener('click', function () {
      this.contentEditable = true
      this.classList.add('edit-content-active')
      this.focus()
    })
    element.addEventListener('focus', function () {
      document.execCommand('selectAll', false, null)
    })
    element.addEventListener('blur', function (event) {
      this.contentEditable = false
      this.classList.remove('edit-content-active')
      vnode.data.on.update(this.innerText)
    })
    element.addEventListener('keydown', function (event) {
      if (event.key === 'Enter') {
        this.contentEditable = false
        this.classList.remove('edit-content-active')
        vnode.data.on.update(this.innerText)
        this.blur()
      }
    })
  }
})
