import FpDimension from './FpDimension'
import get from 'lodash/get'

class TimeResult {
  constructor (opts) {
    this.name = opts.x
    this.value = [
      new Date(opts.x),
      opts.value
    ]
    this.tooltip = opts.tooltip.bind(opts)
  }
}

class PieResult {
  constructor (opts) {
    this.name = opts.name
    this.value = opts.value
    this.tooltip = opts.tooltip.bind(opts)
  }
}
class EchartResult {
  tooltip () {
    return this.serie.name + '<br>' + this.name + ' : ' + this.display
  }

  constructor (result, serie, xAxis, axisType, options) {
    this.name = []
    let scatterValues = []

    if (serie.type === 'scatter') {
      const xGetter = options?.scatterX?.type === 'data' ? `data.${options?.scatterX?.attribute}.${options?.scatterX?.compute}[0].value` : `scales.${options?.scatterX?.attribute}`
      const yGetter = options?.scatterY?.type === 'data' ? `data.${options?.scatterY?.attribute}.${options?.scatterY?.compute}[0].value` : `scales.${options?.scatterY?.attribute}`

      scatterValues = [get(result, xGetter), get(result, yGetter)]
      this.name = get(result, yGetter)
    }

    this.serie = serie
    this.xAxis = xAxis
    this.value = serie.type === 'scatter' ? scatterValues : get(result.data, `${serie.forepaas.data}.${serie.forepaas.computeMode}.${serie.forepaas.evol}.value`)
    this.display = serie.forepaas.data
    if (axisType === 'time') {
      this.x = xAxis.getDate(result.axis.x)
      this.name = xAxis.formatFromTimestamp(this.x)
      if (serie.type !== 'pie') return new TimeResult(this)
      if (serie.type === 'pie') return new PieResult(this)
    }

    if (serie.type !== 'scatter') {
      for (const scale in result.axis.x) {
        const name = new FpDimension(scale).formatFromScale(get(result.data, `${serie.forepaas.data}.${serie.forepaas.computeMode}.${serie.forepaas.evol}.${scale}`))
        this.name.push(name)
      }
      this.name = this.name.join(' ')
    }

    return {
      tooltip: this.tooltip.bind(this),
      name: this.name,
      value: this.value
    }
  }
}
export default EchartResult
