import { API, APIObject } from '@/shared/plugins/Api/API'
import _omit from 'lodash/omit'
import _set from 'lodash/set'

class Instance extends APIObject {
  constructor (options) {
    // Init
    super('DM', options)
  }

  _filter (object) {
    const obj = _omit(super._filter(object), [
      'created_at',
      'updated_at'
    ])
    return obj
  }
}

class Instances extends API {
  list () {
    return this.request({
      method: 'get',
      url: 'v4/instances'
    })
      .then(instances => {
        return instances.map(instance => {
          try {
            return new Instance(instance)
          } catch (err) {
            console.error(err.stack)
            return null
          }
        }).filter(i => i)
      })
  }

  new (queryString) {
    const item = {}
    for (const key in queryString) {
      _set(item, key, queryString[key])
    }
    return new Instance(item)
  }
}

export default Instances
export {
  Instance,
  Instances
}
