import moment from 'moment'
import Vue from 'vue'
import _groupBy from 'lodash/groupBy'

const sortDataplants = (a, b) => {
  if (a.display_name?.toLowerCase() < b.display_name?.toLowerCase()) return -1
  if (a.display_name?.toLowerCase() > b.display_name?.toLowerCase()) return 1
  return 0
}

export default {
  state: {
    dataplants: [],
    dataplantsRefreshedAt: {
    },
    providers: {},
    prices: {},
    offers: null
  },
  mutations: {
    SET_PRICES (state, prices) { // Not WS
      state.prices = prices
    },
    UPDATE_DATAPLANTS_BY_ORGANIZATION_ID (state, { organizationId, dataplants }) { // Not WS
      state.dataplants = state.dataplants.filter(dp => dp.organization_id !== organizationId)
      state.dataplants = state.dataplants.concat(dataplants)
      state.dataplants.sort(sortDataplants)
    },
    UPDATE_DATAPLANTS_BY_DATAPLANT_ID (state, { dataplantId, dataplant }) { // Not WS
      state.dataplants = state.dataplants.filter(dp => dp.id !== dataplantId)
      state.dataplants.push(dataplant)
      state.dataplants.sort(sortDataplants)
    },
    DELETE_DATAPLANT (state, dataplantId) { // Not WS
      state.dataplants = state.dataplants.filter(dp => dp.id !== dataplantId)
    },
    SET_OFFERS (state, offers) { // Not WS
      state.offers = offers
    }
  },
  actions: {
    async LOAD_OFFERS ({ commit }) {
      commit('SET_OFFERS', await Vue.api.FPAPI.offers())
    },
    async REFRESH_PROVIDERS_BY_ORGANIZATION ({ commit, state }, organizationId) {
      const providers = await Vue.api.FPAPI.configuration(organizationId)
      Vue.set(state.providers, organizationId, providers)
    },
    async REFRESH_DATAPLANT_BY_ORGANIZATION_ID ({ state, commit }, { organizationId, ignoreCache = false, lite = false }) {
      if ((!ignoreCache && !!state.dataplantsRefreshedAt[organizationId]) || !organizationId) return
      state.dataplantsRefreshedAt[organizationId] = moment()
      commit('UPDATE_DATAPLANTS_BY_ORGANIZATION_ID', { organizationId, dataplants: await Vue.api.FPAPI.dataplants.list(organizationId, lite) })
    },
    async REFRESH_DATAPLANT_FOR_ALL_ORGANIZATIONS ({ state, commit }, { organizationToNotGet = [], lite = false }) {
      const res = await Vue.api.FPAPI.dataplants.list(null, lite)
      const resByOrganization = _groupBy(res, 'organization_id')
      Object.keys(resByOrganization).forEach(organizationId => {
        if (!organizationToNotGet.includes(organizationId) && !state.dataplantsRefreshedAt[organizationId]) {
          state.dataplantsRefreshedAt[organizationId] = moment()
          commit('UPDATE_DATAPLANTS_BY_ORGANIZATION_ID', { organizationId, dataplants: resByOrganization[organizationId] })
        }
      })
    },
    async REFRESH_DATAPLANT_BY_DATAPLANT_ID ({ state, commit }, { dataplantId, ignoreCache = false }) {
      if (!ignoreCache && !!state.dataplantsRefreshedAt[dataplantId]) return
      state.dataplantsRefreshedAt[dataplantId] = moment()
      commit('UPDATE_DATAPLANTS_BY_DATAPLANT_ID', { dataplantId, dataplant: await Vue.api.FPAPI.dataplants.findOne(dataplantId) })
    },
    async REFRESH_DATAPLANT_BY_DATAPLANT_ID_CASCADE ({ state, commit, dispatch }, { dataplantId, ignoreCache = false }) {
      await dispatch('REFRESH_DATAPLANT_BY_DATAPLANT_ID', { dataplantId, ignoreCache })
      const dp = state.dataplants.find(dataplant => dataplant.id === dataplantId)
      if (!dp) return null
      await dispatch('REFRESH_DATAPLANT_BY_ORGANIZATION_ID', { organizationId: dp.organization_id, ignoreCache })
    },
    async LOAD_PRICES ({ commit }) {
      commit('SET_PRICES', await Vue.api.FPAPI.prices.list())
    }
  },
  getters: {
    OFFERS (state) {
      return state.offers
    },
    DATAPLANTS (state) {
      return state.dataplants
    },
    DATAPLANT_BY_ID (state) {
      return (id) => {
        return state.dataplants.find(dataplant => dataplant.id === id)
      }
    },
    DATAPLANTS_BY_ORGANIZATION_ID (state) {
      return (organizationId) => {
        return state.dataplants.filter(dataplant => dataplant.organization_id === organizationId)
      }
    },
    PROVIDERS_BY_ORGANIZATION_ID (state) {
      return (organizationId) => {
        return state.providers[organizationId]
      }
    },
    PRICES (state) {
      return state.prices
    },
    ADDON_BY_ID (state) {
      return (addonId) => {
        return state.prices.addons.find(a => a.id === addonId)
      }
    },
    COUPON_BY_ID (state) {
      return (couponId) => {
        return state.prices.coupons.find(c => c.id === couponId)
      }
    },
    PLAN_BY_ID (state) {
      return (planId) => {
        return state.prices.plans.find(p => p.id === planId)
      }
    }
  }
}
