<template lang='pug'>
  .fp-aside(ref="aside")
    .sidebar-content.aside-full(ref='itemListWithSection')
      template(v-if="isAsideWithSection")
        .aside-full-content-with-sections-wrapper
          .aside-full-content-with-sections(
            v-for="(value, key) in asideList"
            :key="`aside-sec-${key}`"
          )
            ul.nav-section(
              v-if="key !== 'noSection'"
              @click="toggleSectionExpandAndCollapse(key)"
              :class="{ 'active-section' : isSectionHasActiveAside(value) }"
            )
              li {{ $t(`aside.section.${key}`).toUpperCase() }}
              .arrow
                i.fp4.fp4-angle-up(v-if="sectionIsOpenList[key]")
                i.fp4.fp4-angle-down(v-if="!sectionIsOpenList[key]")
            ul.nav.nav-sidebar(
              :class='color'
            )
              //- for the aside that has no section above
              template(
                v-if="key === 'noSection'"
              )
                fp-aside-item(
                  v-for='val, key in value'
                  :key='key'
                  :id='key'
                  :dataplant-id="dataplantId"
                  :menutype="menutype"
                  :val="val"
                )
              //- for the aside that has a section
              template(v-else)
                transition-group(name="slide-fade")
                  .fp-aside-item-wrapper(v-show="sectionIsOpenList[key]" :key="'nav-section'")
                    fp-aside-item(
                      v-for='val, key in value'
                      :key='key'
                      :id='key'
                      :dataplant-id="dataplantId"
                      :menutype="menutype"
                      :val="val"
                      :section-is-open-list="sectionIsOpenList"
                      :callback="showAside"
                    )
          span.aside-animated-border(
            v-show='showAnimatedBorder'
            ref='animatedBorderWithSection'
            :class='color'
          )
      template(v-else)
        .aside-full-content.aside-without-section
          ul.nav.nav-sidebar(:class='color', ref='itemList')
            fp-aside-item(
              v-for='val, key in asideFiltered'
              :key='key',
              :id='key',
              :dataplant-id="dataplantId"
              :menutype="menutype"
              :val="val"
            )
            span.aside-animated-border(
              v-show='showAnimatedBorder'
              ref='animatedBorder'
              :class='color'
            )
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import FpAsideItem from './FpAsideItem'

export default {
  components: {
    FpAsideItem
  },
  props: {
    dataplantId: { type: String, default: '' },
    color: { type: String, default: 'blue' },
    menutype: { type: String, default: '' },
    aside: { type: Object, required: true }
  },
  data () {
    return {
      mouseOver: false,
      sectionIsOpenList: {},
      asideFiltered: {},
      asideList: {}
    }
  },
  computed: {
    showAnimatedBorder () {
      let countActive = this.getActiveAside().length
      if (this.isAsideWithSection) {
        // Check if there is a hidden section
        const isSectionHidden = Object.keys(this.sectionIsOpenList).filter(sec => !this.sectionIsOpenList[sec])
        if (isSectionHidden) {
          // If active aside is in a hidden section, hide the active aside border style
          for (const sectionName of isSectionHidden) {
            const AsideNameList = Object.keys(this.asideList[sectionName])
            const isActiveSrviceHidden = AsideNameList.filter(aside => this.isActive(this.asideList[sectionName][aside]))
            if (isActiveSrviceHidden?.length) {
              countActive = 0
              break
            }
          }
        }
      }
      return countActive > 0
    },
    comingSoonImage () {
      return require('./aside-coming-soon.png')
    },
    // Check if the aside is with sections or without sections
    isAsideWithSection () {
      return Object.values(this.aside).find(asd => asd.section)
    }
  },
  watch: {
    $route () {
      process.nextTick(() => this.setBorder())
    }
  },
  async mounted () {
    // With condition in showing and enable it takes
    // more time to process, so keep the setTimeout
    const interval = setInterval(this.setBorder, 100)
    setTimeout(() => clearInterval(interval), 2000)

    const asideList = {}
    const asideFiltered = _cloneDeep(this.aside)
    for (const [key, value] of Object.entries(asideFiltered)) {
      const check = await this.show(value)
      if (!check) delete asideFiltered[key]
      else {
        const sectionName = value.section
        // Create a new aside object for the aside with sections
        if (sectionName) {
          this.$set(this.sectionIsOpenList, [sectionName], true)

          if (asideList[sectionName]) asideList[sectionName] = { ...asideList[sectionName], [key]: value }
          else asideList[sectionName] = { [key]: value }
        } else {
          asideList.noSection = { [key]: value }
        }
      }
    }
    this.asideFiltered = asideFiltered
    this.asideList = asideList
  },
  methods: {
    // If active aside is in a hidden section, hide the active aside style
    showAside (asideItemValue) {
      const sectionHiddenList = Object.keys(this.sectionIsOpenList).filter(sec => this.sectionIsOpenList[sec] === false)
      if (this.isAsideWithSection && sectionHiddenList) {
        for (const sectionName of sectionHiddenList) {
          for (const key of Object.keys(this.asideList[sectionName])) {
            if (this.asideList[sectionName][key].url === asideItemValue.url) {
              return false
            }
          }
        }
        return true
      }
    },
    toggleSectionExpandAndCollapse (sectionKey) {
      this.$set(this.sectionIsOpenList, sectionKey, !this.sectionIsOpenList[sectionKey])
      this.setBorder()
    },
    async show (val) {
      if (typeof val.showing === 'function') {
        return await val.showing.call(this)
      }
      if (typeof val.showing !== 'undefined') {
        return val.showing
      }
      return true
    },
    isLoading (val) {
      if (typeof val.loading === 'function') return val.loading.call(this)
      if (val.loading) { return true }
      return false
    },
    addTooltip (val) {
      if (typeof val.addTooltip === 'function') return this.$t(val.addTooltip.call(this))
      return this.$t(val.addTooltip)
    },
    addAside (val) {
      return typeof val.add === 'function' && val.add.call(this)
    },
    getDisableClass (val) {
      if (val.disable || val.soon) return 'disable events-pointer'
      return ''
    },
    getItemClass (val) {
      let ret = ''
      if (this.isActive(val)) { ret += ' active' }
      if (val.hidden) { ret += ' hidden' }
      return ret
    },
    getMenuName (key) {
      const lckey = key.toLowerCase()
      if (this.menutype) {
        return this.$t(`aside.${this.menutype}.${lckey}`, { default: key })
      } else {
        return this.$t(`aside.${lckey}`, { default: key })
      }
    },
    isActive (val) {
      if (!val.url) { return false }
      const path = this.$route.fullPath.split('?')[0]
      if (val.url && (`/${this.dataplantId}${val.url}` === path || `/${this.dataplantId}${val.url}` === path + '/')) return true
      if (val.regexp && path.match(val.regexp)) return true
      return false
    },
    setBorder () {
      // Need setTimeout to wait the aside UI change
      setTimeout(() => {
        const asideRef = this.isAsideWithSection ? this.$refs.itemListWithSection : this.$refs.itemList
        const animatedBorderRef = this.isAsideWithSection ? this.$refs.animatedBorderWithSection : this.$refs.animatedBorder
        if (asideRef && animatedBorderRef) {
          const activeItem = asideRef.querySelector('.aside-item.active')
          const scrollTop = this.$refs.itemListWithSection.scrollTop
          if (activeItem) {
            const activeItemInfo = activeItem.getBoundingClientRect()
            const asideInfo = this.$refs.aside.getBoundingClientRect()
            const top = activeItemInfo.top - asideInfo.top + scrollTop // Need to add scroll Top for the case when aside height is longer than screen size
            const height = activeItemInfo.height
            animatedBorderRef.style.top = `${top}px`
            animatedBorderRef.style.bottom = `${height}px`
          }
        }
      }, 30)
    },
    getActiveAside () {
      return Object.keys(this.asideFiltered).filter(key => {
        return this.isActive(this.asideFiltered[key])
      })
    },
    // If there is a active aside inside of the section, make the section letter blue
    isSectionHasActiveAside (section) {
      const [activeAsideName] = this.getActiveAside()
      return Object.keys(section).includes(activeAsideName)
    }
  }
}
</script>

<style lang='less'>
@import "~@/shared/styles/_variables.less";

.sidebar-content {
  width: 124px;
  transition:0.3s all;
  background-color: @background-color_aside;
  position: absolute;
  bottom: 0;
  top:0;
  padding: 0 0 2px 0;
  left: 0;
  right: 0;
  overflow: auto;
  overflow: overlay;
  .nav-section {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    border-top: 2px solid #E4E7EC;
    border-bottom: 2px solid #E4E7EC;
    li {
      color: #97A7B7;
      text-align: center;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      height: 24px;
      width: 84px;
    }
    &.active-section {
      i,li {
        color: @blue !important;
      }
    }
    .arrow {
      .fp4 {
        padding-left: 2px;
        color: #97A7B7;
        width: 16px;
        height: 16px;
      }
    }
    &:hover {
      li {
        cursor: pointer;
        color: @blue;
      }
      .arrow {
        .fp4 {
          color: @blue !important;
        }
      }
    }
  }
  .aside-item {
    width: 100%;
    height: 86px;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    position: relative;
    display: block;
    + .aside-item {
      border-top: 1px solid #E9EDF2;
    }
    &.active {
      background: @white;
      border-top: 0px;
      + .aside-item {
        border-top: 0px
      }
      box-shadow: 0 3px 13px 0 rgba(151,167,183,0.3);
      a {
        i,span {
          color: @blue;
        }
      }
    }
    .coming-soon-overlay {
      position: absolute;
      span {
        position: absolute;
        left: 0;
        bottom: 12px;
        color: white;
        font-size: 10px;
        font-weight: bold;
        transform-origin: 0 0;
        transform: rotate(-45deg);
        letter-spacing: 1;
        &.fr {
          left: -2px;
          bottom: 10px;
        }
      }
      > img {
        height: 45px;
      }
    }
    a {
      font-size: 12px;
      line-height: 17px;
      padding-bottom: 12px;
      padding-top: 9px;
      display: block;
      height: 86px;
      &:hover {
        i, span {
          color: @blue;
        }
      }
      position: relative;
      i {
        display: block;
        font-size: 190%;
        color: @grey;
        cursor: pointer;
        transition: 0.3 all;
        margin-top: 0;
        margin-bottom: 0;
        &.fp4 {
          font-size: 44px;
        }
      }
      span {
        opacity: 1;
        text-align: center;
        font-size: 11px;
        color: @grey;
        font-weight: 600;
        cursor: pointer;
        height: auto;
        line-height: normal;
        max-height: 200px;
        transition: 0.3 all;
        display: block;
        hyphens: auto;
      }
      img {
        position: absolute;
        left: 0px;
        top: 0px;
      }
    }
  }
  ul {
    margin: 0;
  }
  .aside-without-section, .aside-full-content-with-sections-wrapper {
    box-shadow: 0 3px 13px 0 rgba(151,167,183,0.3);
  }
  // Add margin to show box shadow below aside
  .aside-full-content-with-sections-wrapper{
    margin-bottom: 10px;
  }
  .aside-animated-border {
    position: absolute;
    width: 4px;
    min-height: 45px;
    height: 86px;
    background-color: @blue;
    right: 0;
    transition: bottom 300ms ease-out, top 250ms ease-in 50ms;
  }
}

@media @mobile {
  .sidebar {
    display: none;
  }
}

.nav-sidebar {
  padding: 0;
  a.disable {
    color:#bbb!important;
    cursor: not-allowed!important;
    opacity: 0.5;
    i,span {
      cursor: not-allowed!important;
      color:#bbb!important
    }
  }
}

main {
  &.asided {
    left: 124px;
  }
}
.fp-aside {
  .aside-full {
    .events-pointer {
      pointer-events: none;
    }
    a:hover, a:focus, a:active {
      text-decoration: none;
      color: inherit;
    }
  }
  .hidden {
    display: none;
  }
}
</style>
